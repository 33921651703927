import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../context/authContext';
import SignRoutes from './SignRoutes';
import OtherRoutes from './OtherRoutes';
import DigitalFolderRoutes from './DigitalFolderRoutes';

const Routes: React.FC = () => {
  const location = useLocation();
  const { signed } = useContext(AuthContext);

  if (location.pathname.split('/')[1] === 'digital_folder') {
    return <DigitalFolderRoutes />;
  }

  return signed ? <OtherRoutes /> : <SignRoutes />;
};

export default Routes;
