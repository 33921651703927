import { HTMLAttributes } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Tooltip } from '../Tooltip';

interface HeaderProps extends HTMLAttributes<HTMLSpanElement> {
  text: string;
  regular?: boolean;
  tooltip?: string;
}

export function Header(props: HeaderProps) {
  const renderTooltip = () => {
    return (
      <Tooltip placement='right-start' title={props.tooltip}>
        <FaRegQuestionCircle />
      </Tooltip>
    );
  };

  return (
    <div className={ `${styles.container} ${props.regular && styles.regular}` }>
      <span
        {...props}
        className={`${styles.title} ${props.className}`}
      >
          {props.text}
      </span>
      {props.tooltip && renderTooltip()}
    </div>
  );
}
