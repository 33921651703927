import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputText } from '../../../../components/InputText';
import { TextArea } from '../../../../components/TextArea';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { RadioGroup } from '../../../../components/RadioGroup';
import FormHelper from '../../../../helpers/form';
import { ProcessType } from '../../../../helpers/enums';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Select from '../../../../components/Select';

type NewIntimationProps = {
  fluxId?: number;
  closeModal: () => void;
};

export function NewIntimation(props: NewIntimationProps) {
  const { addToasts } = useContext(ProcedureActionsContext);

  const [subjectUnified, setSubjectUnified] = useState([]);
  const [competenceType, setCompetenceType] = useState([]);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    const result = ProcedureBoxService.getNewProcedureIntimationData();
    result.then((resp) => {
      setCompetenceType(resp.data.competence_type);
      setSubjectUnified(resp.data.subject_unified_process_cnj_id);
    });
    setValue('process_type', 'citation');
  }, []);

  const submit = (form: any) => {
    setLoading(true);
    const data = { intimations: { ...form }, flux_id: props.fluxId };
    ProcedureBoxService.createProcedureIntimation(data)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();

          addToasts({
            type: 'success',
            text: FormHelper.customToastMessage(res.id),
          });
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(submit)}>
      <Header text={t('procedureBox.newProcedure.newIntimation.form.data')} />
      <Grid container columns={{ xs: 1, sm: 10, md: 12 }}>
        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newIntimation.form.processNumber.label')}
                placeholder={t('procedureBox.newProcedure.newIntimation.form.processNumber.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('process_number', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="fiscal_year"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newIntimation.form.exercise.label')}
                placeholder={t('procedureBox.newProcedure.newIntimation.form.exercise.placeholder')}
                maxLength={4}
              />
            )}
          />
          {FormHelper.renderErrorField('fiscal_year', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="competence_type"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newIntimation.form.competition_id.label')}
                placeholder={t('procedureBox.newProcedure.newIntimation.form.competition_id.placeholder')}
                options={competenceType}
                returnValue
                required
              />
            )}
          />
          {FormHelper.renderErrorField('competence_type', errors)}
        </Grid>

        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="observations"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('procedureBox.newProcedure.newIntimation.form.observation.label')}
                placeholder={t('procedureBox.newProcedure.newIntimation.form.observation.placeholder')}
              />
            )}
          />
          {FormHelper.renderErrorField('observations', errors)}
        </Grid>

        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('procedureBox.newProcedure.newIntimation.form.description.label')}
                placeholder={t('procedureBox.newProcedure.newIntimation.form.description.placeholder')}
              />
            )}
          />
          {FormHelper.renderErrorField('description', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="subject_unified_process_cnj_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newIntimation.form.subject_title_id.label')}
                placeholder={t('procedureBox.newProcedure.newIntimation.form.subject_title_id.placeholder')}
                options={subjectUnified}
                returnValue
                required
              />
            )}
          />
          {FormHelper.renderErrorField('subject_unified_process_cnj_id', errors)}
        </Grid>

        <Grid item className={styles.filterItem} xs={12} md={4} sm={4}>
          <Controller
            control={control}
            name="process_type"
            render={({ field }) => (
              <RadioGroup
                {...field}
                label={t('procedureBox.newProcedure.newIntimation.form.process_type.label')}
                options={ProcessType()}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className={styles.submit}>
        <Button
          title={t('procedureBox.newProcedure.submit')}
          textCenter
          round
          buttonType="primary"
          size="flat"
          type="submit"
          disabled={loading}
        />
      </div>
    </form>
  );
}
