import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import { truncate } from '../../../helpers/strings';
import { Tooltip } from '../../Tooltip';
import { GenericMarkerTab, MarkerPendingTab, MarkerPriorityTab } from '../../../@types/tab';

type MarkerItemProps = {
  value: MarkerPriorityTab | MarkerPendingTab | GenericMarkerTab;
  onSelect: () => void;
};

export function MarkerItem(props: MarkerItemProps) {
  const { t } = useTranslation();
  const getName = (marker: any) => {
    if (marker.marker_priority) {
      return `${t(`procedureBox.markerMenu.${marker.marker_priority}`)}`;
    }
    if (marker.marker_pending) {
      return `${t(`procedureBox.markerMenu.${marker.marker_pending}`)}`;
    }
    return marker.label;
  };

  return (
    <div
    className={`${styles.fluxItemContainer} ${props.value.selected ? styles.selected : ''}`}
    onClick={props.onSelect}
      >
      <div className={`${styles.name} ${props.value.selected ? styles.selected : ''}`}>
        <Tooltip title={getName(props.value)} placement='top-end'>
          <p>{truncate(getName(props.value).replace('/', ' '), 20)}</p>
        </Tooltip>
      </div>
      <p className={styles.total} >{props.value.total}</p>
      {
      props.value.was_not_listed > 0 ? (
        <p className={`${styles.unread} ${props.value.selected ? styles.hidden : ''}`} >
          {`${t(
            'procedureBox.filterTabs.unreadProcedure',
            { value: props.value.was_not_listed, plural: props.value.was_not_listed === 1 ? '' : 's' },
          )}`
          }
        </p>
      ) : (
        <p className={`${styles.unread} ${styles.empty}`} ></p>
      )
    } </div>
  );
}
