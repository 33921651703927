import { useEffect } from 'react';

import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type NewContributorProps = {
  title?: string;
};

export function NewContributor(props: NewContributorProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/contribuintes`}/>
  );
}
