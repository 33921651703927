import styles from './styles.module.scss';

type CardInfosProps = {
  title: string;
  content: string;
};

export function CardInfos(props: CardInfosProps) {
  const renderExpanded = () => {
    return (
      <div className={ styles.expanded }>
        <div className={ styles.header }>
          <span>
            {props.title}
          </span>
        </div>
        <span className={ styles.emptyText }>
          {props.content}
        </span>
      </div>
    );
  };

  return renderExpanded();
}
