import { useContext } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { FaKey, FaShoePrints, FaRegCalendarAlt } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Movement } from '../../../../@types/pje';
import { Table } from '../../../../components/Table';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';

interface MovementModalProps {
  handleCloseModal: () => void;
  movements: Movement[];
}

export function MovementsModal(props: MovementModalProps) {
  const keyI18n = 'procedureBox.processExtract.pjeExtract.movement';
  const { proceduresSeleted } = useContext(ProcedureActionsContext);

  const showAttribute = (text: string, icon: any) => {
    return (
      <div className={styles.showAttribute}>
        {icon}
        {text}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>
          <Trans
            i18nKey={`${keyI18n}.modal.selectedProcedure`}
            components={{ bold: <strong /> }}
            values={{ number: proceduresSeleted[0].process_number, flux: proceduresSeleted[0].flux.name }}
          />
        </span>
      </div>

      <div className={styles.header}>
        <span className={styles.title}>
          <Trans
            i18nKey={`${keyI18n}.modal.countMovement`}
            components={{ bold: <strong /> }}
            values={{ count: props.movements.length }}
          />
        </span>
      </div>

      <Table>
        <thead>
          <tr>
            <th>{t(`${keyI18n}.table.id`)}</th>
            <th>{t(`${keyI18n}.table.dateMovement`)}</th>
            <th>{t(`${keyI18n}.table.description`)}</th>
            <th>{t(`${keyI18n}.table.dateConsult`)}</th>
          </tr>
        </thead>
        {
          props.movements.map((movement) => (
            <tbody>
              <td>
                {showAttribute(movement.code, <FaKey />)}
              </td>
              <td>
                {showAttribute(movement.moviment_date, <FaRegCalendarAlt />)}
              </td>
              <td>
                {showAttribute(movement.description, <FaShoePrints />)}
              </td>
              <td>
                {showAttribute(movement.consult_date, <FaRegCalendarAlt />)}
              </td>
            </tbody>
          ))
        }
      </Table>
    </div>
  );
}
