import { useEffect, useState, useContext } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { SelectedProcedures } from '../SelectedProcedures';
import { Button } from '../Button';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import styles from './styles.module.scss';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { TextArea } from '../TextArea';

type ForceTramitProps = {
  reasonValue: string | undefined;
  multipleActions?: MultipleActionStep;
};

export function ForceTramit(props: ForceTramitProps) {
  const {
    proceduresSeleted, setAlert, removeProcedures, setModal,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const [disabledButton, setDisabledButton] = useState(true);
  const [reasonValue, setReasonValue] = useState('');

  const handleReason = (value: string) => {
    setReasonValue(value);
  };

  const handleConfirm = () => {
    const body = {
      reasonValue,
    };

    const procedures = proceduresSeleted;
    const type = 'forceTramit';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };

    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
    setAlert(undefined);
    setModal(undefined);
  };

  const handleAlert = () => {
    setModal(undefined);

    setAlert({
      handleConfirm: () => handleConfirm(),
      visible: true,
      title:
        proceduresSeleted.length > 1
          ? t('procedureBox.actions.forceTramit.modal.modal_alert.procedures.title', { total_procedures: proceduresSeleted.length })
          : t('procedureBox.actions.forceTramit.modal.modal_alert.procedure.title', { procedure_number: proceduresSeleted[0].process_number }),
      text:
        proceduresSeleted.length > 1 ? (
          <Trans
            i18nKey={
              'procedureBox.actions.forceTramit.modal.modal_alert.procedures.text'
            }
            components={{
              a: <a />,
              total_procedures: proceduresSeleted.length,
            }}
          />
        ) : (
          <Trans
            i18nKey={
              'procedureBox.actions.forceTramit.modal.modal_alert.procedure.text'
            }
            components={{
              a: <a />,
              procedure_number: proceduresSeleted[0].process_number,
            }}
          />
        ),
      type: 'warning',
      confirmText: t('procedureBox.actions.forceTramit.modal.modal_alert.buttonConfirm'),
      cancelText: t('procedureBox.actions.forceTramit.modal.modal_alert.buttonCancel'),
    });
  };

  const getValues = () => {
    if (props.reasonValue) {
      setReasonValue(props.reasonValue);
    }
  };

  useEffect(() => {
    getValues();
  }, []);

  useEffect(() => {
    if (reasonValue.length > 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [reasonValue]);

  return (
    <div className={styles.content}>
      <SelectedProcedures />
      <div className={styles.infos}>
        <span>{t('procedureBox.actions.forceTramit.modal.infos')}</span>
      </div>
      <div className={styles.fieldText}>
        <TextArea
          label={t('procedureBox.actions.forceTramit.modal.textLabel')}
          placeholder={t('procedureBox.actions.forceTramit.modal.textPlaceholder')}
          onChange={(event) => handleReason(event.target.value)}
          value={reasonValue}
          required
        />
      </div>
      <div className={styles.button}>
        <Button
          title={t('procedureBox.actions.forceTramit.modal.buttonText')}
          disabled={disabledButton}
          buttonType='primary'
          size='flat'
          round
          onClick={() => handleAlert()}
        />
      </div>
    </div>
  );
}
