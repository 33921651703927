import moment from 'moment';
import { FaCheckDouble } from 'react-icons/fa';
import { useContext, useState } from 'react';
import { NotificationType } from '../../../@types/home';
import styles from './styles.module.scss';
import GeneralService from '../../../services/generalService';
import { HomeContext } from '../../../context/homeContext';
import Show from '../../Show';

type ItemProps = {
  notification: NotificationType;
  hideAction?: boolean;
};

export function Item({ notification, hideAction }: ItemProps) {
  const [read, setRead] = useState(notification.read);
  const { notificationsCount, setNotificationsCount } = useContext(HomeContext);

  const changeRead = () => {
    const updatedCount = read ? notificationsCount + 1 : notificationsCount - 1;
    GeneralService.updateNotifications(notification.id, !read);
    setNotificationsCount(updatedCount);
    setRead(!read);
  };

  const renderCheck = () => {
    if (read) {
      return <FaCheckDouble onClick={changeRead} className={styles.check} />;
    }
    return <FaCheckDouble onClick={changeRead} className={styles.read} />;
  };
  return (
    <div className={ `${styles.container} ${read ? styles.seen : styles.notSeen}` }>
      <span className={ styles.message } dangerouslySetInnerHTML={{ __html: notification.message }}>
      </span>
      <div className={styles.details}>
        <span className={ styles.created_at }>
          {moment(notification.created_at).format('DD MMM')}
        </span>
        <Show if={!hideAction}>
          {renderCheck()}
        </Show>
      </div>
    </div>
  );
}
