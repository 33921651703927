import { useEffect } from 'react';
import styles from './styles.module.scss';
import { EPGM_URL } from '../../config/constants';
import { loadTitle } from '../../helpers/changeTitle';

type NewProcedureProps = {
  title?: string;
};

export function NewProcedure(props: NewProcedureProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <div className={ styles.content }>
      <iframe
        src={`${EPGM_URL}/processos_digitais/processos?agilis=true`}
        className={styles.iframe}
        allowTransparency
        loading="lazy"/>
    </div>
  );
}
