import { FaCheckCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { QuantityPerFlux } from '../../../@types/digitalProcesses/fluxes';
import styles from './styles.module.scss';
import { Flux, Procedure } from '../../../@types/model';

interface ProcessTypeProps {
  procedures: Procedure[];
  headerQuantity: (quantity: number) => void;
  setFlux: (id: number) => void
  fluxSelectedQuantity: QuantityPerFlux[] | undefined | any;
  componentLocation: string;
}

export function ProcessTypeHeader(props: ProcessTypeProps) {
  const [fluxSelected, setFluxSelected] = useState(0);

  const { procedures } = props;
  const fluxes: Flux[] = procedures.reduce((uniqueFluxes: Flux[], procedure) => {
    if (!uniqueFluxes.some((flux) => flux.id === procedure.flux.id)) {
      uniqueFluxes.push(procedure.flux);
    }
    return uniqueFluxes;
  }, []);

  useEffect(() => {
    setFluxSelected(procedures[0].flux.id);
  }, []);

  const setFluxId = (id: number) => {
    props.setFlux(id);
  };

  const showCounterUpdated = (objectId: number) => {
    if (props.componentLocation.includes('manual')) {
      const selecteds = props.fluxSelectedQuantity.filter((item: any) => item.fluxId === objectId);
      return selecteds.length;
    }

    const fluxQuantityCounter = props.fluxSelectedQuantity?.filter((data: any) => data.fluxId === objectId);
    const totalAmount = fluxQuantityCounter?.reduce((value: any, newvalue: any) => { return value + newvalue.quantity; }, 0);
    return fluxQuantityCounter?.length !== 0 ? totalAmount : fluxQuantityCounter[0] ? fluxQuantityCounter[0] : 0;
  };

  const checkTotalValue = (flux: number, total: number) => {
    if (props.componentLocation.includes('manual')) {
      const proceduresSelected = props.fluxSelectedQuantity.filter((item: any) => item.flux === flux);
      return (
        proceduresSelected.length === total && <FaCheckCircle className={styles.checkIcon}/>
      );
    }

    return (
      props.fluxSelectedQuantity?.filter((e: any) => e.fluxId === flux).length !== 0
        ? ((props.fluxSelectedQuantity?.filter((data: any) => data.fluxId === flux))
          ?.reduce((value: any, newvalue: { quantity: any; }) => value + newvalue.quantity, 0) === total)
          && <FaCheckCircle className={styles.checkIcon}/>
        : <></>
    );
  };

  const renderHeader = () => {
    return (
      fluxes.map((flux) => {
        const total = procedures.filter((e) => e.flux.id === flux.id).length;
        props.headerQuantity(fluxes.length);
        return (
          <div className={styles.headerProcess} onClick={() => setFluxSelected(flux.id)}>
            <div className={fluxSelected === flux.id ? styles.processFluxSelected : styles.processFlux}>
              {fluxSelected === flux.id && setFluxId(flux.id)}
              {flux.name}
              ({props.fluxSelectedQuantity?.length !== 0
                ? showCounterUpdated(flux.id)
                : 0
              }
              /{total})
            </div>
            {checkTotalValue(flux.id, total)}
          </div>
        );
      })
    );
  };

  return (
    <div className={styles.container}>
      {renderHeader()}
    </div>
  );
}
