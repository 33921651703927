import {
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';
import styles from './styles.module.scss';

type NestedSelectProps = {
  placeholder: string;
  setOpen: (value: boolean) => void;
  open: boolean;
  popupOpen: boolean;
  setAnchorEl: (value: any) => void;
  getRootProps: () => any;
  getInputProps: () => any;
};

export default function Input(props: NestedSelectProps) {
  return (
    <div className={styles.container}>
      <div className={styles.inputWrapper} {...props.getRootProps()}>
        <div
          onClick={() => props.setOpen(!props.open)}
          className={`${styles.inputGroup}
          ${props.popupOpen ? styles.open : ''}`} ref={props.setAnchorEl}
        >
          <input {...props.getInputProps()} placeholder={props.placeholder}/>
          <div className={styles.icon} {...props.getInputProps()}>
            {props.popupOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </div>
    </div>
  );
}
