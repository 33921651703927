import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { BoxIcon } from '../../BoxIcon';
import { Alert } from '../Alert';
import styles from './styles.module.scss';

export function RegistrationSpuAlert() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div className={ styles.container }>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'registrationSpu').map((batch) => (
        <Alert
          icon={<BoxIcon text={t('procedureBox.actions.spu.title')} />}
          textIcon
          title={t('procedureBox.actions.spu.registrationOficialLetter')}
          text={<Trans
            i18nKey='procedureBox.actions.registrationSpu.modal.alertLoading.loadingMessage'
            components={{ bold: <strong /> }}
            values={{
              field: batch.body.spuObject.documentData.field,
              institution: batch.body.spuObject.documentData.institution,
            }}
          />}
          batch={batch}
        />
      ))}
    </div>
  );
}
