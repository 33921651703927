import { useTranslation } from 'react-i18next';
import './styles.scss';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

type ItemAttributes = {
  text: string;
  icon?: ReactNode;
  href?: string;
  id: string;
  route?: string;
  subItems?: ItemAttributes[];
  level?: number;
  onOpenItems?: () => void;
  close?: () => void;
};

type ItemProps = {
  item: ItemAttributes;
  open?: boolean;
  hover: boolean;
  closeMenu: () => void;
};

export function Item(props: ItemProps) {
  const [openItems, setOpenItems] = useState(props.open);
  const [openSubItems, setOpenSubItem] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const hasSelected = (item: ItemAttributes) => {
    if (item.href === location.pathname && props.hover) {
      return true;
    } if (item.subItems && !openItems) {
      return item.subItems.some((el) => el.href === location.pathname);
    }
    return false;
  };

  const verifySelected = (item: ItemAttributes) => {
    if (item.route) {
      const locationSplit = location.pathname.split('/');
      const hrefSplit = item.route.split('/');

      if (locationSplit[1] === hrefSplit[1] && !props.hover) {
        return true;
      }
    }

    return false;
  };

  const handleClick = (item: ItemAttributes) => {
    if (props.item.href) {
      if (item.level === 3) {
        setOpenSubItem(true);
      } else {
        props.closeMenu();
      }
    } else {
      setOpenItems(!openItems);
    }
  };

  return (
    <div>
      <Link
        className={`itemContainer level${props.item.level} ${(hasSelected(props.item) || verifySelected(props.item)) ? 'selected' : ''}`}
        onClick={() => handleClick(props.item)}
        to={props.item.href ? props.item.href : window.location.pathname}
      >
        <div className='chevronIcon'>
          {props.item.subItems ? openItems && props.hover ? <FaChevronUp/> : <FaChevronDown/> : null}
        </div>
        { props.item.icon }
        <span>{ props.hover ? t(props.item.text) : '' }</span>
      </Link>
      { ((openItems || openSubItems) && props.hover)
        ? <div className='subItems'>
            { props.item.subItems?.map((subItem) => <Item
                item={ Object(subItem) }
                hover={ props.hover }
                closeMenu={props.closeMenu}
              />)}
          </div>
        : null
        }
    </div>
  );
}
