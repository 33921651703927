import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { ProcedureMarkersContext } from '../../context/procedureMarkersContext';
import { Marker } from '../../@types/model';
import { removeSpecialChars } from '../../helpers/markers';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { QueryContext } from '../../context/queryContext';
import { MarkersList } from './MarkersList';
import { Tab } from './Tab';
import { Button } from '../Button';
import { InputText } from '../InputText';

type ExpandedMarkersProps = {
  procedureId: number;
};

export function ExpandedMarkers(props: ExpandedMarkersProps) {
  const { availableMarkers } = useContext(ProcedureMarkersContext);
  const { openMarkerModal, proceduresSeleted } = useContext(ProcedureActionsContext);
  const { setLoadingListMarkers, addCustomMarker, removeMarkersProcedure } = useContext(QueryContext);
  const [markers, setMarkers] = useState<Marker[]>(availableMarkers.public_markers);
  const [selectedMarkers, setSelectedMarkers] = useState<Marker[]>([]);
  const [markersType, setMarkersType] = useState<'public' | 'private'>('public');
  const [valueState, setValueState] = useState(0);

  const data = proceduresSeleted.map((procedure) => procedure.markers)[0];

  const uniqueData = Array.from(new Set(data.map((item) => item.id))).map((id) => {
    return data.find((item) => item.id === id);
  }) as Marker[];

  useEffect(() => {
    const isPrivate = markersType === 'private';

    setSelectedMarkers(uniqueData.filter((uniqData: Marker) => uniqData.private_marker === isPrivate));

    if (isPrivate) {
      setMarkers(
        availableMarkers.private_markers.filter((newMarker) => !uniqueData.map((selectedMarker) => selectedMarker.id).includes(newMarker.id)),
      );
    } else {
      setMarkers(
        availableMarkers.public_markers.filter((newMarker) => !uniqueData.map((selectedMarker) => selectedMarker.id).includes(newMarker.id)),
      );
    }
  }, [markersType]);

  const handleChange = () => {
    const markersIds = selectedMarkers.map((marker) => marker.id);

    const removedMarkers = uniqueData.filter((selectedMarker) => !markersIds.includes(selectedMarker.id));

    selectedMarkers.forEach((marker: Marker) => {
      addCustomMarker(marker, props.procedureId, proceduresSeleted);
    });

    removedMarkers.forEach((marker: Marker) => {
      removeMarkersProcedure(marker.id, proceduresSeleted.map((procedureSelected) => procedureSelected.id));
    });

    setLoadingListMarkers(true);
  };

  const handleChangeTabs = (newValue: number) => {
    setValueState(newValue);
    if (newValue === 0) {
      setMarkers(availableMarkers.public_markers);
      setMarkersType('public');
    } else {
      setMarkers(availableMarkers.private_markers);
      setMarkersType('private');
    }
  };

  const searchMarkers = (value: string) => {
    if (markersType === 'public') {
      const newMarkers = availableMarkers.public_markers.filter((marker) => removeSpecialChars(marker.label).includes(removeSpecialChars(value)));
      setMarkers(
        newMarkers.filter((newMarker) => !selectedMarkers.map((selectedMarker) => selectedMarker.id).includes(newMarker.id)),
      );
    } else {
      const newMarkers = availableMarkers.private_markers.filter((marker) => removeSpecialChars(marker.label).includes(removeSpecialChars(value)));
      setMarkers(
        newMarkers.filter((newMarker) => !selectedMarkers.map((selectedMarker) => selectedMarker.id).includes(newMarker.id)),
      );
    }
  };

  const addSelectedMarker = (marker: Marker) => {
    setSelectedMarkers(selectedMarkers.concat(marker));
    const filteredMarker = markers.filter((element) => element.id !== marker.id);
    setMarkers(filteredMarker);
  };

  const removeSelectedMarker = (marker: Marker) => {
    setMarkers(markers.concat(marker));
    const filteredMarker = selectedMarkers.filter((element) => element.id !== marker.id);
    setSelectedMarkers(filteredMarker);
  };

  return (
    <div className={styles.container}>
      <Grid container columns={{ xs: 1, sm: 6, md: 6 }}>
        <Grid item xs={1} sm={6} md={6} className={styles.tabs}>
          <Tab
            onClick={() => handleChangeTabs(0)}
            markerTabSelected={valueState === 0}
            title={'public'}
          />

          <Tab
            onClick={() => handleChangeTabs(1)}
            markerTabSelected={valueState === 1}
            title={'private'}
          />
        </Grid>
        <Grid item xs={1} sm={4} md={4}>
          <InputText
            label={t('procedureBox.actions.expandedMarkers.modal.inputText.label')}
            placeholder={t('procedureBox.actions.expandedMarkers.modal.inputText.placeholder')}
            onChange={(e) => searchMarkers(e.target.value)}
          />
        </Grid>
        <Grid item xs={1} sm={2} md={2} className={styles.addMarker}>
          <Button
            title={t('procedureBox.actions.expandedMarkers.modal.button')}
            buttonType='primary'
            size='flat'
            onClick={() => handleChange()}
            round
          />
        </Grid>
      </Grid>
      <MarkersList
        openMarkerModal={openMarkerModal}
        procedureId={props.procedureId}
        markers={markers}
        selectedMarkers={selectedMarkers}
        addSelectedMarker={addSelectedMarker}
        removeSelectedMarker={removeSelectedMarker}
      />
    </div>
  );
}
