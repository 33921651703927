import { Trans } from 'react-i18next';
import EmptyActions from '../../../assets/emptyActions.svg';
import styles from './styles.module.scss';

export function EmptyList() {
  return (
    <div className={styles.emptyListContainer}>
        <img src={EmptyActions}/>
        <p className={styles.title}>{
          <Trans
            i18nKey='procedureBox.actions.multipleActions.selectedText'
            components={{ bold: <strong /> }}
          />}
        </p>
    </div>
  );
}
