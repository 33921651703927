import { Modal } from '@mui/material';
import { t } from 'i18next';
import { Content } from './Content';
import styles from './styles.module.scss';

type ApplicantsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  participations: any;
  setApplicantsList: () => void;
  subProcedureId: number;
};

export function ApplicantsModal(props: ApplicantsModalProps) {
  return (
    <Modal
      open={props.open}
      disableAutoFocus={true}
    >
      <div
        className={styles.content}
      >
        <div className={styles.head}>
          <span>{t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.addApplicants.title')}</span>
        </div>
        <div className={styles.body}>
          <Content
            setOpen={props.setOpen}
            setApplicantsList={props.setApplicantsList}
            subProcedureId={props.subProcedureId}
            participations={props.participations}
          />
        </div>
      </div>
    </Modal>
  );
}
