import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from '../Select';
import { Button } from '../Button';

import styles from './styles.module.scss';
import ProcedureBoxService from '../../services/procedureBoxService';
import { OptionType } from '../SelectFilter';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { QueryContext } from '../../context/queryContext';

type UpdateCategoryProps = {
  closeModal: () => void;
};

export function UpdateCategory(props: UpdateCategoryProps) {
  const { pathname } = useLocation();
  const { addToasts } = useContext(ProcedureActionsContext);
  const { setLoadingDetails } = useContext(QueryContext);
  const { handleSubmit, control } = useForm();

  const url = pathname.split('/');
  const procedureId = Number(url[3]);

  const [options, setOptions] = useState<OptionType []>([]);

  const loadOptions = async () => {
    const response = await ProcedureBoxService.getCategoryInProcess(procedureId);
    const categoriesList = response.map((option: any) => {
      return {
        label: option.category,
        value: option.id,
      };
    });

    setOptions(categoriesList);
  };

  const submit = (data: any) => {
    ProcedureBoxService.updateCategoryInProcess(
      data.category_selected?.value,
      procedureId,
    ).then((res) => {
      addToasts(({
        type: 'success',
        text: res.message,
      }));
      setLoadingDetails(true);
    }).catch((res) => {
      addToasts(({
        type: 'error',
        text: res.message,
      }));
    });
    props.closeModal();
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid className={styles.content}>
        <Controller
          control={control}
          name="category_selected"
          render={({ field }) => (
            <Select
              { ...field }
              placeholder={t('procedureBox.actions.category.create.selectCategory')}
              label={t('procedureBox.actions.category.create.selectCategory')}
              className={styles.select}
              options={options}
              required
            />
          )}
        />

        <div className={styles.footer}>
          <Button
            title={t('procedureBox.actions.category.button.update')}
            className={styles.button}
            buttonType='primary'
            round
          />
        </div>
      </Grid>
    </form>
  );
}
