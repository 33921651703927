import { Timeline } from '../Timeline';
import styles from './styles.module.scss';
import { Procedure } from '../../../@types/model';
import { Tables } from '../Tables';

type DataProps = {
  procedure: Procedure;
};

export function Data(props: DataProps) {
  return (
    <div className={ styles.container }>
      <Tables procedure={ props.procedure } />
      <Timeline
        procedureId={props.procedure.id}
        procedureStatus={props.procedure.status}
      />
    </div>
  );
}
