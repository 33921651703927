import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import styles from '../styles.module.scss';
import { truncate } from '../../../helpers/strings';
import { Tooltip } from '../../Tooltip';
import { QueryContext } from '../../../context/queryContext';
import { FluxTab } from '../../../@types/tab';

type FluxItemProps = {
  value: FluxTab;
  onSelect: () => void;
};

export function FluxItem(props: FluxItemProps) {
  const { t } = useTranslation();
  const { isFluxSelected } = useContext(QueryContext);

  const getFluxStyle = () => {
    if (Boolean(props.value.allFluxes) && !isFluxSelected) {
      return styles.selected;
    }
    return props.value.selected ? styles.selected : '';
  };

  return (
    <div
      className={`${styles.fluxItemContainer} ${getFluxStyle()}`}
      onClick={props.onSelect}
      data-cy={'cypress-box-flux-item'}
    >
    <p className={styles.acronym} >{props.value.acronym}</p>
    <div className={`${styles.name} ${props.value.selected ? styles.selected : ''}`}>
      <Tooltip title={props.value.name} placement='top-end'>
        <p>{truncate(props.value.name.replace('/', ' '), 20)}</p>
      </Tooltip>
    </div>
    <p className={styles.total} >{props.value.total}</p>
    {
      props.value.was_not_listed > 0 ? (
        <p className={`${styles.unread} ${props.value.selected ? styles.hidden : ''}`} >
          {`${t(
            'procedureBox.filterTabs.unreadProcedure',
            { value: props.value.was_not_listed, plural: props.value.was_not_listed === 1 ? '' : 's' },
          )}`
          }
        </p>
      ) : (
        <p className={`${styles.unread} ${styles.empty}`} ></p>
      )
    }
    </div>
  );
}
