import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Batch } from '../../@types/backgroundActions';
import { ShareProcedureType } from '../../@types/config';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { SelectedProcedures } from '../SelectedProcedures';
import { Form } from './Form';

type ShareProcedureProps = {
  closeModal: () => void;
  form?: ShareProcedureType | undefined;
};

export function ShareProcedure(props: ShareProcedureProps) {
  const { proceduresSeleted, setAlert, removeProcedures } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const renderText = (shareForm: ShareProcedureType) => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.shareProcedure.alertConfirmation.textManyProcedures'
          components={{ bold: <strong /> }}
          values={{
            number: proceduresSeleted.length,
            individuals: shareForm?.individuals.length,
          }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.shareProcedure.alertConfirmation.text'
        components={{ bold: <strong /> }}
        values={{ number: proceduresSeleted[0].process_number, individuals: shareForm?.individuals.length }}
      />
    );
  };

  const handleConfirm = (shareForm: ShareProcedureType | undefined) => {
    if (shareForm) {
      const procedures = proceduresSeleted;
      const type = 'shareProcedure';
      const id = (Math.random() + 1).toString(36).substring(1);
      const batch: Batch = {
        id,
        procedures,
        type,
        successList: [],
        failedList: [],
        count: procedures.length,
        body: shareForm,
      };
      addBatch(batch);
      removeProcedures(procedures);
      setAlert(undefined);
      props.closeModal();
    }
  };

  const showAlert = (shareForm: ShareProcedureType) => {
    setAlert({
      visible: true,
      handleConfirm: () => handleConfirm(shareForm),
      title: t('procedureBox.actions.shareProcedure.alertConfirmation.title'),
      text: renderText(shareForm),
    });
  };

  return (
    <div>
      <SelectedProcedures />
      <Form
        handleConfirm={(request) => showAlert(request)}
      />
    </div>
  );
}
