import {
  createContext,
  FC,
  useState,
} from 'react';
import { ModalType } from '../@types/modal';
import { Alert, Modal, ToastProps } from '../@types/config';

const contextDefaultValues: ModalType = {
  addToasts: () => {},
  setModal: () => {},
  setToasts: () => {},
  setAlert: () => {},
  setNotificationsCount: () => {},
  notificationsCount: 0,
  alert: {
    visible: false,
    handleConfirm: () => {},
  },
  toasts: [],
  modal: {
    visible: false,
    handleClose: () => {},
    title: '',
    size: 'medium',
  },
};

export const HomeContext = createContext<ModalType>(
  contextDefaultValues,
);

const HomeProvider: FC = ({ children }) => {
  const [modal, setModal] = useState<Modal | undefined>();
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [alert, setAlert] = useState<Alert | undefined>();
  const [notificationsCount, setNotificationsCount] = useState(0);

  const addToasts = (props: ToastProps) => {
    setToasts([...toasts, props]);
  };
  return (
      <HomeContext.Provider
        value={{
          modal,
          setModal,
          setAlert,
          alert,
          toasts,
          setToasts,
          addToasts,
          notificationsCount,
          setNotificationsCount,
        }}>
          {children}
      </HomeContext.Provider>
  );
};

export default HomeProvider;
