import styles from './styles.module.scss';

type ItemProps = {
  item: any;
};

export function Item(props: ItemProps) {
  const itens = [
    { type: 'far', class: styles.far },
    { type: 'next', class: styles.next },
    { type: 'delayed', class: styles.delayed },
    { type: 'completed', class: styles.completed },
    { type: 'external', class: styles.external },
  ];

  const getColor = () => {
    return itens.find((i) => i.type === props.item.backgroundColor);
  };

  return (
    <div
      className={`${styles.item} ${getColor()?.class}`}
    >
      <i>{props.item.event.title}</i>
    </div>
  );
}
