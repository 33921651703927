import { useContext, useEffect, useState } from 'react';
import { SelectedProcedures } from '../SelectedProcedures';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import styles from './styles.module.scss';
import { Verify } from './Verify';
import { VerifySignatures } from './VerifySignatures';
import { Description } from './Description';
import { View } from './View';
import { Tab } from './Tab';
import Screen from '../../helpers/screen';
import { MultipleActionStep } from '../../@types/backgroundActions';

type ForwardIpmProps = {
  multipleActions?: MultipleActionStep;
};

export function ForwardIpm(props: ForwardIpmProps) {
  const {
    verify,
    verifySignatures,
    stateDescription,
    ipmStateSelected,
    setIpmStateSelected,
  } = useContext(ProcedureActionsContext);
  let renderView;

  const [marginResponsive, setMarginResponsive] = useState(10);

  const isMobile = Screen.isMobile();

  switch (ipmStateSelected) {
    case 'verify':
      renderView = <Verify />;
      break;
    case 'verifySignatures':
      renderView = <VerifySignatures />;
      break;
    case 'description':
      renderView = <Description isMobile={isMobile}/>;
      break;
    case 'view':
      renderView = <View
        isMobile={isMobile}
        multipleActions={props.multipleActions}
      />;
      break;
    default:
      break;
  }

  useEffect(() => {
    const handleTabResponsive = () => {
      if (ipmStateSelected === 'verifySignatures' && marginResponsive < 400) {
        return setMarginResponsive(marginResponsive - 150);
      } if (ipmStateSelected === 'description' && marginResponsive < 400) {
        return setMarginResponsive(marginResponsive - 150);
      } if (ipmStateSelected === 'view' && marginResponsive < 400) {
        return setMarginResponsive(marginResponsive - 150);
      }

      return setMarginResponsive(0);
    };

    handleTabResponsive();
  }, [ipmStateSelected]);

  return (
    <div className={styles.container}>
      <SelectedProcedures />
      <div className={`${styles.tabs} ${isMobile && styles.tabsResponsive}`}>
        <div className={`${styles.tabsContent} ${isMobile && styles.tabsContentResponsive}`} style={{ marginLeft: isMobile ? marginResponsive : 0 }}>
          <Tab
            onClick={() => setIpmStateSelected('verify')}
            ipmStateSelected={ipmStateSelected === 'verify'}
            title={'verifyAttach'}
            showIcon={verify}
            isMobile={isMobile}
          />

          <Tab
            onClick={() => setIpmStateSelected('verifySignatures')}
            ipmStateSelected={ipmStateSelected === 'verifySignatures'}
            title={'verifySignatures'}
            disabled={verify === undefined}
            showIcon={verifySignatures}
            isMobile={isMobile}
          />

          <Tab
            onClick={() => setIpmStateSelected('description')}
            ipmStateSelected={ipmStateSelected === 'description'}
            title={'description'}
            disabled={verifySignatures === undefined}
            showIcon={stateDescription}
            isMobile={isMobile}
          />

          <Tab
            onClick={() => setIpmStateSelected('view')}
            ipmStateSelected={ipmStateSelected === 'view'}
            title={'preview'}
            disabled={stateDescription === undefined || stateDescription === false}
            isMobile={isMobile}
          />
        </div>
      </div>
      <div className={styles.content}>
        {renderView}
      </div>
     </div>
  );
}
