import { useContext, useState } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaSearch,
} from 'react-icons/fa';
import { t } from 'i18next';
import { GenericMarker } from '../../../../../../@types/markers';
import { Marker, Procedure } from '../../../../../../@types/model';
import { ProcedureMarkersContext } from '../../../../../../context/procedureMarkersContext';
import { QueryContext } from '../../../../../../context/queryContext';
import { isMarker, removeSpecialChars } from '../../../../../../helpers/markers';
import { RoundButton } from '../../../../../RoundButton';
import { MarkerOption } from '../MarkerOption';
import styles from './styles.module.scss';
import { InputText } from '../../../../../InputText';
import { Label } from '../../../../../Label';
import { ProcedureActionsContext } from '../../../../../../context/procedureActionsContext';

type CustomMarkerOptionProps = {
  onChange: () => void;
  type: 'public' | 'private';
  procedureId: number;
  procedures?: Procedure[];
  open?: boolean;
};

export function CustomMarkerOption(props: CustomMarkerOptionProps) {
  const { openExpandedMarkersModal } = useContext(ProcedureActionsContext);
  const { availableMarkers } = useContext(ProcedureMarkersContext);
  const { procedures, removeMarkersProcedure, addCustomMarker } = useContext(QueryContext);

  const [isOpen, setIsOpen] = useState(props.open);
  const [isOpenInput, setIsOpenInput] = useState(false);
  const [markersFiltered, setMarkersFiltered]: any = useState({
    publicMarkers: availableMarkers.public_markers.slice(0, 10),
    privateMarkers: availableMarkers.private_markers.slice(0, 10),
  });

  let options: Marker[];
  let title = '';

  switch (props.type) {
    case 'public':
      options = markersFiltered.publicMarkers;
      title = 'Público';
      break;
    case 'private':
      options = markersFiltered.privateMarkers;
      title = 'Privado';
      break;
    default:
      break;
  }

  const handleSearchMarker = (target: any) => {
    if (props.type === 'public') {
      const publicMarkersFiltered = availableMarkers.public_markers.filter((marker) => removeSpecialChars(marker.label).includes(removeSpecialChars(target.value)));
      setMarkersFiltered({ publicMarkers: publicMarkersFiltered.slice(0, 10) });
    } else {
      const privateMarkersFiltered = availableMarkers.private_markers.filter((marker) => removeSpecialChars(marker.label).includes(removeSpecialChars(target.value)));
      setMarkersFiltered({ privateMarkers: privateMarkersFiltered.slice(0, 10) });
    }
  };

  const handleChange = (marker: Marker | GenericMarker) => {
    const procedure = procedures.find((obj) => {
      return obj.id === props.procedureId;
    });
    if (isMarker(marker)) {
      if (procedure && procedure.markers.length > 0) {
        const result = procedure.markers.find((markerObject) => {
          return markerObject.id === marker.id;
        });
        if (result) {
          const procedureIds = props.procedures?.map((propProcedure) => propProcedure.id) || [props.procedureId];
          removeMarkersProcedure(marker.id, procedureIds);
        } else {
          addCustomMarker(marker, props.procedureId, props.procedures);
        }
      } else {
        addCustomMarker(marker, props.procedureId, props.procedures);
      }
    }
    props.onChange();
  };

  const renderOptions = () => {
    if (options.length > 0) {
      return options.map((option) => {
        return (
          <MarkerOption
            onChange={(marker) => handleChange(marker)}
            marker={ option }
            expanded={isOpenInput}
            procedureId={props.procedureId}
          />
        );
      });
    }
    return <div className={styles.notFound}><span>{t('procedureBox.markersSelect.notFound')}</span></div>;
  };

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const viewMoreTitle = () => {
    if (props.type === 'public') {
      return `${t('procedureBox.markers.viewMore')} ${availableMarkers.public_markers.length - markersFiltered.publicMarkers.length}`;
    }

    if (props.type === 'private' && availableMarkers.private_markers.length > 10) {
      return `${t('procedureBox.markers.viewMore')} ${availableMarkers.private_markers.length - markersFiltered.privateMarkers.length}`;
    }

    return '';
  };

  return (
    <div className={ styles.container }>
      <div className={ styles.title }>
        <div className={styles.label} onClick={ (e) => handleOnClick(e) }>
          { isOpen ? <FaChevronUp/> : <FaChevronDown/> }
          <span>{ title }</span>
        </div>
        <RoundButton
          size='micro'
          icon={ <FaSearch /> }
          onClick={ () => setIsOpenInput(!isOpenInput)}
        />
        {isOpenInput && <InputText placeholder={t('procedureBox.markers.placeholder')} onChange={(e) => handleSearchMarker(e.target)}/>}
      </div>
      <div>
        { isOpen ? renderOptions() : null }
      </div>
      <div>
        <Label
          text={viewMoreTitle()}
          className={styles.viewMore}
          onClick={() => openExpandedMarkersModal(props.procedureId)}
        />
      </div>
    </div>
  );
}
