/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

import { isValid, parse } from 'date-fns';
import moment from 'moment';

/* eslint-disable no-plusplus */
function digitModulus(modulus: number, value: string, weights: number[]): string {
  let digit = 0;
  let newValue = value;

  const _weights: number[] = weights.concat();
  const digitLimit = 10;
  const indexStart = 0;
  const subIndexLength = 1;
  const weigthBase = 0;
  const newValueLengthStop = 0;

  while (newValue.length > newValueLengthStop) {
    const indexLength = newValue.length - subIndexLength;
    digit += Number(newValue.charAt(indexLength)) * (_weights.pop() || weigthBase);
    newValue = newValue.substr(indexStart, indexLength);
  }
  digit = modulus - (digit % modulus);

  return digit >= digitLimit ? '0' : digit.toString();
}

function digitsOf(baseValue: string, checkDigitsLength: number, modulus: number = 11, weights: number[] = []): string {
  let i: number;
  const weightsZero = 0;

  if (weights.length === weightsZero) {
    const factorBase = 2;
    const factorLimit = 9;
    const startIndexOne = 0;
    const subDigit = 1;

    let factor = factorBase;

    for (i = startIndexOne; i < (baseValue.length + checkDigitsLength - subDigit); i++) {
      weights.unshift(factor);
      factor++;
      if (factor > factorLimit) {
        factor = factorBase;
      }
    }
  }

  let result = '';
  const startIndexTwo = 1;
  for (i = startIndexTwo; i <= checkDigitsLength; i++) {
    result += digitModulus(modulus, `${baseValue}${result}`, weights);
  }

  return result;
}

function addDigitsTo(baseValue: string, checkDigitsLength: number, modulus: number = 11, weights: number[] = []): string {
  return baseValue + digitsOf(baseValue, checkDigitsLength, modulus, weights);
}

function isValidModulus(value: string, checkDigitsLength: number, weights: number[] = []): boolean {
  if (!value) {
    return false;
  }

  const modulus = 11;
  const startPosition = 0;
  const newValue = (value.match(/[0-9A-Za-z]/g) || []).join('');

  let buffer: string = newValue.substr(startPosition, newValue.length - checkDigitsLength);
  buffer = addDigitsTo(buffer, checkDigitsLength, modulus, weights);

  return newValue === buffer;
}

export function isCpf(value: string): boolean {
  if (value === 'Sem Identificação') {
    return true;
  }
  // tslint:disable-next-line: no-magic-numbers
  const WEIGHTS = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
  const DIGITS_LENGTH = 2;

  return isValidModulus(value, DIGITS_LENGTH, WEIGHTS);
}

export function isCnpj(value: string): boolean {
  // tslint:disable-next-line: no-magic-numbers
  const WEIGHTS = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const DIGITS_LENGTH = 2;

  return isValidModulus(value, DIGITS_LENGTH, WEIGHTS);
}

export function isCpfOrCnpj(value: string): boolean {
  const cpfSize = 11;
  if (value.match(/[0-9A-Za-z]/g)?.length === cpfSize) {
    return isCpf(value);
  }

  return isCnpj(value);
}

export function validateEmail(email: string): boolean {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return expression.test(email);
}

export function isValidDate(value: string | undefined) {
  if (!value) return false;

  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

  if (!dateRegex.test(value)) return false;
  const formattedValue = value.replace(dateRegex, '$3-$2-$1');
  const selectedDate = parse(formattedValue, 'yyyy-MM-dd', new Date());

  return isValid(selectedDate);
}

export const validateDateRange = (startingPoint: string, endingPoint: string): boolean => {
  const startingDate = moment(startingPoint, 'DD-MM-YYYY');
  const endingDate = moment(endingPoint, 'DD-MM-YYYY');
  if (startingDate.isAfter(endingDate)) {
    return false;
  }
  return true;
};
