import { t } from 'i18next';
import { ChildrenOptionType } from '../../../@types/config';
import styles from './styles.module.scss';

type SubmenuProps = {
  popupOpen: boolean;
  anchorEl: any;
  hover: boolean;
  chidren?: ChildrenOptionType[];
  top: number;
  selected: any;
  handleOnClick: (value: any) => void;
};

export default function Submenu(props: SubmenuProps) {
  const renderTitleSubmenu = (length: number) => {
    return t('dashboard.digitalProcesses.seeArea', { length, plural: length === 1 ? '' : 'es' });
  };

  return (
    <div className={styles.container}>
      { props.hover && props.chidren && props.chidren.length !== 0 && <ul className={styles.submenu}
          style={{
            top: props.top,
            left: props.anchorEl.offsetLeft + props.anchorEl.offsetWidth,
          }}
        >
          <li
            className={`${styles.item}`}
            onClick={() => props.handleOnClick(props.selected)}
          >
            {renderTitleSubmenu(props.chidren.length)}
          </li>
          {props.chidren.map((option: any, index) => (
            <li
              key={index}
              className={`${styles.item}`}
              onClick={() => props.handleOnClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      }
    </div>
  );
}
