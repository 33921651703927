export const StreetTypes = () => [
  {
    value: 1,
    label: 'Rua',
  },
  {
    value: 2,
    label: 'Avenida',
  },
  {
    value: 3,
    label: 'Travessa',
  },
  {
    value: 4,
    label: 'Lote',
  },
  {
    value: 5,
    label: 'Vila',
  },
  {
    value: 6,
    label: 'Loteamento',
  },
];

export const PersonTypes = () => [
  {
    value: 'cpf',
    label: 'Pessoa física',
  },
  {
    value: 'cnpj',
    label: 'Pessoa jurídica',
  },
];

export const Gender = () => [
  {
    value: 'female',
    label: 'Feminino',
  },
  {
    value: 'male',
    label: 'Masculino',
  },
];

export const ProcessType = () => [
  {
    label: 'Citação',
    value: 'citation',
  },
  {
    label: 'Intimação',
    value: 'intimation',
  },
];

export const Currency = () => [
  {
    value: 'real',
    label: 'Real',
  },
  {
    value: 'others',
    label: 'Outras moedas',
  },
];

export const ProcessTypeSuspension = () => [
  {
    label: 'Suspensão',
    value: 'suspension',
  },
  {
    label: 'Cancelamento',
    value: 'cancellation',
  },
];

export const EventTypes = () => [
  {
    label: 'Sem evento',
    value: undefined,
  },
  {
    label: 'Audiência',
    value: 0,
  },
];

export const DistributionType = () => [
  {
    label: 'Manual',
    value: 'manual',
  },
  {
    label: 'Automática',
    value: 'automatic',
  },
];

export const documentsOrder = () => [
  { label: 'Data do anexo (Mais antigo)', value: 'date desc' },
  { label: 'Data do anexo (Mais recente)', value: 'date asc' },
  { label: 'Arquivo (A-Z)', value: 'file_name asc' },
  { label: 'Arquivo (Z-A)', value: 'file_name desc' },
  { label: 'Anexado por (A-Z)', value: 'owner asc' },
  { label: 'Anexado por (Z-A)', value: 'owner desc' },
  { label: 'Tipo de documento (A-Z)', value: 'type asc' },
  { label: 'Tipo de documento (Z-A)', value: 'type desc' },
];

export const orderCalculationReport = [
  { label: 'Número principal (A-Z)', value: 'main_process asc' },
  { label: 'Número principal (Z-A)', value: 'main_process desc' },
  { label: 'Número do subprocesso (A-Z)', value: 'subprocess asc' },
  { label: 'Número do subprocesso (Z-A)', value: 'subprocess desc' },
  { label: 'Objeto (A-Z)', value: 'process_category asc' },
  { label: 'Objeto (Z-A)', value: 'process_category desc' },
  { label: 'Procurador (Z-A)', value: 'attorney asc' },
  { label: 'Procurador (Z-A)', value: 'attorney desc' },
  { label: 'Data de envio (Mais antigo)', value: 'send_date_initial asc' },
  { label: 'Data de envio (Mais recente)', value: 'send_date_initial desc' },
  { label: 'Prazo de entrega (Mais antigo)', value: 'deadline asc' },
  { label: 'Prazo de entrega (Mais recente)', value: 'deadline desc' },
  { label: 'Data de resposta (Mais antigo)', value: 'response_date_initial asc' },
  { label: 'Data de resposta (Mais recente)', value: 'response_date_initial desc' },
];

export const orderPaymentReport = [
  { label: 'Tipo de Requisição (A-Z)', value: 'order_type asc' },
  { label: 'Tipo de Requisição (Z-A)', value: 'order_type desc' },

  { label: 'Beneficiário (A-Z)', value: 'interested_part asc' },
  { label: 'Beneficiário (Z-A)', value: 'interested_part desc' },

  { label: 'Número do processo (A-Z)', value: 'process_number asc' },
  { label: 'Número do processo (Z-A)', value: 'process_number desc' },

  { label: 'Número do SPU (A-Z)', value: 'spu_number asc' },
  { label: 'Número do SPU (Z-A)', value: 'spu_number desc' },

  { label: 'Objeto (A-Z)', value: 'process_category asc' },
  { label: 'Objeto (Z-A)', value: 'process_category desc' },

  { label: 'Vara (A-Z)', value: 'payment_order_court_id_eq asc' },
  { label: 'Vara (Z-A)', value: 'payment_order_court_id_eq desc' },

  { label: 'Situação (A-Z)', value: 'state asc' },
  { label: 'Situação (Z-A)', value: 'state desc' },

  { label: 'Periodo (Mais antiga)', value: 'period_initial_gteq asc' },
  { label: 'Periodo (Mais recente)', value: 'period_final_lteq desc' },

  { label: 'Valor (Menor-Maior)', value: 'payment_value asc' },
  { label: 'Valor (Maior-Menor)', value: 'payment_value desc' },

  { label: 'Data do Ofício para SEFIN (Mais antiga)', value: 'origin_document_date asc' },
  { label: 'Data do Ofício para SEFIN (Mais recente)', value: 'origin_document_date desc' },

  { label: 'Prazo de Resposta (Mais antiga)', value: 'official_letter_deadline asc' },
  { label: 'Prazo de Resposta (Mais recente)', value: 'official_letter_deadline desc' },
];

export const findCurrency = (value: string) => {
  return Currency().find((currency) => currency.value === value);
};
