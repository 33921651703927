import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Breadcumb } from '../../components/Breadcumb';
import styles from './styles.module.scss';
import { Modal } from '../../components/Modal';
import { LoadingBottomAlerts } from '../../components/LoadingBottomAlerts';
import { Alert } from '../../components/Alert';
import { AppContextProvider } from '../../context/appContextProvider';
import { ToastsList } from '../../components/ToastsList';
import { FaqList } from '../../components/FaqList';
import { loadTitle } from '../../helpers/changeTitle';

type FaqProps = {
  title?: string;
};

export function Faq(props: FaqProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <AppContextProvider>
      <ToastsList />
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={2}
        className={ styles.childrenContent }
      >
        <Grid item xs={12} className={ styles.title }>
          <Grid item xs={3} sm={6}>
            <h1>{ t('faq.title') }</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FaqList />
        </Grid>
      </Grid>
      <Modal />
      <LoadingBottomAlerts />
      <Alert />
    </AppContextProvider>
  );
}
