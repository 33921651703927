import { t } from 'i18next';
import {
  FormControl, FormControlLabel, FormLabel, Grid, RadioGroup as RadioGroupMui, Radio, ThemeProvider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { isValid, parse } from 'date-fns';
import { InputDate } from '../../InputDate';
import Show from '../../Show';
import { InputText } from '../../InputText';
import { RadioGroup } from '../../RadioGroup';
import Select from '../../Select';
import { OptionType } from '../../../@types/config';
import styles from './styles.module.scss';
import { PaymentOrderData, PaymentOrderDocument } from '../../../@types/paymentOrder';
import { ErrorField } from '../../ErrorField';
import { Button } from '../../Button';
import { Header } from '../../Header';
import { customTheme } from '../../SubProcess/OfficialData/customTheme';
import { ListBeneficiaries } from '../ListBeneficiaries';

type OrderDataProps = {
  content: PaymentOrderData | undefined;
  parentProcedureId: number;
  dependencies: any;
  paymentOrderObject: PaymentOrderDocument;
  nextStep: (val: any, next: string) => void;
  setBeneficiariesData: (val: any) => void;
};

interface FormValues {
  deadline: string;
  court: OptionType;
  orderType: string;
  acknowledgmentDate: string;
}

export function OrderData(props: OrderDataProps) {
  const [valueOption, setValueOption] = useState<number | undefined>(undefined);
  const orderTypes: OptionType[] = [
    {
      label: t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.options.smallValueRequest'),
      value: '0',
    },
    {
      label: t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.options.precatory'),
      value: '1',
    },
  ];

  const [valueCheckbox, setValueCheckBox] = useState(orderTypes[0].value);
  const schema = Yup.object().shape({
    deadline: Yup
      .string()
      .test('is-future-date', t('general.date'), (value) => {
        if (!value) return false;

        const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        if (!dateRegex.test(value)) return false;

        const today = new Date();
        const formattedValue = value.replace(dateRegex, '$3-$2-$1');
        const selectedDate = parse(formattedValue, 'yyyy-MM-dd', new Date());

        return isValid(selectedDate) && selectedDate >= today;
      })
      .required(t('general.requeried')),
    acknowledgmentDate: Yup.string().test('cpf-required', t('general.requeried'), function (value) {
      const { path, createError } = this;

      if (valueCheckbox === '0') {
        if (!value) {
          return createError({ path, message: t('general.requeried') });
        }

        const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        if (!dateRegex.test(value)) return createError({ path, message: t('general.invalidDate') });

        const formattedValue = value.replace(dateRegex, '$3-$2-$1');
        const selectedDate = parse(formattedValue, 'yyyy-MM-dd', new Date());
        if (value !== undefined && !isValid(selectedDate)) {
          return createError({ path, message: t('general.invalidDate') });
        }
      }
      return true;
    }),
    court: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.string().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
  });

  const {
    handleSubmit, formState: { errors }, control, setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const nextStepAttach = () => {
    if (valueOption === 0) {
      return 'prepareDocument';
    }
    if (valueOption === 1) {
      return 'attachFiles';
    }
    return '';
  };

  const handleNextStep = (form: FormValues) => {
    const data: PaymentOrderData = {
      processNumber: props.dependencies.process_number,
      object: props.dependencies.object,
      parentProcedureId: props.parentProcedureId,
      ...form,
    };
    props.nextStep(data, nextStepAttach());
  };

  const handleOrderType = (value: any) => {
    setValue('orderType', value);
    setValueCheckBox(value);
  };

  useEffect(() => {
    if (valueCheckbox === '1') {
      setValue('acknowledgmentDate', '');
    }
  }, [valueCheckbox]);

  useEffect(() => {
    if (props.content !== undefined) {
      setValue('deadline', props.content.deadline);
      setValue('acknowledgmentDate', props.content.acknowledgmentDate);
      setValue('court', props.content.court);
      setValue('orderType', props.content.orderType.toString());
      setValueCheckBox(props.content.orderType);
    }
  }, []);

  return (
    <form>
      <Grid
        container
        columns={{ xs: 4, sm: 12, md: 12 }}
        spacing={{ xs: 2, md: 2 }}
      >
        <Grid item xs={4} sm={12} md={12}>
          <Controller
            control={control}
            name="orderType"
            render={({ field }) => (
              <RadioGroup
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.typeOrderPayment')}
                options={orderTypes}
                value={props.content?.orderType}
                onChange={(e) => handleOrderType(e.target.value)}
              />
            )}
          />
        </Grid>
        <Show if={valueCheckbox === '0'}>
          <Grid item xs={4} sm={4} md={4}>
            <Controller
              control={control}
              name="acknowledgmentDate"
              render={({ field }) => (
                <InputDate
                  {...field}
                  label={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.dataScience')}
                  tooltip={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.dateTooltip')}
                  placeholder={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.datePlaceholder')}
                  value={props.content?.acknowledgmentDate}
                />
              )}
            />
            <ErrorField text={errors.acknowledgmentDate?.message} />
          </Grid>
        </Show>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="deadline"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.deadline')}
                tooltip={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.tooltips.deadline')}
                placeholder={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.deadlinePlaceholder')}
                value={props.content?.deadline || ''}
              />
            )}
          />
          <ErrorField text={errors.deadline?.message} />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <InputText
            label={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.processNumber')}
            value={props.dependencies?.process_number}
            tooltip={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.processNumberTooltip')}
            readOnly
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <InputText
            label={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.object')}
            tooltip={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.objectTooltip')}
            value={props.dependencies?.object?.label}
            readOnly
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="court"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.stick')}
                tooltip={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.stickTooltip')}
                placeholder={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.placeholderStick')}
                options={props.dependencies.court_id || []}
                defaultValue={props.content?.court}
              />
            )}
          />
          <ErrorField text={errors.court?.value?.message} />
          <ErrorField text={errors.court?.message} />
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <div className={styles.header}>
            <Header text={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.title')}/>
          </div>
          <ListBeneficiaries
            content={props.paymentOrderObject.beneficiariesData}
            dependencies={props.dependencies}
            parentProcedureId={props.parentProcedureId}
            handleForm={(val) => props.setBeneficiariesData(val)}
          />
        </Grid>
        <ThemeProvider theme={customTheme}>
          <FormControl className={styles.content}>
            <FormLabel className={styles.label} id='radioGroup'>
              {t('procedureBox.actions.subProcedure.modal.judicialOrderModal.continue')}
            </FormLabel>
            <RadioGroupMui aria-labelledby='radioGroup' className={styles.options}>
              <FormControlLabel
                label={t(
                  'procedureBox.actions.subProcedure.modal.judicialOrderModal.options.elaborate_document',
                ).toString()}
                checked={valueOption === 0}
                onChange={() => setValueOption(0)}
                control={<Radio />}
                className={styles.select}
              />
              <FormControlLabel
                label={t(
                  'procedureBox.actions.subProcedure.modal.judicialOrderModal.options.attach',
                ).toString()}
                checked={valueOption === 1}
                onChange={() => setValueOption(1)}
                control={<Radio />}
                className={styles.select}
              />
            </RadioGroupMui>
          </FormControl>
        </ThemeProvider>
        <Grid item xs={4} sm={12} md={12} className={styles.footer}>
          <Button
            onClick={handleSubmit(handleNextStep)}
            title={t('procedureBox.actions.spu.createPaymentOrder')}
            buttonType='primary'
            size='flat'
            round
          />
        </Grid>
      </Grid>
    </form>
  );
}
