import moment from 'moment';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default class Format {
  public static currency(currency: number): string {
    const value = currency || 0;
    return value.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' });
  }

  public static formatValueName(currency: number): string {
    const value = currency || 0;
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(value);
  }

  public static formatNumber(currency: number): string {
    const value = currency || 0;
    return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);
  }

  public static formatDate(date: string): string {
    const regex = /^([0-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return regex.test(date) ? date : moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  public static formatDateWithHours(text: string): string {
    return format(parseISO(text), "dd/MM/yyyy 'às' HH:mm", { locale: ptBR });
  }

  public static removeSpecialCharacters(text: string): string {
    const accentsMap: { [key: string]: string } = {
      á: 'a',
      à: 'a',
      â: 'a',
      ã: 'a',
      ä: 'a',
      é: 'e',
      è: 'e',
      ê: 'e',
      ë: 'e',
      í: 'i',
      ì: 'i',
      î: 'i',
      ï: 'i',
      ó: 'o',
      ò: 'o',
      ô: 'o',
      õ: 'o',
      ö: 'o',
      ú: 'u',
      ù: 'u',
      û: 'u',
      ü: 'u',
      ç: 'c',
    };

    const textWithoutAccents = text.replace(/[áàâãäéèêëíìîïóòôõöúùûüç]/g, (match) => accentsMap[match] || match);

    return textWithoutAccents.replace(/[^a-zA-Z0-9\s]/g, ' ');
  }

  public static removeCurrencyFormat(value: string) {
    return value ? parseFloat(value.replace('R$ ', '').replaceAll('.', '').replaceAll(',', '.')) : 0;
  }

  public static parseDate(dateString: string) {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day).getTime();
  }

  public static formatCpf(cpf: string) {
    const unwantedCharacters = cpf.replace(/[^\d]/g, '');

    return unwantedCharacters.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  public static base64ToBlob(base64: string, type: string) {
    const decodedData = atob(base64);
    const byteNumbers = new Array(decodedData.length);

    for (let i = 0; i < decodedData.length; i += 1) {
      byteNumbers[i] = decodedData.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type });
  }
}
