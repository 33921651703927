import { useContext, useState } from 'react';
import { t } from 'i18next';
import { FaStickyNote, FaPlus } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { RoundButton } from '../../RoundButton';
import styles from './styles.module.scss';
import { Procedure } from '../../../@types/model';
import { CurrentUser } from '../../../@types/currentUser';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { NotesType } from '../../../@types/config';
import { ButtonMenu } from '../ButtonMenu';

type AnotationsProps = {
  procedure: Procedure;
  notes: NotesType[];
};

export function AnotationsInfos(props: AnotationsProps) {
  const { addProcedures, openNotesModal } = useContext(ProcedureActionsContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const myNotes = props.notes.filter((n) => n.individual_id === currentUser.id);
  const otherNotes = props.notes.filter((n) => n.individual_id !== currentUser.id);

  const openNotes = (e: any) => {
    addProcedures([props.procedure]);
    openNotesModal();
    e.stopPropagation();
  };

  const handleOpened = (e: any) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClosed = (e: any) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <div className={styles.container}>
      <RoundButton
        icon={ <FaStickyNote/> }
        className={styles.button}
        tooltip={t('procedureBox.notes.notes')}
        size={isMobile ? 'intermediate' : 'medium'}
        onClick={(e) => handleOpened(e)}
        badge={otherNotes.length > 0 ? otherNotes.length : undefined}
      />
      <ButtonMenu
        anchorEl={anchorEl}
        handleClosed={handleClosed}
        id='anotations-id'
      >
        <div className={styles.notes}>
          <a className={styles.myNotes} onClick={(e) => openNotes(e)}>
            <strong>{ myNotes.length }</strong> { t('procedureBox.notes.myNotes') }
          </a>

          <a className={styles.othersNotes} onClick={(e) => openNotes(e)}>
            <strong>{ otherNotes.length }</strong> { t('procedureBox.notes.otherNotes') }
          </a>

          <div className={ styles.newNote } onClick={(e) => openNotes(e)}>
            <FaPlus />
            <span>{ t('procedureBox.notes.newNote') }</span>
          </div>
        </div>
      </ButtonMenu>
    </div>
  );
}
