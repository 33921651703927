import { t } from 'i18next';
import {
  FaExclamationTriangle,
  FaRegCheckCircle,
} from 'react-icons/fa';
import styles from './styles.module.scss';

type TabProps = {
  title: string;
  onClick: () => void;
  tabSelected: boolean
  disabled?: boolean;
  showIcon?: boolean | undefined;
};

export function Tab(props: TabProps) {
  const returnIconStatus = (status: boolean | undefined) => {
    return (
      status === undefined ? <></> : status
        ? <FaRegCheckCircle className={`${styles.icon} ${styles.success}`}/>
        : <FaExclamationTriangle className={`${styles.icon} ${styles.warning}`}/>
    );
  };

  return (
    <div className={styles.container}>
      <a
        onClick={props.onClick}
        className={
          `
            ${styles.tab} 
            ${props.tabSelected ? styles.selected : ''}
            ${props.disabled ? styles.disabled : ''}
          `
        }>
        {t(`procedureBox.actions.eletronicCalculation.modal.tabs.${props.title}`)}
        {returnIconStatus(props.showIcon)}
      </a>
    </div>
  );
}
