import {
  DigitalProcessesAmountResponse,
  PerformanceResponse,
  ProductivityResponse,
} from '../../@types/digitalProcessesDashboard';

export const procedureAmountData: DigitalProcessesAmountResponse = {
  total_inbox: 21084.0,
  total_inputbox: 44.0,
  total_outbox: 22.0,
};

export const performanceData: PerformanceResponse = {
  year: '2021',
  months: [
    {
      month_number: 1,
      total_inbox: 25511,
      total_outbox: 9663,
      total_inputbox: 11656,
      total_snapshot: 1188,
    },
    {
      month_number: 2,
      total_inbox: 26650,
      total_outbox: 8743,
      total_inputbox: 10802,
      total_snapshot: 1188,
    },
    {
      month_number: 3,
      total_inbox: 30068,
      total_outbox: 10275,
      total_inputbox: 12161,
      total_snapshot: 1188,
    },
    {
      month_number: 4,
      total_inbox: 31131,
      total_outbox: 9761,
      total_inputbox: 11338,
      total_snapshot: 1188,
    },
    {
      month_number: 5,
      total_inbox: 35526,
      total_outbox: 11651,
      total_inputbox: 14156,
      total_snapshot: 1188,
    },
    {
      month_number: 6,
      total_inbox: 36672,
      total_outbox: 10662,
      total_inputbox: 12797,
      total_snapshot: 1188,
    },
    {
      month_number: 7,
      total_inbox: 42107,
      total_outbox: 12531,
      total_inputbox: 16097,
      total_snapshot: 1188,
    },
    {
      month_number: 8,
      total_inbox: 46229,
      total_outbox: 14586,
      total_inputbox: 16653,
      total_snapshot: 1188,
    },
    {
      month_number: 9,
      total_inbox: 47939,
      total_outbox: 14951,
      total_inputbox: 16296,
      total_snapshot: 1188,
    },
    {
      month_number: 10,
      total_inbox: 52501,
      total_outbox: 22547,
      total_inputbox: 19512,
      total_snapshot: 1188,
    },
    {
      month_number: 11,
      total_inbox: 54740,
      total_outbox: 23156,
      total_inputbox: 24787,
      total_snapshot: 1188,
    },
    {
      month_number: 12,
      total_inbox: 48000,
      total_outbox: 15022,
      total_inputbox: 16416,
      total_snapshot: 1188,
    },
  ],
};

export const productivityData: ProductivityResponse = {
  total_individuals: 190,
  fields: [
    {
      field_name: 'PGM',
      field_id: 5,
      total_individuals: 14,
      individuals: [
        {
          individual_id: 365,
          individual_name: 'Colaborador 1',
          job_title_name: 'Cargo 1',
          job_title_code: 'CAR0007',
          total_inbox: 120,
          total_outbox: 60,
          total_inputbox: 30,
        },
        {
          individual_id: 91,
          individual_name: 'Colaborador 2',
          job_title_name: 'Cargo 2',
          job_title_code: 'CAR0026',
          total_inbox: 60,
          total_outbox: 30,
          total_inputbox: 15,
        },
        {
          individual_id: 56,
          individual_name: 'Colaborador 3',
          job_title_name: 'Cargo 3',
          job_title_code: 'CAR0074',
          total_inbox: 30,
          total_outbox: 15,
          total_inputbox: 120,
        },
        {
          individual_id: 198,
          individual_name: 'Colaborador 4',
          job_title_name: 'Cargo 4',
          job_title_code: 'CAR0001',
          total_inbox: 15,
          total_outbox: 120,
          total_inputbox: 60,
        },
        {
          individual_id: 359,
          individual_name: 'Colaborador 5',
          job_title_name: 'Cargo 5',
          job_title_code: 'CAR0001',
          total_inbox: 80,
          total_outbox: 40,
          total_inputbox: 20,
        },
        {
          individual_id: 94,
          individual_name: 'Colaborador 6',
          job_title_name: 'Cargo 6',
          job_title_code: 'CAR0005',
          total_inbox: 40,
          total_outbox: 20,
          total_inputbox: 10,
        },
      ],
    },
  ],
};
