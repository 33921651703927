import { FaFileAlt, FaTrashAlt } from 'react-icons/fa';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { AttachDocument, OptionType } from '../../../@types/config';
import styles from './styles.module.scss';
import Select from '../../Select';
import { orderOptionType } from '../../../helpers/strings';
import ProcedureBoxService from '../../../services/procedureBoxService';

type ResponsiveListProps = {
  documents: AttachDocument[];
};

export function ResponsiveList(props: ResponsiveListProps) {
  const [filteredData, setFilteredData] = useState<OptionType[]>([]);

  useEffect(() => {
    ProcedureBoxService.getDocumentTypes().then((res) => {
      setFilteredData(orderOptionType(res));
    });
  }, []);

  return (
    <div className={ styles.container }>
      {
        props.documents.map((document) => (
          <div className={styles.item}>
            <div className={styles.column}>
              <span className={styles.title}>{t('procedureBox.actions.prepareDocument.modal.form.list.fileName')}</span>
              <div>
                <FaFileAlt />
                <a>{document.file.name}</a>
              </div>
            </div>
            <div className={styles.column}>
              <span className={styles.title}>{t('procedureBox.actions.prepareDocument.modal.form.list.type')}</span>
              <Select
                label={''}
                options={filteredData}
                placeholder={t('procedureBox.actions.prepareDocument.modal.form.list.typePlaceholder')}
                onChange={(e) => {}}
              />
            </div>
            <div className={styles.column}>
              <div>
                <FaTrashAlt />
                <a>{t('procedureBox.actions.prepareDocument.modal.form.list.deleteTooltip')}</a>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
}
