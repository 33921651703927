interface MaskInput {
  unmask(): string;
  mask(): string;
}

export function maskCpfOrCnpj(value: string): MaskInput {
  if (!value) {
    return {
      unmask: () => value,
      mask: () => value,
    };
  }

  const strFrom = 0;
  const cpfLength = 11;
  const cleanValue = value.replace(/\D/g, '');

  if (cleanValue.length <= cpfLength) {
    return {
      unmask: (): string => cleanValue,
      mask: (): string => cleanValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2'),
    };
  }
  const cnpjLength = 14;

  return {
    unmask: () => cleanValue,
    mask: () => cleanValue.substr(strFrom, cnpjLength)
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2'),
  };
}

export function maskPhone(value: string) {
  const strFrom = 0;
  const maxLenght = 11;
  const cleanValue = value.replace(/\D/g, '');
  if (cleanValue.length <= 10) {
    return cleanValue
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d{4})/, '$1-$2');
  }
  return cleanValue.substr(strFrom, maxLenght)
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})/, '$1-$2');
}

export function maskCEP(value: string) {
  const strFrom = 0;
  const maxLenght = 8;
  const cleanValue = value.replace(/\D/g, '');
  return cleanValue.substr(strFrom, maxLenght).replace(/^(\d{2})(\d{3})(\d{3})+?$/, '$1.$2-$3');
}

export function maskDate(value: string): string {
  const formattedValue = value.replace(/\D/g, '');

  if (formattedValue.length <= 2) {
    return formattedValue;
  } if (formattedValue.length <= 4) {
    const day = formattedValue.slice(0, 2);
    const month = formattedValue.slice(2, 4);
    return `${day}/${month}`;
  }
  const day = formattedValue.slice(0, 2);
  const month = formattedValue.slice(2, 4);
  const year = formattedValue.slice(4, 8);
  return `${day}/${month}/${year}`;
}

export function maskDateTime(value: string): string {
  const formattedValue = value.replace(/[^\d]/g, '');

  if (!formattedValue) {
    return '';
  }

  const day = formattedValue.slice(0, 2);
  const month = formattedValue.slice(2, 4);
  const year = formattedValue.slice(4, 8);
  const hour = formattedValue.slice(8, 10);
  const minute = formattedValue.slice(10, 12);

  let formattedDateTime = `${day}/${month}/${year}`;
  if (hour) {
    formattedDateTime += ` ${hour}`;
  }
  if (minute) {
    formattedDateTime += `:${minute}`;
  }

  return formattedDateTime;
}
