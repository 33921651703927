import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button } from '../Button';
import { SelectedProcedures } from '../SelectedProcedures';
import { DocumentList } from '../DocumentList';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { AttachedDocument } from '../../@types/config';
import warningImg from '../../assets/warning.svg';
import styles from './styles.module.scss';
import Show from '../Show';
import Screen from '../../helpers/screen';

type DeleteDocumentProps = {
  closeModal: () => void;
  documents: AttachedDocument[];
  multipleActions?: MultipleActionStep;
};

export function DeleteDocument(props: DeleteDocumentProps) {
  const { setAlert, proceduresSeleted, removeProcedures } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [documentsSeleted, setDocumentsSeleted] = useState<AttachedDocument[]>(props.documents || []);
  const isMobile = Screen.isMobile();

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return t(
        'procedureBox.actions.removeDocument.modal.alert.pluralTitle',
        {
          qnt_doc: documentsSeleted.length,
          plural: documentsSeleted.length > 1 ? 's' : '',
          procedure_qnt: proceduresSeleted.length,
        },
      );
    }
    return t(
      'procedureBox.actions.removeDocument.modal.alert.title',
      {
        qnt_doc: documentsSeleted.length,
        plural: documentsSeleted.length > 1 ? 's' : '',
        procedure_number: proceduresSeleted[0].process_number,
      },
    );
  };

  const handleDelete = () => {
    const body = {
      documents: documentsSeleted,
    };
    setDocumentsSeleted([]);

    const procedures = proceduresSeleted;
    const type = 'removeDocument';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: documentsSeleted.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
    setAlert(undefined);
    props.closeModal();
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleDelete(),
      title: renderTitle(),
      text: t('procedureBox.actions.removeDocument.modal.alert.text'),
      confirmText: t('procedureBox.actions.removeDocument.modal.alert.button'),
      confirmType: 'danger',
    });
  };

  const handleOnClick = () => {
    showAlert();
  };
  return (
    <div className={ styles.container }>
      <SelectedProcedures />
      <div className={`${styles.message} ${isMobile ? styles.mobile : ''}`}>
        <Show if={!isMobile}>
          <img src={warningImg} />
        </Show>
        <div className={styles.content}>
          <p className={styles.title}>{t('procedureBox.actions.removeDocument.modal.warning')}</p>
          <p className={styles.text}>
            {
              <Trans
                i18nKey='procedureBox.actions.removeDocument.modal.text'
                components={{ bold: <strong /> }}
              />
            }
          </p>
        </div>
        </div>
      <div className={styles.header}>
        <span className={styles.title}>{t('procedureBox.actions.removeDocument.modal.label')}</span>
      </div>
      <DocumentList
        documents={props.documents}
        onChange={(val) => setDocumentsSeleted(val)}
        isMobile={isMobile}
      />
      <div className={styles.submit}>
      <Button
        title={t('procedureBox.actions.removeDocument.modal.submit')}
        textCenter
        round
        buttonType='danger'
        size='flat'
        disabled={documentsSeleted.length < 1}
        onClick={handleOnClick}
      />
      </div>
    </div>
  );
}
