import { t } from 'i18next';
import { GenericMarker } from '../@types/markers';
import { Marker } from '../@types/model';

const pendingKey: { [key: string]: string } = {
  no_pending: '0',
  documents: '1',
  information: '2',
  archiving: '3',
};

const priorityKey: { [key: string]: string } = {
  low: '0',
  normal: '1',
  high: '2',
};

const priorityColor: { [key: string]: string } = {
  low: '#707070',
  normal: '#ffcc00',
  high: '#e62155',
};

export const priorityItems: GenericMarker[] = [
  {
    marker_priority: 'high',
    marker_pending: '',
  },
  {
    marker_priority: 'normal',
    marker_pending: '',
  },
  {
    marker_priority: 'low',
    marker_pending: '',
  },
];

export const pendencyItems: GenericMarker[] = [
  {
    marker_pending: 'no_pending',
    marker_priority: '',
  },
  {
    marker_pending: 'documents',
    marker_priority: '',
  },
  {
    marker_pending: 'information',
    marker_priority: '',
  },
  {
    marker_pending: 'archiving',
    marker_priority: '',
  },
];

export function isMarker(object: any): object is Marker {
  return 'id' in object;
}

export function getPendingKey(value: string): string {
  return pendingKey[value];
}

export function getPriorityKey(value: string): string {
  return priorityKey[value];
}

export function getPriorityColor(value: string): string {
  return priorityColor[value];
}

export function removeSpecialChars(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^\w\s]/gi, '').toLowerCase();
}

export function getMarkerLabel(marker: Marker | GenericMarker, showProceduresTotal?: boolean) {
  if (isMarker(marker)) {
    return `${marker.label} ${showProceduresTotal ? `(${marker.total})` : ''}`;
  }
  if (marker.marker_priority) {
    return `${t(`procedureBox.markerMenu.${marker.marker_priority}`)}`;
  }
  return `${t(`procedureBox.markerMenu.${marker.marker_pending}`)}`;
}

export function getMarkerColor(marker: Marker | GenericMarker) {
  if (isMarker(marker)) {
    return marker.color;
  }
  if (marker.marker_priority) {
    return getPriorityColor(marker.marker_priority);
  }
  return '#ADABAB';
}
