import {
  FaChevronRight,
} from 'react-icons/fa';
import { NestedOptionType } from '../../../@types/config';
import styles from './styles.module.scss';

type MenuProps = {
  groupedOptions: any[];
  options: NestedOptionType[];
  getListboxProps: () => any;
  getOptionProps: (value: any) => any;
  openSubmenu: (option: NestedOptionType, val: any) => void;
  anchorEl: any;
};

export default function Menu(props: MenuProps) {
  return (
    <div className={styles.container}>
      <ul
        className={styles.listbox}
        {...props.getListboxProps()}
        style={{ width: props.anchorEl?.offsetWidth }}
      >
        {(props.groupedOptions as typeof props.options).map((option, index) => (
          <li
            key={index}
            {...props.getOptionProps({ option, index })}
            onMouseEnter={(el) => props.openSubmenu(option, el)}
          >
            <span>{option.label}</span>
            { option.children && option.children.length !== 0 && <FaChevronRight/> }
          </li>
        ))}
      </ul>
    </div>
  );
}
