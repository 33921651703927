import { t } from 'i18next';
import lock from '../../assets/lock.svg';
import styles from './styles.module.scss';

type UnauthorizedProps = {
  title: string;
};

export function Unauthorized(props: UnauthorizedProps) {
  return (
    <div className={styles.container}>
      <img src={lock} alt={t('home.dashboard.lock')}/>
      <p className={styles.title}>{props.title}</p>
      <p className={styles.link}>{t('home.dashboard.lock')}</p>
    </div>
  );
}
