import { t } from 'i18next';
import { FiExternalLink } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Hash } from '../../../../@types/digitalProcesses/timeline';
import { Table } from '../../../../components/Table';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import { hideLayout } from '../../../../helpers/barStyles';
import { loadTitle } from '../../../../helpers/changeTitle';

type DetailsProps = {
  isFullScreen?: boolean;
  progressId?: number;
  progress?: any;
  title?: string;
  status?: 'error' | 'loading';
};

export function Details(props: DetailsProps) {
  const { pathname } = useLocation();
  const id = Number(pathname.split('/')[4]);
  const renderValue = (data: Hash) => {
    if (typeof data.value === 'string' || data.value instanceof String) {
      const isUrl = data.value.includes('http://') || data.value.includes('https://');
      const fileName = data.value.split('/')[data.value.split('/').length - 1];
      return isUrl ? <a className={styles.url} href={data.value.toString()} target='_blank'>{fileName}</a>
        : <p className={styles.value}>{data.value}</p>;
    }
    return <p>{data.value}</p>;
  };
  const [progress, setProgress] = useState([]);

  const renderValues = (values: Hash[]) => {
    if (values) {
      return (
        <div>
          { values.map((data) => (
            <div>
              <div className={styles.dataField}>
                <p className={styles.key}>{data.key}</p>
                {renderValue(data)}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return <></>;
  };

  const renderTable = (data: any) => {
    if (data.values[0]) {
      const columns = (data.values[0].map((val: Hash) => val.key));
      return (
        <div className={styles.table}>
          <Table>
            <thead>
              <tr>
                {
                  columns.map((col: string) => <th>{col}</th>)
                }
              </tr>
            </thead>
            {
              data.values.map((value: Hash[]) => (
                <tbody>
                  { value.map((row) => <td>{ renderValue(row) }</td>) }
                </tbody>
              ))
            }
          </Table>
        </div>
      );
    }
    return <></>;
  };

  const getProgress = () => {
    return props.progress || progress;
  };

  useEffect(() => {
    if (props.isFullScreen) {
      ProcedureBoxService.getProgress(id)
        .then((res) => {
          setProgress(res.data);
        });
      hideLayout();
    }

    loadTitle(props.title);
  }, []);

  return (
    <div className={ `${styles.container} ${props.isFullScreen ? styles.progressExpanded : ''}` }>
      {getProgress()
        ? getProgress().map((data: any) => (
            <>
              <span className={styles.title} >{data.title} </span>
              <a href={`/procedure_box/show_procedure/progress/${props.progressId}`} target={'_blank'} className={styles.toExpanded} ><FiExternalLink/></a>

              {data.table ? renderTable(data) : renderValues(data.values)}
            </>
        ))
        : props.status === 'error' && (
          <span className={styles.title}>{t('procedureBox.processExtract.timeline.error')}</span>
        )
      }
    </div>
  );
}
