import {
  FaStickyNote, FaPaperPlane, FaFileAlt, FaPaperclip,
  FaPenNib, FaTrashAlt, FaArchive, FaFolder, FaHands,
  FaBoxOpen,
  FaLink,
} from 'react-icons/fa';
import { ImShuffle } from 'react-icons/im';
import { MultipleAction } from '../../@types/backgroundActions';
import { Icon } from '../ProcedureList/Actions/ResponsiveMenu/Icon';

export const initialData: MultipleAction[] = [
  {
    id: 'notes',
    label: 'procedureBox.actions.notes.title',
    icon: <FaStickyNote />,
  },
  {
    id: 'attachFiles',
    label: 'procedureBox.actions.attachFiles.title',
    icon: <FaPaperclip />,
  },
  {
    id: 'removeDocument',
    label: 'procedureBox.actions.removeDocument.title',
    icon: <FaTrashAlt />,
  },
  {
    id: 'joinDocuments',
    label: 'procedureBox.actions.joinDocuments.title',
    icon: <FaFolder />,
  },
  {
    id: 'prepareDocument',
    label: 'procedureBox.actions.prepareDocument.title',
    icon: <FaFileAlt />,
  },
  {
    id: 'tramit',
    label: 'procedureBox.actions.tramit.title',
    icon: <FaPaperPlane />,
  },
  {
    id: 'signDocument',
    label: 'procedureBox.actions.sign_document.title',
    icon: <FaPenNib />,
  },
  {
    id: 'archive',
    label: 'procedureBox.actions.archive.title_procedure',
    icon: <FaArchive />,
  },
  {
    id: 'changeFlux',
    label: 'procedureBox.actions.changeFlux.title',
    icon: <ImShuffle />,
  },
  {
    id: 'forceTramit',
    label: 'procedureBox.actions.forceTramit.title',
    icon: <FaHands />,
  },
  {
    id: 'unArchive',
    label: 'procedureBox.actions.unArchive.title',
    icon: <FaBoxOpen/>,
  },
  {
    id: 'attachProcedure',
    label: 'procedureBox.actions.attach_procedure.title',
    icon: <FaLink/>,
  },
  {
    id: 'initialPetition',
    label: 'procedureBox.actions.court.initialPetition',
    icon: <Icon text='court' />,
  },
  {
    id: 'intermediatePetition',
    label: 'procedureBox.actions.court.intermediatePetition',
    icon: <Icon text='court' />,
  },
  {
    id: 'ipm',
    label: 'procedureBox.actions.ipm.forwardIPM.text',
    icon: <Icon text='ipm'/>,
  },
  {
    id: 'spu',
    label: 'procedureBox.actions.spu.sendOficialLetter',
    icon: <Icon text='spu'/>,
  },
  {
    id: 'registrationSpu',
    label: 'procedureBox.actions.spu.registrationOficialLetter',
    icon: <Icon text='spu'/>,
  },
];
