import Select from '../../../../components/Select';
import styles from './styles.module.scss';

type OrderSelectProps = {
  label?: string;
  placeholder?: string;
  options: any;
  onChange: (val: any) => void;
};

export function OrderSelect(props: OrderSelectProps) {
  return (
    <div className={styles.container}>
      <label>{props.label}</label>
      <Select
        label={''}
        placeholder={props.placeholder}
        options={props.options}
        onChange={(e) => props.onChange(e) }
        className={styles.inputSelect}
        returnValue
        onlySelect
      />
    </div>
  );
}
