import { ReactNode, useState } from 'react';
import { Collapse } from '@mui/material';
import styles from './styles.module.scss';

type ItemType = {
  label: string;
  onClick: () => void;
  dataCy?: String;
};

type DropdownButtonProps = {
  title: string;
  items: ItemType[];
  icon?: ReactNode;
  dataCy?: String;
};

export function DropdownButton({
  title, icon, items, dataCy,
}: DropdownButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const renderOption = (item: ItemType) => {
    return (
      <button onClick={item.onClick} data-cy={item.dataCy} className={styles.listContent}>
        <span className={styles.title}>{item.label}</span>
      </button>
    );
  };

  return (
    <div className={styles.container}>
      <button className={styles.button} data-cy={dataCy} onClick={handleClick}>
        <div className={`${styles.buttonContent} ${isOpen ? styles.open : ''}`}>
          {icon}
          <span className={styles.title}>
            {title}
          </span>
        </div>
      </button>
      <Collapse in={ isOpen } className={styles.list}>
        {items.map((item) => renderOption(item))}
      </Collapse>
    </div>
  );
}
