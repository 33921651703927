import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import Select from '../Select';
import { SelectedProcedures } from '../SelectedProcedures';
import { Button } from '../Button';
import ProcedureBoxService from '../../services/procedureBoxService';
import { OptionType } from '../../@types/config';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { InputText } from '../InputText';

type ProcessesArchiveProps = {
  values?: any;
  waiting?: boolean;
  multipleActions?: MultipleActionStep;
};

export function ProcessesArchives(props: ProcessesArchiveProps) {
  const {
    proceduresSeleted, setAlert, removeProcedures, setModal,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const { handleSubmit, control } = useForm();

  const [description, setDescription] = useState('');
  const [reasons, setReasons] = useState<OptionType[]>([
    {
      label: '',
      value: 1,
    },
  ]);
  const [reasonsValue, setReasonsValue] = useState(0);
  const [reasonsLabel, setReasonsLabel] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    const loadReasons = async () => {
      const response = await ProcedureBoxService.getReasonsArchiveProcedure();
      const optionsReasons = response.reasons.map((reason: OptionType[]) => {
        return {
          value: reason[0],
          label: reason[1],
        };
      });

      setReasons(optionsReasons);
    };

    const getValues = (values: any) => {
      if (props.values?.description && props.values?.reasonsValue !== 0) {
        setReasonsValue(values.reasonsValue);
        setReasonsLabel(values.reasonsLabel);
        setDescription(values.description);
      }
    };

    getValues(props.values);
    loadReasons();
  }, []);

  useEffect(() => {
    if (description.length >= 1 && reasonsValue !== 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [description, reasonsValue]);

  const getAlertText = () => {
    if (proceduresSeleted.length > 1) {
      return t('procedureBox.actions.archive.archive_alert.title_procedures', {
        proceduresSelectedLength: proceduresSeleted.length,
      });
    }
    return t('procedureBox.actions.archive.archive_alert.title_procedure', {
      procedureNumber: proceduresSeleted[0].process_number,
    });
  };

  const getAlertLabel = () => {
    if (proceduresSeleted.length > 1) {
      return t('procedureBox.actions.archive.archive_alert.label_procedures', {
        proceduresSelectedLength: proceduresSeleted.length,
      });
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.archive.archive_alert.label_procedure'
        components={{ a: <a /> }}
        values={{ procedureNumber: proceduresSeleted[0].process_number }}
      />
    );
  };

  const handleSelect = (reason: any) => {
    setReasonsLabel(reason.label);
    setReasonsValue(reason.value);
  };

  const handleArchive = () => {
    const body = {
      reasonsValue,
      reasonsLabel,
      description,
      waiting: props.waiting,
    };

    const procedures = proceduresSeleted;
    const type = 'archive';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
    setAlert(undefined);
    setModal(undefined);
  };

  const submit = () => {
    if (proceduresSeleted.length > 1) {
      setAlert({
        handleConfirm: () => handleArchive(),
        visible: true,
        title: getAlertText(),
        text: getAlertLabel(),
        type: 'warning',
      });
    } else {
      handleArchive();
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(submit)}>
      <SelectedProcedures />
      <div className={styles.header}>
        <span>{t('procedureBox.actions.archive.archive_fields.info')}</span>
      </div>
      <Grid
        container
        columns={{ xs: 1, sm: 2, md: 2 }}
        className={styles.field}
      >

        <Grid xs={1} sm={1} md={1}>
          <Select
            options={reasons}
            placeholder={t(
              'procedureBox.actions.archive.archive_fields.select_reason',
            )}
            onChange={(reason) => handleSelect(reason)}
            label={t('procedureBox.actions.archive.archive_fields.reason')}
            value={{ label: reasonsLabel, value: reasonsValue }}
            required
          />
        </Grid>

        <Grid className={styles.textareaField} xs={1} sm={2} md={2}>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <InputText
              {...field}
              label={t('procedureBox.actions.archive.archive_fields.observation')}
              className={styles.textarea}
              onChange={(event) => setDescription(event.target.value)}
              value={description}
              placeholder={t('procedureBox.actions.archive.archive_fields.placeholder_observation')}
              required
            />
          )}
        />
      </Grid>
      </Grid>

      <div className={styles.button}>
        <Button
          size='flat'
          title={t('procedureBox.actions.archive.archive_fields.button_text')}
          round
          buttonType='primary'
          disabled={disabledButton}
        />
      </div>
    </form>
  );
}
