import { t } from 'i18next';
import { useContext } from 'react';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Alert } from '../Alert';
import styles from './styles.module.scss';

export function AttachExtractAlert() {
  const { batches } = useContext(BackgroundActionsContext);

  return (
    <div className={styles.container}>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'attachExtract').map((batch) => (
        <Alert
          icon={<FaFileInvoiceDollar />}
          title={t('procedureBox.actions.attachExtract.modal.alertLoading.title')}
          text={t('procedureBox.actions.attachExtract.modal.alertLoading.text')}
          batch={batch}
        />
      ))}
    </div>
  );
}
