import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Menu as MuiMenu } from '@mui/material';
import styles from './styles.module.scss';
import Show from '../../Show';

export type ItemType = {
  id: string;
  selected: boolean;
};

type MenuProps = {
  itens: ItemType[];
  onChange: (query: string) => void;
  chevron: boolean;
  title?: string;
};

export function Menu(props: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [items, setItems] = useState<ItemType[]>(props.itens);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (item: ItemType) => {
    const list = items.map((itemSelected) => {
      if (item.id === itemSelected.id) {
        return { ...itemSelected, selected: true };
      }
      return { ...itemSelected, selected: false };
    });

    setItems(list);
    handleClose();
    props.onChange(item.id);
  };

  return (
    <div
      className={`${styles.container} ${open ? styles.containerOpen : ''}`}
      onClick={open ? handleClose : handleClick}
    >
      <span className={`${styles.selectedItem}`}>{props.title ? props.title : items.find((i) => i.selected)?.id}</span>
      <Show if={props.chevron}>
        { open ? <FaChevronUp className={ styles.chevron } /> : <FaChevronDown className={ styles.chevron } /> }
      </Show>
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((i) => (
          <div
            className={`${i.selected ? styles.itemSelected : styles.item}`}
            onClick={i.selected ? () => setAnchorEl(null) : () => handleChange(i)}
          >
            <p>{i.id}</p>
          </div>
        ))}
      </MuiMenu>
    </div>
  );
}
