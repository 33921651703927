import { useContext, useEffect } from 'react';
import { Procedure } from '../../../@types/model';
import { Alert } from '../../../components/Alert';
import { LoadingBottomAlerts } from '../../../components/LoadingBottomAlerts';
import { Modal } from '../../../components/Modal';
import { Menu } from '../../../components/ProcedureList/Actions/Menu';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import Screen from '../../../helpers/screen';
import { ResponsiveMenu } from '../../../components/ProcedureList/Actions/ResponsiveMenu';

type ActionsProps = {
  procedure: Procedure;
};

export function Actions(props: ActionsProps) {
  const {
    addProcedures, isEmpty, isShowProcedure,
  } = useContext(ProcedureActionsContext);
  const isMobile = Screen.isMobile();

  useEffect(() => {
    addProcedures([props.procedure]);
  }, []);

  return (
    <>
      { (!isEmpty || isShowProcedure) && (!isMobile ? <Menu procedure={props.procedure} />
        : <ResponsiveMenu procedure={props.procedure} key={props.procedure?.id} />) }
      <Modal />
      <Alert />
      <LoadingBottomAlerts />
    </>
  );
}
