import {
  useEffect,
  useState,
  useContext,
} from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import Show from '../Show';
import styles from './styles.module.scss';
import { QueryContext } from '../../context/queryContext';
import { PaginationMenu } from '../PaginationMenu';
import { Actions } from '../ProcedureList/Actions';
import { SearchResult } from '../ProcedureList/SearchResult';
import { SortMenu } from '../SortMenu';
import { MarkersSelect } from '../MarkersSelect';
import { ResponsiveMenu as MarkersResponsiveMenu } from '../MarkersSelect/ResponsiveMenu';
import { EmptyCollection } from './EmptyCollection';
import { ProcedureList } from '../ProcedureList';
import { Toast } from '../Toast';
import { Tab } from '../Tab';

export function CollectionHome() {
  const { procedures } = useContext(QueryContext);
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 700px)').matches,
  );
  const [scroll, setScroll] = useState(0);

  window.addEventListener('scroll', () => {
    setScroll(window.scrollY);
  });

  const getQntProcedures = () => {
    if (procedures !== undefined) {
      return procedures.length;
    }
    return 0;
  };
  useEffect(() => {
    window
      .matchMedia('(max-width: 800px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      <Show if={getQntProcedures() > 0}>
        <Grid
          container
          columns={{ xs: 2, sm: 12, md: 12 }}
          spacing={2}
          className={ styles.childrenContent } >
            <Grid item xs={2} sm={3} md={2}>
              {matches ? <MarkersResponsiveMenu /> : <MarkersSelect />}
            </Grid>
            <Grid item xs={2} sm={9} md={10}>
              <div className={ styles.backgroundProcess }>
                { sessionStorage.getItem('query') != null && (
                  <Toast
                    type='info'
                    collapse
                    text={t('procedureCollections.redirect', { total: getQntProcedures() })}
                    open
                  />
                )}
                <Tab />
                <SearchResult/>
                <div className={ styles.actions }>
                  <Actions />
                  <div className={ styles.menu }>
                    <SortMenu />
                    <PaginationMenu />
                  </div>
                </div>
                <ProcedureList origin='collection' scroll={scroll}/>
                <div className={ styles.bottomMenu }>
                  <PaginationMenu />
                </div>
              </div>
            </Grid>
          </Grid>
      </Show>
      <Show if={getQntProcedures() <= 0}>
        <EmptyCollection />
      </Show>
    </>
  );
}
