import { useContext } from 'react';
import { Form } from './Form';
import { SelectedProcedures } from '../SelectedProcedures';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { OptionType } from '../../@types/config';

type ChangeProps = {
  flux?: OptionType,
  observation?: string;
  multipleActions?: MultipleActionStep;
};

export function ChangeFlux(props: ChangeProps) {
  const {
    proceduresSeleted, removeProcedures, setAlert, setModal,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const handleSubmit = (flux: OptionType, observation: string) => {
    const type = 'changeFlux';
    const procedures = proceduresSeleted;
    const id = (Math.random() + 1).toString(36).substring(1);
    const body = {
      flux,
      observation,
      in_bulk: procedures.length > 1,
      waiting: '',
    };
    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
    setAlert(undefined);
    setModal(undefined);
  };

  return (
    <div>
      <SelectedProcedures />
      <Form submit={handleSubmit}
        observation={props.observation}
        flux={props.flux}
      />
    </div>
  );
}
