import { HTMLProps, ReactNode } from 'react';
import styles from './styles.module.scss';

interface ErrorFieldProps extends HTMLProps<HTMLSpanElement> {
  text?: ReactNode;
}

export function ErrorField(props: ErrorFieldProps) {
  return <span className={ styles.container }>{props.text}</span>;
}
