import { OptionType } from './config';
import { Procedure } from './model';

export const requestCalculationInitialState: RequestCalculationDocument = {
  orderData: undefined,
  subProcedure: undefined,
  dependencies: undefined,
  calculationData: undefined,
};

export type RequestCalculationDocument = {
  orderData?: RequestData;
  subProcedure?: Procedure;
  dependencies?: ProcedureDependencies;
  calculationData?: CalculationInfoData;
};

export type RequestData = {
  generateNumber: boolean;
};

export type ProcedureDependencies = {
  main_procedure: string;
  calculation_types: OptionType[];
  document_types: OptionType[];
  interest_initial_terms: OptionType[];
  monetary_indices: OptionType[];
  monetary_term_indices: OptionType[];
  moratorium_interest_indices: OptionType[];
  object: OptionType[];
  participations: OptionType[];
  requesters: InterestedParts[];
  update_end_terms: OptionType[];
};

export type CalculationInfoData = {
  main_calculate: MainCalculate;
  attorney_calculate: AttorneyCalculate;
};

export type MainCalculate = {
  starting_point: string;
  ending_point: string;
  total_value_authorial: string;
  citation_date: string;
  deadline: string;
  fee_conviction: boolean;
  fee_update_criteria: string;
  another_notes: string;
  calculation_details: string;
  monetary_index: OptionType;
  monetary_index_note: string;
  monetary_term_index: OptionType;
  monetary_term_note: string;
  moratorium_interest_index: OptionType;
  moratorium_interest_index_note: string;
  interest_initial_term: OptionType;
  interest_initial_term_note: string;
  update_end_term: OptionType;
  update_end_term_note: string;
  calculation_type: number;
};

export type AttorneyCalculate = {
  calculation_type: number;
  calculation_details: string;
  deadline: string;
};

export type InterestedParts = {
  id: number;
  address: string;
  address_number: string;
  cep: string;
  city: string;
  cnpj: string;
  complement: string;
  cpf: string;
  gender: string | number;
  issuing_authority: string;
  name: string;
  neighborhood: string;
  participation_description: string;
  participation_id: number;
  rg: string;
};

export type Applicant = {
  participation_id: number;
  legalPerson: string;
  gender: string;
  cpf: string;
  rg: string;
  issuing_authority: string;
  name: string;
  cnpj: string;
  corporate_name: string;
  cep: string;
  address: string;
  address_number: string;
  complement: string;
  neighborhood: string;
  city: string;
};
