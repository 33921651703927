import { useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { Menu, MenuItem } from '@material-ui/core';
import styles from './styles.module.scss';

type MenuItemProp = {
  text: string;
  onClick: () => void;
  hidden?: boolean;
};

type NoteMenuProps = {
  menuItems: MenuItemProp[];
};

export function MoreVerticalMenu({ menuItems }: NoteMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: Element) => {
    setAnchorEl(event);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <FiMoreVertical onClick={(e) => { handleClick(e.currentTarget); }} className={styles.menu} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} classes={{ list: `${styles.menuMUI}` }}>
        {menuItems.map((menuItem, index) => (
          !menuItem?.hidden && (
            <MenuItem key={index} onClick={() => { menuItem.onClick(); handleClose(); }}>
              <span className={ styles.item }>
                {menuItem.text}
              </span>
            </MenuItem>
          )
        ))}
      </Menu>
    </>
  );
}
