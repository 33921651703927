import { LinearProgress, ThemeProvider } from '@mui/material';
import { t } from 'i18next';
import { Batch } from '../../../@types/backgroundActions';
import styles from './styles.module.scss';
import { customTheme } from './theme';

type ProgressBarProps = {
  batch: Batch;
};
export function ProgressBar(props: ProgressBarProps) {
  const getPercentageSuccess = () => {
    return Math.round(((props.batch.successList ? props.batch.successList.length : 0) / props.batch.count) * 100);
  };

  const handleProgressMessage = (batch: Batch) => {
    if (batch.type === 'prepareDocument') {
      if (batch.procedures.length > 1) {
        return t(`procedureBox.actions.${props.batch.type}.modal.alertLoading.results.progress.manyProcess`, {
          number: props.batch.successList.length,
          total: batch.procedures.length,
          not: props.batch.failedList.length === batch.procedures.length ? 'não' : '',
        });
      }
      return t(`procedureBox.actions.${props.batch.type}.modal.alertLoading.results.progress.oneProcess`, {
        number: props.batch.procedures[0].process_number,
        not: props.batch.failedList.length === batch.procedures.length ? 'não' : '',
      });
    }
    const text = `${props.batch.successList ? props.batch.successList?.length : 0} de ${props.batch.count}`;
    const textEnd = `${t(`procedureBox.actions.${props.batch.type}.modal.alertLoading.results.progress`)}`;
    return `${text} ${textEnd}`;
  };

  return (
    <div className={ styles.container }>
      <ThemeProvider theme={customTheme}>
        <LinearProgress
          variant="determinate"
          value={getPercentageSuccess()}
          color={props.batch.status === 'finished' || props.batch.status === 'waitingClose' ? 'secondary' : 'primary'}
          className={`
                      ${styles.linear} 
                      ${props.batch.failedList.length > 0 && (props.batch.status === 'finished' || props.batch.status === 'waitingClose') ? styles.withError : ''}
                    `}
        />
      </ThemeProvider>
      <div className={styles.description}>
        <span>{handleProgressMessage(props.batch)}</span>
        <span>{getPercentageSuccess()}%</span>
      </div>
    </div>
  );
}
