import { Skeleton } from '@mui/material';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Format from '../../../helpers/format';
import { Tooltip } from '../../Tooltip';
import styles from './styles.module.scss';

type InfoProps = {
  title: string;
  totalValue: number;
  backgroundColor: string;
  textColor: string;
  tooltip?: string;
  loading: boolean;
  currency?: boolean;
};

export function Info(props: InfoProps) {
  const containerStyle = {
    backgroundColor: `${props.backgroundColor}`,
  };

  const textStyle = {
    color: `${props.textColor}`,
  };

  return (
    <>
      {!props.loading
        ? <div className={ styles.header } style={containerStyle}>
        <Tooltip title={ props.tooltip || ''} >
          <p className={ styles.title }>{props.title}</p>
        </Tooltip>
        {props.currency === false && props.tooltip ? <p className={styles.totalProcedure} style={textStyle}>
          {props.totalValue.toLocaleString('pt-BR', { minimumIntegerDigits: 2, useGrouping: false })} <Tooltip title={ props.tooltip }><FaRegQuestionCircle /></Tooltip>
          </p> : <p className={styles.total} style={textStyle}>
            {Format.currency(props.totalValue)}
          </p> }
      </div>
        : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
    </>
  );
}
