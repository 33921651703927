import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { ImShuffle } from 'react-icons/im';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Alert } from '../Alert';

export function ChangeFluxAlert() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'changeFlux').map((batch) => (
        <Alert
          icon={<ImShuffle />}
          title={t('procedureBox.actions.changeFlux.title')}
          text={<Trans
            i18nKey='procedureBox.actions.changeFlux.modal.alertLoading.text'
            components={{ bold: <strong />, fluxName: batch.body.flux.label }}
          />}
          batch={batch}
        />
      ))}
    </div>
  );
}
