import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { Header } from '../../../../components/Header';
import styles from './styles.module.scss';
import { AutoCompleteRemote } from '../../../../components/SearchBar/FilterAdvanced/AutoComplete';
import DashboardService from '../../../../services/dashboardService';
import { OptionType } from '../../../../@types/config';
import Show from '../../../../components/Show';
import { Label } from '../../../../components/Label';
import { Tooltip } from '../../../../components/Tooltip';
import { Table } from '../../../../components/Table';
import { Hash } from '../../../../@types/digitalProcesses/timeline';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import { Button } from '../../../../components/Button';
import Format from '../../../../helpers/format';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import FormHelper from '../../../../helpers/form';

type NewRequestedLegalActionProps = {
  fluxId?: number;
  closeModal: () => void;
};

export function NewRequestedLegalAction(props: NewRequestedLegalActionProps) {
  const { handleSubmit, control } = useForm();
  const { addToasts } = useContext(ProcedureActionsContext);

  const [contributor, setContributor] = useState<any[]>([]);
  const [inscriptions, setInscriptions] = useState<any[]>([]);
  const [inscriptionIds, setInscriptionIds] = useState<number[]>([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleData = async (value?: OptionType) => {
    if (value) {
      const contributorData = await DashboardService.getContributorData(value.value);
      if (contributorData.length > 0) {
        setContributor(contributorData);
        setMessage(t('procedureBox.newProcedure.newRequestedLegalAction.form.contributorMessage'));
      } else {
        setMessage(t('procedureBox.newProcedure.newRequestedLegalAction.form.contributorError'));
      }
    }
  };

  useEffect(() => {
    if (contributor) {
      const fetchData = async () => {
        setInscriptions(await DashboardService.getInscriptionsData(contributor[0].legal_action_inscription_ids));
        setInscriptionIds(contributor[0].legal_action_inscription_ids);
      };
      fetchData();
    }
  }, [contributor]);

  useEffect(() => {
    setMessage(null);
    setLoading(false);
  }, [inscriptions]);

  const setIds = (id: number) => {
    const newArray = inscriptionIds.filter((inscriptionId) => inscriptionId !== id);
    const hasInscription = inscriptionIds.some((item) => item === id);
    if (hasInscription && newArray.length > 0) {
      setInscriptionIds(newArray);
    } else if (!hasInscription) {
      setInscriptionIds([...inscriptionIds, id]);
    }
  };

  const renderTable = (data: any) => {
    if (data.length > 0) {
      const columns = data[0].map((val: Hash) => val.key);
      return (
        <div className={styles.table}>
          <Table>
            <thead>
              <tr>
                {
                  columns.map((col: string) => <th>{t(`procedureBox.newProcedure.newRequestedLegalAction.form.${col}`)}</th>)
                }
              </tr>
            </thead>
            <tbody>
              {
                data.map((value: Hash[]) => (
                  <tr>
                    {
                      value.map((v: Hash, index: number) => (
                        index !== 0
                          ? <td>{v.value}</td>
                          : <td>
                              <input
                                type='checkbox'
                                id='inscriptions_id'
                                name={'inscriptions_id'}
                                checked={inscriptionIds.some((item) => item === v.value)}
                                onClick={() => setIds(v.value)}
                              />
                            </td>
                      ))
                    }
                  </tr>
                ))
              }
             </tbody>
          </Table>
        </div>
      );
    }
    return '';
  };

  const submit = (form: any) => {
    setLoading(true);
    ProcedureBoxService.requestToLegalAction(inscriptionIds)
      .then((res) => {
        setMessage(res.message);
        ProcedureBoxService.sendToLegalAction(inscriptionIds)
          .then((resp) => {
            navigate(`/procedure_box/show_procedure/${resp.id}`);
          });
        props.closeModal();
        addToasts({
          type: 'success',
          text: FormHelper.customToastMessage(res.id),
        });
      })
      .catch(() => setLoading(false));
  };

  const setColor = (value: number) => {
    return value > 5400 ? styles.green : styles.red;
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(submit)}>
      <Header text={t('procedureBox.newProcedure.newRequestedLegalAction.form.data')}/>
      <AutoCompleteRemote
        id='procedure_numbers_num_cont'
        title={t('procedureBox.newProcedure.newRequestedLegalAction.form.contributors.title')}
        placeholder={t('procedureBox.newProcedure.newRequestedLegalAction.form.contributors.placeholder')}
        tooltip={t('procedureBox.newProcedure.newRequestedLegalAction.form.contributors.tooltip')}
        onChange={handleData}
        getUrl={DashboardService.getContributors}/>
      <Show if={contributor.length > 0}>
        <Grid
          container
          className={styles.containerInfo}
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            xs={1}
            sm={10}
            md={12}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'contributor'}
              render={() => (
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.contributor')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.contributor')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={contributor[0].name} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'document'}
              render={() => (
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.document')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.document')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={contributor[0].cpf || contributor[0].cnpj} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'receipt_type'}
              render={() => (
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.receipt_type')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.receipt_type')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={contributor[0].filter_receipt_type_names[0]} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'next_prescription'}
              render={() => (
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.next_prescription')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.next_prescription')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={Format.formatDate(contributor[0].next_prescription)} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'filter_inscriptions'}
              render={() => (
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.filter_inscriptions')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.filter_inscriptions')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={contributor[0].count_contributor_inscriptions} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'filter_balance'}
              render={() => (
                <div className={`${styles.info} ${setColor(contributor[0].filter_balance)}`}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.filter_balance')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.filter_balance')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={`R$ ${Format.formatNumber(contributor[0].filter_balance)}`} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'conglobated_inscriptions'}
              render={() => (
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.conglobated_inscriptions')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.conglobated_inscriptions')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={contributor[0].legal_action_inscription_count} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'conglobated_balance'}
              render={() => (
                <div className={`${styles.info} ${setColor(contributor[0].conglobated_balance)}`}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.conglobated_balance')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.conglobated_balance')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={`R$ ${Format.formatNumber(contributor[0].conglobated_balance)}`} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'all_inscriptions'}
              render={() => (
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.all_inscriptions')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.all_inscriptions')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={contributor[0].c_count_inscriptions} />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={3}
            className={styles.input}
          >
            <Controller
              control={control}
              name={'total_balance'}
              render={() => (
                <div className={`${styles.info} ${setColor(contributor[0].balance_contributor_inscriptions)}`}>
                  <div className={styles.header}>
                    <Label text={t('procedureBox.newProcedure.newRequestedLegalAction.form.total_balance')} />
                    <Tooltip title={t('procedureBox.newProcedure.newRequestedLegalAction.form.total_balance')}>
                      <FaRegQuestionCircle />
                    </Tooltip>
                  </div>
                  <Label text={`R$ ${Format.formatNumber(contributor[0].balance_contributor_inscriptions)}`} />
                </div>
              )}
            />
          </Grid>
        </Grid>
        <Show if={inscriptionIds.length > 0}>
          { renderTable(inscriptions) }
        </Show>
      </Show>
      <span className={styles.message}>{ message }</span>
      <div className={styles.submit}>
        <Button
          title={t('procedureBox.newProcedure.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
        />
      </div>
    </form>
  );
}
