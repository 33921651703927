import { t } from 'i18next';
import { useContext } from 'react';
import EmptySearch from '../../assets/emptySearch.svg';
import EmptyProcedure from '../../assets/emptyProcedure.svg';
import { QueryContext } from '../../context/queryContext';
import Show from '../Show';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';

export function EmptyList() {
  const {
    quickSearch, filterMarkers, filterGenericMarkers, redirectToProcedureCollection, box,
  } = useContext(QueryContext);
  const { openNewCitationIntimation, openNewProcedure } = useContext(ProcedureActionsContext);
  return (
    <div className={styles.emptyListContainer}>
      <Show if={quickSearch === '' && filterMarkers.length === 0 && filterGenericMarkers.length === 0}>
        <img src={EmptyProcedure} alt={t('general.noOptions')}/>
        { box === 'citationIntimation'
          ? <>
              <p className={styles.title}>
                {t('procedureBox.procedureList.citationIntimation.empty')}
              </p>
              <p
                onClick={openNewCitationIntimation}
                className={styles.link}
                data-cy={'cypress-link-to-new-citation-intimation-modal'}
              >
                  {t('procedureBox.procedureList.citationIntimation.link')}
              </p>
            </>
          : <>
              <p className={styles.title}>{t('procedureBox.procedureList.empty')}</p>
              <p
                onClick={openNewProcedure}
                className={styles.link}
                data-cy={'cypress-link-to-new-procedure-modal'}
              >
                {t('procedureBox.procedureList.link')}
              </p>
            </>
        }
      </Show>
      <Show if={quickSearch !== '' || filterMarkers.length > 0 || filterGenericMarkers.length > 0}>
        <img src={EmptySearch} alt={t('general.noOptions')}/>
        <p className={styles.title}>{t('general.message.emptyList')}</p>
        <a className={styles.link} onClick={redirectToProcedureCollection}>{t('general.message.label')}</a>
      </Show>
    </div>
  );
}
