import { useState, useEffect, useContext } from 'react';
import Carousel from 'react-elastic-carousel';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Show from '../Show';
import { QueryContext } from '../../context/queryContext';
import { Loading } from '../Loading';
import { FluxTab, GenericMarkerTab } from '../../@types/tab';
import styles from './styles.module.scss';
import './carousel.scss';
import { FluxItem } from './FluxItem';
import { MarkerItem } from './MarkerItem';
import { SwitchButton } from '../SwitchButton';

export function Tab() {
  const { t } = useTranslation();
  const {
    fluxes, markers, loadingTabs, handleFluxSelected, tabSwitch, setTabSwitch, handleMarkerSelected, box,
  } = useContext(QueryContext);
  const [emptyTabs, setEmptyTabs] = useState(false);
  const fluxSelected: FluxTab[] = fluxes ? fluxes.filter((item) => item.selected === true) : [];
  const [selectedMarker, setSelectedMarker] = useState(Object.values(markers).flat().some((marker) => marker.selected === true));
  const markersCount = Object.values(markers).flatMap((tab) => tab as any).reduce((acc, curr) => acc + curr.total, 0);
  const breakPoints = [
    {
      width: 150, itemsToShow: 1, showEmptySlots: false,
    },
    {
      width: 300, itemsToShow: 2, showEmptySlots: false,
    },
    {
      width: 450, itemsToShow: 3, showEmptySlots: false,
    },
    {
      width: 600, itemsToShow: 4, showEmptySlots: false,
    },
    {
      width: 750, itemsToShow: 5, showEmptySlots: false,
    },
    {
      width: 900, itemsToShow: 6, showEmptySlots: false,
    },
    {
      width: 1050, itemsToShow: 7, showEmptySlots: false,
    },
    {
      width: 1200, itemsToShow: 8, showEmptySlots: false,
    },
    {
      width: 1350, itemsToShow: 9, showEmptySlots: false,
    },
  ];
  const [renderCarousel, setRenderCarousel] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(window.innerWidth / 150);
  const renderTab = (tab: number) => {
    switch (tab) {
      case 0:
        return fluxes && fluxes.map((value) => (
          <FluxItem
            value={value}
            onSelect={() => handleFluxSelected(value)}/>));
      case 1:
        return Object.values(markers).map((item) => {
          return item.map((marker) => (
            <MarkerItem
            value={marker}
            onSelect={() => { handleMarkerSelected(marker); }}/>
          ));
        });
      default:
        return (<></>);
    }
  };
  const clearFilter = () => {
    handleFluxSelected((fluxSelected[0]));
    handleMarkerSelected({} as GenericMarkerTab);
  };

  useEffect(() => {
    window
      .matchMedia('(max-width: 1400px)')
      .addEventListener('change', (e) => {
        setRenderCarousel(e.matches);
        setItemsToShow(window.innerWidth / 150);
      });
  }, []);

  useEffect(() => {
    setEmptyTabs(!loadingTabs && (fluxes.some((flux: FluxTab) => flux.total === 0) || fluxes.length === 0));
  }, [box, fluxes, loadingTabs]);

  useEffect(() => {
    setSelectedMarker(Object.values(markers).flat().some((marker) => marker.selected === true));
  }, [markers]);

  return (
    <div className={styles.tabContainer}>
      <div className={styles.button}>
        <div
          className={`${styles.topButton} ${styles.selected}`}
          onClick={() => clearFilter()}
        >
          <Show if={fluxSelected.length >= 1 || selectedMarker}>
            <FaArrowLeft />
            <p> { t('procedureBox.filterTabs.allTabs') }</p>
          </Show>
        </div>
        {box !== 'citationIntimation' && <SwitchButton
          leftText='Fluxos' leftOnClick={() => { setTabSwitch(0); clearFilter(); } }
          rightText='Marcadores' rightOnClick={() => { setTabSwitch(1); clearFilter(); } }
          selected={tabSwitch}/>}
      </div>
      <Show if={loadingTabs && box !== 'citationIntimation'}>
        <Loading variant={'rectangular'} styles={ styles.tabLoadingContainer }/>
      </Show>
      <Show if={!loadingTabs && fluxes.length > 0 && box !== 'citationIntimation' && !emptyTabs}>
        {
          !!renderCarousel || fluxes.length > 9 || markersCount > 9 ? (
            <div>
              <Carousel
                itemPadding={[5, 0]}
                outerSpacing={0}
                itemsToShow={itemsToShow}
                itemsToScroll={1}
                isRTL={false}
                pagination={false}
                showEmptySlots={false}
                itemPosition={'START'}
                breakPoints={breakPoints}
              >
                {renderTab(tabSwitch)}
              </Carousel>
          </div>
          ) : (
          <div className={styles.tabItemContainer}>
            {renderTab(tabSwitch)}
          </div>
          )
        }
      </Show>
      <Show if={emptyTabs}>
        <div className={styles.tabItemContainer}>
          <div className={styles.emptyContainer}>
            <p className={styles.title}>{t('procedureBox.filterAdvanced.fluxes.message.emptyList')}</p>
            <p className={styles.link} >{t('procedureBox.filterAdvanced.fluxes.message.label')}</p>
          </div>
        </div>
      </Show>
    </div>
  );
}
