import { Grid, Skeleton } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Individual } from '../../../@types/model';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { Item } from './Item';
import styles from './styles.module.scss';

type SuggestionsProps = {
  onChange: (individual: Individual) => void;
  selected?: Individual;
};

export function Suggestions(props: SuggestionsProps) {
  const [users, setUsers] = useState<Individual[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    ProcedureBoxService.getTramitSuggestions()
      .then((res) => {
        setLoading(false);
        setUsers(res);
      });
  }, []);

  const renderLoading = () => {
    return (
      [1, 2, 3].map(() => (
      <Grid
        item
        md={ 1 }
        sm={ 1 }
        xs={ 1 }
      >
        <Skeleton variant="rectangular" className={styles.skeleton} />
      </Grid>
      ))
    );
  };

  const handleClick = (individual: Individual) => {
    props.onChange(individual);
  };

  const renderList = () => {
    if (users.length > 0) {
      return (
        users.map((individual) => (
          <Grid
            item
            md={ 1 }
            sm={ 1 }
            xs={ 1 }
          >
            <Item
              selected={props.selected?.individual_id === individual.individual_id}
              individual={individual}
              onClick={() => handleClick(individual)}
              dataCy={'cypress-user-suggestion-tramit'}
            />
          </Grid>
        ))
      );
    }
    return (
      <Grid
        item
        md={ 3 }
        sm={ 3 }
        xs={ 1 }
      >
        <div className={styles.empty}>
          <span>{t('procedureBox.actions.tramit.modal.suggestions.empty')}</span>
        </div>
      </Grid>
    );
  };

  return (
    <div className={ styles.container }>
      <div className={styles.header}>
        <span className={styles.title}>{t('procedureBox.actions.tramit.modal.suggestions.title')}</span>
      </div>
      <Grid
        container
        columns={{ xs: 1, sm: 3, md: 3 }}
        className={styles.content}
      >
        {
          loading ? renderLoading() : renderList()
        }
      </Grid>
    </div>
  );
}
