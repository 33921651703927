import { Popover } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { QueryContext } from '../../context/queryContext';
import { Button } from '../Button';
import { InputDate } from '../InputDate';
import { InputText } from '../InputText';
import styles from './styles.module.scss';
import Show from '../Show';

type DateTimePickerProps = {
  label: string;
  placeholder?: string;
  tooltip?: string;
  filterKey?: string;
  disabledPopover?: boolean;
  setValueFrom?: (value: string) => void;
  setValueUntil?: (value: string) => void;
  onChange?: (beginDate: string, endDate: string) => void;
};

export function DateTimePicker(props: DateTimePickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const [valueUntil, setValueUntil] = useState('');
  const [valueFrom, setValueFrom] = useState('');
  const { hasAnyFilter, updateAdvancedFilter } = useContext(QueryContext);

  useEffect(() => {
    if (hasAnyFilter === false) {
      setValueUntil('');
      setValueFrom('');
    }
  }, [hasAnyFilter]);

  useEffect(() => {
    const keyFrom = `${props.filterKey}_gteq`;
    const keyUntil = `${props.filterKey}_lteq`;

    if (props.onChange) {
      props.onChange(valueFrom, valueUntil);
    } else if (props.filterKey) {
      updateAdvancedFilter('', '', {
        [keyFrom]: valueFrom,
        [keyUntil]: valueUntil,
      } as any);
    }
  }, [valueUntil, valueFrom]);

  const handleClick = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    if (props.setValueFrom && props.setValueUntil) {
      props.setValueFrom(valueFrom);
      props.setValueUntil(valueUntil);
    }
  };

  const open = Boolean(anchorEl);

  const popoverContent = () => {
    return (
      <div className={ styles.container }>
        <div className={ styles.buttonGroup }>
          <div className={ styles.button }>
            <InputDate
              label={t('procedureBox.filterAdvanced.procedureCreatedAt.from')}
              value={valueFrom}
              onChange={setValueFrom}
            />
          </div>
          <div>
            <InputDate
              label={t('procedureBox.filterAdvanced.procedureCreatedAt.until')}
              value={valueUntil}
              onChange={setValueUntil}
            />
          </div>
        </div>
        <Show if={!props.disabledPopover}>
          <div className={ styles.footer }>
            <Button
              buttonType='primary'
              round
              size='flat'
              title={t('general.concluded')}
              onClick={handleClose}
            />
          </div>
        </Show>
      </div>
    );
  };

  return (
    <div>
      {
        !props.disabledPopover ? (
          <>
            <InputText
              readOnly={true}
              label={props.label}
              placeholder={props.placeholder}
              value={`${valueFrom} - ${valueUntil}`}
              tooltip={props.tooltip}
              onChange={() => {}}
              onFocus={(event) => { handleClick(event); } }
            />
            <Popover
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              disableAutoFocus
              disableEnforceFocus
              disableRestoreFocus
            >
              {popoverContent()}
            </Popover>
          </>
        ) : popoverContent()
      }
    </div>
  );
}
