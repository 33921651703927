import { useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Procedure as ProcedureType } from '../../../@types/model';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import styles from './styles.module.scss';
import Show from '../../Show';

type ProcedureProps = {
  procedure: ProcedureType;
  link?: boolean;
};
export function Procedure(props: ProcedureProps) {
  const { removeProcedure } = useContext(ProcedureActionsContext);
  const handleRender = () => {
    if (props.link) {
      return <Link to={`/procedure_box/show_procedure/${props.procedure.id}`} className={styles.number}>{props.procedure.process_number}</Link>;
    }
    return <span className={styles.number}>{props.procedure.process_number}</span>;
  };

  return (
    <div className={ styles.container }>
      {handleRender()}
      <Show if={!props.link}>
        <FaTimes className={styles.delete} onClick={() => removeProcedure(props.procedure)}/>
      </Show>
    </div>
  );
}
