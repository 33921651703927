import styles from './styles.module.scss';

type IFrameProps = {
  url: string;
};

export function IFrame(props: IFrameProps) {
  return (
    <div className={ styles.content }>
      <iframe
        src={props.url}
        className={styles.iframe}
        allowTransparency
        loading="lazy"/>
    </div>
  );
}
