import styles from './styles.module.scss';

type SeparatorProps = {
  title: string;
};

export function Separator(props: SeparatorProps) {
  return (
  <div className={ styles.separator }>
    <p className={styles.title}>
      {props.title}
    </p>
  </div>);
}
