import { t } from 'i18next';
import styles from './styles.module.scss';
import { truncate } from '../../../../helpers/strings';

type LabelGroupProps = {
  label: string;
  value: any;
  icon?: any;
  onClick?: () => void;
};

export function LabelGroup(props: LabelGroupProps) {
  const renderLink = () => {
    return (
      <a href='#' className={styles.link} onClick={props.onClick}>
        {props.icon}
        <span>{props.value}</span>
      </a>
    );
  };

  return (
    <div className={styles.labelGroup}>
      <span className={styles.label}>{t(`procedureBox.processExtract.pjeExtract.basicData.${props.label}`)}</span>
      <span className={styles.value}>
        {props.icon ? renderLink() : truncate(props.value, 30)}
      </span>
    </div>
  );
}
