import { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaPlus } from 'react-icons/fa';
import { QuantityPerFlux } from '../../../@types/digitalProcesses/fluxes';

import styles from './styles.module.scss';
import { ProcessTypeHeader } from '../../DistributeProcesses/ProcessTypeHeader';
import { Procedure } from '../../../@types/model';

interface ProcedureCarouselProps {
  procedures: Procedure[];
  isMobile: boolean;
  onClick: (value: number) => void;
  setFluxId?: (fluxId: number) => void;
  componentLocation?: string;
  fluxSelectedQuantity?: QuantityPerFlux[] | any;
}

export function ProcedureCarousel(props: ProcedureCarouselProps) {
  const [headerQuantity, setHeaderQuantity] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);
  const [procedureLengthRight, setProcedureLengthRight] = useState(props.procedures.length < 8 ? 0 : props.procedures.length - 8);
  const [procedureLengthLeft, setProcedureLengthLeft] = useState(0);
  const [processSelected, setProcessSelected] = useState(0);
  const [headerLeft, setHeaderLeft] = useState(0);
  const [headerRight, setHeaderRight] = useState(headerQuantity < 5 ? 0 : headerQuantity - 5);

  const handleSelectedProcedure = (index: number) => {
    props.onClick(index);
    setProcessSelected(index);
  };

  useEffect(() => {
    setHeaderRight(headerQuantity < 5 ? 0 : headerQuantity - 5);
  }, [headerQuantity]);

  const handleClickLeft = () => {
    if (props.componentLocation?.includes('distributeProcess')) {
      if (headerQuantity > 5) {
        const x = window.innerWidth / 4.8;

        setMarginLeft(marginLeft + x);

        setHeaderLeft(headerLeft - 1);
        setHeaderRight(headerRight + 1);
      }
    } else if (props.procedures.length >= 9) {
      const x = window.innerWidth / 4.8;

      setMarginLeft(marginLeft + x);

      setProcedureLengthLeft(procedureLengthLeft - 1);
      setProcedureLengthRight(procedureLengthRight + 1);
    }
  };

  const handleClickRight = () => {
    if (props.isMobile) {
      if (props.componentLocation?.includes('distributeProcess')) {
        if (headerQuantity >= 3) {
          const x = window.innerWidth / 4.8;

          setHeaderLeft(headerLeft + x);

          setHeaderRight(headerRight - 1);
          setHeaderLeft(headerLeft + 1);
        }
      } else if (props.procedures.length >= 3) {
        const x = window.innerWidth / 4.8;

        setMarginLeft(marginLeft - x);

        setProcedureLengthRight(procedureLengthRight - 1);
        setProcedureLengthLeft(procedureLengthLeft + 1);
      }
    } else if (props.componentLocation?.includes('distributeProcess')) {
      if (headerQuantity >= 5) {
        const x = window.innerWidth / 4.8;

        setMarginLeft(marginLeft - x);

        setHeaderRight(headerRight - 1);
        setHeaderLeft(headerLeft + 1);
      }
    } else if (props.procedures.length >= 9) {
      const x = window.innerWidth / 4.8;

      setMarginLeft(marginLeft - x);

      setProcedureLengthRight(procedureLengthRight - 1);
      setProcedureLengthLeft(procedureLengthLeft + 1);
    }
  };

  useEffect(() => {
    setProcessSelected(props.procedures[0].id);
  }, []);

  return (
    <div className={styles.container}>
      <FaAngleLeft
        className={styles.icon}
        onClick={() => (
          props.componentLocation
            ? headerLeft > 0 && handleClickLeft()
            : procedureLengthLeft > 0 && handleClickLeft()
        )} />
      <div className={styles.box}>
        <FaPlus />
        {props.componentLocation
          ? <p>{ headerLeft }</p>
          : <p>{ procedureLengthLeft }</p>
        }
      </div>

      <div className={styles.procedureList}>
        <div
          className={styles.tabsContent}
          style={{
            marginLeft,
          }}
        >
          {props.componentLocation?.includes('distributeProcess')
            ? <ProcessTypeHeader
                procedures={props.procedures}
                componentLocation={props.componentLocation}
                headerQuantity={(e) => setHeaderQuantity(e)}
                setFlux={(id) => props.onClick && props.onClick(id)}
                fluxSelectedQuantity={props.fluxSelectedQuantity}
              />
            : props.procedures.map((p: Procedure, index: number) => {
              return (
                <div
                  key={index}
                  className={`${styles.tab} ${props.isMobile && styles.tabResponsive} ${processSelected === p.id && styles.selected}`}
                  onClick={() => handleSelectedProcedure(p.id)}
                >
                  <span className={`${props.isMobile && styles.spanResponsive}`}>{p.process_number}</span>
                </div>
              );
            })
          }
        </div>
      </div>

      <div className={styles.box}>
        <FaPlus />
        {props.componentLocation
          ? <p>{ headerRight < 0 ? 0 : headerRight }</p>
          : <p>{ procedureLengthRight }</p>
        }
      </div>
      <FaAngleRight
        className={styles.icon}
        onClick={() => (
          props.componentLocation
            ? headerRight > 0 && handleClickRight()
            : procedureLengthRight > 0 && handleClickRight()
        )}/>
    </div>
  );
}
