import { t } from 'i18next';
import { Trans } from 'react-i18next';
import styles from './styles.module.scss';
import { ListActivity } from '../../../@types/model';

type SummaryProps = {
  activity?: ListActivity;
};

export function Summary(props: SummaryProps) {
  const renderExpanded = () => {
    if (props.activity?.description) {
      return (
        <div className={ styles.expanded }>
          <div className={ styles.header }>
            <span>
              <Trans i18nKey='procedureCollections.procedureList.resume' />
            </span>
          </div>
          <span className={ styles.summary }>{props.activity?.description}</span>
        </div>
      );
    }

    return (
      <div className={ styles.expanded }>
        <div className={ styles.header }>
          <span>
            <Trans i18nKey='procedureCollections.procedureList.resume' />
          </span>
        </div>
        <span className={ styles.emptyText }>{t('procedureCollections.procedureList.empty.resume')}</span>
      </div>
    );
  };

  return renderExpanded();
}
