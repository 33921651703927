import { t } from 'i18next';
import { useState, useEffect, useContext } from 'react';
import { SelectedProcedures } from '../SelectedProcedures';
import { Edit } from './Edit';
import styles from './styles.module.scss';
import Show from '../Show';
import { Preview } from './Preview';
import ProcedureBoxService from '../../services/procedureBoxService';
import { SelectGroupOption } from '../../@types/config';
import { DocumentVariables } from '../../@types/createDocument';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { MultipleActionStep } from '../../@types/backgroundActions';

type CreateDocumentProps = {
  closeModal: () => void;
  waiting?: boolean;
  values?: any;
  multipleActions?: MultipleActionStep;
  reload?: boolean;
};

export type CreateDocumentInBulk = {
  id: number;
  value: string;
};

export function CreateDocument(props: CreateDocumentProps) {
  const { proceduresSeleted, addToasts, setAlert } = useContext(ProcedureActionsContext);
  const [documentVariables, setDocumentVariables] = useState<DocumentVariables[]>([]);
  const [options, setOptions] = useState<SelectGroupOption[]>([]);
  const [selected, setSelected] = useState<'edit' | 'view'>('edit');
  const [edit, setEdit] = useState(false);
  const [content, setContent] = useState(props.values?.body.content || '');
  const [contentInBulk, setContentInBulk] = useState<CreateDocumentInBulk[]>([]);
  const [description, setDescription] = useState(props.values?.body.title || '');
  const [template, setTemplate] = useState(props.values?.body.template || '');
  const [templateName, setTemplateName] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [loading, setLoading] = useState(false);

  const templates: SelectGroupOption[] = [
    {
      value: 'empty',
      name: t('procedureBox.actions.prepareDocument.modal.form.selectGroup.empty'),
      header: false,
    },
    {
      value: 'newTemplate',
      name: t('procedureBox.actions.prepareDocument.modal.form.selectGroup.newTemplate'),
      header: false,
    },
  ];

  const loadDocumentTemplates = () => {
    ProcedureBoxService.getDocumentTemplates()
      .then((res) => {
        templates.push({
          value: null,
          name: t('procedureBox.actions.prepareDocument.modal.form.selectGroup.byMe'),
          header: true,
        });
        res.own_document_templates.map((doc) => templates.push({
          value: doc.id,
          name: doc.name,
          header: false,
          menu: true,
        }));
        res.document_templates.map((individual) => {
          templates.push({
            value: individual.individual_id,
            name: individual.individual_name,
            header: true,
            menu: false,
          });
          individual.documents.map((doc) => templates.push({
            value: doc.id,
            name: doc.name,
            header: false,
          }));
        });
        setOptions(templates);
      });
  };

  const loadDocumentMethods = () => {
    ProcedureBoxService.getDocumentMethods(proceduresSeleted[0].id)
      .then((res) => {
        const documentMethods: DocumentVariables[] = res.methods.map((item) => {
          const variable: DocumentVariables = {
            id: item.id,
            name: item.description,
            fullName: `[:${item.name}:]`,
          };
          return variable;
        });
        setDocumentVariables(documentMethods);
      });
  };

  const updateState = (contentArray: CreateDocumentInBulk[], data: CreateDocumentInBulk): CreateDocumentInBulk[] => {
    if (contentArray.find((x) => x.id === data.id)) {
      const newArray = contentInBulk.map((item) => {
        if (data.id === item.id) {
          return { ...item, value: data.value };
        }
        return data;
      });
      return newArray;
    }
    return contentArray.concat(data);
  };

  const loadDocumentTemplate = (id: number) => {
    if (proceduresSeleted.length > 1) {
      let contentBulkArray: CreateDocumentInBulk[] = [];
      proceduresSeleted.map(async (procedure) => {
        await ProcedureBoxService.getDocumentTemplate(id, procedure.id)
          .then((res) => {
            const data = {
              id: procedure.id,
              value: res.content,
            };
            if (proceduresSeleted.indexOf(procedure) === 0) {
              setContent(res.content);
              contentBulkArray = updateState(contentBulkArray, data);
            } else {
              contentBulkArray = updateState(contentBulkArray, data);
            }
          });
        setContentInBulk(contentBulkArray);
        setLoading(false);
      });
    } else {
      ProcedureBoxService.getDocumentTemplate(id, proceduresSeleted[0].id)
        .then((res) => {
          setContent(res.content);
          setLoading(false);
        });
    }
  };

  const getDocumentTemplate = (id: number) => {
    ProcedureBoxService.getDocumentModel(id)
      .then((res) => {
        setContent(res.content);
        setLoading(false);
      });
  };

  const handleContent = (text: string) => {
    setContent(text);
  };

  const handleTemplate = (model: string) => {
    setLoading(true);
    setDescription('');
    const name = model ? `${options.find((x) => x.value === model)?.name}` : '';
    if (/^-?\d+$/.test(model)) {
      loadDocumentTemplate(Number(model));
      setTemplateName(name);
      setDescription(name);
    } else {
      setContent('');
      setDescription('');
      setTemplateName(name);
      setLoading(false);
    }
    setTemplate(model);
    setEdit(false);
  };

  const handleEdit = (item: SelectGroupOption) => {
    setLoading(true);
    getDocumentTemplate(Number(item.value));
    setEdit(true);
    setDescription(item.name);
    setTemplate(item.value);
  };

  const handleDeleteTemplate = (id: number) => {
    ProcedureBoxService.deleteDocumentTemplate(id)
      .then((res) => {
        if (res.status === 200) {
          loadDocumentTemplates();
          setAlert(undefined);
        } else {
          addToasts({
            type: 'error',
            text: t('procedureBox.actions.prepareDocument.modal.actions.error'),
          });
        }
      });
  };

  const showAlert = (id: number) => {
    setAlert({
      visible: true,
      handleConfirm: () => handleDeleteTemplate(id),
      title: t('procedureBox.actions.prepareDocument.modal.actions.title'),
      text: t('procedureBox.actions.prepareDocument.modal.actions.subtitle'),
      confirmText: t('general.yes'),
      confirmType: 'primary',
    });
  };

  useEffect(() => {
    loadDocumentTemplates();
    loadDocumentMethods();
  }, []);

  return (
    <div className={ styles.container }>
      <SelectedProcedures />
      <div className={styles.tabs}>
        <a
          onClick={() => setSelected('edit')}
          className={`${styles.tab} ${selected === 'edit' ? styles.selected : ''}`}>
          {t('procedureBox.actions.prepareDocument.modal.tabs.edit')}
        </a>
        <a
          onClick={() => setSelected('view')}
          className={`${styles.tab} ${selected === 'view' ? styles.selected : ''}`}>
          {t('procedureBox.actions.prepareDocument.modal.tabs.view')}
        </a>
      </div>
      <div className={styles.content}>
        <Show if={selected === 'edit' && !loading}>
          <Edit
            closeModal={props.closeModal}
            content={content}
            contentInBulk={contentInBulk}
            description={description}
            documentType={documentType}
            template={template}
            options={options}
            isEdit={edit}
            documentVariables={documentVariables}
            onChangeContent={(text) => handleContent(text)}
            onChangeDescription={setDescription}
            onChangeType={(text) => setDocumentType(text)}
            onChangeTemplate={(model) => handleTemplate(model)}
            onChangeTemplateName={setTemplateName}
            loadTemplates={loadDocumentTemplates}
            onDeleteTemplate={(id) => showAlert(id)}
            onEditTemplate={(item) => handleEdit(item)}
            waiting={props.waiting}
            multipleActions={props.multipleActions}
            reload={props.reload}
          />
        </Show>
        <Show if={selected === 'view'}>
          <Preview
            content={content}
            description={description}
            template={template}
            templateName={templateName}
          />
        </Show>
      </div>
    </div>
  );
}
