import { t } from 'i18next';
import styles from './styles.module.scss';

export function EmptyList() {
  return (
    <div className={ styles.container }>
      <span>{t('reports.general.emptyList')}</span>
    </div>
  );
}
