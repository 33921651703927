import { SelectedProcedures } from '../SelectedProcedures';
import { OfficialData } from './OfficialData';
import styles from './styles.module.scss';

export function SubProcess() {
  return (
    <div className={styles.container}>
      <SelectedProcedures />
      <div className={styles.content}>
        {<OfficialData />}
      </div>
    </div>
  );
}
