import { ResponseMarker, MarkerCreate } from '../@types/markers';
import { Marker } from '../@types/model';
import Axios from '../config/api';

export default class MarkerService {
  public static async addPriorityMarker(procedureId: number[], priority: string) {
    return Axios.put(`api/procedures/marker_priority?id=${procedureId}`, { marker_priority: priority })
      .then((res) => res);
  }

  public static async addPendencyMarker(procedureId: number[], pendency: string) {
    return Axios.put(`api/procedures/marker_pending?id=${procedureId}`, { marker_pending: pendency })
      .then((res) => res);
  }

  public static async addCustomMarker(markerId: number, procedureIds: number[]) {
    return Axios.put(`api/markers/${markerId}/linked_marker_procedures`, { procedure_ids: procedureIds })
      .then((res) => res);
  }

  public static async editMarker(markerId: number, marker: MarkerCreate) {
    return Axios.put(`/api/markers/${markerId}`, marker)
      .then((res) => res);
  }

  public static async getMarkerList(box?: string) {
    return Axios.get<ResponseMarker>(`api/procedures/marker_procedures_count?box=${box || 'inbox'}`)
      .then((res) => res.data);
  }

  public static async getMarkers() {
    return Axios.get<Marker[]>('api/markers')
      .then((res) => res.data);
  }

  public static async createMarker(marker: MarkerCreate) {
    return Axios.post('api/markers', marker)
      .then((res) => res.data);
  }

  public static async deleteMarker(marker_id: number) {
    return Axios.delete(`/api/markers/${marker_id}`);
  }
}
