import { Grid } from '@mui/material';
import { t } from 'i18next';
import styles from './styles.module.scss';
import Select from '../../../../components/Select';
import { orderByOptions } from '../../../../helpers/menuItems';
import { OptionType } from '../../../../@types/config';

type FilterProps = {
  fieldsOptions: OptionType[];
  fluxesOptions: OptionType[];
  handleOnChange: (value: string | undefined, type: string) => void;
};

export function Filter(props: FilterProps) {
  return (
    <div className={styles.container}>
      <Grid container columns={{ xs: 1, sm: 12, md: 12 }} spacing={3}>
        <Grid item xs={1} sm={4} md={4}>
          <Select
            label={t('procedureBox.processExtract.samePart.mainField.label')}
            placeholder={t('procedureBox.processExtract.samePart.mainField.placeholder')}
            options={props.fieldsOptions}
            onChange={(e) => props.handleOnChange(e?.label, 'field')}
            className={styles.select}
          />
        </Grid>
        <Grid item xs={1} sm={4} md={4}>
          <Select
            label={t('procedureBox.processExtract.samePart.flux.label')}
            placeholder={t('procedureBox.processExtract.samePart.flux.placeholder')}
            options={props.fluxesOptions}
            onChange={(e) => props.handleOnChange(e?.label, 'flux')}
            className={styles.select}
          />
        </Grid>
        <Grid item xs={1} sm={4} md={4}>
          <div className={styles.orderContainer}>
            <label>{t('procedureBox.processExtract.samePart.order.label')}</label>
            <Select
              label={''}
              placeholder={t('procedureBox.processExtract.samePart.order.placeholder')}
              options={orderByOptions}
              defaultValue={orderByOptions[0]}
              onChange={(e) => props.handleOnChange(e as any, 'order')}
              className={styles.orderBy}
              returnValue
              onlySelect
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
