import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { LinearProgress, ThemeProvider } from '@mui/material';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { customTheme } from './theme';

const IMG_ANIMATION = require('../../../assets/lotties/lf20_8x4zudzr.json');

type LoadingProps = {
  porcentage: number;
  value: number;
  length: number;
  type: 'verifyAttach' | 'verifySignature';
};

export function Loading(props: LoadingProps) {
  return (
    <div className={styles.container}>
      <div className={styles.animation}>
        <Player
          loop
          keepLastFrame={true}
          src={IMG_ANIMATION}
          autoplay
          className={styles.lottie}
        >
          <Controls visible={false} />
        </Player>
      </div>
      <div className={styles.loadingProgress}>
        <span>{t(`procedureBox.actions.ipm.forwardIPM.modal.verify.${props.type}`)}</span>
        <ThemeProvider theme={customTheme}>
          <LinearProgress
            variant="determinate"
            sx={{ height: 8, borderRadius: 5 }}
            value={props.porcentage}
            className={`${styles.bar}`}
            color='primary'
          />
        </ThemeProvider>
        <div className={styles.footerProgress}>
          <span>
            {t(
              `procedureBox.actions.ipm.forwardIPM.modal.verify.${props.type}Procedures`,
              { proceduresVerify: props.value, proceduresLength: props.length, plural: props.length > 1 ? 's' : '' },
            )}
            </span>
          <span>{props.porcentage}%</span>
        </div>
      </div>
    </div>
  );
}
