import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import styles from './styles.module.scss';
import Select from '../../Select';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { ShareProcedureType } from '../../../@types/config';
import { Button } from '../../Button';
import { InputText } from '../../InputText';
import { Header } from '../../Header';
import { MultipleSelect } from '../../MultipleSelect';
import { ErrorField } from '../../ErrorField';

const defaultInstitution = { label: 'Procuradoria Geral do Município de Fortaleza', value: 1 };

type FormProps = {
  handleConfirm: (data: ShareProcedureType) => void;
};

export function Form(props: FormProps) {
  const [institutions, setInstitutions] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [individuals, setIndividuals] = useState([]);

  const schema = Yup.object().shape({
    institution: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    field: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    individuals: Yup.array()
      .of(Yup.number().required(t('general.requeried')))
      .min(1, t('general.requeried'))
      .required(t('general.requeried')),
    observation: Yup.string(),
  });

  const {
    handleSubmit, formState: { errors }, control, setValue, resetField,
  } = useForm<ShareProcedureType>({
    resolver: yupResolver(schema),
  });

  const loadInstituitions = () => {
    ProcedureBoxService.getInstitutions().then((e) => {
      setInstitutions(e.map((i: any) => { return { label: i.name, value: i.id }; }));
      setValue('institution', defaultInstitution);
    });
  };

  useEffect(() => {
    loadInstituitions();
  }, []);

  const institutionCode = useWatch({
    control,
    name: 'institution.value',
    defaultValue: undefined,
  });

  useEffect(() => {
    ProcedureBoxService.getFieldsByInstitution(institutionCode).then((e) => {
      setFieldList(e.map((i: any) => { return { label: i.name, value: i.id }; }));
      resetField('field');
      resetField('individuals');
    });
  }, [institutionCode]);

  const fieldCode = useWatch({
    control,
    name: 'field.value',
    defaultValue: undefined,
  });

  useEffect(() => {
    ProcedureBoxService.getIndividualsByMainFieldTramit(fieldCode).then((e) => {
      setIndividuals(e.map((i: any) => { return { label: i.name, value: i.id }; }));
      resetField('individuals');
    });
  }, [fieldCode]);

  return (
    <form className={ styles.container } onSubmit={handleSubmit(props.handleConfirm)}>
      <div className={styles.header}>
        <Header
          text={t('procedureBox.actions.shareProcedure.form.header')}
        />
      </div>
      <Grid
        container
        columns={{ xs: 1, sm: 3, md: 3 }}
        className={styles.content}
      >
        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.text}
        >
          <span>{t('procedureBox.actions.shareProcedure.form.text')}</span>
        </Grid>

        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Controller
            control={control}
            name="institution"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.shareProcedure.form.institution')}
                defaultValue={defaultInstitution}
                options={institutions}
              />
            )}
          />
          <ErrorField text={errors.institution?.value?.message} />
          <ErrorField text={errors.institution?.message} />
        </Grid>

        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Controller
            control={control}
            name="field"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.shareProcedure.form.field')}
                options={fieldList}
              />
            )}
          />
          <ErrorField text={errors.field?.value?.message} />
          <ErrorField text={errors.field?.message} />
        </Grid>

        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Controller
            control={control}
            name="individuals"
            render={({ field }) => (
              <MultipleSelect
                {...field}
                options={individuals}
                label={t('procedureBox.actions.shareProcedure.form.individuals')}
              />
            )}
          />
          <ErrorField text={errors.individuals?.message} />
        </Grid>

        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.observation}
        >
          <Controller
            control={control}
            name={'observation'}
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.shareProcedure.form.observation')}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.submit}
        >
          <Button
            title={t('procedureBox.actions.shareProcedure.form.submit')}
            type='submit'
            textCenter
            round
            buttonType='primary'
            size='flat'
          />
        </Grid>
      </Grid>
    </form>
  );
}
