import { AttachedDocument, OptionType } from './config';

export const spuInitialState: SpuJudicialDocument = {
  documentData: undefined,
  documentSelected: [],
};

export type SpuJudicialDocument = {
  documentData: SpuDocumentData | undefined;
  documentSelected: AttachedDocument[];
};

export type SpuDocumentData = {
  procedureId: number;
  origin: string;
  deadline: string;
  priority: OptionType;
  targetInstitution: OptionType;
  targetSector: OptionType;
  orderBody: string;
  protester: OptionType;
  name: string;
  responsible: string;
  organization: string;
  cpf: string;
  cnpj: string;
  gender: OptionType;
  address: string;
  addressNumber: string;
  addressComplement: string;
  cep: string;
  state: OptionType;
  city: OptionType;
  neighborhood: OptionType;
  homePhone: string;
  cellPhone: string;
  commercialPhone: string;
  email: string;
  observation: string;
};
