import {
  FaChevronDown, FaChevronUp, FaRegClock, FaRegQuestionCircle,
} from 'react-icons/fa';
import Show from '../../Show';
import { Tooltip } from '../../Tooltip';
import styles from './styles.module.scss';

type HeaderLineProps = {
  title: string;
  tooltip?: string;
  info?: string;
  infoValue?: string;
  onClick?: () => void;
  display?: boolean;
};

export function HeaderLine(props: HeaderLineProps) {
  return (
      <div className={ styles.header }>
        <div className={styles.subHeader}>
          <p className={ styles.title }>{props.title}</p>
          <div className={ styles.info }>
            {props.tooltip
              ? <Tooltip title={ props.tooltip }><FaRegQuestionCircle /></Tooltip>
              : null
            }
          </div>
        </div>
        <div className={ styles.action }>
          <Show if={!!props.info}>
            <FaRegClock style={ { color: 'var(--orange)', height: '12px' } }/>
            <p className={ styles.period }>{props.info}</p>
            <p className={ styles.value }>{props.infoValue}</p>
            <div id='btnPrescriptionChart' className={ styles.chevron } onClick={props.onClick}>
              <Show if={!!props.display}><FaChevronUp /></Show>
              <Show if={!props.display}><FaChevronDown /></Show>
            </div>
          </Show>
        </div>
      </div>
  );
}
