import './styles.scss';

type LineProps = {
  hidden?: boolean;
};

export function Line(props: LineProps) {
  if (!props.hidden) {
    return <div className='lineContainer' />;
  }
  return <div/>;
}
