import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type GeolocationContributorsRegionsProps = {
  title?: string;
};

export function GeolocationContributorsRegions(props: GeolocationContributorsRegionsProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/dashboards/geolocalizacao_contribuintes_por_bairros_regionais`}/>
  );
}
