import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { InputText } from '../../../components/InputText';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { InputDate } from '../../../components/InputDate';
import { OptionType } from '../../../@types/config';
import { SelectFilter } from '../../../components/SelectFilter';
import { Breadcumb } from '../../../components/Breadcumb';
import Format from '../../../helpers/format';
import { EmptyList } from '../EmptyList';
import { Loading } from '../Loading';
import ReportsService from '../../../services/reportsService';
import { Modal } from '../../../components/Modal';
import { ExpandedInterestedParts } from '../../../components/ExpandedInterestedParts';

type AudienceProps = {
  process_number: string;
  individual_name: string;
  court_name: string;
  event_schedule: string;
  interested_parts: any;
  procedure_id: number;
};

export function Audience() {
  const { handleSubmit, control, setValue } = useForm();

  const [result, setResult] = useState<AudienceProps[]>([]);
  const [initialPeriod, setInitialPeriod] = useState<any>('');
  const [finalPeriod, setFinalPeriod] = useState<any>('');
  const [attorneys, setAttorneys] = useState<OptionType[]>([]);
  const [sticks, setSticks] = useState<OptionType[]>([]);
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [openExpandedInterestedParts, setOpenExpandedInterestedParts] = useState(false);
  const [interessedParts, setInteressedParts] = useState([]);

  const { pathname } = useLocation();

  useEffect(() => {
    setResult([]);
    setLoading(true);
    if (query.length > 0) {
      ReportsService.getAudiencesList(query).then((res) => {
        setResult(res);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    setValue('individual_id_in', []);
    setValue('court_id_in', []);
    setValue('process_number_eq', '');
    setValue('procedure_judicial_process_judicial_parts_name_cont', '');
    setValue('event_schedule_gteq', '');
    setValue('event_schedule_lteq', '');

    ReportsService.getAudience().then((res) => {
      const { data } = res;
      setSticks(data.court_id);
      setAttorneys(data.individual_id);
    });
  }, []);

  const submit = (form: any) => {
    let queryUrl = `?q[process_number_eq]=${form.process_number_eq}`;
    form.individual_id_in.forEach((individual_id: number) => {
      queryUrl = queryUrl.concat(`&q[individual_id_in][]=${individual_id}`);
    });
    form.court_id_in.forEach((court_id: number) => {
      queryUrl = queryUrl.concat(`&q[court_id_in][]=${court_id}`);
    });
    queryUrl = queryUrl.concat(`&q[procedure_judicial_process_judicial_parts_name_cont]=${form.procedure_judicial_process_judicial_parts_name_cont}`);
    queryUrl = queryUrl.concat(`&q[event_schedule_gteq]=${form.event_schedule_gteq}&q[event_schedule_lteq]=${form.event_schedule_lteq}`);

    setQuery(queryUrl);
  };

  const handlePrintReport = () => {
    ReportsService.getPrintReport(query).then((resp) => {
      const blob = Format.base64ToBlob(resp, 'application/pdf');
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    });
  };

  const cleanFilter = () => {
    window.location.reload();
  };

  const handleSelect = (type: string, event: string | null, field: any) => {
    if (type === 'initial_period') {
      setInitialPeriod(event);
    } else {
      setFinalPeriod(event);
    }

    field.onChange(event);
  };

  const renderLoading = () => {
    if (loading) {
      return <Loading />;
    } if (query.length > 0) {
      return <EmptyList />;
    }
    return <></>;
  };

  const renderTable = () => {
    return (
      <div>
        <div className={styles.printButton}>
          <Button
            title={t('reports.audience.print_report')}
            buttonType={'primary'}
            onClick={handlePrintReport}
            round
          />
        </div>
        <Table>
          <thead>
            <tr>
              <th>{t('reports.audience.table.process_number')}</th>
              <th>{t('reports.audience.table.interested_parts')}</th>
              <th>{t('reports.audience.table.court')}</th>
              <th>{t('reports.audience.table.schedule')}</th>
              <th>{t('reports.audience.table.attorney')}</th>
            </tr>
          </thead>
            {
              result.map((data: AudienceProps, index: number) => (
                <tbody key={index}>
                  <td>
                    <a href={`/procedure_box/show_procedure/${data.procedure_id}`} target='_blank'>
                      {data.process_number}
                    </a>
                  </td>
                  <td>{data.interested_parts ? (
                    data.interested_parts?.length > 2 ? (
                      <a
                        onClick={() => {
                          setInteressedParts(data.interested_parts);
                          setOpenExpandedInterestedParts(true);
                        }}
                      >
                        {
                          t('procedureBox.processExtract.extractDataTable.interessedPartReduce', {
                            firstInteressedPart: data.interested_parts[0],
                            count: data.interested_parts.length,
                          })
                        }
                      </a>
                    ) : data.interested_parts.join(', ')
                  ) : data.interested_parts}
                  </td>
                  <td>{data.court_name}</td>
                  <td>{Format.formatDateWithHours(data.event_schedule)}</td>
                  <td>{data.individual_name}</td>
                </tbody>
              ))
            }
        </Table>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid item xs={3} sm={6}>
            <h1>{ t('reports.audience.title') }</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.content}>
          <form className={ styles.form } onSubmit={handleSubmit(submit)}>
            <Grid
              container
              columns={{ xs: 1, sm: 8, md: 8 }}
              spacing={3}
            >
              <Grid
                item
                xs={1}
                sm={2}
                md={2}
              >
                <Controller
                  control={control}
                  name="process_number_eq"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('reports.audience.form.process_number')}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                md={2}
              >
                <Controller
                  control={control}
                  name="individual_id_in"
                  render={({ field }) => (
                    <SelectFilter
                      label={t('reports.audience.form.attorney')}
                      options={attorneys}
                      placeholder={''}
                      handleSelectedItems={(e) => field.onChange(e)}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                md={2}
              >
                <Controller
                  control={control}
                  name="court_id_in"
                  render={({ field }) => (
                    <SelectFilter
                      label={t('reports.audience.form.stick')}
                      options={sticks}
                      placeholder={''}
                      handleSelectedItems={(e) => field.onChange(e)}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                md={2}
              >
                <Controller
                  control={control}
                  name="procedure_judicial_process_judicial_parts_name_cont"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('reports.audience.form.interested_part')}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                md={2}
              >
                <Controller
                  control={control}
                  name="event_schedule_gteq"
                  render={({ field }) => (
                    <InputDate
                      label={t('reports.audience.form.initial_period')}
                      value={initialPeriod}
                      onChange={(e) => handleSelect('initial_period', e, field)}
                      datetime
                      fixedValue
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                md={2}
              >
                <Controller
                  control={control}
                  name="event_schedule_lteq"
                  render={({ field }) => (
                    <InputDate
                      {...field}
                      label={t('reports.audience.form.final_period')}
                      value={finalPeriod}
                      onChange={(e) => handleSelect('final_period', e, field)}
                      datetime
                      fixedValue
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={8}
                md={8}
                className={styles.buttons}
              >
                <Button
                  title={t('procedureBox.filterAdvanced.clearFilter')}
                  buttonType='default'
                  size='flat'
                  type='button'
                  round
                  onClick={cleanFilter}
                />
                <Button
                  title={t('procedureBox.filterAdvanced.filter')}
                  buttonType='primary'
                  size='flat'
                  disabled={loading}
                  round
                />
              </Grid>
            </Grid>
          </form>
          {result.length > 0 ? renderTable() : renderLoading()}
        </Grid>
      </Grid>
      <Modal
        open={openExpandedInterestedParts}
        onClose={() => setOpenExpandedInterestedParts(false)}
      >
        <ExpandedInterestedParts interestedParts={interessedParts}/>
      </Modal>
    </div>
  );
}
