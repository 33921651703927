import { useEffect } from 'react';

import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type RequestRequirementProps = {
  title?: string;
};

export function RequestRequirement(props: RequestRequirementProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/requerimentos/solicitar_analise`}/>
  );
}
