import {
  useEffect,
  useState,
} from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { FaSlidersH } from 'react-icons/fa';
import EmptySearchCollection from '../../../assets/emptySearchCollection.svg';
import Show from '../../Show';
import styles from './styles.module.scss';

export function EmptyCollection() {
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 700px)').matches,
  );
  useEffect(() => {
    window
      .matchMedia('(max-width: 800px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);
  return (
    <div className={styles.noSearch} data-cy={'procedure-collection-empty'}>
      <Show if={!matches}>
        <img src={EmptySearchCollection} alt={t('general.noOptions')} className={styles.img}/>
      </Show>
      <div>
        <p className={styles.labelTitle}>{
          <Trans
            i18nKey='procedureCollections.procedureList.empty.title'
            components={{ bold: <strong /> }}
          />}
        </p>
        <p className={styles.labelMessage}>{
          <Trans
            i18nKey='procedureCollections.procedureList.empty.labelOne'
            components={{ bold: <strong /> }}
          />}
        </p>
        <p className={styles.labelMessage}>{
          <Trans
            i18nKey='procedureCollections.procedureList.empty.labelTwo'
            components={{ bold: <strong />, i: <FaSlidersH/> }}
          />}
        </p>
        <p className={styles.labelMessage}>{
          <Trans
            i18nKey='procedureCollections.procedureList.empty.labelThree'
            components={{ bold: <strong />, a: <a/> }}
          />}
        </p>
      </div>
    </div>
  );
}
