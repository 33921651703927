import { useContext, useState } from 'react';
import { FormControlLabel, Grid, Radio } from '@mui/material';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isValid, parse } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../Button';
import { TextArea } from '../../../TextArea';
import screen from '../../../../helpers/screen';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { Batch } from '../../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../../context/backgroundActions';
import { Procedure } from '../../../../@types/model';
import { InputDate } from '../../../InputDate';
import { AttorneyCalculate as AttorneyCalculateType } from '../../../../@types/requestCalculation';

type AttorneyCalculateProps = {
  subProcedure: Procedure;
  prevStep: () => void;
};

interface FormValues {
  calculation_details: string;
  deadline: string;
}

export function AttorneyCalculate(props: AttorneyCalculateProps) {
  const { setAlert, proceduresSeleted, setModal } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [attachAndTramit, setAttachAndTramit] = useState(false);
  const i18nKey = 'procedureBox.actions.eletronicCalculation.modal';
  const i18nOptionsKey = 'calculationDataContent.options';
  const isMobile = screen.isMobile();

  const schema = Yup.object().shape({
    calculation_details: Yup.string().required(t('general.requeried')),
    deadline: Yup
      .string()
      .test('is-future-date', t('general.date'), (value) => {
        if (!value) return false;

        const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        if (!dateRegex.test(value)) return false;

        const today = new Date();
        const formattedValue = value.replace(dateRegex, '$3-$2-$1');
        const selectedDate = parse(formattedValue, 'yyyy-MM-dd', new Date());

        return isValid(selectedDate) && selectedDate >= today;
      })
      .required(t('general.requeried')),
  });

  const {
    handleSubmit, formState: { errors }, control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const handleRequestCalculate = (form: any) => {
    const data: AttorneyCalculateType = {
      calculation_type: 2,
      ...form,
    };

    const procedures = proceduresSeleted;
    const type = 'eletronicCalculation';
    const body = {
      attachAndTramit,
      subProcedure: props.subProcedure,
      procedure_id: props.subProcedure.id,
      requestCalculations: {
        ...data,
      },
    };

    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
    };
    addBatch(batch);

    setAlert(undefined);
    setModal(undefined);
  };

  const showAlert = (form: any) => {
    setAlert({
      visible: true,
      handleConfirm: () => handleRequestCalculate(form),
      title: t(`${i18nKey}.alertConfirmation.title`, { processNumber: proceduresSeleted[0].process_number }),
      text: t(`${i18nKey}.alertConfirmation.text`),
      confirmText: t('general.yes'),
      confirmType: 'primary',
    });
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(showAlert)}>
      <Grid
        container
        columns={{ xs: 3, sm: 12, md: 12 }}
        spacing={{ xs: 1, md: 1 }}
      >
        <Grid item xs={4} sm={12} md={12}>
          <p>
            {t(`${i18nKey}.${i18nOptionsKey}.attorneyCalculate.warningSolicitationOfCalculate`)}
          </p>
          <Controller
            control={control}
            name="calculation_details"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t(`${i18nKey}.${i18nOptionsKey}.attorneyCalculate.calculateDetails`)}
                tooltip={t(`${i18nKey}.tooltips.calculateDetailsTooltip`)}
                placeholder={t(`${i18nKey}.${i18nOptionsKey}.attorneyCalculate.calculateDetailsPlaceholder`)}
              />
            )}
          />
          <p className={styles.error}>{errors.calculation_details?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="deadline"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t(`${i18nKey}.${i18nOptionsKey}.mainCalculate.deliveryTime`)}
                tooltip={t(`${i18nKey}.tooltips.deliveryTimeTooltip`)}
                placeholder={t(`${i18nKey}.${i18nOptionsKey}.mainCalculate.datePlaceholder`)}
              />
            )}
          />
          <p className={styles.error}>{errors.deadline?.message}</p>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <div className={styles.documents}>
            <div className={styles.header}>
              <span>{t(`${i18nKey}.${i18nOptionsKey}.mainCalculate.documentsLabel`)}</span>
            </div>
            <p>{t(`${i18nKey}.${i18nOptionsKey}.mainCalculate.documentsText`)}</p>
            <section className={styles.calculateType}>
              <FormControlLabel
                checked={attachAndTramit}
                onChange={() => setAttachAndTramit(true)}
                control={<Radio />}
                label={t(`${i18nKey}.${i18nOptionsKey}.mainCalculate.attachAndTramit`).toString()
                }
              />
            </section>
          </div>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <footer>
            <Button
              className={styles.buttonLeft}
              title={t(`${i18nKey}.${i18nOptionsKey}.buttons.previusStep`)}
              onClick={() => props.prevStep()}
              size={isMobile ? 'small' : 'flat'}
              buttonType='default'
              round
            />

            <Button
              title={t(`${i18nKey}.${i18nOptionsKey}.buttons.createEletronicOrder`)}
              type='submit'
              size={isMobile ? 'small' : 'flat'}
              buttonType='primary'
              round
            />
          </footer>
        </Grid>
      </Grid>
    </form>
  );
}
