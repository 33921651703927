import { useEffect } from 'react';

import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type ApproveRequirementProps = {
  title?: string;
};

export function ApproveRequirement(props: ApproveRequirementProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/requerimentos/aprovar`}/>
  );
}
