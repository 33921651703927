import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import Select from '../Select';

import { Button } from '../Button';

import styles from './styles.module.scss';
import ProcedureBoxService from '../../services/procedureBoxService';
import { OptionType } from '../../@types/config';
import { InputText } from '../InputText';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';

type CreateCategoryProps = {
  closeModal: () => void;
};

export function CreateCategory(props: CreateCategoryProps) {
  const { pathname } = useLocation();
  const url = pathname.split('/');

  const { control, handleSubmit } = useForm();

  const [options, setOptions] = useState([]);
  const [optionSelected, setOptionSelected] = useState<OptionType | null>({ label: '', value: 0 });

  const { addToasts } = useContext(ProcedureActionsContext);

  const procedureId = Number(url[3]);

  const loadOptions = async () => {
    const response = await ProcedureBoxService.getCategoryInProcess(Number(url[3]));
    const categoriesList = response.map((option: any) => {
      return {
        label: option.category,
        value: option.id,
      };
    });
    setOptions(categoriesList);
  };

  const submit = (form: any) => {
    const data = form;

    if (optionSelected?.value === 0) {
      ProcedureBoxService.createCategoryInProcess(
        procedureId,
        data.category_text,
        data.category_key,
      ).then((res) => {
        addToasts({
          type: 'success',
          text: t('procedureBox.actions.category.create.toasts.successCreate'),
        });
      })
        .catch(() => {
          addToasts({
            type: 'error',
            text: t('procedureBox.actions.category.create.toasts.errorCreate'),
          });
        });
    } else {
      ProcedureBoxService.editCategoryInProcess(
        optionSelected?.value,
        data.category_text,
        data.category_key,
      ).then((res) => {
        addToasts({
          type: 'success',
          text: t('procedureBox.actions.category.create.toasts.successEdit'),
        });
      })
        .catch(() => {
          addToasts({
            type: 'error',
            text: t('procedureBox.actions.category.create.toasts.errorEdit'),
          });
        });
    }

    props.closeModal();
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid className={styles.content}>
        <div className={styles.select}>
          <Controller
            control={control}
            name="category_selected"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.category.create.selectCategory')}
                defaultValue={{ label: t('procedureBox.actions.category.create.createText'), value: 0 }}
                onChange={(value) => setOptionSelected(value)}
                className={styles.select}
                options={options}
                required
              />
            )}
          />
        </div>

        <div className={styles.inputField}>
          <Controller
            control={control}
            name="category_text"
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value}
                label={t('procedureBox.actions.category.create.categoryText')}
                required
              />
            )}
          />
        </div>

        <div className={styles.inputField}>
          <Controller
            control={control}
            name="category_key"
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value}
                label={t('procedureBox.actions.category.create.categoryKey')}
                required
              />
            )}
          />
        </div>

        <div className={styles.footer}>
          <Button
            type='submit'
            title={t('procedureBox.actions.category.button.create')}
            buttonType='primary'
            className={styles.button}
            round
          />
        </div>
      </Grid>
    </form>
  );
}
