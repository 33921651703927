import { Skeleton } from '@mui/material';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { getBarHeight, getBarWidth, getBarBorderRadius } from '../../../helpers/barStyles';
import Format from '../../../helpers/format';
import { Tooltip } from '../../Tooltip';
import styles from './styles.module.scss';

type DebtPercentage = {
  percentage: number;
  color: string;
};

type HeaderProps = {
  title: string;
  totalValue: number;
  barColor: string;
  width?: number;
  period?: string;
  tooltip?: string;
  titleSize?: string;
  titleColor?: string;
  totalValueSize?: string;
  totalValueColor?: string;
  info?: string;
  debtsValues: DebtPercentage[];
  loading: boolean;
  chartType?: 'digital_processes' | 'debts';
};

export function HeaderDebt(props: HeaderProps) {
  const fillerStyles = {
    height: getBarHeight(),
    width: getBarWidth(props.width),
    borderRadius: getBarBorderRadius(),
    marginBottom: '10px',
    display: 'flex',
  };

  const setBorderRadius = (index: number, side: string) => {
    if (side === 'left' && index === 0) {
      return '10px';
    }
    if (side === 'right' && index === 2) {
      return '10px';
    }
    return '0px';
  };

  const totalValueStyles = {
    fontSize: props.totalValueSize,
    color: `${props.totalValueColor}`,
  };

  const titleStyles = {
    fontSize: props.titleSize,
    color: `${props.titleColor}`,
  };

  const renderTotalValue = () => {
    if (props.chartType !== null && props.chartType === 'digital_processes') {
      return Format.formatNumber(props.totalValue);
    }

    return Format.currency(props.totalValue);
  };

  return (
    <>
      <div className={ styles.header }>
        {props.info
          ? <Tooltip
            title={ props.info }
            placement={'right-start'}
          >
            <p className={ styles.title } style={props.titleSize ? titleStyles : undefined}>{props.title}</p>
          </Tooltip>
          : <p className={ styles.title } style={props.titleSize ? titleStyles : undefined}>{props.title}</p>
        }
        <div className={ styles.info }>
          {props.period ? <p className={ styles.period }>{props.period}</p> : null}
          {props.tooltip
            ? <Tooltip title={ props.tooltip }><FaRegQuestionCircle /></Tooltip>
            : null
          }
        </div>
      </div>
      {!props.loading
        ? <>
        <p
          className={styles.total}
          style={props.totalValueSize ? totalValueStyles : undefined}>{renderTotalValue()}</p>
        <div style={fillerStyles}>
          {props.debtsValues.map((debt, i) => {
            return <div style={{
              background: debt.color,
              width: `${debt.percentage.toFixed(2)}%`,
              borderBottomRightRadius: setBorderRadius(i, 'right'),
              borderTopRightRadius: setBorderRadius(i, 'right'),
              borderBottomLeftRadius: setBorderRadius(i, 'left'),
              borderTopLeftRadius: setBorderRadius(i, 'left'),
            }}></div>;
          })}
        </div>
      </> : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
    </>
  );
}
