import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Skeleton } from '@mui/material';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { InputText } from '../../../components/InputText';
import { loadTitle } from '../../../helpers/changeTitle';
import detranService from '../../../services/detranService';
import { Button } from '../../../components/Button';
import { Vehicle } from '../../../@types/detran';
import Show from '../../../components/Show';
import styles from './styles.module.scss';
import { Toast } from '../../../components/Toast';
import { Breadcumb } from '../../../components/Breadcumb';

type DetranProps = {
  title?: string;
};

export function Detran(props: DetranProps) {
  const { control, handleSubmit } = useForm();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const keyi18n = 'detran';
  const { pathname } = useLocation();

  useEffect(() => {
    loadTitle(props.title);
  }, []);

  const submit = (form: any) => {
    setLoading(true);
    const query = form.cpf;
    detranService.consultVehicles(query).then((res) => {
      setVehicles(res);
      setLoading(false);
    }).catch((res) => {
      setError(true);
    }).finally(() => {
      setLoading(false);
      setShow(true);
    });
  };

  return (
    <div className={styles.container}>
      {
        error && (
        <div className={styles.toast} data-testid={'cypress-toast-error'}>
          <Toast
            type='error'
            collapse
            text={t(`${keyi18n}.error`)}
            open
          />
        </div>)
      }

      <Grid
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12} className={ styles.title }>
          <Grid item xs={3} sm={6}>
            <h1>{ t(`${keyi18n}.title`) }</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(submit)} data-testid={'cypress-detran-form'}>
        <Grid
          container
          columns={{ xs: 1, sm: 12, md: 12 }}
          className={styles.content}
        >
          <Grid
            item
            xs={ 1 }
            sm={ 12 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="cpf"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t(`${keyi18n}.inputText`)}
                  className={styles.inputText}
                  required
                  id={'consult-detran'}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={ 1 }
            sm={ 2 }
            md={ 2 }
            className={styles.button}
          >
            <Button
              type='submit'
              title={t(`${keyi18n}.search`)}
              buttonType='primary'
              round
              data-testid={'cypress-button-search-detran'}
            />
          </Grid>
        </Grid>
      </form>
      <Show if={show}>
        <div data-testid={'cypress-detran-count'} className={styles.count}>
          { t(`${keyi18n}.count.${vehicles.length <= 1 ? 'singular' : 'plural'}`, { count: vehicles.length }) }
        </div>
      </Show>
      {!loading
        ? <Show if={vehicles.length > 0}>
            { vehicles.map((vehicle: Vehicle) => (
              <div className={styles.contentVehicle} data-testid={'cypress-detran-result'}>
                 <Grid
                  container
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  <Grid
                    item
                    xs={ 1 }
                    sm={ 12 }
                    md={ 12 }
                  >
                    <div className={styles.license}>
                      <b>{t(`${keyi18n}.license_plate`)}</b>
                      <span className={styles.value}>{ vehicle.license_plate }</span>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={ 1 }
                    sm={ 12 }
                    md={ 12 }
                    className={styles.owner}
                  >
                    <div>
                      <b>{t(`${keyi18n}.name`)}</b>
                      <span className={styles.value}>{ vehicle.name }</span>
                    </div>
                    <div>
                      <b>{t(`${keyi18n}.address`)}</b>
                      <span className={styles.value}>{ vehicle.address }</span>
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  columns={{ xs: 1, sm: 12, md: 12 }}
                  className={styles.vehicle}
                >
                  <Grid
                    item
                    xs={ 1 }
                    sm={ 12 }
                    md={ 4 }
                  >
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.motor`)}</b>
                      <span className={styles.value}>{ vehicle.motor }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.renavam`)}</b>
                      <span className={styles.value}>{ vehicle.renavam }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.chassi`)}</b>
                      <span className={styles.value}>{ vehicle.chassi }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.color`)}</b>
                      <span className={styles.value}>{ vehicle.color }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.type`)}</b>
                      <span className={styles.value}>{ vehicle.type }</span>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={ 1 }
                    sm={ 12 }
                    md={ 4 }
                  >
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.manufacture_year`)}</b>
                      <span className={styles.value}>{ vehicle.manufacture_year }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.model_year`)}</b>
                      <span className={styles.value}>{ vehicle.model_year }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.number_passengers`)}</b>
                      <span className={styles.value}>{ vehicle.number_passengers }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.financial`)}</b>
                      <span className={styles.value}>{ vehicle.financial }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.category`)}</b>
                      <span className={styles.value}>{ vehicle.category }</span>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={ 1 }
                    sm={ 12 }
                    md={ 4 }
                  >
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.species`)}</b>
                      <span className={styles.value}>{ vehicle.species }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.fuel`)}</b>
                      <span className={styles.value}>{ vehicle.fuel }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.car_body`)}</b>
                      <span className={styles.value}>{ vehicle.car_body }</span>
                    </div>
                    <div className={styles.item}>
                      <b>{t(`${keyi18n}.model`)}</b>
                      <span className={styles.value}>{ vehicle.model }</span>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Show>
        : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
    </div>
  );
}
