import { useEffect } from 'react';
import { loadTitle } from '../../helpers/changeTitle';
import { Modal } from '../../components/Modal';
import { AppContextProvider } from '../../context/appContextProvider';
import { Alert } from '../../components/Alert';
import { Calendar } from './Calendar';
import { ToastsList } from '../../components/ToastsList';

type ScheduleProps = {
  title?: string;
};

export function Schedule(props: ScheduleProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <AppContextProvider>
      <ToastsList />
      <Calendar />
      <Modal />
      <Alert />
    </AppContextProvider>
  );
}
