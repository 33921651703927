import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { useContext } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { DashboardDigitalProcessesContext } from '../../context/dashboardDigitalProcessesContext';
import Show from '../Show';
import { Tooltip } from '../Tooltip';
import { Header } from './Header';
import { Item } from './Item';
import styles from './styles.module.scss';

export function ProductivityChart() {
  const {
    loading, fields, productivity, handleSearch,
  } = useContext(DashboardDigitalProcessesContext);

  const getMaxValue = (type: 'total_inbox' | 'total_inputbox' | 'total_outbox') => {
    if (!productivity) {
      return 0;
    }
    if (type === 'total_inbox') {
      return Math.max(
        ...productivity.fields.flatMap(({ individuals }) => individuals.map(({ total_inbox }) => total_inbox)),
      );
    } if (type === 'total_inputbox') {
      return Math.max(
        ...productivity.fields.flatMap(({ individuals }) => individuals.map(({ total_inputbox }) => total_inputbox)),
      );
    } if (type === 'total_outbox') {
      return Math.max(
        ...productivity.fields.flatMap(({ individuals }) => individuals.map(({ total_outbox }) => total_outbox)),
      );
    }
    return 0;
  };

  return (
    <div id='productivityChart' className={ styles.container }>
      <Header
        title={`${t('dashboard.digitalProcesses.productivityChart.title')} ${productivity ? ` (${productivity.total_individuals}) ` : ''}`}
        tooltip={t('dashboard.digitalProcesses.productivityChart.tooltip')}
        onSearch={(value: string) => handleSearch(value)}
      />
      {
        !loading && productivity !== undefined && productivity.fields.length >= 0 ? (
        <>
        <div className={ styles.header }>
          <Tooltip title={t('dashboard.digitalProcesses.productivityChart.dateTooltip')}>
            <p>{moment(new Date()).format('MMM/YY')}</p>
            <FaRegQuestionCircle />
          </Tooltip>
        </div>
        <div className={ styles.chart }>
        { fields.map((option, index) => (
          <Show if={option.individuals.length > 0}>
            <Item
              key={index}
              field={option}
              maxTotalInbox={getMaxValue('total_inbox')}
              maxTotalInputbox={getMaxValue('total_inputbox')}
              maxTotalOutbox={getMaxValue('total_outbox')}
            />
          </Show>
        ))}
        </div>
        </>
        )
          : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
    </div>
  );
}
