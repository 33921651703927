import { ItemType } from '../components/Charts/Menu';

export function getMenuItems(count: number, baseYear: number): ItemType[] {
  const itemList: ItemType[] = [];
  for (let i = 0; i < count; i += 1) {
    itemList.push({
      id: `${baseYear - i}`,
      selected: (baseYear - i === baseYear),
    });
  }
  return itemList;
}

export const getYearsOptions = (count: number, baseYear: number) => {
  const options = [];
  for (let i = 0; i < count; i += 1) {
    const val = baseYear - i;
    options.push({ label: val.toString(), value: val });
  }
  return options;
};
