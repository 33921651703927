import { Controls, Player } from '@lottiefiles/react-lottie-player';
import styles from './styles.module.scss';

const LOADING_ANIMATION = require('../../../assets/lotties/loading.json');

export function Loading() {
  return (
    <div className={styles.container}>
      <Player
        loop={true}
        autoplay
        keepLastFrame={true}
        src={LOADING_ANIMATION}
        speed={2}
        className={styles.lottie}
      >
        <Controls visible={false} />
      </Player>
    </div>
  );
}
