import QueryProvider from '../../context/queryContext';
import ProcedureActionsProvider from '../../context/procedureActionsContext';
import { Details } from './Details';
import { AppContextProvider } from '../../context/appContextProvider';
import ProcedureMarkersProvider from '../../context/procedureMarkersContext';
import BackgroundActionsProvider from '../../context/backgroundActions';
import { ToastsList } from '../../components/ToastsList';
import ProcedureDetailsProvider from '../../context/procedureDetailsContextProvider';

type ShowProcedureProps = {
  title?: string;
};

export function ShowProcedure(props: ShowProcedureProps) {
  return (
    <QueryProvider>
      <AppContextProvider>
        <ProcedureActionsProvider>
          <BackgroundActionsProvider>
            <ProcedureMarkersProvider>
              <ProcedureDetailsProvider>
                <ToastsList />
                <Details title={props.title} />
              </ProcedureDetailsProvider>
            </ProcedureMarkersProvider>
          </BackgroundActionsProvider>
        </ProcedureActionsProvider>
      </AppContextProvider>
    </QueryProvider>
  );
}
