import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import DashboardService from '../services/dashboardService';
import {
  DigitalProcessesAmountResponse, Field, PerformanceResponse, ProductivityResponse,
} from '../@types/digitalProcessesDashboard';
import { DashboardDigitalProcessesType, DashboardFilter } from '../@types/dashboardDigitalProcesses';
import { performanceData, procedureAmountData as procedureData, productivityData } from './utils/procedureData';
import AuthContext from './authContext';
import { CurrentUser } from '../@types/currentUser';

const contextDefaultValues: DashboardDigitalProcessesType = {
  loading: false,
  unauthorizedProcedure: false,
  query: '',
  totalProcedures: 0,
  procedureTotals: {
    total_inbox: 0,
    total_outbox: 0,
    total_inputbox: 0,
  },
  dashboardFilter: {
    fieldId: undefined,
    individualId: undefined,
  },
  year: '',
  performance: {
    year: '',
    months: [],
  },
  productivity: {
    total_individuals: 0,
    fields: [],
  },
  fields: [],
  setYear: () => {},
  setDashboardFilter: () => {},
  handleSearch: (name: string) => {},
};

export const DashboardDigitalProcessesContext = createContext<DashboardDigitalProcessesType>(
  contextDefaultValues,
);

const DashboardDigitalProcessesProvider: FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [unauthorizedProcedure, setUnauthorizedProcedure] = useState(false);
  const [query, setQuery] = useState('');
  const [year, setYear] = useState(`${moment().year()}`);
  const [fields, setFields] = useState<Field[]>([]);
  const [auxFields, setAuxFields] = useState<Field[]>([]);
  const [totalProcedures, setTotalProcedures] = useState(0);
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>();
  const [procedureTotals, setProcedureTotals] = useState<DigitalProcessesAmountResponse>(contextDefaultValues.procedureTotals);
  const [performance, setPerformance] = useState<PerformanceResponse>();
  const [productivity, setProductivity] = useState<ProductivityResponse>();
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);

  const loadProceduresData = async () => {
    setProcedureTotals(contextDefaultValues.procedureTotals);
    try {
      let newQuery = '';
      if (query.length > 0 && query !== 'allFields') {
        newQuery = query;
      } else if (query.length > 0 && query === 'allFields') {
        newQuery = '';
      } else if (user?.id === undefined) {
        newQuery = `?q[individual_id_eq]=${currentUser?.id}`;
      } else {
        newQuery = `?q[individual_id_eq]=${user?.id}`;
      }
      const proceduresAmounts = await DashboardService.getDigitalProcessesAmount(newQuery);
      setTotalProcedures(proceduresAmounts.total_inbox);
      setProcedureTotals(proceduresAmounts);
      setUnauthorizedProcedure(false);
    } catch (error) {
      setTotalProcedures(procedureData.total_inbox);
      setProcedureTotals(procedureData);
      setUnauthorizedProcedure(true);
    }
  };

  const loadProductivity = async () => {
    setProductivity(undefined);
    let newQuery = '';
    if (query.length > 0 && query !== 'allFields') {
      newQuery = query;
    } else if (query.length > 0 && query === 'allFields') {
      newQuery = '';
    } else if (user?.id === undefined) {
      newQuery = `?q[individual_id_eq]=${currentUser?.id}`;
    } else {
      newQuery = `?q[individual_id_eq]=${user?.id}`;
    }
    try {
      const response: ProductivityResponse = await DashboardService.getProductivity(newQuery);
      setProductivity(response);
      setFields(response.fields);
      setAuxFields(response.fields);
    } catch (error) {
      setProductivity(productivityData);
      setFields(productivityData.fields);
    }
  };

  const loadPerformance = async () => {
    setPerformance(undefined);
    const currentYear = `${moment().year()}`;
    const queryYear = `year=${year || currentYear}`;
    let newQuery = '';
    if (query.length > 0 && query !== 'allFields') {
      newQuery = query.concat(`&${queryYear}`);
    } else if (query.length > 0 && query === 'allFields') {
      newQuery = `?${queryYear}`;
    } else if (user?.id === undefined) {
      newQuery = `?q[individual_id_eq]=${currentUser?.id}&${queryYear}`;
    } else {
      newQuery = `?q[individual_id_eq]=${user?.id}&${queryYear}`;
    }
    try {
      const response: PerformanceResponse = await DashboardService.getPerformance(newQuery);
      setPerformance(response);
    } catch (error) {
      setPerformance(performanceData);
    }
  };

  const loadData = async () => {
    setLoading(true);
    try {
      loadProceduresData();
      loadPerformance();
      loadProductivity();
    } catch (error) {
      setTotalProcedures(procedureData.total_inbox);
      setProcedureTotals(procedureData);
      setPerformance(performanceData);
      setProductivity(productivityData);
      setFields(productivityData.fields);
    }
    setLoading(false);
  };

  const updateQuery = () => {
    let newQuery = '';
    let prefix = '';
    if (dashboardFilter) {
      if (dashboardFilter.individualId) {
        prefix = 'individual_id_eq';
        newQuery = newQuery.concat(`?q[${prefix}]=${dashboardFilter?.individualId}`);
      } else if (dashboardFilter.fieldId !== 0) {
        prefix = 'main_field_id_in';
        newQuery = newQuery.concat(`?q[${prefix}]=${dashboardFilter?.fieldId}`);
      } else {
        newQuery = 'allFields';
      }
    }
    setQuery(newQuery);
  };

  const handleSearch = (name: string) => {
    if (name.length > 3) {
      const newList = auxFields.map((listElement) => ({
        ...listElement,
        individuals: listElement.individuals.filter((groupElement) => groupElement.individual_name.toLowerCase().includes(name.toLocaleLowerCase())),
      }));
      setFields(newList);
    } else if (name.length === 0 || name === '') {
      setFields(auxFields);
    }
  };

  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(() => {
    loadPerformance();
  }, [year]);

  useEffect(() => {
    updateQuery();
  }, [dashboardFilter]);

  return (
    <DashboardDigitalProcessesContext.Provider
      value={{
        query,
        loading,
        unauthorizedProcedure,
        procedureTotals,
        totalProcedures,
        dashboardFilter,
        setDashboardFilter,
        year,
        setYear,
        performance,
        productivity,
        fields,
        handleSearch,
      }}>
        {children}
    </DashboardDigitalProcessesContext.Provider>
  );
};

export default DashboardDigitalProcessesProvider;
