import styles from './styles.module.scss';

type BoxIconProps = {
  text: string,
};

export function BoxIcon(props: BoxIconProps) {
  return (
    <div className={styles.box}>
      <span>{props.text}</span>
    </div>
  );
}
