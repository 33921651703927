import { useState, useEffect } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { FaPlusCircle } from 'react-icons/fa';
import { ApplicantsType, OptionType, ApplicantsToSend } from '../../../@types/config';
import styles from './styles.module.scss';
import screen from '../../../helpers/screen';
import { Applicants } from './Applicants';
import Show from '../../Show';
import { ApplicantsModal } from './ApplicantsModal';
import { BeneficiariesData } from '../../../@types/paymentOrder';

type ListBeneficiariesType = {
  content: BeneficiariesData | undefined;
  dependencies: any;
  parentProcedureId: number;
  handleForm: (val: any) => void;
};

export function ListBeneficiaries(props: ListBeneficiariesType) {
  const [dataApplicants, setDataApplicants] = useState([]);
  const [applicantsToSend, setApplicantsToSend] = useState<ApplicantsToSend[]>([]);
  const [participations, setParticipations] = useState<OptionType[]>([]);
  const [openModalApplicants, setOpenModalApplicants] = useState(false);

  const isMobile = screen.isMobile();

  useEffect(() => {
    const data: BeneficiariesData = {
      applicantsToSend,
    };
    props.handleForm(data);
  }, [applicantsToSend]);

  useEffect(() => {
    if (props.content !== undefined) {
      setApplicantsToSend(props.content?.applicantsToSend);
    }
    setDataApplicants(props.dependencies.judicial_parts);
    setParticipations(props.dependencies.participation_id);
  }, [props.dependencies]);

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 12, md: 12 }}
      spacing={{ xs: 2, md: 2 }}
      className={styles.content}
    >
      <Grid item xs={4} sm={12} md={12}>
        <span className={styles.text}>{t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.text')}</span>
      </Grid>
      <Show if={dataApplicants?.length > 0}>
        <Grid item xs={4} sm={12} md={12}>
          {
            dataApplicants?.map((applicant: ApplicantsType) => <Applicants
              id={applicant.id}
              participation_id={applicant.participation_id}
              name={applicant.name}
              cpf={applicant.cpf}
              cnpj={applicant.cnpj}
              corporateName={applicant.corporateName}
              isMobile={isMobile}
              applicants={dataApplicants}
              applicantsToSend={applicantsToSend}
              setApplicantsToSend={setApplicantsToSend}
            />)
          }
        </Grid>
      </Show>
      <Grid item xs={4} sm={12} md={12} className={styles.plus}>
        <FaPlusCircle
          className={styles.plusIcon}
          onClick={() => setOpenModalApplicants(true)}
        />
      </Grid>
      <ApplicantsModal
        open={openModalApplicants}
        setOpen={setOpenModalApplicants}
        setDataApplicants={setDataApplicants}
        dataApplicants={dataApplicants}
        participations={participations}
        parentProcedure={props.parentProcedureId}
      />
    </Grid>
  );
}
