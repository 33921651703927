import { useState } from 'react';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { NestedOptionType, ChildrenOptionType } from '../../@types/config';
import styles from './styles.module.scss';
import Input from './Input';
import Menu from './Menu';
import Submenu from './Submenu';

type NestedSelectProps = {
  options: NestedOptionType[];
  placeholder: string;
  value: NestedOptionType | ChildrenOptionType;
  onChange?: (value: NestedOptionType | ChildrenOptionType) => void;
  defaultValue: NestedOptionType;
};

export default function NestedSelect(props: NestedSelectProps) {
  const [open, setOpen] = useState(false);
  const [top, setTop] = useState(0);
  const [chidren, setChildren] = useState<ChildrenOptionType[]>([]);
  const [selected, setSelected] = useState<NestedOptionType>();
  const [hover, setHover] = useState(false);

  const propsOnchange = (val: any) => {
    if (props.onChange && val) {
      props.onChange(val);
      setHover(false);
      setOpen(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    popupOpen,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    open,
    options: [props.defaultValue, ...props.options],
    value: props.value,
    clearOnBlur: true,
    clearOnEscape: true,
    onChange: (el, val) => {
      propsOnchange(val);
    },
  });

  const handleOnClick = (item: any) => {
    propsOnchange(item);
  };

  const openSubmenu = (option: NestedOptionType, el :any) => {
    setSelected(option);
    setTop(el.target.offsetTop + 245);
    if (option.children) {
      setChildren(option.children);
    }
    setHover(true);
  };

  const closeSubmenu = () => {
    setChildren([]);
    setHover(false);
  };

  return (
    <div className={styles.container}>
      <Input
        getRootProps={() => getRootProps()}
        getInputProps={() => getInputProps()}
        placeholder={props.placeholder}
        open={open}
        popupOpen={popupOpen}
        setAnchorEl={setAnchorEl}
        setOpen={() => setOpen(!open)}
      />

      { groupedOptions.length > 0 ? (
        <div onMouseLeave={() => closeSubmenu()}>
          <Menu
            anchorEl={anchorEl}
            getListboxProps={() => getListboxProps()}
            getOptionProps={(el) => getOptionProps(el)}
            groupedOptions={groupedOptions}
            openSubmenu={(option, el) => openSubmenu(option, el)}
            options={props.options}
          />

          <Submenu
            chidren={chidren}
            handleOnClick={handleOnClick}
            hover={hover}
            popupOpen={popupOpen}
            anchorEl={anchorEl}
            selected={selected}
            top={top}
          />
        </div>
      ) : null }
    </div>
  );
}
