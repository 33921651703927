import { useContext, useState } from 'react';
import { t } from 'i18next';
import { FaTimes, FaShareAlt, FaCheckSquare } from 'react-icons/fa';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { RoundButton } from '../../RoundButton';
import styles from './styles.module.scss';
import { SharedPeople, Procedure } from '../../../@types/model';
import { ButtonMenu } from '../ButtonMenu';
import { Avatar } from '../Avatar';
import { truncate } from '../../../helpers/strings';
import Show from '../../Show';
import { CurrentUser } from '../../../@types/currentUser';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { QueryContext } from '../../../context/queryContext';

type ShareInfosProps = {
  procedure: Procedure;
  shareList: SharedPeople[];
  box: string;
};

export function ShareInfos(props: ShareInfosProps) {
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const shareIndividual = props.shareList.find((obj) => obj.id === currentUser.id);
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const { setAlert, addToasts } = useContext(ProcedureActionsContext);
  const { loadData } = useContext(QueryContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpened = (e: any) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClosed = (e: any) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleText = (name: String) => {
    return (
      <Trans
        i18nKey='procedureBox.actions.shareProcedure.remove.text'
        components={{ br: <br /> }}
        values={{
          number: props.procedure.process_number,
          name,
        }}
      />
    );
  };

  const handleDelete = async (person: SharedPeople) => {
    if (person.id) {
      const response = await ProcedureBoxService.removeSharedPeople(props.procedure.id, person.id);
      setAlert(undefined);
      if (response.status === 204) {
        addToasts({
          type: 'default',
          text: t('procedureBox.actions.shareProcedure.remove.success', { name: person.name }),
        });
        loadData();
      } else {
        addToasts({
          type: 'error',
          text: t('procedureBox.actions.shareProcedure.remove.error'),
        });
      }
    } else {
      addToasts({
        type: 'error',
        text: t('procedureBox.actions.shareProcedure.remove.error'),
      });
      setAlert(undefined);
    }
  };

  const handleDeleteSharedPerson = (e: any, person: SharedPeople) => {
    setAlert({
      visible: true,
      handleConfirm: () => handleDelete(person),
      title: t('procedureBox.actions.shareProcedure.remove.title'),
      text: handleText(person.name),
    });
    e.stopPropagation();
    handleClosed(e);
  };

  const renderTextHeader = () => {
    if (shareIndividual) {
      const date = moment(shareIndividual?.share_date).format('DD/MM');
      return (
        <Trans
          i18nKey='procedureBox.actions.shareProcedure.createdBy'
          tOptions={{ interpolation: { escapeValue: false } }}
          values={{ individual_name: shareIndividual.owner, tramit_date: date }}
        />
      );
    }
    return (
      <></>
    );
  };

  const renderTextObservation = () => {
    const sharedObject = props.shareList.find((obj) => obj.id === currentUser.id);
    return sharedObject?.observation || t('procedureBox.actions.shareProcedure.empty');
  };

  const handleMarkAsDone = async (e: any) => {
    if (shareIndividual?.shared_id && !shareIndividual.was_seen) {
      e.stopPropagation();
      const response = await ProcedureBoxService.markAsSeen(shareIndividual.shared_id, shareIndividual.id);
      if (response.status === 200) {
        loadData();
      } else {
        addToasts({
          type: 'error',
          text: t('procedureBox.actions.shareProcedure.error'),
        });
      }
    }
    setAnchorEl(null);
    e.stopPropagation();
  };

  const renderContent = () => {
    if (props.box === 'inbox') {
      return (
        <>
          <div className={styles.header}>
            <span>{t('procedureBox.actions.shareProcedure.sharedWith')}</span>
          </div>
          { props.shareList.map((people, index) => (
            <div className={styles.people}>
              <Avatar
                name={ people.name }
                className={styles.avatar}
                color={people.color}
                profile_picture={people.profile_picture}
              />
              <span className={styles.name}>{truncate(people.name, 25)}</span>
              <Show if={props.procedure.individual_id === currentUser.id}>
                <FaTimes
                  className={styles.delete}
                  onClick={(e) => handleDeleteSharedPerson(e, people)}
                />
              </Show>
            </div>
          ))}
        </>
      );
    }
    return (
      <>
        <div className={styles.observationHeader}>
          <span>{renderTextHeader()}</span>
        </div>
        <span className={styles.observation}>
          {renderTextObservation()}
        </span>
        <div className={`${styles.action} ${shareIndividual?.was_seen ? styles.done : ''}`}>
          <FaCheckSquare onClick={(e) => handleMarkAsDone(e)} />
          <span>{t(`procedureBox.actions.shareProcedure.${shareIndividual?.was_seen ? 'done' : 'markAsFinished'}`)}</span>
        </div>
      </>
    );
  };

  const getBadge = (): number | undefined => {
    if (props.box === 'inbox') {
      return props.shareList?.length;
    }
    if (props.box === 'sharedbox' && !shareIndividual?.was_seen) {
      return 1;
    }
    return undefined;
  };

  return (
    <div className={styles.container}>
      <RoundButton
        icon={ <FaShareAlt/> }
        className={styles.button}
        onClick={(e) => { handleOpened(e); }}
        badge={getBadge()}
        size={isMobile ? 'intermediate' : 'medium'}
        tooltip={props.box !== 'inbox' ? t('procedureBox.actions.shareProcedure.tooltip') : ''}
        shared={props.box === 'inbox'}
      />
      <ButtonMenu
        anchorEl={anchorEl}
        handleClosed={handleClosed}
        id='tramit-content'
      >
        <div className={styles.sharedContent}>
          {renderContent()}
        </div>
      </ButtonMenu>
    </div>
  );
}
