import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { AttachedDocument, OptionType } from '../../../@types/config';
import { SpuDocumentData } from '../../../@types/spu';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { Button } from '../../../components/Button';
import { InputText } from '../../../components/InputText';
import styles from './styles.module.scss';
import { TextArea } from '../../../components/TextArea';
import Select from '../../../components/Select';
import { isCnpj, isCpf } from '../../../helpers/validator';
import Show from '../../../components/Show';
import { maskCpfOrCnpj } from '../../../helpers/masks';
import AddressService from '../../../services/addressService';
import { ErrorField } from '../../../components/ErrorField';

type DocumentDataProps = {
  priorityOptions: OptionType[];
  institutionOptions: OptionType[];
  protesterOptions: OptionType[];
  stateOptions: OptionType[];
  content: SpuDocumentData | undefined;
  registration?: boolean;
  nextStep: (val: any) => void;
  documents?: AttachedDocument[];
};
interface FormValues {
  priority: OptionType;
  targetInstitution: OptionType;
  targetSector: OptionType;
  orderBody: string;
  protester: OptionType;
  name: string;
  responsible: string;
  organization: string;
  cpf: string;
  cnpj: string;
  gender: OptionType;
  address: string;
  addressNumber: string;
  addressComplement: string;
  cep: string;
  state: OptionType;
  city: OptionType;
  neighborhood: OptionType;
  homePhone: string;
  cellPhone: string;
  commercialPhone: string;
  email: string;
  observation: string;
}

export function DocumentData(props: DocumentDataProps) {
  const genders: OptionType[] = [
    {
      value: 0,
      label: t('procedureBox.actions.spu.modal.partData.male'),
    },
    {
      value: 1,
      label: t('procedureBox.actions.spu.modal.partData.female'),
    },
  ];
  const { proceduresSeleted } = useContext(ProcedureActionsContext);
  const [deadline, setDeadline] = useState(props.content?.deadline || '');
  const [origin, setOrigin] = useState(props.content?.origin || '');
  const [optionFields, setOptionFields] = useState<OptionType[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionType[]>([]);
  const [open, setOpen] = useState(Boolean(props.registration));
  const [cepError, setCepError] = useState('');
  const [neighborhoodOptions, setNeighborhoodOptions] = useState<OptionType[]>([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (props.documents) {
      const docSigned = props.documents.find((doc) => doc.signed !== null);
      setDisabled(docSigned === undefined);
    }
  }, [props.documents]);

  const schema = Yup.object().shape({
    orderBody: Yup.string().required(t('general.requeried')),
    priority: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    targetInstitution: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    targetSector: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    name: Yup.string().required(t('general.requeried')),
    protester: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.string().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    cpf: Yup.string().test('cpf-required', t('general.requeried'), function (value) {
      const { path, createError } = this;

      const protesterType = this.parent.protester;
      const protesterValue = Object.keys(protesterType).length > 0 ? protesterType.value : '';
      if (protesterType !== null && protesterValue.toLowerCase().includes('cpf') && !value) {
        return createError({ path, message: t('general.requeried') });
      }
      if (value !== undefined && !isCpf(value) && protesterValue.toLowerCase().includes('cpf')) {
        return createError({ path, message: t('general.cpf') });
      }
      return true;
    }),
    cnpj: Yup.string().test('cnpj-required', t('general.requeried'), function (value) {
      const { path, createError } = this;

      const protesterType = this.parent.protester;
      const protesterValue = Object.keys(protesterType).length > 0 ? protesterType.value : '';
      if (protesterType !== null && protesterValue.toLowerCase().includes('cnpj') && !value) {
        return createError({ path, message: t('general.requeried') });
      }
      if (value !== undefined && !isCnpj(value) && protesterValue.toLowerCase().includes('cnpj')) {
        return createError({ path, message: t('general.cnpj') });
      }
      return true;
    }),
    gender: Yup.mixed().test(
      'genderRequiredIfProtesterIsCpf',
      t('general.requeried'),
      function (value) {
        const protesterType = this.parent.protester;
        if (
          protesterType !== null
            && Object.keys(protesterType).length > 0
            && protesterType?.value.toLowerCase().includes('cpf')
        ) {
          return value !== undefined && value !== null;
        }
        return true;
      },
    ).nullable(),
    state: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    city: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    neighborhood: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    email: Yup.string().nullable().test(
      'is-email',
      t('procedureBox.actions.spu.validators.email'),
      (value) => {
        if (value && !/\S+@\S+\.\S+/.test(value)) {
          return false;
        }
        return true;
      },
    ),
  });

  const {
    handleSubmit, formState: { errors }, control, setValue, resetField,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const handleNextStep = (form: FormValues) => {
    const data: SpuDocumentData = {
      procedureId: proceduresSeleted[0].id,
      origin,
      deadline,
      ...form,
    };
    props.nextStep(data);
  };

  const targetInstitutionCode = useWatch({
    control,
    name: 'targetInstitution.value',
    defaultValue: undefined,
  });

  const protesterTypeValue = useWatch({
    control,
    name: 'protester.value',
    defaultValue: '',
  });

  const stateCode = useWatch({
    control,
    name: 'state.value',
    defaultValue: undefined,
  });

  useEffect(() => {
    ProcedureBoxService.getSpuCities(stateCode)
      .then((res) => {
        const cities: OptionType[] = [];
        res.json.map((doc: any) => cities.push({
          label: doc[0],
          value: doc[1],
        }));
        setCityOptions(cities);
        if (!props.content || props.content?.state.value !== stateCode) {
          resetField('city');
        } else {
          setValue('city', props.content.city);
        }
      });
  }, [stateCode]);

  const cityCode = useWatch({
    control,
    name: 'city.value',
    defaultValue: undefined,
  });

  useEffect(() => {
    ProcedureBoxService.getSpuNeighborhoods(cityCode)
      .then((res) => {
        const neighborhoods: OptionType[] = [];
        res.json.map((doc: any) => neighborhoods.push({
          label: doc[0],
          value: doc[1],
        }));
        setNeighborhoodOptions(neighborhoods);
        if (!props.content || props.content?.city.value !== cityCode) {
          resetField('neighborhood');
        } else {
          setValue('neighborhood', props.content.neighborhood);
        }
      });
  }, [cityCode]);

  useEffect(() => {
    ProcedureBoxService.getSpuFields(targetInstitutionCode)
      .then((res) => {
        const fields: OptionType[] = [];
        res.json.map((doc: any) => fields.push({
          label: doc[0],
          value: doc[1],
        }));
        setOptionFields(fields);
        if (!props.content || props.content?.targetInstitution.value !== targetInstitutionCode) {
          resetField('targetSector');
        } else {
          setValue('targetSector', props.content.targetSector);
        }
      });
  }, [targetInstitutionCode]);

  useEffect(() => {
    if (props.content) {
      setValue('priority', props.content.priority);
      setValue('targetInstitution', props.content.targetInstitution);
      setValue('targetSector', props.content.targetSector);
      setValue('orderBody', props.content.orderBody);
      setValue('name', props.content.name);
      setValue('cpf', props.content.cpf);
      setValue('cnpj', props.content.cnpj);
      setValue('responsible', props.content.responsible);
      setValue('organization', props.content.organization);
      setValue('protester', props.content.protester);
      setValue('gender', props.content.gender);
      setValue('address', props.content.address);
      setValue('addressNumber', props.content.addressNumber);
      setValue('addressComplement', props.content.addressComplement);
      setValue('cep', props.content.cep);
      setValue('state', props.content.state);
      setValue('city', props.content.city);
      setValue('neighborhood', props.content.neighborhood);
      setValue('homePhone', props.content.homePhone);
      setValue('cellPhone', props.content.cellPhone);
      setValue('commercialPhone', props.content.commercialPhone);
      setValue('email', props.content.email);
      setValue('observation', props.content.observation);
    }
    ProcedureBoxService.getSpuOriginNumber(proceduresSeleted[0].id)
      .then((res) => {
        setDeadline(res.deadline);
        setOrigin(res.number);
      });
  }, []);

  const handleCepChange = async (event: any) => {
    const cepValue = event.target.value.replace(/\D/g, '');
    setValue('cep', cepValue);
    if (cepValue.length === 8) {
      try {
        setCepError('');
        const { logradouro, complemento } = await AddressService.getAddressFromCep(cepValue);
        setValue('address', logradouro);
        setValue('addressComplement', complemento);
      } catch {
        setCepError(t('general.cepError'));
      }
    } else {
      setCepError(t('general.cep'));
    }
  };

  return (
    <form onSubmit={handleSubmit(handleNextStep)}>
      <Grid className={styles.container}
        container
        columns={{ xs: 4, sm: 12, md: 12 }}
        spacing={{ xs: 2, md: 2 }}
      >
      <Show if={!props.registration}>
        <Grid item xs={4} sm={12} md={12}>
          <div className={styles.spuData} onClick={() => setOpen(!open)}>
            <span className={`${styles.text} ${open ? styles.open : ''}`}>
              {t('procedureBox.actions.spu.spuData')}
            </span>
            { open
              ? <FaChevronUp className={ styles.chevron } />
              : <FaChevronDown className={ styles.chevron } />
            }
          </div>
        </Grid>
      </Show>
      <Show if={open}>
        <Grid item xs={4} sm={12} md={12}>
          <div className={ `${styles.header}` }>
            {t('procedureBox.actions.spu.tabs.documentData')}
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <InputText
            label={t('procedureBox.actions.spu.modal.documentData.origin')}
            onChange={(event) => {}}
            value={origin}
            placeholder={t('procedureBox.actions.spu.modal.documentData.origin')}
            readOnly
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <InputText
            label={t('procedureBox.actions.spu.modal.documentData.deadline')}
            onChange={(event) => {}}
            value={deadline}
            tooltip={t('procedureBox.actions.spu.modal.documentData.tooltip.deadline')}
            placeholder={t('procedureBox.actions.spu.modal.documentData.deadline')}
            readOnly
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="priority"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.spu.modal.documentData.priority')}
                tooltip={t('procedureBox.actions.spu.modal.documentData.tooltip.priority')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.priorityPlaceholder')}
                defaultValue={props.content?.priority}
                options={props.priorityOptions}
              />
            )}
          />
          <p className={styles.error}>{errors.priority?.message || errors.priority?.label?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="targetInstitution"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.spu.modal.documentData.institution')}
                tooltip={t('procedureBox.actions.spu.modal.documentData.tooltip.institution')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.institutionPlaceholder')}
                defaultValue={props.content?.targetInstitution}
                options={props.institutionOptions}
              />
            )}
          />
          <p className={styles.error}>{errors.targetInstitution?.message || errors.targetInstitution?.label?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="targetSector"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.spu.modal.documentData.field')}
                tooltip={t('procedureBox.actions.spu.modal.documentData.tooltip.field')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.fieldPlaceholder')}
                defaultValue={props.content?.targetSector}
                options={optionFields}
              />
            )}
          />
          <p className={styles.error}>{errors.targetSector?.message || errors.targetSector?.label?.message}</p>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Controller
            control={control}
            name="orderBody"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('procedureBox.actions.spu.modal.documentData.description')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.descriptionPlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{errors.orderBody?.message}</p>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <div className={ `${styles.header}` }>
            {t('procedureBox.actions.spu.tabs.partData')}
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="protester"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.spu.modal.partData.part')}
                placeholder={t('procedureBox.actions.spu.modal.partData.partPlaceholder')}
                defaultValue={props.content?.protester}
                options={props.protesterOptions}
              />
            )}
          />
          <p className={styles.error}>{errors.protester?.message || errors.protester?.label?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partData.name')}
                tooltip={t('procedureBox.actions.spu.modal.partData.tooltip.name')}
                placeholder={t('procedureBox.actions.spu.modal.partData.namePlaceholder')}
                defaultValue={props.content?.name}
              />
            )}
          />
          <p className={styles.error}>{errors.name?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="responsible"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partData.responsible')}
                tooltip={t('procedureBox.actions.spu.modal.partData.tooltip.responsible')}
                placeholder={t('procedureBox.actions.spu.modal.partData.responsiblePlaceholder')}
                defaultValue={props.content?.responsible}
              />
            )}
          />
          <p className={styles.error}>{errors.responsible?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="organization"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partData.organization')}
                tooltip={t('procedureBox.actions.spu.modal.partData.tooltip.organization')}
                placeholder={t('procedureBox.actions.spu.modal.partData.organizationPlaceholder')}
                defaultValue={props.content?.organization}
              />
            )}
          />
          <p className={styles.error}>{errors.organization?.message}</p>
        </Grid>
        <Show if={protesterTypeValue !== null && protesterTypeValue.toLowerCase().includes('cpf')}>
          <Grid item xs={4} sm={4} md={4}>
            <Controller
              control={control}
              name="cpf"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.actions.spu.modal.partData.cpf')}
                  placeholder={t('procedureBox.actions.spu.modal.partData.cpfPlaceholder')}
                  onChange={(event) => setValue('cpf', maskCpfOrCnpj(event.target.value).mask())}
                  defaultValue={props.content?.cpf}
                />
              )}
            />
            <p className={styles.error}>{errors.cpf?.message}</p>
          </Grid>
        </Show>
        <Show if={protesterTypeValue !== null && protesterTypeValue.toLowerCase().includes('cnpj')}>
          <Grid item xs={4} sm={4} md={4}>
            <Controller
              control={control}
              name="cnpj"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.actions.spu.modal.partData.cnpj')}
                  placeholder={t('procedureBox.actions.spu.modal.partData.cnpjPlaceholder')}
                  onChange={(event) => setValue('cnpj', maskCpfOrCnpj(event.target.value).mask())}
                  defaultValue={props.content?.cnpj}
                />
              )}
            />
            <p className={styles.error}>{errors.cnpj?.message}</p>
          </Grid>
        </Show>
        <Show if={protesterTypeValue !== null && protesterTypeValue.toLowerCase().includes('cpf')}>
          <Grid item xs={4} sm={4} md={4}>
            <Controller
              control={control}
              name="gender"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('procedureBox.actions.spu.modal.partData.gender')}
                  placeholder={t('procedureBox.actions.spu.modal.partData.genderPlaceholder')}
                  options={genders}
                  defaultValue={props.content?.gender}
                />
              )}
            />
            <p className={styles.error}>{errors.gender?.message || errors.gender?.label?.message}</p>
          </Grid>
        </Show>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partAddress.street')}
                placeholder={t('procedureBox.actions.spu.modal.partAddress.streetPlaceholder')}
                defaultValue={props.content?.address}
              />
            )}
          />
          <p className={styles.error}>{errors.address?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="addressNumber"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partAddress.number')}
                placeholder={t('procedureBox.actions.spu.modal.partAddress.number')}
                defaultValue={props.content?.addressNumber}
              />
            )}
          />
          <p className={styles.error}>{errors.addressNumber?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="addressComplement"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partAddress.complement')}
                placeholder={t('procedureBox.actions.spu.modal.partAddress.complement')}
                defaultValue={props.content?.addressComplement}
              />
            )}
          />
          <p className={styles.error}>{errors.addressComplement?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="cep"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partAddress.cep')}
                placeholder={t('procedureBox.actions.spu.modal.partAddress.cepPlaceholder')}
                mask={'99999-999'}
                defaultValue={props.content?.cep}
                onChange={handleCepChange}
              />
            )}
          />
          <p className={styles.error}>{errors.cep?.message}</p>
          <ErrorField text={cepError} />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.spu.modal.partAddress.state')}
                placeholder={t('procedureBox.actions.spu.modal.partAddress.statePlaceholder')}
                defaultValue={props.content?.state}
                options={props.stateOptions}
              />
            )}
          />
          <p className={styles.error}>{errors.state?.message || errors.state?.label?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.spu.modal.partAddress.city')}
                placeholder={t('procedureBox.actions.spu.modal.partAddress.cityPlaceholder')}
                defaultValue={props.content?.city}
                options={cityOptions}
              />
            )}
          />
          <p className={styles.error}>{errors.city?.message || errors.city?.label?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="neighborhood"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.spu.modal.partAddress.neighborhood')}
                placeholder={t('procedureBox.actions.spu.modal.partAddress.neighborhoodPlaceholder')}
                defaultValue={props.content?.neighborhood}
                options={neighborhoodOptions}
              />
            )}
          />
          <p className={styles.error}>{errors.neighborhood?.message || errors.neighborhood?.label?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="homePhone"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partContact.homePhone')}
                placeholder={t('procedureBox.actions.spu.modal.partContact.phonePlaceholder')}
                defaultValue={props.content?.homePhone}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="cellPhone"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partContact.cellPhone')}
                placeholder={t('procedureBox.actions.spu.modal.partContact.phonePlaceholder')}
                defaultValue={props.content?.cellPhone}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="commercialPhone"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partContact.commercialPhone')}
                placeholder={t('procedureBox.actions.spu.modal.partContact.phonePlaceholder')}
                defaultValue={props.content?.commercialPhone}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partContact.email')}
                placeholder={t('procedureBox.actions.spu.modal.partContact.email')}
                defaultValue={props.content?.email}
              />
            )}
          />
          <p className={styles.error}>{errors.email?.message}</p>
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          <Controller
            control={control}
            name="observation"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.spu.modal.partContact.observation')}
                placeholder={t('procedureBox.actions.spu.modal.partContact.observationPlaceholder')}
                defaultValue={props.content?.observation}
              />
            )}
          />
        </Grid>
      </Show>
      <div className={styles.footer}>
        <Button
          title={t(`procedureBox.actions.spu.${!props.registration ? 'sendOficialLetter' : 'registrationOficialLetter'}`)}
          buttonType='primary'
          type='submit'
          size='flat'
          disabled={disabled && !!props.documents}
          round
        />
      </div>
    </Grid>
    </form>
  );
}
