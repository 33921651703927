import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import { RecoverPassword } from '../pages/RecoverPassword';

const SignRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/forgot_password" element={<RecoverPassword />} />
      <Route path="/" element={<SignIn />} />
    </Routes>
  );
};

export default SignRoutes;
