import { t } from 'i18next';
import { ErrorField } from '../components/ErrorField';

export default class FormHelper {
  public static renderErrorField = (field: string, errors: any) => {
    if (errors) {
      if (errors[field]) {
        const errorDescription = errors[field][0];
        return <ErrorField text={errorDescription}/>;
      }
    }
    return false;
  };

  public static customToastMessage = (procedureId: number, action?: string) => {
    return (
        <span>
          {t(`procedureBox.newProcedure.toasts.${action === 'edit' ? 'edit' : 'success'}`)}
          <a
            href={`/procedure_box/show_procedure/${procedureId}`}
            target='_blank'
            data-cy={'new-procedure-toasts-success'}
          >
            <b>{t('procedureBox.newProcedure.toasts.showProcess')}</b>
          </a>
        </span>
    );
  };

  public static customToastMessageEdit = (procedureId: number) => {
    return (
        <span data-cy={'edit-toasts-success'}>
          {t('procedureBox.editProcedure.toasts.success')}
          <a href={`/procedure_box/show_procedure/${procedureId}`} target='_blank'>
            <b>{t('procedureBox.newProcedure.toasts.showProcess')}</b>
          </a>
        </span>
    );
  };

  public static setValueIfExists = (key: string, value: any, set: any) => {
    if (value !== null && value !== undefined) {
      set(key, value);
    }
  };
}
