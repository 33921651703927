import { t } from 'i18next';
import { useContext } from 'react';
import { FaBell } from 'react-icons/fa';
import styles from './styles.module.scss';
import { HomeContext } from '../../../context/homeContext';
import { AllNotifications } from '../AllNotifications';

export function Footer() {
  const { setModal } = useContext(HomeContext);

  const handleSeeAll = () => {
    setModal({
      visible: true,
      icon: <FaBell />,
      title: t('home.notifications.modal.title'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'larger',
      children: <AllNotifications closeModal={() => setModal(undefined)} />,
    });
  };

  return (
    <div className={ styles.container }>
      <span className={styles.read} onClick={handleSeeAll}>
        {t('home.notifications.see_all')}
      </span>
    </div>
  );
}
