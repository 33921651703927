import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type MigrationRegisteredDebtsProps = {
  title?: string;
};

export function MigrationRegisteredDebts(props: MigrationRegisteredDebtsProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/inscription_auxiliaries/criar`}/>
  );
}
