import { useEffect } from 'react';
import DashboardProvider from '../../context/dashboardContext';
import DashboardDigitalProcessesProvider from '../../context/dashboardDigitalProcessesContext';
import { Boxes } from './Boxes';
import styles from './styles.module.scss';
import { loadTitle } from '../../helpers/changeTitle';

type HomeProps = {
  title?: string;
};

export function Home(props: HomeProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <DashboardProvider>
      <DashboardDigitalProcessesProvider>
        <div className={ styles.content }>
          <Boxes />
        </div>
      </DashboardDigitalProcessesProvider>
    </DashboardProvider>
  );
}
