import { FaCircle } from 'react-icons/fa';
import { TimelineDay } from '../../../../@types/digitalProcesses/timeline';
import { Moment } from '../../../../config/moment';
import verifyArchivedProcess from '../../../../helpers/verifyArchivedProcess';
import { Progress } from '../Progress';
import styles from './styles.module.scss';

type DayProps = {
  day: TimelineDay;
  last?: boolean;
  first?: boolean;
  selected?: number;
  onClick: (id: number) => void;
  procedureStatus: string;
};

export function Day(props: DayProps) {
  const lastStepVerify = (procedureStatus: string) => {
    if (props.first && !props.last) {
      if (verifyArchivedProcess(procedureStatus)) {
        return (
          <FaCircle className={styles.endDot}/>
        );
      } return (
        <FaCircle className={styles.currentDot}/>
      );
    }
    if (props.last) {
      return (
        <FaCircle className={styles.startDot}/>
      );
    } return (
      <FaCircle className={styles.standardDot}/>
    );
  };

  return (
    <div className={styles.container }>
      <div className={`${styles.timeline} ${props.last ? styles.last : ''}`}>
        {lastStepVerify(props.procedureStatus)}
        <div className={styles.content}>
          <p className={styles.date}>{Moment(props.day.date).format('DD [de] MMMM [de] YYYY')}</p>
          {
            props.day.progresses.map((progress, index) => (
              <Progress
                progress={progress}
                first={index === 0}
                last={index === (props.day.progresses.length - 1)}
                selected={props.selected}
                onClick={(id: number) => props.onClick(id)}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}
