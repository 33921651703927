import { Modal as ModalMui } from '@mui/material';
import { useContext } from 'react';
import { Header } from './Header';
import styles from './styles.module.scss';
import { HomeContext } from '../../../context/homeContext';

export function Modal() {
  const { modal, setModal } = useContext(HomeContext);
  if (modal) {
    return (
      <ModalMui
        open={modal.open || false}
        onClose={() => setModal()}
        disableAutoFocus={true}
      >
        <div
          className={`${styles.box} ${
            modal.size === 'small'
              ? styles.small
              : modal.size === 'medium'
                ? styles.medium
                : ''
          }`}
        >
          <Header
            title={modal.title}
            onClose={modal.handleClose}
            icon={modal.icon}
            boxComponent={modal.box}
            minimize={modal.minimize}
            onMinimize={modal.onMinimize}
          />
          <div className={styles.body}>{modal.children}</div>
        </div>
      </ModalMui>
    );
  }
  return <></>;
}
