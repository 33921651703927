import {
  NegotiationProgress,
  DebtAmountResponse,
  Negotiations,
  NegotiationPerMonthResponse,
} from '../../@types/debtDashboard';

export const debtAmountData: DebtAmountResponse = {
  total_debts: 8418713920.12,
  debts: [
    {
      title: 'total_requirement_requested',
      total: 953144760.12,
    },
    {
      title: 'total_requirement_approved',
      total: 40526080.58,
    },
    {
      title: 'total_inscript',
      total: 3037756168.3,
    },
  ],
};

export const negotiationsData: Negotiations = {
  total_paid: 53998898.13,
  total_lost: 63638686.45,
  total_start: 74246887.77,
  total_active: 1483314.34,
  total_overdue: 29207234.38,
};

export const negotiationsPerMonthData: NegotiationPerMonthResponse = {
  year: 2023,
  total_paid: 1234,
  months: [
    {
      month: 1,
      total_paid: 1234,
    },
  ],
};

export const negotiationProgressData: NegotiationProgress[] = [
  {
    status: 'sent_protest',
    current: 205433514.85,
    previous: 0.0,
  },
  {
    status: 'legal_action',
    current: 97425631.05,
    previous: 120321658.12,
  },
  {
    status: 'canceled',
    current: 0.0,
    previous: 0.0,
  },
  {
    status: 'prescribed',
    current: 111503.08,
    previous: 56823.84,
  },
  {
    status: 'settled',
    current: 40371488.35,
    previous: 74752335.47,
  },
  {
    status: 'redeemed',
    current: 0.0,
    previous: 650.26,
  },
];
