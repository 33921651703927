import { Grid } from '@mui/material';
import { useContext } from 'react';
import { DashboardContext } from '../../../context/dashboardContext';
import { DashboardDigitalProcessesContext } from '../../../context/dashboardDigitalProcessesContext';
import { DebtDashboard } from '../DebtDashboard';
import { DigitalProcessesDashboard } from '../DigitalProcessesDashboard';
import Screen from '../../../helpers/screen';

export function Boxes() {
  const { unauthorizedDebt } = useContext(DashboardContext);
  const { unauthorizedProcedure } = useContext(DashboardDigitalProcessesContext);
  const isMobile = Screen.isMobile();
  return (
    <Grid
      container
      columns={{ xs: 1, sm: 1, md: unauthorizedDebt === unauthorizedProcedure ? 2 : 1 }}
      spacing={2}
      direction={unauthorizedProcedure && !unauthorizedDebt && isMobile ? 'column-reverse' : 'row'}
    >
      {!unauthorizedProcedure && <Grid item xs={1} md={1}>
          <DigitalProcessesDashboard />
        </Grid>
      }
      {!unauthorizedDebt && <Grid item xs={1} md={1}>
          <DebtDashboard />
        </Grid>
      }
    </Grid>
  );
}
