import { t } from 'i18next';
import { useContext } from 'react';
import { FaHands } from 'react-icons/fa';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Alert } from '../Alert';

export function ForceTramitAlert() {
  const { batches } = useContext(BackgroundActionsContext);

  return (
    <div>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'forceTramit').map((batch) => (
        <Alert
          icon={<FaHands />}
          title={t('procedureBox.actions.forceTramit.modal.alertLoading.title')}
          text={t('procedureBox.actions.forceTramit.modal.alertLoading.text')}
          batch={batch}
        />
      ))}
    </div>
  );
}
