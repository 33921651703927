import {
  useContext, useState, useEffect, useRef,
} from 'react';
import { t } from 'i18next';
import {
  FaChevronDown,
  FaChevronUp,
  FaSearch,
} from 'react-icons/fa';
import { Marker } from '../../../../@types/model';
import { RoundButton } from '../../../RoundButton';
import { Option } from '../../Option';
import { ProcedureMarkersContext } from '../../../../context/procedureMarkersContext';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { QueryContext } from '../../../../context/queryContext';
import { SearchMarkers } from './SearchMarkers';
import { OptionType } from '../../../../@types/config';

type CustomOptionProps = {
  type: 'public' | 'private';
  open?: boolean;
};

export function CustomOption(props: CustomOptionProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(props.open);
  const { markers } = useContext(ProcedureMarkersContext);
  const { filterMarkers, addMarker, removeMarker } = useContext(QueryContext);
  const { removeAllProcedures } = useContext(ProcedureActionsContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState<Marker[]>([]);
  const [selectMarkers, setSelectMarkers] = useState<OptionType[]>([]);
  const options: Marker[] = props.type === 'public'
    ? markers.public_markers : markers.private_markers;

  const loadData = (markersParam: Marker[]) => {
    const markersList = markersParam.map((option: Marker) => {
      const obj = { label: option.label, value: option.id };
      return obj;
    });
    setSelectMarkers(markersList);
  };

  const loadMarkers = () => {
    if (props.type === 'public') {
      setDataFilter(markers.public_markers);
      loadData(markers.public_markers);
    } else {
      setDataFilter(markers.private_markers);
      loadData(markers.private_markers);
    }
  };

  useEffect(() => {
    loadMarkers();
  }, []);

  const mountQuery = (marker: any) => {
    if (filterMarkers.includes(marker)) {
      removeMarker(marker);
    } else {
      addMarker(marker);
    }
    removeAllProcedures();
    return false;
  };

  const renderOptions = () => {
    if (options.length > 0) {
      return options.map((option) => {
        return (
          <Option
            onChange={(marker) => mountQuery(marker)}
            marker={ option }
            value={ filterMarkers.includes(option) }
          />
        );
      });
    }
    return (
      <div className={styles.emptyContainer}>
        <p className={styles.label}>{t(`procedureBox.markers.message.${props.type}`)}</p>
        <p className={styles.link} >{t(`procedureBox.markers.link.${props.type}`)}</p>
      </div>
    );
  };

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setOpenFilter(!openFilter);
    setIsOpen(true);
  };

  return (
    <div className={ styles.container}>
      <div className={styles.groupTitle} >
        <div className={ styles.title } onClick={ (e) => handleOnClick(e) }>
          { isOpen ? <FaChevronUp/> : <FaChevronDown/> }
          <span>{ t(`procedureBox.markers.${props.type}`) }</span>
        </div>
        <RoundButton
          size='micro'
          icon={ <FaSearch /> }
          onClick={ handleSearch }
        />
        { openFilter
          ? <div className={styles.searchMarkers} ref={ref}>
                <SearchMarkers
                  data={dataFilter}
                  markers={selectMarkers}
                  setMarkers={(markersParam) => setSelectMarkers(markersParam)}
                  setClose={() => setOpenFilter(false)}
                />
            </div>
          : null }
      </div>
      <div className={styles.list}>
        { isOpen ? renderOptions() : null }
      </div>
    </div>
  );
}
