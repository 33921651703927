import { useState, useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  FaTimes,
  FaChevronDown,
  FaRegQuestionCircle,
} from 'react-icons/fa';
import {
  CssBaseline,
  ThemeProvider,
  createFilterOptions,
  AutocompleteGetTagProps,
  Tooltip,
  Autocomplete,
  TextField,
  Checkbox,
} from '@mui/material/';
import { Label } from '../Label';
import styles from './styles.module.scss';
import { globalTheme } from './globalTheme';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

type MarkerColor = {
  value: any;
  color: string;
};

type SelectFilterProps = {
  id?: string;
  options: OptionType[];
  placeholder?: string;
  label?: string;
  tooltip?: string;
  markersColors?: MarkerColor[];
  subject?: boolean;
  handleSelectedItems?: (itens: number[]) => void;
  returnValue?: boolean;
  onChange?: (value: [] | null) => void;
  unitField?: OptionType[];
  dataCy?: String;
};

export type OptionType = {
  value: any;
  label: string;
};

function Tag(props: TagProps) {
  const { label, onDelete } = props;
  return (
    <div className={styles.tag}>
      <span>{label}</span>
      <FaTimes onClick={onDelete} className={styles.faTimes} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>();

export function MultipleSelect(props: SelectFilterProps) {
  const { t } = useTranslation();
  const idSelectAll = 'select-all';
  const [quantity, setQuantity] = useState<number>(1);
  const [checkSelected, setCheckSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const filter = createFilterOptions<OptionType>();
  const noOptions = quantity === 0;

  const handleToggleOption = (selectedOptionsTemp :OptionType[]) => {
    setSelectedOptions(selectedOptionsTemp);
  };

  useEffect(() => {
    setSelectedOptions([]);
  }, []);

  useEffect(() => {
    if (props.unitField && props.unitField.length > 1) {
      setSelectedOptions([]);
    } else if (props.unitField) {
      setSelectedOptions(props.unitField);
    }
  }, [props.unitField]);

  const onChange = (val: any) => {
    if (props.onChange) {
      if (props.returnValue) {
        props.onChange(val?.value);
      } else {
        props.onChange(val);
      }
    }
  };

  const handleChange = (event: any, selectedOptionsTemp :OptionType[], reason :string, details :any) => {
    onChange(selectedOptionsTemp.map((jbts) => (jbts.value)));
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (checkSelected) {
        let result = [];
        result = props.options.filter((el :any) => el.value !== details.option.value);
        handleToggleOption(result);
      } else {
        handleToggleOption(selectedOptionsTemp);
      }
      setCheckSelected(false);
    }
    if (reason === 'clear') {
      setSelectedOptions([]);
    }
  };

  const optionRenderer = (others :any, option :OptionType, { selected } :any) => {
    const optionSelectAll = option.value === idSelectAll;
    const disabled = optionSelectAll && quantity !== props.options.length;
    return (
      <>
        <li {...others} aria-disabled={disabled}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            style={{
              padding: '2px',
              marginRight: '8px',
            }}
            checked={checkSelected ? true : selected}
            disabled={disabled}
          />
          {props.markersColors ? <div className={styles.square} style={ { backgroundColor: (props.markersColors.filter((marker) => marker.value === option.value))[0].color } }></div> : <></>}
          {option.label}
        </li>
        {
          noOptions
            ? <li className={styles.noOptions}>
                {t('general.noOptions')}
              </li>
            : null
        }
      </>
    );
  };

  const tagRenderer = (tagValue: OptionType[], getTagProps: any) => {
    const tags = tagValue.map((option :OptionType, index :number) => (
      <Tooltip title={ option.label }>
        <div className={styles.containerTag}>
          <StyledTag {...getTagProps({ index })}
          label={props.markersColors ? <div className={styles.tagMarker}>
            <div className={styles.square} style={ { backgroundColor: (props.markersColors.filter((marker) => marker.value === option.value))[0].color } }>
            </div> {option.label}
          </div> : option.label} />
        </div>
      </Tooltip>
    ));
    return tags;
  };

  const handleSelected = () => {
    if (props.handleSelectedItems) {
      props.handleSelectedItems(selectedOptions.map((a) => a.value));
    }
  };

  return (
    <div className={styles.container}>
      <div className={ styles.info }>
        {props.label && <Label text={props.label} />}
        {props.tooltip
          ? <Tooltip title={ props.tooltip }>
            <div className={styles.containerTag}>
              <FaRegQuestionCircle />
            </div>
            </ Tooltip>
          : null}
      </div>
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <Autocomplete
          data-cy={props.dataCy}
          popupIcon={<FaChevronDown className={styles.icon} />}
          isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
          limitTags={2}
          multiple
          options={props.options}
          value={selectedOptions}
          disableCloseOnSelect
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            setQuantity(filtered.length);
            return [...filtered];
          }}
          onChange={handleChange}
          onBlur={() => handleSelected()}
          getOptionLabel={(option) => option.label}
          renderOption={optionRenderer}
          renderTags={tagRenderer}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.placeholder || ''} />
          )}
        />
      </ThemeProvider>
    </div>
  );
}
