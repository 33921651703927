import { ApplicantsToSend, OptionType } from './config';

export const paymentOrderInitialState: PaymentOrderDocument = {
  paymentOrderData: undefined,
  beneficiariesData: undefined,
};

export type PaymentOrderDocument = {
  paymentOrderData?: PaymentOrderData;
  beneficiariesData?: BeneficiariesData;
};

export type PaymentOrderData = {
  orderType: string;
  deadline: string;
  processNumber: string;
  parentProcedureId: number;
  object: OptionType;
  court: OptionType;
  acknowledgmentDate: string;
};

export type BeneficiariesData = {
  applicantsToSend: ApplicantsToSend[];
};
