import { Grid } from '@mui/material';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { OptionType } from '../../../@types/config';
import { RadioGroup } from '../../../components/RadioGroup';
import { Button } from '../../../components/Button';
import { InputText } from '../../../components/InputText';
import { TextArea } from '../../../components/TextArea';
import Select from '../../../components/Select';
import NewProcessService from '../../../services/newProcessService';
import { SubjectUnifiedProcessCnjs } from '../../../@types/citationIntimation';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { QueryContext } from '../../../context/queryContext';

type NewCitationIntimationProps = {
  closeModal: () => void;
};

export function NewCitationIntimation(props: NewCitationIntimationProps) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue } = useForm();

  const { setModal, addToasts } = useContext(ProcedureActionsContext);
  const { loadData: loadDataQuery } = useContext(QueryContext);

  const citationIntimationType: OptionType[] = [
    {
      label: 'Citação',
      value: 0,
    },
    {
      label: 'Intimação',
      value: 1,
    },
  ];
  const [, setTypeCheckBox] = useState(
    citationIntimationType[0].value,
  );

  const handleCitationIntimationType = (value: any) => {
    setValue('citation_intimation_type', value);
    setTypeCheckBox(value);
  };

  const competenceType: OptionType[] = [
    {
      label: 'Cível',
      value: 0,
    },
    {
      label: 'Trabalhista',
      value: 1,
    },
    {
      label: 'Federal',
      value: 2,
    },
  ];

  const [, setCompetenceCheckBox] = useState(
    competenceType[0].value,
  );

  const handleCompetenceType = (value: any) => {
    setValue('competence_type', value);
    setCompetenceCheckBox(value);
  };

  const handleSubject = (value: any) => {
    setValue('subject', value);
    setTypeCheckBox(value);
  };

  const handleCreate = async (data: FieldValues) => {
    setLoading(true);
    await NewProcessService.createCitationIntimation(data)
      .then((res) => {
        if (!res.errors) {
          setModal(undefined);
          loadDataQuery();
          addToasts({
            type: 'success',
            text: t('procedureBox.newProcedure.toasts.successCitation'),
          });
        }
      });
    setModal(undefined);
  };

  const loadOptions = async () => {
    const data = await NewProcessService.getSubjectsCnjs();
    const subjectList = data.map((option: SubjectUnifiedProcessCnjs) => {
      return {
        label: `${option.code} - ${option.description}`,
        value: option.id,
      };
    });
    setOptions(subjectList);
  };

  useEffect(() => {
    setValue('competence_type', 0);
    setValue('citation_intimation_type', 0);
    loadOptions();
  }, []);

  return (
    <form onSubmit={handleSubmit(handleCreate)} className={styles.container}>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
        className={styles.head}
      >
        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name='citation_intimation_type'
            render={({ field }) => (
              <RadioGroup
                {...field}
                label={t('procedureBox.newProcedure.citationIntimation.form.type')}
                options={citationIntimationType}
                onChange={(e) => handleCitationIntimationType(e.target.value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.citationIntimation.form.processNumber.label')}
                placeholder={t('procedureBox.newProcedure.citationIntimation.form.processNumber.placeholder')}
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="subject"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.citationIntimation.form.subject')}
                placeholder='Selecione um assunto'
                onChange={(e) => handleSubject(e?.value)}
                className={styles.select}
                options={options}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name='competence_type'
            render={({ field }) => (
              <RadioGroup
                {...field}
                label={t('procedureBox.newProcedure.citationIntimation.form.competence')}
                options={competenceType}
                onChange={(e) => handleCompetenceType(e.target.value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="exercise"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.citationIntimation.form.exercise')}
                type="number"
                min="1900"
                max="2100"
                placeholder={'Digite um ano de exercício.'} />
            )} />
        </Grid>
        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="content"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('procedureBox.newProcedure.citationIntimation.form.content')}
                placeholder={'Digite aqui uma descrição'} />
            )} />
        </Grid>
        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="observation"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('procedureBox.newProcedure.citationIntimation.form.observation')}
                placeholder={'Digite aqui uma descrição'} />
            )} />
        </Grid>
        <Grid item xs={1} sm={6} md={4} className={styles.input}>
          <Button
            title={t('procedureBox.newProcedure.citationIntimation.form.submit')}
            buttonType='primary'
            size='flat'
            disabled={loading}
            round
          />
        </Grid>
      </Grid>
    </form>
  );
}
