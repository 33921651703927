import { useContext } from 'react';
import { t } from 'i18next';
import {
  ThemeProvider,
  Autocomplete,
  TextField,
  ClickAwayListener,
} from '@mui/material/';
import { OptionType } from '../../../../../@types/config';
import { searchMarkerTheme } from '../../../../../styles/customThemes/searchMarkerTheme';
import { QueryContext } from '../../../../../context/queryContext';
import { Marker } from '../../../../../@types/model';
import { TagRenderer } from './Tag';

type SearchMarkersProps = {
  data: any[];
  markers: OptionType[];
  setMarkers: (markers: OptionType[]) => void;
  setClose: (value: boolean) => void;
};

export function SearchMarkers(props: SearchMarkersProps) {
  const {
    filterMarkers, addMarker, removeMarker,
  } = useContext(QueryContext);

  const loadData = (data: Marker[]) => {
    const markersSelected: OptionType[] = data.map((option: Marker) => {
      const obj = { label: option.label, value: option.id };
      return obj;
    });
    return markersSelected;
  };

  const verifyReason = (reason: string, element: Marker) => {
    const obj = { label: element.label, value: element.id };

    if (reason === 'selectOption') {
      addMarker(element);
      props.setMarkers(props.markers.filter((el) => el.value !== obj.value));
    } else {
      removeMarker(element);
      props.setMarkers([...props.markers, obj]);
    }
  };

  const handleChange = (e: any, values: any, reason :string, details :any) => {
    e.preventDefault();
    const element = props.data.filter((el: Marker) => el.id === details?.option.value)[0];
    verifyReason(reason, element);
  };

  const getOptions = (equals: boolean) => {
    const difference = equals
      ? props.data.filter((marker) => filterMarkers.includes(marker))
      : props.data.filter((marker) => !filterMarkers.includes(marker));

    return loadData(difference).map((option) => option);
  };

  const listenerEventClose = (e: any) => {
    const item = e?.composedPath()[1];
    const ruleEventClickOutside = item.id === 'paperMarkers' || item.id === 'listboxMarkers';
    if (!ruleEventClickOutside) {
      props.setClose(false);
    }
  };

  return (
    <ThemeProvider theme={searchMarkerTheme}>
      <ClickAwayListener onClickAway={(e) => listenerEventClose(e)}>
        <Autocomplete
          multiple
          noOptionsText={t('general.noOptions')}
          options={getOptions(false)}
          popupIcon={false}
          onChange={handleChange}
          value={getOptions(true)}
          getOptionLabel={(option) => option.label}
          filterSelectedOptions
          renderTags={TagRenderer}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('procedureBox.markers.placeholder')}
            />
          )}
          componentsProps={{
            paper: {
              id: 'paperMarkers',
            },
          }}
          ListboxProps={{
            id: 'listboxMarkers',
          }}
        />
      </ClickAwayListener>
    </ThemeProvider>
  );
}
