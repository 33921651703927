import { useState, useEffect, useContext } from 'react';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../../services/procedureBoxService';
import Show from '../../../components/Show';
import { List } from './List';
import { BasicData } from './BasicData';
import { ConsultProcedure } from '../../../@types/pje';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Header } from './Header';

type PjeExtractProps = {
  procedureId: number;
};

export function PjeExtract(props: PjeExtractProps) {
  const [procedure, setProcedure] = useState<ConsultProcedure>();
  const { addToasts } = useContext(ProcedureActionsContext);
  const [loading, setLoading] = useState(true);

  const getShowProcedurePje = () => {
    ProcedureBoxService.getShowProcedurePje(props.procedureId)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setProcedure(res);
        } else {
          addToasts({
            type: 'error',
            text: res.message,
          });
        }
      }).catch(() => {
        addToasts({
          type: 'error',
          text: t('procedureBox.processExtract.pjeExtract.error.message'),
        });
      });
  };

  useEffect(() => {
    getShowProcedurePje();
  }, []);

  const getUpdatedProcedure = () => {
    setLoading(true);
    ProcedureBoxService.updateProcedurePje(props.procedureId).then((res) => {
      setProcedure(res);
      setLoading(false);
    });
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <span className={styles.text}>
            {t('procedureBox.processExtract.pjeExtract.consulting')}
          </span>
          <Skeleton variant={'rectangular'} className={ styles.row }/>
        </div>
      );
    }
    return (
      <Show if={procedure !== undefined}>
        <Header procedure={procedure} updatedProcedure={getUpdatedProcedure}/>
        <BasicData
          data={procedure}
          procedureId={props.procedureId}
        />
        <List data={procedure?.polo_active} active />
        <List data={procedure?.polo_passive} />
      </Show>
    );
  };

  return (
    <div className={ styles.container }>
      { renderContent() }
    </div>
  );
}
