import { t } from 'i18next';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MarkersList } from '../@types/markers';
import { Marker } from '../@types/model';
import { ProcedureMarkersType } from '../@types/procedureMarkers';
import MarkerService from '../services/markerService';
import { ProcedureActionsContext } from './procedureActionsContext';
import { priorityItems, pendencyItems } from '../helpers/markers';
import { QueryContext } from './queryContext';

const contextDefaultValues: ProcedureMarkersType = {
  markers: {
    priority_markers: [],
    pending_markers: [],
    private_markers: [],
    public_markers: [],
  },
  availableMarkers: {
    priority_markers: [],
    pending_markers: [],
    private_markers: [],
    public_markers: [],
  },
  markerId: 0,
  deleteMarker: (marker: Marker) => {},
  loadMarkers: () => {},
  setMarkerId: () => {},
};

export const ProcedureMarkersContext = createContext<ProcedureMarkersType>(
  contextDefaultValues,
);

const ProcedureMarkersProvider: FC = ({ children }) => {
  const { addToasts, setAlert } = useContext(ProcedureActionsContext);
  const { loadingListMarkers } = useContext(QueryContext);
  const [markers, setMarkers] = useState<MarkersList>(contextDefaultValues.markers);
  const [markerId, setMarkerId] = useState(contextDefaultValues.markerId);
  const [availableMarkers, setAvailableMarkers] = useState<MarkersList>(contextDefaultValues.availableMarkers);

  const loadMarkers = async () => {
    const markerList = await MarkerService.getMarkerList('inbox');
    setMarkers({
      priority_markers: markerList.priority_markers_procedures_count,
      pending_markers: markerList.pending_markers_procedures_count,
      private_markers: markerList.private_markers_procedures_count,
      public_markers: markerList.public_markers_procedures_count,
    });
    const availableMarkerList = await MarkerService.getMarkers();
    const publicMarkers = availableMarkerList.filter((item) => item.private_marker === false);
    const privateMarkers = availableMarkerList.filter((item) => item.private_marker === true);
    setAvailableMarkers({
      priority_markers: priorityItems,
      pending_markers: pendencyItems,
      private_markers: privateMarkers,
      public_markers: publicMarkers,
    });
  };

  useEffect(() => {
    loadMarkers();
  }, []);

  useEffect(() => {
    loadMarkers();
  }, [loadingListMarkers]);

  const handleDeleteMarker = async (marker: Marker) => {
    try {
      const response = await MarkerService.deleteMarker(marker.id);
      if (response.status === 200) {
        addToasts({
          type: 'success',
          text: t('procedureBox.modalMarker.delete.success', { marker: marker.label }),
        });
        loadMarkers();
      } else {
        addToasts({
          type: 'error',
          text: t('procedureBox.modalMarker.delete.error', { marker: marker.label }),
        });
      }
    } catch (error) {
      addToasts({
        type: 'error',
        text: t('procedureBox.modalMarker.delete.error', { marker: marker.label }),
      });
    }
  };

  const deleteMarker = (marker: Marker) => {
    setAlert({
      visible: true,
      title: t('procedureBox.modalMarker.delete.title'),
      text: t('procedureBox.modalMarker.delete.subtitle', { marker: marker.label }),
      type: 'warning',
      confirmText: t('procedureBox.modalMarker.delete.confirm'),
      confirmType: 'danger',
      handleConfirm: () => {
        setAlert(undefined);
        handleDeleteMarker(marker);
      },
    });
  };

  return (
      <ProcedureMarkersContext.Provider
        value={{
          markers,
          availableMarkers,
          loadMarkers,
          markerId,
          setMarkerId,
          deleteMarker,
        }}>
          {children}
      </ProcedureMarkersContext.Provider>
  );
};

export default ProcedureMarkersProvider;
