import { Individual } from '../../../../@types/model';
import styles from './styles.module.scss';

type ItemProps = {
  individual: Individual;
  selected: boolean;
  onClick: () => void;
  dataCy?: string;
};

export function Item(props: ItemProps) {
  return (
    <a
      className={ `${styles.container} ${props.selected ? styles.selected : ''}` }
      onClick={props.onClick}
      data-cy={props.dataCy}
    >
      <span className={styles.name}>{props.individual.individual_name} ({props.individual.job_title_name})</span>
      <span className={styles.field}>{props.individual.field_name}</span>
      <span className={styles.institution}>{props.individual.institution_name}</span>
    </a>
  );
}
