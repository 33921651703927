/* eslint-disable func-names */
import { useState } from 'react';
import { t } from 'i18next';
import {
  FormControl,
  FormControlLabel, FormLabel, Grid, Radio, RadioGroup,
} from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../../../../Button';
import { InputText } from '../../../../../InputText';
import Select from '../../../../../Select';

import styles from './styles.module.scss';
import ProcedureBoxService from '../../../../../../services/procedureBoxService';
import { isCnpj, isCpf } from '../../../../../../helpers/validator';
import AddressService from '../../../../../../services/addressService';

type ContentProps = {
  setOpen: (open: boolean) => void;
  participations: any;
  setApplicantsList: (value: any) => void;
  subProcedureId: number;
};

export function Content(props: ContentProps) {
  const [participationsId, setParticipationsId]: any = useState({});
  const [status, setStatus] = useState<'physicalPerson' | 'legalPerson'>('physicalPerson');
  const [gender, setGender] = useState<'male' | 'female'>('female');

  const schema = Yup.object().shape({
    name: Yup.string().required(t('general.requeried')),
    cpf: Yup.string().test('cpf-required', t('general.requeried'), function (value) {
      const { path, createError } = this;

      if (status === 'physicalPerson' && !value) {
        return createError({ path, message: t('general.requeried') });
      }
      if (value !== undefined && !isCpf(value)) {
        return createError({ path, message: t('general.cpf') });
      }
      return true;
    }),
    cnpj: Yup.string().test('cnpj-required', t('general.requeried'), function (value) {
      const { path, createError } = this;

      if (status === 'legalPerson' && !value) {
        return createError({ path, message: t('general.requeried') });
      }
      if (value !== undefined && !isCnpj(value)) {
        return createError({ path, message: t('general.cnpj') });
      }
      return true;
    }),
    cep: Yup.string().min(8, t('general.cep')).nullable(),
  });

  const {
    handleSubmit, formState: { errors }, control, setValue, setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadApplicants = () => {
    ProcedureBoxService.getDependenciesPaymentOrders(props.subProcedureId).then((dep) => {
      props.setApplicantsList(dep.judicial_parts);
    });
  };

  const submit = (form: any) => {
    const data: any = {
      participation_id: participationsId.value,
      ...form,
      legalPerson: status,
      gender: status === 'physicalPerson' ? gender : undefined,
    };

    ProcedureBoxService.createInterestedPartOrder(props.subProcedureId, data)
      .then((res) => {
        loadApplicants();
      })
      .catch((err) => {
      });
    props.setOpen(false);
  };

  const handleCepChange = async (event: any) => {
    const cepValue = event.target.value.replace(/\D/g, '');
    setValue('cep', cepValue);
    if (cepValue.length === 8) {
      try {
        const {
          logradouro, complemento, bairro, localidade,
        } = await AddressService.getAddressFromCep(cepValue);
        setValue('address', logradouro);
        setValue('complement', complemento);
        setValue('neighborhood', bairro);
        setValue('city', localidade);
      } catch (error) {
        setError('cep', { type: 'custom', message: t('general.cepError') });
      }
    }
  };

  return (
    <form className={styles.container}>
    <Grid
      className={styles.content}
      container
      columns={{ xs: 1, sm: 10, md: 15 }}
      gap={5}
    >
      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <Select
          className={styles.selectStyles}
          onChange={(part: any) => setParticipationsId(part)}
          label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.part')}
          options={props.participations}
          required
        />
      </Grid>

      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <FormControl className={styles.selectsContainer}>
          <FormLabel className={styles.label} id="demo-radio-buttons-group-label">
            {t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.type.text')}
          </FormLabel>
          <RadioGroup
            aria-labelledby='radioGroup'
            name='controlledRadioGroup'
            className={styles.radioGroup}
            row
          >
            <FormControlLabel
              value={true}
              onChange={() => setStatus('physicalPerson')}
              checked={status === 'physicalPerson'}
              control={<Radio size='small' color={'primary'}/>}
              className={styles.option}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.type.physicalPerson').toString()}
            />

            <FormControlLabel
              value={false}
              onChange={() => setStatus('legalPerson')}
              checked={status === 'legalPerson'}
              control={<Radio size='small' color={'primary'} />}
              className={styles.option}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.type.legalPerson').toString()}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {
        status === 'physicalPerson' ? (
          <>
             <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
            >
              <Controller
                control={control}
                name="cpf"
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.CPF')}
                    mask={'999.999.999-99'}
                    className={styles.inputText}
                    required
                  />
                )}
              />
              <p className={styles.error}>{errors.cpf?.message}</p>
            </Grid>

            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
            >
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.name')}
                    className={styles.inputText}
                    required
                  />
                )}
              />
              <p className={styles.error}>{errors.name?.message}</p>
            </Grid>

            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
            >
              <Controller
                control={control}
                name="rg"
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.RG')}
                    className={styles.inputText}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
            >
              <Controller
                control={control}
                name="issuing_authority"
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.issuingAgency')}
                    className={styles.inputText}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
            >
              <FormControl className={styles.selectsContainer}>
                <FormLabel className={styles.label} id="demo-radio-buttons-group-label">
                  {t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.gender')}
                </FormLabel>
                  <RadioGroup
                    aria-labelledby='radioGroup'
                    name='controlledRadioGroup'
                    className={styles.radioGroup}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      onChange={() => setGender('female')}
                      className={styles.option}
                      checked={gender === 'female'}
                      control={<Radio size='small' color='primary' />}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.sex.female').toString()}
                    />

                    <FormControlLabel
                      value={false}
                      onChange={() => setGender('male')}
                      className={styles.option}
                      checked={gender === 'male'}
                      control={<Radio size='small' color='primary' />}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.sex.male').toString()}
                    />
                  </RadioGroup>
              </FormControl>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
            >
              <Controller
                control={control}
                name="cnpj"
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.CNPJ')}
                    mask={'99.999.999/9999-99'}
                    className={styles.inputText}
                    required
                  />
                )}
              />
              <p className={styles.error}>{errors.cnpj?.message}</p>
            </Grid>

            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
            >
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.corporateName')}
                    className={styles.inputText}
                    type='text'
                    required
                  />
                )}
              />
              <p className={styles.error}>{errors.name?.message}</p>
            </Grid>
          </>
        )
      }

      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <Controller
          control={control}
          name="cep"
          render={({ field }) => (
            <InputText
              {...field}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.CEP')}
              mask={'99999-999'}
              className={styles.inputText}
              onChange={handleCepChange}
            />
          )}
        />
        <p className={styles.error}>{errors.cep?.message}</p>
      </Grid>

      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <Controller
          control={control}
          name="address"
          render={({ field }) => (
            <InputText
              {...field}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.publicPlace')}
              className={styles.inputText}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <Controller
          control={control}
          name="address_number"
          render={({ field }) => (
            <InputText
              {...field}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.number')}
              className={styles.inputText}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <Controller
          control={control}
          name="complement"
          render={({ field }) => (
            <InputText
              {...field}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.complement')}
              className={styles.inputText}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <Controller
          control={control}
          name="neighborhood"
          render={({ field }) => (
            <InputText
              {...field}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.neighborhood')}
              className={styles.inputText}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={ 1 }
        sm={ 5 }
        md={ 4 }
      >
        <Controller
          control={control}
          name="city"
          render={({ field }) => (
            <InputText
              {...field}
              label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.county')}
              className={styles.inputText}
            />
          )}
        />
      </Grid>
    </Grid>
    <footer>
      <Button
        className={styles.buttonLeft}
        title={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.buttons.cancel')}
        buttonType='default'
        onClick={() => props.setOpen(false)}
        size='flat'
        round
      />

      <Button
        title={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.buttons.addPart')}
        buttonType='primary'
        type='submit'
        disabled={participationsId.value === undefined}
        onClick={handleSubmit(submit)}
        size='flat'
        round
      />
    </footer>
  </form>
  );
}
