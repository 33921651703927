import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Timeline as TimelineMui } from '@mui/lab';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { ProcedureCarousel } from '../../ForwardIpm/ProcedureCarousel';
import { Label } from '../../Label';
import { Item } from './Item';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { Note } from '../../../@types/model';

export function Timeline() {
  const { proceduresSeleted } = useContext(ProcedureActionsContext);
  const [procedure, setProcedure] = useState(proceduresSeleted[0].id);
  const [history, setHistory] = useState<Note[]>([]);

  useEffect(() => {
    ProcedureBoxService.getTimelineNote(procedure).then((res) => { setHistory(res); });
  }, [procedure]);

  return (
    <div className={ styles.container }>
      <Label text={t('procedureBox.actions.notes.modal.timeline')} />
      <ProcedureCarousel procedures={proceduresSeleted} isMobile={false} onClick={(e) => { setProcedure(e); }} />
      <div className={ styles.timeline }>
      <TimelineMui position="right">
        {history && history.map((note) => {
          return <Item key={note.id} note={note} procedure_id={procedure} />;
        })}
      </TimelineMui>
      </div>
    </div>
  );
}
