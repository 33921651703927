import { useContext, useEffect, useState } from 'react';
import {
  FaChevronUp, FaChevronDown,
} from 'react-icons/fa';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import styles from './styles.module.scss';
import { DocumentList } from '../../DocumentList';
import { AttachedDocument, Description } from '../../../@types/config';
import { Button } from '../../Button';
import { Batch, MultipleActionStep } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { ProcedureCarousel } from '../ProcedureCarousel';
import Show from '../../Show';

interface ViewProps {
  isMobile: boolean;
  multipleActions?: MultipleActionStep;
}

export function View(props: ViewProps) {
  const {
    descriptions, proceduresSeleted, setIpmStateSelected,
    setAlert, archives, setModal,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [documentsSelected, setDocumentsSelected] = useState<AttachedDocument[]>(archives);
  const [openDescription, setOpenDescription] = useState(true);
  const [openAttach, setOpenAttach] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [description, setDescription] = useState<Description>();
  const [documents, setDocuments] = useState<AttachedDocument[]>([]);
  const isIndividual = proceduresSeleted.length === 1;

  let dispatch;

  switch (description?.typeDispatch) {
    case '0':
      dispatch = t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.deferment');
      break;
    case '1':
      dispatch = t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.deferralReservation');
      break;
    case '2':
      dispatch = t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.rejection');
      break;
    case '3':
      dispatch = t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.diligence');
      break;
    default:
      break;
  }

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return t('procedureBox.actions.forwardIPM.modal.alert.procedures.title', { number: proceduresSeleted.length });
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.forwardIPM.modal.alert.procedure.title'
        components={{ a: <a /> }}
        values={{ procedureNumber: proceduresSeleted[0].process_number }}
      />
    );
  };

  const renderSubtitle = () => {
    if (proceduresSeleted.length > 1) {
      return t('procedureBox.actions.forwardIPM.modal.alert.procedures.subtitle', { number: proceduresSeleted.length });
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.forwardIPM.modal.alert.procedure.subtitle'
        components={{ a: <a /> }}
        values={{ procedureNumber: proceduresSeleted[0].process_number }}
      />
    );
  };

  const fowardToIpm = () => {
    const procedures = proceduresSeleted;
    const type = 'forwardIPM';

    const data = proceduresSeleted.map((procedure) => {
      const getDescription = descriptions.find((desc) => desc.procedureId === procedure.id);
      const docs = documentsSelected.filter((doc) => doc.procedure_id === procedure.id);
      const parseDocuments = docs.map((archive: AttachedDocument) => {
        const obj = { file_class: archive.file_class, file_id: archive.file_id };
        return obj;
      });

      const obj = { procedure_id: procedure.id, description: getDescription, archives: parseDocuments };

      return obj;
    });

    const body = {
      data,
      in_bulk: procedures.length > 1,
    };

    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };

    addBatch(batch);
    setAlert(undefined);
    setModal(undefined);
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => fowardToIpm(),
      title: renderTitle(),
      text: renderSubtitle(),
      confirmText: t('general.yes'),
      confirmType: 'primary',
    });
  };

  useEffect(() => {
    if (documentsSelected.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [documentsSelected]);

  useEffect(() => {
    if (isIndividual) {
      setDescription(descriptions[0]);
    }
  }, []);

  const showDescription = (procedureIdParam: number) => {
    const descriptionSelected = descriptions.find((desc) => desc.procedureId === procedureIdParam);
    setDocuments(archives.filter((doc) => doc.procedure_id === procedureIdParam));
    setDescription(descriptionSelected);
  };

  useEffect(() => {
    const { procedureId } = descriptions[0];
    if (procedureId) {
      showDescription(procedureId);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Show if={!isIndividual}>
        <ProcedureCarousel
          procedures={proceduresSeleted}
          onClick={(value) => showDescription(value)}
          isMobile={false}/>
      </Show>
      <div className={styles.info}>
        <div className={styles.title}>
          <h3>{t('procedureBox.actions.ipm.forwardIPM.modal.description.title')}</h3>

          <div onClick={() => setOpenDescription(!openDescription)}>
            {openDescription ? <FaChevronUp className={styles.icon} /> : <FaChevronDown className={styles.icon} />}
          </div>
        </div>
        { description
          && <Grid
            direction={'column'}
            spacing={5}
            className={`${openDescription ? styles.expanded : styles.closed}`}
          >
            <div className={styles.item}>
              <span>{t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.title')}</span>
              <p>{dispatch}</p>
            </div>

            <div className={styles.item}>
              <span>{t('procedureBox.actions.ipm.forwardIPM.modal.description.legalAnalysis.title')}</span>
              <p>{description?.legalAnalysis}</p>
            </div>

            <div className={styles.item}>
              <span>{t('procedureBox.actions.ipm.forwardIPM.modal.description.report.title')}</span>
              <p>{description?.report}</p>
            </div>

            <div className={styles.item}>
              <span>{t('procedureBox.actions.ipm.forwardIPM.modal.description.conclusion.title')}</span>
              <p>{description?.conclusion}</p>
            </div>
          </Grid>
        }
      </div>

      <div className={styles.info}>
        <div className={styles.title}>
          <h3>{t('procedureBox.actions.ipm.forwardIPM.modal.preview.attachments.title')}</h3>

          <div onClick={() => setOpenAttach(!openAttach)}>
            {openAttach ? <FaChevronUp className={styles.icon} /> : <FaChevronDown className={styles.icon} />}
          </div>
        </div>
        <Grid
          direction={'column'}
          spacing={5}
          className={`${openAttach ? styles.expanded : styles.closed} ${styles.attach}`}
        >
          <span>{t('procedureBox.actions.ipm.forwardIPM.modal.preview.attachments.label')}</span>
          <DocumentList
            documents={documentsSelected}
            isMobile={props.isMobile}
            onChange={(value: AttachedDocument[]) => setDocumentsSelected(value)}
            documentsList={documents}
          />
        </Grid>

        <div className={styles.footer}>
          <Button
            disabled={disabled}
            title={t('procedureBox.actions.ipm.forwardIPM.modal.button.forwardIpm')}
            buttonType='default'
            size='large'
            round
            className={styles.right}
            onClick={showAlert}
          />

         <Button
            disabled={false}
            title={t('procedureBox.actions.ipm.forwardIPM.modal.button.previous')}
            buttonType='primary'
            size='large'
            round
            onClick={() => setIpmStateSelected('description')}
          />
        </div>
      </div>
    </div>
  );
}
