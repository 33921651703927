import { Grid } from '@mui/material';
import { t } from 'i18next';
import html2canvas from 'html2canvas';
import { jsPDF as JSPdf } from 'jspdf';
import { useContext, useEffect, useState } from 'react';
import { FaDollarSign, FaDownload, FaRegQuestionCircle } from 'react-icons/fa';
import { DebtCollection } from '../../../components/DebtCollection';
import { DebtNegotiations } from '../../../components/DebtNegotiations';
import { DebtProgress } from '../../../components/DebtProgress';
import { DebtPrescriptions } from '../../../components/DebtPrescriptions';
import { SearchDashboard } from '../../../components/SearchDashboard';
import { SelectFilter, OptionType } from '../../../components/SelectFilter';
import { DashboardContext } from '../../../context/dashboardContext';
import DashboardService from '../../../services/dashboardService';
import { getLastUpdateDate } from '../../../helpers/getLastUpdateDate';
import styles from './styles.module.scss';
import { Tooltip } from '../../../components/Tooltip';
import Show from '../../../components/Show';
import { Unauthorized } from '../../../components/Unauthorized';

export function DebtDashboard() {
  const {
    addReceiptType, unauthorizedDebt, debts, loading,
    negotiations, negotiationProgress, prescriptionsData,
  } = useContext(DashboardContext);
  const [receiptTypes, setReceiptTypes] = useState<OptionType[]>([]);
  const [lastUpdated, setLastUpdated] = useState('');
  const loadReceiptTypes = async () => {
    const receiptTypeList = await DashboardService.getReceiptTypes();
    const list = receiptTypeList.map((itemSelected: any) => {
      return {
        value: itemSelected.id,
        label: itemSelected.description,
      };
    });
    setReceiptTypes(list);
  };

  const createPDF = async () => {
    document.getElementById('btnPrescriptionChart')!.click();
    setTimeout(() => {
      const htmlSource = document.getElementById('activeDebtCharts');
      document.getElementById('btnExportActiveDebt')!.style.visibility = 'hidden';
      const filename = 'activeDebtReport';

      if (!htmlSource) {
        return;
      }

      html2canvas(htmlSource, {
        windowWidth: 1900,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        const pdf = new JSPdf('p', 'mm');

        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save(filename);
        document.getElementById('btnPrescriptionChart')!.click();
        document.getElementById('btnExportActiveDebt')!.style.visibility = 'visible';
      });
    }, 500);
  };

  const loadDate = async () => {
    const response = await DashboardService.getLastUpdatedInDashboard();

    if (response.active_debt != null) {
      setLastUpdated(t('home.dashboard.lastUpdated', {
        date: getLastUpdateDate(response.active_debt),
        interpolation: { escapeValue: false },
      }));
    }
  };

  useEffect(() => {
    loadReceiptTypes();
    loadDate();
  }, []);

  return (
    <div className={ styles.container }>
      <Grid
          container
          columns={{ xs: 1, sm: 1, md: 1 }}
          spacing={2}
      >
        <Grid item
          xs={1}
          md={1}
        >
        <div className={ `${unauthorizedDebt ? styles.content : ''}` }>
          <div className={ styles.topHeader }>
            <div className={ styles.dashboardContent} >
                <div className={ styles.dashboardTitle }>
                  <span className={ styles.dashboard }>{t('home.dashboard.title')}:</span>
                  <Tooltip
                    title={ t('home.dashboard.debt.tooltip') }
                  >
                    <FaRegQuestionCircle />
                  </Tooltip>
                </div>
                <span className={ styles.lastUpdated }>{ lastUpdated }</span>
              </div>
            <a href='/debt_box'>{t('home.dashboard.debt.goToBox')}</a>
          </div>
          <div id='activeDebtCharts'>
            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 4 }}
              className={ styles.title }
              spacing={2}
            >
              <Grid item
                xs={1}
                sm={1}
                md={2}
              >
                <FaDollarSign />
                <span>{t('home.dashboard.debt.title')}</span>
              </Grid>
              <Grid item
                xs={1}
                sm={1}
                md={2}
              >
                <SearchDashboard options={[]}/>
              </Grid>
              <Grid item
                xs={1}
                sm={1}
                md={3}
              >
                <SelectFilter
                  options={receiptTypes}
                  placeholder={t('home.dashboard.receiptTypeFilter.placeholder')}
                  handleSelectedItems={(item) => addReceiptType(item)}
                />
              </Grid>
              <Grid item
                xs={1}
                sm={1}
                md={1}
              >
                <Show if={
                      (!loading || debts == null || debts.length === 0)
                      && negotiations !== undefined
                      && negotiationProgress.length > 0
                      && prescriptionsData !== undefined
                    }>
                    <div id='btnExportActiveDebt' className={ styles.button } onClick={createPDF}>
                      <span>{t('home.dashboard.actions.export')}</span>
                      <FaDownload />
                    </div>
                  </Show>
              </Grid>
              <Grid item
                xs={1}
                sm={1}
                md={4}
              >
                <DebtCollection />
                <DebtPrescriptions />
                <DebtNegotiations />
                <DebtProgress />
              </Grid>
            </Grid>
          </div>
        </div>
        </Grid>
      </Grid>
      <Show if={unauthorizedDebt}>
        <Unauthorized title={t('home.dashboard.debt.unauthorized')} />
      </Show>
    </div>
  );
}
