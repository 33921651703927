import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { ToastsList } from '../../../components/ToastsList';
import styles from './styles.module.scss';
import { Breadcumb } from '../../../components/Breadcumb';
import { Search } from './Search';
import { Instructions } from './Instructions';
import { SHOW_AGILIS_DEBITBOX } from '../../../config/constants';
import { IFrameView } from './IFrameView';

export function DebtBox() {
  const { pathname } = useLocation();

  if (!SHOW_AGILIS_DEBITBOX) {
    return (
      <IFrameView />
    );
  }

  return (
    <div className={styles.container}>
      <ToastsList />
      <Grid
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12} className={ styles.title }>
          <Grid item xs={3} sm={6}>
            <h1>{ t('debtBox.title') }</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>

        <Grid item xs={6} className={ styles.instructions }>
          <Instructions />
        </Grid>

        <Grid item xs={6}>
          <Search />
        </Grid>
      </Grid>
    </div>
  );
}
