import {
  createContext,
  FC,
  useState,
} from 'react';
import { ProcedureDetailsType } from '../@types/procedureDetails';
import { Procedure } from '../@types/model';
import { TimelineDay } from '../@types/digitalProcesses/timeline';
import ProcedureBoxService from '../services/procedureBoxService';
import { verifyUrlProcedureDetails } from '../helpers/procedureDetails';

const contextDefaultValues: ProcedureDetailsType = {
  procedure: {} as Procedure,
  setProcedure: () => {},
  timeline: [],
  setTimeline: () => {},
  loadTimeline: () => {},
  handleLoadTimeline: () => {},
};

export const ProcedureDetailsContext = createContext<ProcedureDetailsType>(
  contextDefaultValues,
);

const ProcedureDetailsProvider: FC = ({ children }) => {
  const [procedure, setProcedure] = useState<Procedure>({} as Procedure);
  const [timeline, setTimeline] = useState<TimelineDay[]>([]);

  const loadTimeline = (procedureId?: number) => {
    if (procedureId || procedure?.id) {
      ProcedureBoxService.getTimeline((procedureId || procedure?.id) as number)
        .then((res) => {
          const { result } = res;
          setTimeline(result);
        });
    }
  };

  const handleLoadTimeline = () => {
    const id = verifyUrlProcedureDetails();
    if (id) {
      loadTimeline(id as unknown as number);
    }
  };

  return (
      <ProcedureDetailsContext.Provider
        value={{
          procedure,
          setProcedure,
          timeline,
          setTimeline,
          loadTimeline,
          handleLoadTimeline,
        }}>
          {children}
      </ProcedureDetailsContext.Provider>
  );
};

export default ProcedureDetailsProvider;
