import { useState } from 'react';
import { Procedure } from '../../../@types/model';
import { MarkerContent } from '../../../components/MarkerContent';
import { Selector } from '../../../components/ProcedureList/Markers/Selector';
import i18n from '../../../config/i18n';
import styles from './styles.module.scss';

type TitleProps = {
  procedure: Procedure;
  setLoading: (value: boolean) => void;
};

export function Title(props: TitleProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
      <div className={ styles.container }>
        <div className={ styles.rigthInfos }>
          <h1 className={styles.title}>
            {i18n.t('procedureBox.processExtract.processNumber', { fluxName: props.procedure.flux.name, number: props.procedure.process_number })}
          </h1>
          <div className={styles.tagMarkers}>
          { props.procedure.markers.map((marker: any) => <Selector
                                            key={marker.id}
                                            marker={ marker }
                                            procedureId={ props.procedure.id }
                                            fixedValue={ false }
                                          />)}
                                          </div>
          <div className={styles.markers}>
            <div className={styles.marker}>
              <MarkerContent
                isOpen={isOpen}
                procedureId={props.procedure.id}
                handleClose={() => setIsOpen(!true)}
              />
            </div>
          </div>
        </div>
      </div>
  );
}
