import Show from '../../../../components/Show';
import styles from './styles.module.scss';

type TabProps = {
  name: string | String[];
  selected?: boolean;
  icon?: any;
  notification?: boolean;
  counter?: number;
  onClick: () => void;
};

export function Tab(props: TabProps) {
  return (
    <a className={ `${styles.container} ${props.selected ? styles.selected : ''}` } onClick={props.onClick}>
      <Show if={props?.counter !== undefined && props?.counter > 0}>
        <div className={styles.counter}>{props.counter}</div>
      </Show>
      <span>{props.name}</span>
      <div className={styles.notification}>
        {props.icon}
        {props.notification && <span className={styles.badge}></span>}
      </div>
    </a>
  );
}
