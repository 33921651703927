import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type OverviewProtestProps = {
  title?: string;
};

export function OverviewProtest(props: OverviewProtestProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/dashboards/visao_gerencial_protesto`}/>
  );
}
