import { t } from 'i18next';
import { useContext } from 'react';
import { QueryContext } from '../../../context/queryContext';
import Format from '../../../helpers/format';
import { Item } from './Item';
import styles from './styles.module.scss';

export function SearchResult() {
  const {
    filterMarkers, filterGenericMarkers, removeAllMarkers, totalProcedures, totalProcedureValues,
  } = useContext(QueryContext);

  const renderMarkers = () => {
    if (filterMarkers.length > 0 || filterGenericMarkers.length > 0) {
      return (
        <div>
          <div className={ styles.markers }>
            { filterMarkers.map((marker) => <Item {...marker}/>)}
            { filterGenericMarkers.map((marker) => <Item {...marker}/>)}
            <a onClick={ removeAllMarkers } className={ styles.removeFilter }>{ t('procedureBox.markersSelect.clean') }</a>
          </div>

          <div className={ styles.totals }>
            <div className={ styles.total }>
              <span className={ styles.label }>{ t('procedureBox.markersSelect.count') }: </span>
              <span>{totalProcedures}</span>
            </div>

            <div className={ styles.total }>
              <span className={ styles.label }>{ t('procedureBox.markersSelect.total') }: </span>
              <span>{Format.currency(totalProcedureValues)}</span>
            </div>
          </div>
        </div>
      );
    }
    return false;
  };

  return (
    <div className={ styles.container }>
      { renderMarkers() }
    </div>
  );
}
