import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { Button } from '../../../components/Button';
import { InputText } from '../../../components/InputText';
import styles from './styles.module.scss';
import AuthService from '../../../services/authService';
import { ErrorField } from '../../../components/ErrorField';

type ForgotPasswordProps = {
  onSubmit: () => void;
};

export function ForgotPassword(props: ForgotPasswordProps) {
  const [error, setError] = useState('');
  const { control, handleSubmit } = useForm();

  const submit = (form: any) => {
    setError('');
    if (form.username) {
      AuthService.setEmailToRecoverPassword(form.username)
        .then(() => props.onSubmit())
        .catch(() => {
          setError(t('general.login.forgotPasswordModal.error'));
        });
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('general.login.forgotPassword')}</h3>
      <p className={styles.instruction}>{t('general.login.forgotPasswordModal.emailInstructions')}</p>
      <form onSubmit={handleSubmit(submit)}>
        <Grid
          container
          columns={{ xs: 1, sm: 8, md: 8 }}
        >
          <Grid
            item
            xs={1}
            sm={4}
          >
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('general.login.forgotPasswordModal.email')}
                  placeholder={t('general.login.forgotPasswordModal.emailPlaceholder')}
                />
              )}
            />
            {error.length > 0 && <ErrorField text={error}/>}
          </Grid>
        </Grid>
        <div className={styles.footer}>
          <Button
            title={t('general.login.forgotPasswordModal.submit')}
            size='flat'
            round
            buttonType='primary'
            disabled={false}
            type='submit'
          />
        </div>
      </form>
    </div>
  );
}
