import { SelectedProcedures } from '../SelectedProcedures';
import { Form } from './Form';

type DistributeProcedureProps = {
  closeModal: () => void;
  distributeForm?: any;
};

export function DistributeProcesses(props: DistributeProcedureProps) {
  return (
    <div>
      <SelectedProcedures />
      <Form />
    </div>
  );
}
