import { Modal as ModalMui } from '@mui/material';
import { t } from 'i18next';
import { useContext } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { FaTimes } from 'react-icons/fa';
import styles from './styles.module.scss';
import { HomeContext } from '../../../context/homeContext';
import { Button } from '../../Button';

const ERROR_ANIMATION = require('../../../assets/lotties/error.json');
const WARNING_ANIMATION = require('../../../assets/lotties/warning.json');

export function Alert() {
  const { alert, setAlert } = useContext(HomeContext);
  if (alert) {
    return (
      <ModalMui
        open={alert.visible}
        onClose={() => setAlert()}
        disableAutoFocus={true}
        onBackdropClick={() => setAlert()}
        >
        <div className={styles.box}>
          <div className={styles.body}>
            <a className={styles.closeButton} onClick={() => setAlert()}>
              <FaTimes />
            </a>
            <div className={styles.icon}>
              <Player
                autoplay
                keepLastFrame={true}
                src={alert.type === 'error' ? ERROR_ANIMATION : WARNING_ANIMATION}
                speed={2}
                className={styles.lottie}
              >
                <Controls visible={false} />
              </Player>
            </div>
            <span className={styles.title}>{alert.title}</span>
            <span className={styles.text}>{alert.text}</span>

            { alert.handleConfirm
              ? <div className={styles.actions}>
                <Button
                  buttonType={alert.cancelType || 'default'}
                  round
                  size='flat'
                  title={alert.cancelText || t('general.no')}
                  onClick={() => setAlert()}
                />
                <Button
                  buttonType={alert.confirmType || 'primary'}
                  round
                  size='flat'
                  title={alert.confirmText || t('general.yes')}
                  onClick={alert.handleConfirm}
                />
              </div>
              : null
            }
          </div>
        </div>
      </ModalMui>
    );
  }
  return <></>;
}
