import { useContext, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { t } from 'i18next';
import { Marker, Procedure } from '../../../../../@types/model';
import { QueryContext } from '../../../../../context/queryContext';
import { Button } from '../../../../Button';
import styles from './styles.module.scss';
import { MarkerOption } from './MarkerOption';
import { CustomMarkerOption } from './CustomMarkerOption';
import { GenericMarker } from '../../../../../@types/markers';
import { ProcedureMarkersContext } from '../../../../../context/procedureMarkersContext';
import { ProcedureActionsContext } from '../../../../../context/procedureActionsContext';
import { isMarker } from '../../../../../helpers/markers';

type MarkerItemProps = {
  onChange: () => void;
  type: 'priority' | 'pendency' | 'custom';
  options?: Marker[] | GenericMarker[];
  procedureId: number;
  procedures?: Procedure[];
  open?: boolean;
};

export function MarkerItem(props: MarkerItemProps) {
  const { changePendencyMarker, changePriorityMarker } = useContext(QueryContext);
  const { markers } = useContext(ProcedureMarkersContext);
  const { openMarkerModal } = useContext(ProcedureActionsContext);
  const [isOpen, setIsOpen] = useState(props.open);
  let options: GenericMarker[] | Marker[];
  let title = '';

  switch (props.type) {
    case 'priority':
      options = markers.priority_markers;
      title = t('procedureBox.markers.priority');
      break;
    case 'pendency':
      options = markers.pending_markers;
      title = t('procedureBox.markers.pendency');
      break;
    case 'custom':
      options = [...markers.private_markers, ...markers.public_markers];
      title = t('procedureBox.markers.custom');
      break;
    default:
      break;
  }

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleChange = (marker: GenericMarker | Marker) => {
    if (!isMarker(marker)) {
      if (marker.marker_pending) {
        changePendencyMarker(props.procedureId, marker.marker_pending, props.procedures);
      } else {
        changePriorityMarker(props.procedureId, marker.marker_priority, props.procedures);
      }
    }
    props.onChange();
  };

  const renderOptions = () => {
    if (props.type === 'custom') {
      return (
        <div className={ styles.customMarkers }>
          <Button onClick={() => openMarkerModal('create')} title={t('procedureBox.markers.newMarker')} icon={ <FaPlus /> }/>
          <CustomMarkerOption
            onChange={() => props.onChange()}
            procedureId={props.procedureId}
            procedures={props.procedures}
            type='public'
            open={props.open}
          />
          <CustomMarkerOption
            onChange={() => props.onChange()}
            procedureId={props.procedureId}
            procedures={props.procedures}
            type='private'
            open={props.open}
          />
        </div>
      );
    }
    return options.map((option) => {
      return (
        <MarkerOption
          onChange={(marker: GenericMarker | Marker) => handleChange(marker)}
          marker={ option }
          procedureId={ props.procedureId }
        />
      );
    });
  };

  return (
    <div className={ styles.container }>
      <div className={ styles.title } onClick={ (e) => handleOnClick(e) }>
        { isOpen ? <FaChevronUp/> : <FaChevronDown/> }
        <span>{ title }</span>
      </div>

      { isOpen ? renderOptions() : null }
    </div>
  );
}
