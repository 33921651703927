import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import styles from './styles.module.scss';
import { SelectedProcedures } from '../../SelectedProcedures';
import { DocumentList } from '../../DocumentList';
import { Table } from './Table';
import { Button } from '../../Button';
import { AttachedDocument, InitialPetition } from '../../../@types/config';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { ProcedureCarousel } from '../../ForwardIpm/ProcedureCarousel';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import Screen from '../../../helpers/screen';
import Format from '../../../helpers/format';
import { Batch, MultipleActionStep } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Login } from '../Login';
import { CurrentUser } from '../../../@types/currentUser';

type PetitionInitialProps = {
  values: any;
  multipleActions?: MultipleActionStep;
};

export function PetitionInitial(props: PetitionInitialProps) {
  const { proceduresSeleted, removeProcedures, setModal } = useContext(ProcedureActionsContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [data, setData] = useState<InitialPetition>();
  const [procedureId, setProcedureId] = useState(proceduresSeleted[0]?.id);
  const [documentsList, setDocumentsList]: any = useState([]);
  const [documentsSeleted, setDocumentsSeleted] = useState<AttachedDocument[]>([]);

  const isMobile = Screen.isMobile();

  const getInitialPetition = async () => {
    const response: InitialPetition = await ProcedureBoxService.getInitialPetitionData(procedureId);

    setData(response);
    const documents = response.documents_signed.map((document: any) => {
      return (
        {
          ...document,
          procedure_id: procedureId,
          disabled: document.type === t('procedureBox.processExtract.pjeExtract.petition'),
          type: document.type,
        }
      );
    });

    setDocumentsList(documents);
  };

  const getAllDocuments = () => {
    const allDocuments: any[] = [];
    for (let index = 0; index < proceduresSeleted.length; index += 1) {
      ProcedureBoxService.getInitialPetitionData(proceduresSeleted[index].id)
        .then((res) => {
          const docs = res.documents_signed.map((document: any) => {
            return { ...document, procedure_id: proceduresSeleted[index].id };
          });

          for (let i = 0; i < docs.length; i += 1) {
            allDocuments.push(docs[i]);
          }
        });
    }

    setDocumentsSeleted(allDocuments);
  };

  const getData = () => {
    const pjeData: any[] = [];
    proceduresSeleted.map((procedure: any) => {
      const documentsToPetition = documentsSeleted.filter((document: any) => {
        return document.procedure_id === procedure.id;
      }).filter((doc: any) => doc.type !== t('procedureBox.processExtract.pjeExtract.petition'));

      const procedureToPetition = {
        procedure_id: procedure.id,
        attach_document_ids: documentsToPetition.map((doc: any) => doc.file_id),
      };

      pjeData.push(procedureToPetition);
    });

    return pjeData;
  };

  const handleSendPetition = (password: string = '') => {
    const proceduresToPetition = getData();
    const body = {
      proceduresToPetition,
      password,
    };
    const procedures = proceduresSeleted;
    const type = 'initialPetition';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    setModal(undefined);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
  };

  const openModalLogin = () => {
    setModal({
      visible: true,
      open: true,
      size: 'small',
      title: t('procedureBox.actions.initialPetition.modal.login.title'),
      children: <Login
                  submit={(password) => handleSendPetition(password)}
                  goBack={() => setModal(undefined)} />,
      handleClose: () => setModal(undefined),
    });
  };

  const handlePetitionInitial = () => {
    if (currentUser.has_pje_user) {
      handleSendPetition();
    } else {
      openModalLogin();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getInitialPetition();
    }, 200);
  }, [procedureId]);

  useEffect(() => {
    getAllDocuments();
  }, []);

  return (
    <div className={styles.container}>
      <SelectedProcedures />

      <div className={styles.procedures}>
        <ProcedureCarousel
          isMobile={isMobile}
          onClick={(id) => setProcedureId(id)}
          procedures={proceduresSeleted}
        />
      </div>

      <section className={styles.data}>
        <div className={styles.header}>
          <p>{t('procedureBox.actions.initialPetition.modal.basicData.text')}</p>
        </div>
          <Grid
            container
            className={styles.contentData}
            xs={10}
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item className={styles.box} xs={3}>
              <span>{t('procedureBox.actions.initialPetition.modal.basicData.proceduralClass')}</span>
              <strong>{ data?.class_denomination_description }</strong>
            </Grid>

            <Grid item className={styles.box} xs={3}>
              <span>{t('procedureBox.actions.initialPetition.modal.basicData.locationCode')}</span>
              <strong>{ data?.manifestation_data.locality_code }</strong>
            </Grid>

            <Grid item className={styles.box} xs={3}>
              <span>{t('procedureBox.actions.initialPetition.modal.basicData.levelOfSecrecy')}</span>
              <strong>{ data?.manifestation_data.secrecy_level }</strong>
            </Grid>

            <Grid item className={styles.box} xs={3}>
              <span>{t('procedureBox.actions.initialPetition.modal.basicData.mainSubject')}</span>
              <strong>{ data?.main_subject }</strong>
            </Grid>

            <Grid item className={styles.box} xs={3}>
              <span>{t('procedureBox.actions.initialPetition.modal.basicData.shareValue')}</span>
              <strong>{t('procedureBox.actions.initialPetition.modal.basicData.symbol', { value: Format.formatNumber(Number(data?.share_price)) }) }</strong>
            </Grid>

            <Grid item className={styles.box} xs={3}>
              <span>{t('procedureBox.actions.initialPetition.modal.basicData.representativeName')}</span>
              <strong>{ data?.subject_name }</strong>
            </Grid>

            <Grid item className={styles.box} xs={3}>
              <span>{t('procedureBox.actions.initialPetition.modal.basicData.typeOfRepresentative')}</span>
              <strong>{ data?.manifestation_data.representative_type }</strong>
            </Grid>
          </Grid>
      </section>

      <section>
        <div className={styles.header}>
          <p>{t('procedureBox.actions.initialPetition.modal.procedureParts.title', { procedureParts: data?.interested_parts.length })}</p>
        </div>

        <Table partsInterested={data?.interested_parts} />
      </section>

      <section>
        <div className={styles.header}>
          <p>{t('procedureBox.actions.initialPetition.modal.documentSend.text')}</p>
        </div>

        <div className={styles.infos}>
          <span>{t('procedureBox.actions.initialPetition.modal.documentSend.documentSelect')}</span>
          <span>{t('procedureBox.actions.initialPetition.modal.documentSend.info')}</span>
        </div>

        <div className={styles.documents}>
          {
            documentsList.length > 0 && (
              <DocumentList
                documents={documentsSeleted}
                isMobile={isMobile}
                documentsList={documentsList}
                selectColor={'var(--primary'}
                sizeField
                removePagination
                onChange={(doc) => setDocumentsSeleted(doc)}
              />
            )
          }
        </div>
      </section>

      <footer>
        { documentsSeleted.length > 0 && (
          <Button
            title={t('procedureBox.actions.initialPetition.modal.button')}
            onClick={() => handlePetitionInitial()}
            size='flat'
            buttonType='primary'
            round
          />
        )}
      </footer>
    </div>
  );
}
