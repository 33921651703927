import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import { Procedure } from '../../../@types/model';
import styles from './styles.module.scss';
import { Listining } from '../Listining';

type SamePartProps = {
  procedure: Procedure;
  listPendens: any;
  load: boolean;
  onChange: () => void;
};

export function SamePart(props: SamePartProps) {
  const header: any[] = [
    [t('procedureBox.processExtract.samePart.process')],
    [t('procedureBox.processExtract.samePart.mainField.label')],
    [t('procedureBox.processExtract.samePart.flux.label')],
    [t('procedureBox.processExtract.samePart.classification')],
    [t('procedureBox.processExtract.samePart.lastVerification')],
    [t('procedureBox.processExtract.samePart.verifiedBy')],
    [t('procedureBox.processExtract.samePart.listOrJudged')],
  ];
  const data = props.listPendens;

  return (
    <div className={ styles.container }>
      {!props.load
        ? <Skeleton variant="rectangular" className={ styles.skeleton } />
        : data !== undefined && data !== null && Object.keys(data).length !== 0
          ? <span className={ styles.pageTitle }>
            {t('procedureBox.processExtract.samePart.title')}
            </span>
          && <Listining
                procedureId={ props.procedure.id }
                headerValues={ header }
                rowsValue={ data }
                onChange={() => props.onChange()}
              />
          : <span className={ styles.pageTitle }>
              {t('procedureBox.processExtract.samePart.noData')}
            </span>
      }
    </div>
  );
}
