import { Grid } from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Tab } from '../../components/Tab';
import { SearchBar } from '../../components/SearchBar';
import { Breadcumb } from '../../components/Breadcumb';
import { BoxesSelect } from '../../components/BoxesSelect';
import { MarkersSelect } from '../../components/MarkersSelect';
import { ResponsiveMenu as MarkersResponsiveMenu } from '../../components/MarkersSelect/ResponsiveMenu';
import { ProcedureList } from '../../components/ProcedureList';
import { SortMenu } from '../../components/SortMenu';
import { Actions } from '../../components/ProcedureList/Actions';
import styles from './styles.module.scss';
import ProcedureMarkersProvider from '../../context/procedureMarkersContext';
import { SearchResult } from '../../components/ProcedureList/SearchResult';
import { PaginationMenu } from '../../components/PaginationMenu';
import { Modal } from '../../components/Modal';
import { LoadingBottomAlerts } from '../../components/LoadingBottomAlerts';
import { Alert } from '../../components/Alert';
import { AppContextProvider } from '../../context/appContextProvider';
import { ToastsList } from '../../components/ToastsList';
import QueryProvider from '../../context/queryContext';
import ProcedureActionsProvider from '../../context/procedureActionsContext';
import BackgroundActionsProvider from '../../context/backgroundActions';
import { NewProcedureButton } from './NewProcedureButton';
import { loadTitle } from '../../helpers/changeTitle';
import Show from '../../components/Show';

type ProcedureBoxProps = {
  title?: string;
};

export function ProcedureBox(props: ProcedureBoxProps) {
  const { t } = useTranslation();
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 700px)').matches,
  );

  const [widthY, setWidthY] = useState(window.screen.width);

  const [scroll, setScroll] = useState(0);

  const { pathname } = useLocation();

  window.addEventListener('scroll', () => {
    setScroll(window.scrollY);
  });

  window.addEventListener('resize', () => {
    setWidthY(window.innerWidth);
  });

  useEffect(() => {
    window
      .matchMedia('(max-width: 700px)')
      .addEventListener('change', (e) => setMatches(e.matches));

    loadTitle(props.title);
  }, []);

  return (
    <QueryProvider>
      <AppContextProvider>
        <ProcedureActionsProvider>
          <BackgroundActionsProvider>
            <ProcedureMarkersProvider>
              <ToastsList />
              <Grid
                container
                columns={{ xs: 2, sm: 12, md: 12 }}
                spacing={2}
                className={ styles.childrenContent }
              >
              <Grid item xs={12} className={ styles.title }>
                <Grid item xs={3} sm={6}>
                  <h1>{ t('procedureBox.title') }</h1>
                  <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={3} md={2}>
                <NewProcedureButton />
                {matches && (
                  <SearchBar />
                )}
                <BoxesSelect />
                {matches ? <MarkersResponsiveMenu /> : <MarkersSelect />}
              </Grid>
              <Grid item xs={2} sm={9} md={10}>
                {!matches && (
                  <SearchBar />
                )}
                  <div className={ styles.backgroundProcess }>
                    <Tab />
                    <SearchResult/>
                    <div className={ styles.actions }>
                      <Actions />
                      <Show if={widthY > 1850}>
                        <div className={ styles.menu }>
                          <SortMenu />
                          <PaginationMenu />
                        </div>
                      </Show>
                    </div>
                    <Show if={widthY < 1850}>
                      <div className={`${styles.menu} ${styles.menuButton}`}>
                        <SortMenu />
                        <PaginationMenu />
                      </div>
                    </Show>
                    <ProcedureList origin='default' scroll={scroll}/>
                    <div className={ styles.bottomMenu }>
                      <PaginationMenu />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Modal />
              <LoadingBottomAlerts />
              <Alert />
            </ProcedureMarkersProvider>
          </BackgroundActionsProvider>
        </ProcedureActionsProvider>
      </AppContextProvider>
    </QueryProvider>
  );
}
