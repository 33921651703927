import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import GeneralService from '../../../services/generalService';
import { MonthNotificationsType } from '../../../@types/home';
import { Item } from '../Item';

type AllNotificationsProps = {
  closeModal: () => void;
};

export function AllNotifications(props: AllNotificationsProps) {
  const [value, setValue] = useState('1');
  const [notifications, setNotifications] = useState({} as MonthNotificationsType);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(`${newValue}`);
  };

  const renderTab = () => {
    return notifications && Object.keys(notifications).map((key) => {
      return <Tab label={key} value={key} />;
    });
  };

  const renderTabPanel = () => {
    return notifications && Object.keys(notifications).map((key) => {
      return (
        <TabPanel style={{ padding: 0 }} value={key}>
          {notifications[key].map((notification) => {
            return <div className={styles.notification}><Item notification={notification} hideAction /></div>;
          })}
        </TabPanel>
      );
    });
  };

  useEffect(() => {
    GeneralService.getAllNotifications().then((res) => {
      setNotifications(res.data);
      setValue(Object.keys(res.data)[0]);
    });
  }, []);

  return (
    <div className={ styles.container }>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <div className={styles.tabList}>
            <TabList onChange={handleChange}>
              {renderTab()}
            </TabList>
          </div>
        </Box>
        <div className={styles.tabPanel}>
          {renderTabPanel()}
        </div>
      </TabContext>
    </div>
  );
}
