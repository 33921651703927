import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputText } from '../../../../components/InputText';
import NewProcessService from '../../../../services/newProcessService';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { ErrorField } from '../../../../components/ErrorField';
import { OptionType } from '../../../../@types/config';
import Select from '../../../../components/Select';
import FormHelper from '../../../../helpers/form';

type NewOfficialLetterSefinProps = {
  fluxId?: number;
  closeModal: () => void;
};

interface FormValues {
  official_letter_number: string;
  interested_part: string;
  ref_proc: string;
  official_letter_sefin_subject: OptionType;
}

export function NewOfficialLetterSefin(props: NewOfficialLetterSefinProps) {
  const i18nKey = 'procedureBox.newProcedure.newOfficialLetterSefin.form';
  const { addToasts } = useContext(ProcedureActionsContext);

  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);

  const schema = Yup.object().shape({
    official_letter_number: Yup.string().required(t('general.requeried')),
    interested_part: Yup.string().required(t('general.requeried')),
    official_letter_sefin_subject: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.string().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
  });

  const {
    handleSubmit, formState: { errors }, control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    NewProcessService.getNewOfficialLetterSefinData()
      .then((res) => {
        setSubjects(res.data.sefin_process_subject_id);
      });
  }, []);

  const submit = (form: FormValues) => {
    setLoading(true);
    const sefinOfficialLetters = {
      official_letter_number: form.official_letter_number,
      interested_part: form.interested_part,
      ref_proc: form.ref_proc,
      sefin_process_subject_id: form.official_letter_sefin_subject.value,
    };
    const data = { ...{ sefin_official_letter: sefinOfficialLetters }, flux_id: props.fluxId };
    NewProcessService.createOfficialLetterSefin(data)
      .then((res) => {
        if (res.errors) {
          addToasts({
            type: 'error',
            text: t('procedureBox.newProcedure.toasts.error'),
          });
        } else {
          props.closeModal();
          addToasts({
            type: 'success',
            text: FormHelper.customToastMessage(res.id),
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          addToasts({
            type: 'error',
            text: t('procedureBox.newProcedure.toasts.error'),
          });
        }
      });
  };

  return (
    <form className={ styles.container } onSubmit={handleSubmit(submit)}>
      <Header text={t(`${i18nKey}.data`)}/>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="official_letter_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(`${i18nKey}.officialNumber.label`)}
              />
            )}
          />
          <ErrorField text={errors.official_letter_number?.message} />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="interested_part"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(`${i18nKey}.interestedPart.label`)}
              />
            )}
          />
          <ErrorField text={errors.interested_part?.message} />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="ref_proc"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(`${i18nKey}.referenceProc.label`)}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="official_letter_sefin_subject"
            render={({ field }) => (
              <Select
                {...field}
                label={t(`${i18nKey}.official_letter_sefin_subject.label`)}
                options={subjects}
              />
            )}
          />
          <ErrorField text={errors.official_letter_sefin_subject?.value?.message} />
          <ErrorField text={errors.official_letter_sefin_subject?.message} />
        </Grid>
      </Grid>
      <div className={styles.submit}>
        <Button
          title={t('procedureBox.newProcedure.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
        />
      </div>
    </form>
  );
}
