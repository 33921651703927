import { Grid } from '@mui/material';
import { useContext } from 'react';
import { Toast } from '../Toast';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { HomeContext } from '../../context/homeContext';

type ToastsListProps = {
  fromHome?: boolean
};

export function ToastsList(props: ToastsListProps) {
  const { toasts: procedureToasts } = useContext(ProcedureActionsContext);
  const { toasts: homeToasts } = useContext(HomeContext);

  const getToastList = () => {
    if (props.fromHome) {
      return homeToasts;
    }
    return procedureToasts;
  };
  return (
    <div className={styles.toast}>
      <Grid>
        {getToastList().map((toast: any) => (
          <Toast
            key={toast.id}
            type={`${toast.type === 'error' ? 'error' : 'success'}`}
            collapse
            text={toast.text}
            isCloseToast={toast.isCloseToast === undefined ? true : toast.isCloseToast}
            open
          />
        ))}
      </Grid>
    </div>
  );
}
