import { useContext } from 'react';
import { GenericMarker } from '../../../@types/markers';
import { Marker } from '../../../@types/model';
import AuthContext from '../../../context/authContext';
import { getMarkerColor, getMarkerLabel } from '../../../helpers/markers';
import { CheckBox } from '../../CheckBox';
import { CustomMenu } from '../MarkersFixedValues/CustomMenu';
import styles from './styles.module.scss';

type OptionProps = {
  onChange: (marker: Marker | GenericMarker) => void;
  marker: Marker | GenericMarker;
  value: boolean;
};

export function Option(props: OptionProps) {
  const markerLabel = `${getMarkerLabel(props.marker)} ${props.marker.total ? `(${props.marker.total})` : ''}`;
  const { user } = useContext(AuthContext);

  return (
       <div className={ `${styles.container} ${props.value ? styles.selected : ''}` } onClick={ () => props.onChange(props.marker) }>
        <CheckBox
          value={ props.value }
          text={markerLabel}
          color={ getMarkerColor(props.marker) }
          isMarker
        />
          {(props.marker as Marker).created_by_id === user?.id && <CustomMenu marker={props.marker} className={ styles.customMenu }/> }
      </div>
  );
}
