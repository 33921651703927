import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { InputDate } from '../../../../components/InputDate';
import { Button } from '../../../../components/Button';
import ReportsService from '../../../../services/reportsService';
import Select from '../../../../components/Select';

interface QueryObject {
  [key: string]: any;
}

type FiltersProps = {
  onChange: (query: string) => void;
  disabled?: boolean;
};

export function Filters(props: FiltersProps) {
  const { handleSubmit, control } = useForm();
  const [attorneys, setAttorneys] = useState([]);
  const [objects, setObjects] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    ReportsService.getCalculationDependencies().then((res) => {
      setAttorneys(res.attorneys);
      setObjects(res.process_categories);
      setStatuses(res.status);
    });
  }, []);

  useEffect(() => {
    props.onChange(query);
  }, [query]);

  const formatValue = (value: any): string => {
    if (typeof value === 'object' && value !== null && 'value' in value) {
      return value.value.toString();
    }
    if (typeof value === 'string' && value.includes('R$')) {
      return value.replace('R$ ', '');
    }
    return value.toString();
  };

  const clearQuery = (obj: QueryObject): string => {
    let queryString = '';
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (value !== undefined && value !== '') {
          const formattedValue = formatValue(value);
          queryString += `q[${key}]=${formattedValue}&`;
        }
      }
    }
    return queryString.slice(0, -1);
  };

  const submit = (form: any) => {
    const formatted = clearQuery(form);
    setQuery(formatted);
  };

  const cleanFilter = () => {
    window.location.reload();
  };

  return (
    <div className={ styles.container }>
      <form className={ styles.form } onSubmit={handleSubmit(submit)}>
        <Grid
          container
          columns={{ xs: 1, sm: 12, md: 12 }}
          spacing={3}
        >
          <Grid
            item
            xs={1}
            sm={4}
            md={4}
          >
            <Controller
              control={control}
              name="process_category_id_eq"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('reports.calculation.filters.object')}
                  options={objects}
                  returnValue
                  placeholder={''}
                  dataCy='object'
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={4}
          >
            <Controller
              control={control}
              name="attorney_in"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('reports.calculation.filters.attorney')}
                  options={attorneys}
                  returnValue
                  placeholder={''}
                  dataCy='attorney'
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={4}
            md={4}
          >
            <Controller
              control={control}
              name="status_in"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('reports.calculation.filters.status')}
                  options={statuses}
                  returnValue
                  placeholder={''}
                  dataCy='status'
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={3}
            md={3}
          >
            <Controller
              control={control}
              name="send_date_initial_gteq"
              render={({ field }) => (
                <InputDate
                  {...field}
                  label={t('reports.calculation.filters.sendDate.label')}
                  fixedValue
                />
              )}
            />
            <Controller
              control={control}
              name="send_date_final_lteq"
              render={({ field }) => (
                <InputDate
                  {...field}
                  fixedValue
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={3}
            md={3}
          >
            <Controller
              control={control}
              name="response_date_initial_gteq"
              render={({ field }) => (
                <InputDate
                  {...field}
                  dataCy={'initialDate'}
                  label={t('reports.calculation.filters.responseDate.label')}
                  fixedValue
                />
              )}
            />
            <Controller
              control={control}
              name="response_date_initial_lteq"
              render={({ field }) => (
                <InputDate
                  {...field}
                  dataCy={'finalDate'}
                  fixedValue
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={3}
            md={3}
          >
            <Controller
              control={control}
              name="deadline_gteq"
              render={({ field }) => (
                <InputDate
                  {...field}
                  label={t('reports.calculation.filters.deadline.label')}
                  fixedValue
                />
              )}
            />
            <Controller
              control={control}
              name="deadline_lteq"
              render={({ field }) => (
                <InputDate
                  {...field}
                  fixedValue
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={8}
            md={8}
            className={styles.buttons}
          >
            <Button
              title={t('procedureBox.filterAdvanced.clearFilter')}
              buttonType='default'
              size='flat'
              type='button'
              round
              onClick={cleanFilter}
            />
            <Button
              title={t('procedureBox.filterAdvanced.filter')}
              buttonType='primary'
              size='flat'
              disabled={props.disabled}
              round
              dataCy='calculation-submit-filters'
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
