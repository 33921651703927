export function getBarBorderRadius(): string {
  return '10px';
}

export function getBarHeight(): string {
  return '12px';
}

export function getBarWidth(width: number | undefined): string {
  if (width !== undefined && width < 100) {
    return `${width}%`;
  }
  return '100%';
}

export function hideLayout() {
  document.getElementById('sidebar')!.style.display = 'none';
  document.getElementById('topbar')!.style.display = 'none';
}
