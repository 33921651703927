import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type SearchProps = {
  title?: string;
};

export function Search(props: SearchProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/processos_digitais/processes_search`}/>
  );
}
