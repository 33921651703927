import { t } from 'i18next';
import { useState } from 'react';
import { FaRegQuestionCircle, FaSearch, FaTimes } from 'react-icons/fa';
import Show from '../../Show';
import { Tooltip } from '../../Tooltip';
import styles from './styles.module.scss';

type HeaderLineProps = {
  title: string;
  tooltip?: string;
  onSearch: (value: string) => void;
};

export function Header(props: HeaderLineProps) {
  const [search, setSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const handleCloseSearch = () => {
    setSearchInput('');
    props.onSearch('');
    setSearch(false);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    props.onSearch(e.target.value);
  };
  return (
      <div className={ styles.header }>
        <div className={styles.subHeader}>
          <p className={ styles.title }>{props.title}</p>
          <div className={ styles.info }>
            {props.tooltip
              ? <Tooltip title={ props.tooltip }><FaRegQuestionCircle /></Tooltip>
              : null
            }
          </div>
        </div>
        <div className={styles.action}>
          <Show if={search}>
            <div className={styles.inputContainer}>
              <input
                type="text"
                placeholder={t('dashboard.digitalProcesses.productivityChart.placeholder')}
                onChange={handleChange}
                value={searchInput}
              />
            </div>
          </Show>
          <Show if={!search}>
            <div className={styles.icon} onClick={() => setSearch(true)}>
              <FaSearch />
            </div>
          </Show>
          <Show if={search}>
            <div className={styles.icon} onClick={() => handleCloseSearch()}>
              <FaTimes />
            </div>
          </Show>
        </div>
      </div>
  );
}
