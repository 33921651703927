import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { FaPaperPlane } from 'react-icons/fa';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Alert } from '../Alert';
import styles from './styles.module.scss';

export function TramitAlert() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div className={ styles.container }>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'tramit').map((batch) => (
        <Alert
          icon={<FaPaperPlane />}
          title={t('procedureBox.actions.tramit.modal.alertLoading.title')}
          text={<Trans
            i18nKey='procedureBox.actions.tramit.modal.alertLoading.text'
            components={{ bold: <strong /> }}
            values={{ individual: batch.body.individual_name }}
          />}
          batch={batch}
        />
      ))}
    </div>
  );
}
