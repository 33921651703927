export default {
  translations: {
    debtBox: {
      title: 'Carteira de créditos',
      instructions: {
        title: 'Veja créditos a cobrar, gere <strong>relatórios, simulações e boletos</strong>',
        line01: 'Preencha  um ou mais campos para encontrar inscrições de créditos cadastrados na PGM.',
        line02: 'Quanto mais campos com informações, mais refinada será sua busca.',
        line03: 'Dúvidas sobre créditos?',
        link: '<a>Saiba mais aqui</a>',
      },
      search: {
        confirm: 'Consultar',
        clear: 'Limpar consulta',
        moreFilters: 'Mais filtros',
        lessFilters: 'Menos filtros',
        tags: 'Pílulas',
        fields: {
          showExtinctDebits: 'Exibir dívidas extintas',
          contributor: {
            label: 'Contribuinte',
            placeholder: 'cpf/cnpj ou nome',
          },
          processNumber: {
            label: 'Processo judicial',
            placeholder: '00000000000',
          },
          inscription: {
            label: 'Inscrição administrativa',
            placeholder: '00 0000-00',
          },
          cda: {
            label: 'CDA',
            placeholder: '00.00.000.000.00',
          },
          installmentNumber: {
            label: 'Número do parcelamento/acordo',
            placeholder: '0000.000',
          },
          origin: {
            label: 'Chave de origem',
            placeholder: '00000',
          },
        },
        receiptTypes: {
          cdaAndRequeriments: ' {{cdas}} CDAs e {{requeriments}} Requerimentos',
          totalBalance: 'Total: {{balance}}',
        },
      },
      results: {
        address: 'Endereço de cobrança: ',
      },
    },
  },
};
