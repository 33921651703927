/* eslint-disable import/no-default-export */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations from './locales';

const i18nConfig = {
  resources: translations,
  fallbackLng: 'pt-BR',
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
  },
};

i18n
  .use(initReactI18next)
  .init(i18nConfig);

export default i18n;
