import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { FaPaperclip } from 'react-icons/fa';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Alert } from '../Alert';

export function AttachDocumentAlert() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'attachFiles').map((batch) => (
        <Alert
          icon={<FaPaperclip />}
          title={t('procedureBox.actions.attachFiles.title')}
          text={<Trans
            i18nKey='procedureBox.actions.attachFiles.modal.alertLoading.text'
            components={{ bold: <strong /> }}
          />}
          batch={batch}
        />
      ))}
    </div>
  );
}
