import { Badge, Tooltip } from '@mui/material';
import { FaCamera } from 'react-icons/fa';
import styles from './styles.module.scss';
import Show from '../../Show';

type AvatarProps = {
  name: string;
  color: string | undefined;
  className?: string;
  onClick?: (event: any) => void;
  profile_picture?: string;
  size?: 'default' | 'medium' | 'large' | 'xlarge';
  tooltip?: boolean;
  badge?: boolean;
};

export function Avatar(props: AvatarProps) {
  const nickname = () => {
    let splitName: string[];
    try {
      if (props.name) {
        splitName = props.name.split(' ');
        const removeEmpty = splitName.filter((n) => n !== '');
        return `${props.name[0]}${removeEmpty[removeEmpty.length - 1][0]}`;
      }
    } catch (error) {
      return '';
    }
    return '';
  };

  const renderAvatar = () => {
    return (
      <Tooltip title={ props.tooltip ? '' : props.name }>
        {props.profile_picture
          ? <div className={`${styles.container} ${props.size ? styles[props.size] : styles.default}`}>
              <img src={props.profile_picture} alt={props.name}/>
            </div>
          : <div className={ `${styles.container} ${props.className} ${props.size ? styles[props.size] : styles.default}` } style={ { backgroundColor: props.color } }>
              <span className={ `${styles.nickname} ${props.size ? styles[props.size] : styles.default}` }>{ nickname() }</span>
            </div>
        }
      </Tooltip>
    );
  };

  const handleOnClick = (e: any) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <>
      <Show if={Boolean(props.badge)}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <div className={styles.badge} onClick={(e) => handleOnClick(e)}>
              <p className={styles.img}>
                <FaCamera />
              </p>
            </div>
          }
        >
          {renderAvatar()}
        </Badge>
      </Show>
      <Show if={!props.badge}>
        {renderAvatar()}
      </Show>
    </>
  );
}
