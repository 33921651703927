import { useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
  FaChevronUp,
  FaChevronDown,
  FaExclamationTriangle,
  FaDotCircle,
  FaRegCheckCircle,
} from 'react-icons/fa';
import styles from './styles.module.scss';

type ResultProps = {
  status?: boolean;
  totalWithoutDocument: number;
  withDocument: number;
  proceduresLength: number;
  disabled: boolean;
  type: 'verifyAttach' | 'verifySignature';
};

export function Result(props: ResultProps) {
  const [open, setOpen] = useState(true);
  const keyi18n = 'procedureBox.actions.ipm.forwardIPM.modal.verify.result';
  const getPlural = props.proceduresLength > 1 ? 'plural' : 'singular';

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <span>
          {props.status
            ? <FaRegCheckCircle className={styles.success} />
            : <FaExclamationTriangle className={styles.warning}/>}
          {t(
            `${keyi18n}.${props.type}.${props.status ? 'allright' : 'warning'}.${getPlural}`,
            { totalWithoutDocument: props.totalWithoutDocument, length: props.proceduresLength },
          )}
        </span>
        <a onClick={() => setOpen(!open)}>
          {!props.disabled ? open ? <FaChevronUp /> : <FaChevronDown /> : <></>}
        </a>
      </div>
      {!props.disabled && open && props.withDocument > 0 && !props.status && <div className={styles.infoProgress}>
        <span>
          <FaDotCircle className={styles.iconDot}/>
          <Trans
            i18nKey='procedureBox.actions.ipm.forwardIPM.modal.verify.result.verifyAttach.continue'
            components={{ bold: <strong /> }}
            values={{ length: props.withDocument }}
          />
        </span>
      </div>}
    </div>
  );
}
