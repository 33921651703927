import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import styles from './styles.module.scss';
import Show from '../../../components/Show';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { SamePartInfos } from '../../../@types/config';
import Format from '../../../helpers/format';
import { Filter } from './Filter';

type ListiningProps = {
  headerValues: string[];
  rowsValue: SamePartInfos[];
  procedureId: number;
  onChange: () => void;
};

export function Listining(props: ListiningProps) {
  const [cpfs, setCpfs] = useState<string[]>(Array.from(new Set(props.rowsValue.map((lis) => lis.part_document))));

  const mainFields = Array.from(new Set(props.rowsValue.map((lis) => lis.main_field)));
  const fluxes = Array.from(new Set(props.rowsValue.map((lis) => lis.flux_name)));

  const fieldsOptions = mainFields.map((fieldName, index) => ({ label: fieldName, value: index }));
  const fluxesOptions = fluxes.map((fieldName, index) => ({ label: fieldName, value: index }));

  const [field, setField] = useState<string>();
  const [flux, setFlux] = useState<string>();
  const [orderBy, setOrderBy] = useState<string>();

  const handleFilter = (main_field: string, flux_name: string) => {
    return (field === undefined ? true : field === main_field) && (flux === undefined ? true : flux === flux_name);
  };

  useEffect(() => {
    setCpfs(
      Array.from(new Set(props.rowsValue.filter((row: SamePartInfos) => (
        handleFilter(row.main_field, row.flux_name)
      )).map((lis) => lis.part_document))),
    );
  }, [field, flux]);

  const hasActiveRelation = (value: any, condition: boolean) => {
    return value === null ? 'default' : value === condition ? 'primary' : 'default';
  };

  const updateLisPendens = (lisPendensProcessId: number, condition: boolean, procedure_id: number) => {
    ProcedureBoxService.updateSamePart(lisPendensProcessId, condition, props.procedureId, procedure_id).then(() => {
      props.onChange();
    });
  };

  const filterRows = (rows: SamePartInfos[]) => {
    return rows.sort((a, b) => {
      const dateA = a.display_last_verification === '-' ? 0 : Format.parseDate(a.display_last_verification);
      const dateB = b.display_last_verification === '-' ? 0 : Format.parseDate(b.display_last_verification);

      switch (orderBy) {
        case 'process_category_name asc':
          if (a.process_category_name === b.process_category_name) {
            return a.procedure_id - b.procedure_id;
          }
          return a.process_category_name.localeCompare(b.process_category_name);
        case 'process_category_name desc':
          if (a.process_category_name === b.process_category_name) {
            return b.procedure_id - a.procedure_id;
          }
          return b.process_category_name.localeCompare(a.process_category_name);
        case 'display_last_verification asc':
          if (dateA === dateB) {
            return a.procedure_id - b.procedure_id;
          }
          return dateA - dateB;
        case 'display_last_verification desc':
          if (dateA === dateB) {
            return b.procedure_id - a.procedure_id;
          }
          return dateB - dateA;
        case 'main_field desc':
          if (a.main_field === b.main_field) {
            return b.procedure_id - a.procedure_id;
          }
          return b.main_field.localeCompare(a.main_field);
        default:
          if (a.main_field === b.main_field) {
            return a.procedure_id - b.procedure_id;
          }
          return a.main_field.localeCompare(b.main_field);
      }
    });
  };

  const handleOptions = (value: string | undefined, type: string) => {
    if (type === 'field') {
      setField(value);
    } else if (type === 'flux') {
      setFlux(value);
    } else {
      setOrderBy(value);
    }
  };

  const getRowValues = (values: SamePartInfos) => {
    return (
      <>
        <td>
          <a href={`/procedure_box/show_procedure/${values.procedure_id}`} target={'_blank'}>
            {values.procedure_number}
          </a>
        </td>
        <td>{values.main_field}</td>
        <td>{values.flux_name}</td>
        <td>{values.process_category_name}</td>
        <td>{values.display_last_verification}</td>
        <td>{values.display_verified_by}</td>
        <td>
          <span className={styles.buttons}>
            <Button
              title={t('procedureBox.processExtract.samePart.yes')}
              buttonType={hasActiveRelation(values.has_active_relation_with, true)}
              onClick={() => updateLisPendens(values.lis_pendens_process_id, true, values.procedure_id)}
              round
            />
            <Button
              title={t('procedureBox.processExtract.samePart.no')}
              buttonType={hasActiveRelation(values.has_active_relation_with, false)}
              onClick={() => updateLisPendens(values.lis_pendens_process_id, false, values.procedure_id)}
              round
            />
          </span>
        </td>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <Filter
        fieldsOptions={fieldsOptions}
        fluxesOptions={fluxesOptions}
        handleOnChange={handleOptions}
      />
      <Show if={cpfs.length > 0}>
        {cpfs.map((cpf: string) => {
          const filteredRows = filterRows(props.rowsValue.filter(
            (row: SamePartInfos) => row.part_document === cpf && handleFilter(row.main_field, row.flux_name),
          ));

          const partName = filteredRows?.[0]?.part_name;

          return (
            <div className={styles.samePartInfo} key={cpf}>
              <span className={styles.title}>{`${partName} - ${cpf}`}</span>
              <div className={styles.breakLine}></div>
              <Table>
                <tr>
                  {props.headerValues.map((header: string) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
                {filteredRows.map((row: SamePartInfos) => (
                  <tbody>{getRowValues(row)}</tbody>
                ))}
              </Table>
            </div>
          );
        })}
      </Show>
    </div>
  );
}
