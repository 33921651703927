import React from 'react';

interface Props {
  if: boolean;
}

export default function Show({ if: condition, children }: React.PropsWithChildren<Props>) {
  if (condition) {
    return <>{children}</>;
  }

  return null;
}
