import { useContext } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { ConsultProcedure } from '../../../../@types/pje';

type HeaderProps = {
  procedure?: ConsultProcedure;
  updatedProcedure: () => void;
};

export function Header(props: HeaderProps) {
  const keyI18n = 'procedureBox.processExtract.pjeExtract';
  const { openNotices, openMovements, proceduresSeleted } = useContext(ProcedureActionsContext);

  const handleRedirectPageFolder = () => {
    window.open(
      `/digital_folder/${proceduresSeleted[0].id}/pje`,
      '_blank',
    );
  };

  const openModalNotices = () => {
    if (props.procedure && props.procedure.notices_count > 0) {
      openNotices(props.procedure.pending_notices);
    }
  };

  const openModalMovements = () => {
    if (props.procedure && props.procedure.movements_count > 0) {
      openMovements(props.procedure.movements);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FaInfoCircle className={styles.icon}/>
        <Trans
          i18nKey={`${keyI18n}.lastUpdated`}
          components={{ span: <span /> }}
          values={{ date: props.procedure?.updated_date }}
        />
      </div>

      <div className={styles.actions}>
        <Button
          buttonType={'default'}
          round
          size='flat'
          title={t(`${keyI18n}.basicData.buttons.update`)}
          onClick={props.updatedProcedure}
        />

        <Button
          buttonType={'default'}
          round
          size='flat'
          title={t(`${keyI18n}.basicData.buttons.notices`)}
          onClick={openModalNotices}
        />
        <Button
          buttonType={'default'}
          round
          size='flat'
          title={t(`${keyI18n}.basicData.buttons.movements`)}
          onClick={openModalMovements}
        />
        <Button
          buttonType={'primary'}
          round
          size='flat'
          title={t(`${keyI18n}.basicData.buttons.digitalFolder`)}
          onClick={() => handleRedirectPageFolder()}
        />
      </div>
    </div>
  );
}
