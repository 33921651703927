import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { SelectedProcedures } from '../SelectedProcedures';
import { Tab } from './Tab';
import Screen from '../../helpers/screen';
import { OrderData } from './OrderData';
import { CalculationData } from './CalculationData';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import ProcedureBoxService from '../../services/procedureBoxService';
import {
  ProcedureDependencies, RequestCalculationDocument, RequestData, requestCalculationInitialState,
} from '../../@types/requestCalculation';
import { Procedure } from '../../@types/model';

type RequestCalculationProps = {
  requestCalculationObject?: RequestCalculationDocument;
};

export function EletronicCalculation(props: RequestCalculationProps) {
  let renderView;
  const isMobile = Screen.isMobile();
  const { proceduresSeleted } = useContext(ProcedureActionsContext);
  const [selectedTab, setSelectedTab] = useState('orderData');
  const [orderData, setOrderData] = useState();
  const [calculationData, setCalculationData] = useState();
  const [
    requestCalculationObject, setRequestCalculationObject,
  ] = useState<RequestCalculationDocument>(props.requestCalculationObject || requestCalculationInitialState);
  const [subProcedureInfos, setSubProcedureInfos]: any = useState({});
  const [dependenciesResponse, setDependenciesResponse]: any = useState({});

  const createSubProcess = async (generate_number: boolean) => {
    const subprocedure = {
      parent_procedure: proceduresSeleted[0].id,
      flux_id: 62,
      number_process: proceduresSeleted[0].process_number,
      generate_number,
    };
    return ProcedureBoxService.startSubProcedure(subprocedure).then((response) => {
      return response;
    });
  };

  const getDependencies = async (id: number) => {
    return ProcedureBoxService.getDependenciesPaymentRequests(id).then((dep) => {
      return dep.data;
    });
  };

  useEffect(() => {
    setCalculationData(undefined);
  }, []);

  const handleOrderData = async (values: RequestData) => {
    if (requestCalculationObject.subProcedure === undefined) {
      const subPrcedure: Procedure = await createSubProcess(values.generateNumber);
      const dep: ProcedureDependencies = await getDependencies(subPrcedure.id);
      setDependenciesResponse(dep);
      setSubProcedureInfos(subPrcedure);
      const requestCalculationItem: RequestCalculationDocument = {
        ...requestCalculationObject,
        orderData: values,
        dependencies: dep,
        subProcedure: subPrcedure,
      };
      setRequestCalculationObject(requestCalculationItem);
    }
    setSelectedTab('calculationData');
  };

  switch (selectedTab) {
    case 'orderData':
      renderView = (
        <OrderData
          content={requestCalculationObject.orderData}
          handleForm={(e) => setOrderData(e)}
          nextStep={(val) => handleOrderData(val)}
        />
      );
      break;
    case 'calculationData':
      renderView = (
        <CalculationData
          content={requestCalculationObject.calculationData}
          dependencies={dependenciesResponse}
          subProcedure={subProcedureInfos}
          prevStep={() => setSelectedTab('orderData')}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className={styles.container}>
      <SelectedProcedures />
      <div className={`${styles.tabs} ${isMobile ? styles.tabsMobile : ''}`}>
        <Tab
          onClick={() => setSelectedTab('orderData')}
          tabSelected={selectedTab === 'orderData'}
          title={'orderData'}
          showIcon={orderData}
        />
        <Tab
          onClick={() => setSelectedTab('calculationData')}
          tabSelected={selectedTab === 'calculationData'}
          title={'calculationData'}
          showIcon={calculationData}
          disabled={selectedTab !== 'calculationData' && !calculationData}
        />
      </div>
      <div className={styles.content}>
        {renderView}
      </div>
     </div>
  );
}
