import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type OverviewPhasesDebtInscriptionsProps = {
  title?: string;
};

export function OverviewPhasesDebtInscriptions(props: OverviewPhasesDebtInscriptionsProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/dashboards/fase_inscricoes`}/>
  );
}
