import { useState } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { t } from 'i18next';
import { ClickAwayListener, Tooltip } from '@mui/material/';
import { Table } from '../../../../components/Table';
import styles from './styles.module.scss';
import { Address, Polo } from '../../../../@types/pje';
import { truncate } from '../../../../helpers/strings';

type ListProps = {
  data?: Polo[];
  active?: boolean;
};

export function List(props: ListProps) {
  const keyI18n = 'procedureBox.processExtract.pjeExtract.basicData.table';
  const [open, setOpen] = useState(false);

  const renderTextAddress = (address: Address) => {
    if (!address) {
      return null;
    }

    return (
      <div className={`${styles.link} ${open && styles.open}`} onClick={() => setOpen(!open)}>
        <FaMapMarkedAlt />
        <span>{`${address.public_place}, ${address.number}`}</span>
      </div>
    );
  };

  const renderAddress = (address: Address) => {
    if (!open) {
      return null;
    }

    return (
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={styles.tooltip}>
          <div className={styles.content}>
            <h3 className={styles.title}>{t(`${keyI18n}.address.full`)}</h3>
            <Table>
              <thead>
                <tr>
                  <th>{t(`${keyI18n}.address.zipCode`)}</th>
                  <th>{t(`${keyI18n}.address.publicPlace`)}</th>
                  <th>{t(`${keyI18n}.address.number`)}</th>
                  <th>{t(`${keyI18n}.address.complement`)}</th>
                  <th>{t(`${keyI18n}.address.neighborhood`)}</th>
                  <th>{t(`${keyI18n}.address.city`)}</th>
                  <th>{t(`${keyI18n}.address.state`)}</th>
                </tr>
              </thead>
              <tbody>
                <td>{address.zip_code}</td>
                <td>{address.public_place}</td>
                <td>{address.number}</td>
                <td>{address.complement}</td>
                <td>{address.neighborhood}</td>
                <td>{address.city}</td>
                <td>{address.state}</td>
              </tbody>
            </Table>
          </div>
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <>
      <h3>
        {t(`${keyI18n}.${props.active ? 'activePart' : 'passivePart'}`)}
        {props.data && ` (${props.data.length})`}
      </h3>

      <Table>
        <thead>
          <tr>
            <th>{t(`${keyI18n}.name`)}</th>
            <th>{t(`${keyI18n}.representativeType`)}</th>
            <th>{t(`${keyI18n}.dateBirth`)}</th>
            <th>{t(`${keyI18n}.documentNumber`)}</th>
            <th>{t(`${keyI18n}.personType`)}</th>
            <th>{t(`${keyI18n}.documentIssuer`)}</th>
            <th>{t(`${keyI18n}.documentType`)}</th>
            <th>{t(`${keyI18n}.address.short`)}</th>
          </tr>
        </thead>
        {props.data?.map((polo: Polo) => (
          <>
            <tbody>
              <td>
                <Tooltip title={polo.name.length > 30 ? polo.name : ''}>
                  <span>{truncate(polo.name, 30)}</span>
                </Tooltip>
              </td>
              <td>{polo.representative_type}</td>
              <td>{polo.date_birth}</td>
              <td>{polo.document_number}</td>
              <td>{polo.person_type}</td>
              <td>{polo.document_issuer}</td>
              <td>{polo.document_type}</td>
              <td>
                {renderAddress(polo.address)}
                {renderTextAddress(polo.address)}
              </td>
            </tbody>
          </>
        ))}
      </Table>
    </>
  );
}
