import styles from './styles.module.scss';

type HeaderProps = {
  title: string;
};

export function Header(props: HeaderProps) {
  return (
    <div className={ styles.container }>
      <span className={styles.title}>{props.title}</span>
    </div>
  );
}
