import { styled } from '@mui/material/styles';
import { FaTimes } from 'react-icons/fa';
import { AutocompleteGetTagProps } from '@mui/material/';
import styles from './styles.module.scss';
import { OptionType } from '../../../../../../@types/config';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

export function TagRenderer(tagValue :OptionType[], getTagProps :any) {
  function Tag(propsTag: TagProps) {
    const { label, onDelete } = propsTag;
    return (
      <div className={styles.tag}>
        <span>{label}</span>
        <FaTimes onClick={onDelete}/>
      </div>
    );
  }

  const StyledTag = styled(Tag)<TagProps>();

  return (
    tagValue.map((option :OptionType, index :number) => (
      <div>
        <StyledTag {...getTagProps({ index })} label={option.label} />
      </div>
    ))
  );
}
