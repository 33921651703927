import { t } from 'i18next';
import styles from './styles.module.scss';

type HeaderProps = {
  readAll: () => void;
};

export function Header({ readAll }: HeaderProps) {
  return (
    <div className={ styles.container }>
      <span className={styles.title}>
        {t('home.notifications.title')}
      </span>
      <span className={styles.read} onClick={readAll}>
        {t('home.notifications.read')}
      </span>
    </div>
  );
}
