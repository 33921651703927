import { useState, useContext } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Table } from '../../../../components/Table';
import { EPGM_URL } from '../../../../config/constants';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import { Notice } from '../../../../@types/pje';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { typeCommunication } from '../BasicData/constants';
import Format from '../../../../helpers/format';
import WarningImg from '../../../../assets/warning.svg';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import { Login } from '../../../../components/Petition/Login';
import { CurrentUser } from '../../../../@types/currentUser';
import { inactivatePjeUser } from '../../../../helpers/pje';

type PendingNoticesModalProps = {
  handleCloseModal: () => void;
  notices: Notice[];
};

export function PendingNoticesModal(props: PendingNoticesModalProps) {
  const keyI18n = 'procedureBox.processExtract.pjeExtract.notice';
  const { proceduresSeleted, setModal, addToasts } = useContext(ProcedureActionsContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const { setAlert } = useContext(ProcedureActionsContext);
  const [notices, setNotices] = useState(props.notices);

  const renderValue = (value: any, list: any) => {
    const text = list.find((item: any) => item.code === value);

    if (text) {
      return `${text.code} - ${text.description}`;
    }

    return value;
  };

  const openDocument = (url: string) => {
    window.open(
      `${EPGM_URL}${url}`,
      '_blank',
    );
  };

  const openCommunicationContent = (notice: Notice, password: string = '') => {
    ProcedureBoxService.communicationContent(password, notice.code, proceduresSeleted[0].id).then((res) => {
      if (res.status === 401) {
        inactivatePjeUser();
        addToasts({ type: 'error', text: res.message });
        setModal(undefined);
      } else if (res.status === 500) {
        setModal(undefined);
        addToasts({ type: 'error', text: res.message });
      } else {
        const removeItem = notices.filter((i) => i.code !== res.notice.code);
        setNotices([res.notice, ...removeItem]);
      }

      setAlert(undefined);
    });
  };

  const openModalLogin = (notice: Notice) => {
    setModal({
      visible: true,
      open: true,
      size: 'small',
      title: t('procedureBox.actions.initialPetition.modal.login.title'),
      children: <Login
                  submit={(password) => openCommunicationContent(notice, password)}
                  goBack={() => setModal(undefined)} />,
      handleClose: () => setModal(undefined),
    });
  };

  const handleCommunication = (notice: Notice) => {
    if (currentUser.has_pje_user) {
      openCommunicationContent(notice);
    } else {
      setAlert(undefined);
      openModalLogin(notice);
    }
  };

  const handleConfirm = (notice: Notice) => {
    if (!notice.document_url) {
      setAlert({
        type: 'warning',
        visible: true,
        handleConfirm: () => handleCommunication(notice),
        title: <Trans
                i18nKey={`${keyI18n}.modal.alert.title`}
                components={{ b: <strong /> }}
                values={{ id: notice.code }} />,
        text: <Trans
                i18nKey={`${keyI18n}.modal.alert.text`}
                components={{ b: <strong /> }} />,
        cancelText: t('general.no'),
        confirmText: t(`${keyI18n}.modal.alert.confirmText`),
        agreementText: t(`${keyI18n}.modal.alert.agreementText`),
      });
    } else {
      openDocument(notice.document_url);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Trans
          i18nKey={`${keyI18n}.procedureInfo`}
          components={{ b: <b /> }}
          values={{ number: proceduresSeleted[0].process_number, flux: proceduresSeleted[0].flux.name }}
        />
      </div>

      <div className={styles.warning}>
        <img src={WarningImg} alt={t('general.noOptions')} className={styles.img}/>
        <div className={styles.text}>
          <p className={styles.warningTitle}>{t(`${keyI18n}.attention.title`)}</p>
          <p className={styles.warningText}>
            <Trans
              i18nKey={`${keyI18n}.attention.text`}
              components={{ b: <b />, bDanger: <b className={styles.dangerColor} /> }}
            />
          </p>
        </div>
      </div>

      <div className={styles.title}>{t(`${keyI18n}.warning`)}</div>
      <Table>
        <thead>
          <tr>
            <th>{t(`${keyI18n}.table.code`)}</th>
            <th>{t(`${keyI18n}.table.typeCommunication`)}</th>
            <th>{t(`${keyI18n}.table.date`)}</th>
            <th>{t(`${keyI18n}.table.causeValue`)}</th>
            <th>{t(`${keyI18n}.table.judgingOrgan`)}</th>
            <th>{t(`${keyI18n}.table.openedBy`)}</th>
            <th>{t(`${keyI18n}.table.openingDate`)}</th>
            <th>{t(`${keyI18n}.table.deadline`)}</th>
            <th>{t(`${keyI18n}.table.documentation`)}</th>
          </tr>
        </thead>

        {
          notices?.map((notice: Notice) => (
            <tbody>
              <td>{notice.code}</td>
              <td>{renderValue(notice.type_notice, typeCommunication)}</td>
              <td>{notice.date_notice}</td>
              <td>{Format.currency(Number(notice.cause_value))}</td>
              <td>{notice.judging_organ}</td>
              <td>{notice.opened_by || t(`${keyI18n}.table.waiting`)}</td>
              <td>{notice.opening_date || ''}</td>
              <td>{notice.deadline || ''}</td>
              <td>
                <Button
                  buttonType={`${notice.opened_by ? 'danger' : 'primary'}`}
                  round
                  size='flat'
                  title={t(`${keyI18n}.modal.visualize`)}
                  onClick={() => handleConfirm(notice)}
                />
              </td>
            </tbody>
          ))
        }
      </Table>
    </div>
  );
}
