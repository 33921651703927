import { MultipleActionStep } from '../@types/backgroundActions';

export function buildMultipleActionsList(
  actions: MultipleActionStep,
  currentStep: string,
): MultipleActionStep {
  const data = actions.data.filter((item) => item.name !== currentStep);
  return {
    data,
    currentStep: data[0].name,
  };
}
