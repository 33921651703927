import { useState } from 'react';
import { t } from 'i18next';
import {
  FormControl,
  FormControlLabel, FormLabel, Grid, Radio, RadioGroup,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { OptionType } from '../../../../../@types/config';
import { Button } from '../../../../Button';
import { InputText } from '../../../../InputText';
import Select from '../../../../Select';

import styles from './styles.module.scss';
import ProcedureBoxService from '../../../../../services/procedureBoxService';
import { ApplicantType } from '../../../../../@types/model';
import { ErrorField } from '../../../../ErrorField';
import AddressService from '../../../../../services/addressService';

interface ContentProps {
  setOpen: (open: boolean) => void;
  participations: OptionType[];
  setDataApplicants: (applicants: any) => void;
  dataApplicants: ApplicantType[];
  parentProcedure: number;
}

export function Content(props: ContentProps) {
  const [participationsId, setParticipationsId]: any = useState({});
  const [status, setStatus] = useState<'physicalPerson' | 'legalPerson'>('physicalPerson');
  const [gender, setGender] = useState<'male' | 'female'>('female');
  const [cepError, setCepError] = useState('');

  const {
    handleSubmit, control, setValue,
  } = useForm();

  const loadApplicants = () => {
    ProcedureBoxService.getDependenciesPaymentOrders(props.parentProcedure).then((dep) => {
      props.setDataApplicants(dep.judicial_parts);
    });
  };

  const submit = async (form: any) => {
    if (cepError.length === 0) {
      const data: any = {
        ...form,
        participation_id: participationsId.value,
        gender,
        legalPerson: status,
      };
      ProcedureBoxService.createInterestedPartOrder(props.parentProcedure, data).then(() => {
        loadApplicants();
      });
      props.setOpen(false);
    }
  };

  const handleCepChange = async (event: any) => {
    const cepValue = event.target.value.replace(/\D/g, '');
    setValue('cep', cepValue);
    if (cepValue.length === 8) {
      try {
        setCepError('');
        const {
          logradouro, complemento, bairro, localidade,
        } = await AddressService.getAddressFromCep(cepValue);
        setValue('address', logradouro);
        setValue('complement', complemento);
        setValue('neighborhood', bairro);
        setValue('city', localidade);
      } catch {
        setCepError(t('general.cepError'));
      }
    } else {
      setCepError(t('general.cep'));
    }
  };

  return (
    <form className={styles.container}>
      <Grid
        className={styles.content}
        container
        columns={{ xs: 1, sm: 10, md: 15 }}
        gap={5}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <Select
            className={styles.selectStyles}
            onChange={(part: any) => setParticipationsId(part)}
            label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.part')}
            options={props.participations}
            required
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <FormControl className={styles.selectsContainer}>
            <FormLabel className={styles.label} id="demo-radio-buttons-group-label">
              {t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.type.text')}
            </FormLabel>
            <RadioGroup
              aria-labelledby='radioGroup'
              name='controlledRadioGroup'
              className={styles.radioGroup}
              row
            >
              <FormControlLabel
                value={true}
                onChange={() => setStatus('physicalPerson')}
                checked={status === 'physicalPerson'}
                control={<Radio size='small' color={'primary'}/>}
                className={styles.option}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.type.physicalPerson').toString()}
              />

              <FormControlLabel
                value={false}
                onChange={() => setStatus('legalPerson')}
                checked={status === 'legalPerson'}
                control={<Radio size='small' color={'primary'} />}
                className={styles.option}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.type.legalPerson').toString()}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {
          status === 'physicalPerson' ? (
            <>
               <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
              >
                <Controller
                  control={control}
                  name="cpf"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.CPF')}
                      className={styles.inputText}
                      mask={'999.999.999-99'}
                      required
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
              >
                <Controller
                  control={control}
                  name="rg"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.RG')}
                      className={styles.inputText}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
              >
                <Controller
                  control={control}
                  name="issuing_authority"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.issuingAgency')}
                      className={styles.inputText}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
              >
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.name')}
                      className={styles.inputText}
                      required
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
              >
                <FormControl className={styles.selectsContainer}>
                  <FormLabel className={styles.label} id="demo-radio-buttons-group-label">
                    {t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.gender')}
                  </FormLabel>
                    <RadioGroup
                      aria-labelledby='radioGroup'
                      name='controlledRadioGroup'
                      className={styles.radioGroup}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        onChange={() => setGender('female')}
                        className={styles.option}
                        checked={gender === 'female'}
                        control={<Radio size='small' color='primary' />}
                        label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.sex.female').toString()}
                      />

                      <FormControlLabel
                        value={false}
                        onChange={() => setGender('male')}
                        className={styles.option}
                        checked={gender === 'male'}
                        control={<Radio size='small' color='primary' />}
                        label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.sex.male').toString()}
                      />
                    </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
              >
                <Controller
                  control={control}
                  name="cnpj"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.CNPJ')}
                      className={styles.inputText}
                      mask={'99.999.999/9999-99'}
                      required
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
              >
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.corporateName')}
                      className={styles.inputText}
                      type='text'
                      required
                    />
                  )}
                />
              </Grid>
            </>
          )
        }

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <Controller
            control={control}
            name="cep"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.CEP')}
                mask={'99999-999'}
                className={styles.inputText}
                onChange={handleCepChange}
              />
            )}
          />
          <ErrorField text={cepError} />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.publicPlace')}
                className={styles.inputText}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <Controller
            control={control}
            name="address_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.number')}
                className={styles.inputText}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <Controller
            control={control}
            name="complement"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.complement')}
                className={styles.inputText}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <Controller
            control={control}
            name="neighborhood"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.neighborhood')}
                className={styles.inputText}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
        >
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.county')}
                className={styles.inputText}
              />
            )}
          />
        </Grid>
      </Grid>
      <footer>
        <Button
          className={styles.buttonLeft}
          title={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.buttons.cancel')}
          buttonType='default'
          onClick={() => props.setOpen(false)}
          size='flat'
          round
        />

        <Button
          onClick={handleSubmit(submit)}
          title={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.buttons.addPart')}
          buttonType='primary'
          size='flat'
          round
        />
      </footer>
    </form>
  );
}
