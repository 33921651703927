import { FaFolderOpen } from 'react-icons/fa';
import { t } from 'i18next';
import { useMediaQuery } from 'react-responsive';
import { Procedure } from '../../@types/model';
import styles from './styles.module.scss';
import { RoundButton } from '../RoundButton';

type DocVisualizerProps = {
  procedure: Procedure;
  isDetails?: boolean;
};

export function DocVisualizerButton(props: DocVisualizerProps) {
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const handleRedirectPageFolder = (event: any) => {
    window.open(
      `/digital_folder/${props.procedure.id}/default`,
      '_blank',
    );
    event.stopPropagation();
  };

  const getSize = () => {
    if (props.isDetails) {
      return 'small';
    }
    if (isMobile) {
      return 'intermediate';
    }
    return 'medium';
  };

  return (
    <div className={styles.container}>
      <RoundButton
        className={ styles.button }
        onClick={(event: any) => handleRedirectPageFolder(event)}
        icon={<FaFolderOpen/>}
        tooltip={t('procedureBox.procedureList.digitalFolder')}
        size={getSize()}
      />
  </div>
  );
}
