import { Modal } from '@mui/material';
import { t } from 'i18next';
import { Content } from './Content';
import styles from './styles.module.scss';
import { OptionType } from '../../../SelectFilter';
import { ApplicantType } from '../../../../@types/model';

type ApplicantsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  participations: OptionType[];
  dataApplicants: ApplicantType[];
  setDataApplicants: (applicants: any) => void;
  parentProcedure: number;
};

export function ApplicantsModal(props: ApplicantsModalProps) {
  return (
    <Modal
      open={props.open}
      disableAutoFocus={true}
    >
      <div
        className={styles.content}
      >
        <div className={styles.head}>
          <span>{t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.addApplicants.title')}</span>
        </div>
        <div className={styles.body}>
          <Content
            setOpen={props.setOpen}
            dataApplicants={props.dataApplicants}
            setDataApplicants={props.setDataApplicants}
            participations={props.participations}
            parentProcedure={props.parentProcedure}
          />
        </div>
      </div>
    </Modal>
  );
}
