import { Skeleton } from '@mui/material';
import styles from './styles.module.scss';

export function LoadingList() {
  return (
    <div className={ styles.container }>
      {[...Array(10)].map((row) => (
        <Skeleton variant={'rectangular'} className={ styles.row }/>
      ))}
    </div>
  );
}
