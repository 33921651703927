import { FormControlLabel, Radio, RadioGroup as RadioGroupMui } from '@mui/material';
import { OptionType } from '../../@types/config';
import { Label } from '../Label';
import styles from './styles.module.scss';

type RadioGroupProps = {
  dataCy?: string;
  label: string;
  options: OptionType[];
  value?: any;
  onChange: (e: any) => void;
  notDefaultOptions?: boolean;
};

export function RadioGroup(props: RadioGroupProps) {
  return (
    <div className={ styles.container }>
      {props.label && <Label text={props.label} required={!props.notDefaultOptions}/> }
      <RadioGroupMui
        defaultValue={props.notDefaultOptions ? undefined : (props.value ? props.value : props.options[0].value)}
        onChange={(e) => props.onChange(e)}
        row
      >
        {
          props.options.map((option, index) => (
            <FormControlLabel
              data-cy={`${props.dataCy}[${index}]`}
              classes={{ label: styles.label }}
              value={option.value}
              control={
                <Radio
                  size='small'
                  classes={{ colorPrimary: styles.radio }}
                />
              }
              label={option.label}
            />
          ))
        }
      </RadioGroupMui>
    </div>
  );
}
