import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/authService';
import { CurrentUser } from '../@types/currentUser';
import { getCookie } from '../config/cookies';
import { EPGM_LOGIN_SCREEN } from '../config/constants';

interface AuthContextData {
  signed: boolean;
  user: CurrentUser | null;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<CurrentUser | null>(null);
  const navigate = useNavigate();

  async function SignIn() {
    try {
      const res = await AuthService.getCurrentUser();
      const token = (!EPGM_LOGIN_SCREEN && localStorage.getItem('currentUserToken'));

      if (res && (getCookie('_EPGM_uid') || token)) {
        localStorage.setItem('currentUser', JSON.stringify(res));
        setUser(res);
      } else {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserToken');
        setUser(null);
      }
    } catch (error) {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserToken');
      setUser(null);
      if (!window.location.pathname.includes('forgot_password')) {
        navigate('/');
      }
    }
  }

  useEffect(() => {
    SignIn();
  }, []);

  return (
    <AuthContext.Provider value={{ signed: Boolean(localStorage.getItem('currentUser')), user }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
