import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryContext } from '../../../../context/queryContext';
import { Button } from '../../../Button';
import styles from './styles.module.scss';

type FilterButtonsProps = {
  closeFilter: () => void;
};

export function FilterButtons(props: FilterButtonsProps) {
  const { t } = useTranslation();

  const { updateQuery, clearState, advancedFiltersData } = useContext(QueryContext);

  const verifyFilters = () => {
    const entries = Object.entries(advancedFiltersData).filter(([_, v]) => v.length >= 1);
    if (entries !== null && entries.length >= 1) {
      updateQuery();
    }

    props.closeFilter();
  };

  return (
    <div className={`${styles.container}`}>
      <Button
        title={t('procedureBox.filterAdvanced.clearFilter')}
        buttonType='default'
        size='flat'
        round
        onClick={clearState}
      />
      <Button
        title={t('procedureBox.filterAdvanced.filter')}
        buttonType='primary'
        size='flat'
        round
        onClick={verifyFilters}
      />
    </div>
  );
}
