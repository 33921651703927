import { createTheme } from '@mui/material/';

export const menuTheme = (createTheme as any)({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0px !important',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          marginTop: '5px !important',
        },
      },
    },
  },
});
