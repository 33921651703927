import { OptionType, TramitRequest } from '../@types/config';

const emptyValue = { label: '', value: null };
export function parseInstitution(item: TramitRequest | undefined): OptionType {
  const defaultInstitution = { label: 'Procuradoria Geral do Município de Fortaleza', value: 1 };
  if (item) {
    return { value: item.individual.institution_id, label: item.individual.institution_name };
  }
  return defaultInstitution;
}

export function parseField(item: TramitRequest | undefined): OptionType {
  if (item) {
    return { value: item.individual.field_id, label: item.individual.field_name };
  }
  return emptyValue;
}

export function parseIndividual(item: TramitRequest | undefined): OptionType {
  if (item) {
    return { value: item.individual.individual_id, label: item.individual.individual_name };
  }
  return emptyValue;
}
