import { FaChevronRight } from 'react-icons/fa';
import { translateRoute, isValidRoute } from '../../helpers/routeTranslate';
import styles from './styles.module.scss';

type BreadcumbProps = {
  pathnames: string[]
};

export function Breadcumb(props: BreadcumbProps) {
  const paths = props.pathnames.filter((pathItem) => isValidRoute(pathItem));
  const breadcumbLength: number = paths.length;

  return (
    <div className={styles.breadcumbContainer}>
      <a href='/'>Home</a>
      {
        paths.map((value, index) => {
          const routeTo = `/${props.pathnames.slice(0, index + 1).join('/')}`;
          const lastItem: Boolean = breadcumbLength === index + 1;
          if (lastItem) {
            return (
              <div className={styles.breadcumLastItem}>
                <FaChevronRight />
                <p> {translateRoute(value)}</p>
              </div>
            );
          }
          return (
              <div className={styles.breadcumItem}>
                <FaChevronRight />
                <a href={routeTo} data-cy={`cypress-breadcumb-${value}`}>
                  {translateRoute(value)}
                </a>
              </div>
          );
        })}
    </div>
  );
}
