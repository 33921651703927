import { useState, useEffect, useContext } from 'react';
import { t } from 'i18next';
import {
  ThemeProvider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material/';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Button } from '../../Button';
import styles from './styles.module.scss';
import { customTheme } from './customTheme';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { ProcedureCarousel } from '../ProcedureCarousel';
import { Description as DescriptionType } from '../../../@types/config';
import Show from '../../Show';
import { TextArea } from '../../TextArea';

interface DescriptionProps {
  isMobile: boolean;
}

export function Description(props: DescriptionProps) {
  const {
    descriptions,
    setDescriptions,
    setIpmStateSelected,
    setStateDescription,
    proceduresSeleted,
  } = useContext(ProcedureActionsContext);
  const [typeDispatch, setTypeDispatch] = useState('');
  const [legalAnalysis, setLegalAnalysis] = useState('');
  const [report, setReport] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [question, setQuestion] = useState('');
  const [typeDispatchSelected, setTypeDispatchSelected] = useState('');
  const [legalAnalysisSelected, setLegalAnalysisSelected] = useState('');
  const [reportSelected, setReportSelected] = useState('');
  const [conclusionSelected, setConclusionSelected] = useState('');
  const [procedureId, setProcedureId] = useState<number>();
  const [openCarousel, setOpenCarousel] = useState(true);
  const [openDescripton, setOpenDescription] = useState(true);
  const isIndividual = proceduresSeleted.length === 1;

  useEffect(() => {
    if (isIndividual) {
      setQuestion('false');
    }
  }, [isIndividual]);

  useEffect(() => {
    if (typeDispatch !== '' && legalAnalysis !== '' && report !== '' && conclusion !== '' && question !== '') {
      setDisabled(false);
      setStateDescription(true);
    } else {
      setDisabled(true);
      setStateDescription(false);
    }
  }, [typeDispatch, legalAnalysis, report, conclusion, question]);

  const fillForm = (item: DescriptionType, defaultParam: boolean) => {
    if (defaultParam) {
      setTypeDispatch(item.typeDispatch);
      setLegalAnalysis(item.legalAnalysis);
      setReport(item.report);
      setConclusion(item.conclusion);
      setQuestion(item.default?.toString() || '');
    } else {
      setTypeDispatchSelected(item.typeDispatch);
      setLegalAnalysisSelected(item.legalAnalysis);
      setReportSelected(item.report);
      setConclusionSelected(item.conclusion);
    }
  };

  const updateDescription = () => {
    const data = descriptions.filter((desc) => desc.procedureId !== procedureId);
    const descriptionPersonalize = {
      typeDispatch: typeDispatchSelected,
      legalAnalysis: legalAnalysisSelected,
      conclusion: conclusionSelected,
      report: reportSelected,
      procedureId,
      default: false,
    };

    setDescriptions([...data, descriptionPersonalize]);
  };

  const handleQuestion = (value: string) => {
    setQuestion(value);
    const data: any = [];
    proceduresSeleted.map((procedure) => {
      const descriptionDefault = {
        typeDispatch,
        legalAnalysis,
        conclusion,
        report,
        procedureId: procedure.id,
        default: true,
      };

      data.push(descriptionDefault);
    });

    setDescriptions(data);
  };

  const handleCreateDescription = () => {
    if (isIndividual) {
      handleQuestion('false');
    } else {
      updateDescription();
    }
    setIpmStateSelected('view');
  };

  const handleProcedure = (procedureIdParam: number) => {
    setProcedureId(procedureIdParam);
    const descriptionSelected = descriptions.find((desc) => desc.procedureId === procedureIdParam);
    if (descriptionSelected) {
      fillForm(descriptionSelected, false);

      if (procedureId) {
        updateDescription();
      }
    }
  };

  useEffect(() => {
    const defaultObject = descriptions.find((desc) => desc.default === true);
    if (defaultObject) {
      fillForm(defaultObject, true);
    }
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h3>{t('procedureBox.actions.ipm.forwardIPM.modal.description.default')}</h3>

          <div onClick={() => setOpenDescription(!openDescripton)}>
            {openDescripton ? <FaChevronUp className={styles.icon} /> : <FaChevronDown className={styles.icon} />}
          </div>
        </div>
        <Show if={openDescripton}>
          <FormControl>
            <FormLabel className={styles.label} id="radioGroup">
              {t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.title')} *
            </FormLabel>
            <RadioGroup
              aria-labelledby="radioGroup"
              name="controlledRadioGroup"
              value={typeDispatch}
              onChange={(e) => setTypeDispatch(e.target.value)}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.deferment').toString()}/>

              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.deferralReservation').toString()} />

              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.rejection').toString()} />

              <FormControlLabel
                value="3"
                control={<Radio />}
                label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.diligence').toString()} />
            </RadioGroup>
          </FormControl>
          <TextArea
            classNameContainer={styles.input}
            label={t('procedureBox.actions.ipm.forwardIPM.modal.description.legalAnalysis.title')}
            required
            onChange={(event) => { setLegalAnalysis(event.target.value); }}
            value={legalAnalysis}
            placeholder={t('procedureBox.actions.ipm.forwardIPM.modal.description.legalAnalysis.placeholder')} />

          <TextArea
            classNameContainer={styles.input}
            label={t('procedureBox.actions.ipm.forwardIPM.modal.description.report.title')}
            required
            onChange={(event) => { setReport(event.target.value); }}
            value={report}
            placeholder={t('procedureBox.actions.ipm.forwardIPM.modal.description.report.placeholder')} />

          <TextArea
            classNameContainer={styles.input}
            label={t('procedureBox.actions.ipm.forwardIPM.modal.description.conclusion.title')}
            required
            onChange={(event) => { setConclusion(event.target.value); }}
            value={conclusion}
            placeholder={t('procedureBox.actions.ipm.forwardIPM.modal.description.conclusion.placeholder')} />

          <Show if={!isIndividual}>
            <FormControl>
              <FormLabel className={styles.label} id="radioGroup">
                {t('procedureBox.actions.ipm.forwardIPM.modal.description.question.title')} *
              </FormLabel>
              <RadioGroup
                aria-labelledby="radioGroup"
                name="controlledRadioGroup"
                value={question}
                onChange={(e) => handleQuestion(e.target.value)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('procedureBox.actions.ipm.forwardIPM.modal.description.question.yes').toString()}/>

                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t('procedureBox.actions.ipm.forwardIPM.modal.description.question.no').toString()} />
              </RadioGroup>
            </FormControl>
          </Show>
        </Show>
      </div>
      <Show if={question === 'true'}>
        <div className={styles.title}>
          <h3>{t('procedureBox.actions.ipm.forwardIPM.modal.description.personalize.title')}</h3>

          <div onClick={() => setOpenCarousel(!openCarousel)}>
            {openCarousel ? <FaChevronUp className={styles.icon} /> : <FaChevronDown className={styles.icon} />}
          </div>
        </div>
        <Show if={openCarousel}>
          <span className={styles.subTitle}>
            {t('procedureBox.actions.ipm.forwardIPM.modal.description.personalize.subTitle')}
          </span>
          <div className={styles.proceduresList}>
            <ProcedureCarousel procedures={ proceduresSeleted } isMobile={props.isMobile} onClick={(value) => handleProcedure(value)} />
          </div>
          <Show if={procedureId !== undefined}>
            <div className={styles.container}>
              <FormControl>
                <FormLabel className={styles.label} id="radioGroup">
                  {t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.title')} *
                </FormLabel>
                <RadioGroup
                  aria-labelledby="radioGroup"
                  name="controlledRadioGroup"
                  value={typeDispatchSelected}
                  onChange={(e) => setTypeDispatchSelected(e.target.value)}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.deferment').toString()}/>

                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.deferralReservation').toString()} />

                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.rejection').toString()} />

                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label={t('procedureBox.actions.ipm.forwardIPM.modal.description.typeDispatch.diligence').toString()} />
                </RadioGroup>
              </FormControl>

              <TextArea
                classNameContainer={styles.input}
                label={t('procedureBox.actions.ipm.forwardIPM.modal.description.legalAnalysis.title')}
                required
                onChange={(event) => { setLegalAnalysisSelected(event.target.value); }}
                value={legalAnalysisSelected}
                placeholder={t('procedureBox.actions.ipm.forwardIPM.modal.description.legalAnalysis.placeholder')} />

              <TextArea
                classNameContainer={styles.input}
                label={t('procedureBox.actions.ipm.forwardIPM.modal.description.report.title')}
                required
                onChange={(event) => { setReportSelected(event.target.value); }}
                value={reportSelected}
                placeholder={t('procedureBox.actions.ipm.forwardIPM.modal.description.report.placeholder')} />

              <TextArea
                classNameContainer={styles.input}
                label={t('procedureBox.actions.ipm.forwardIPM.modal.description.conclusion.title')}
                required
                onChange={(event) => { setConclusionSelected(event.target.value); }}
                value={conclusionSelected}
                placeholder={t('procedureBox.actions.ipm.forwardIPM.modal.description.conclusion.placeholder')} />
            </div>
          </Show>
        </Show>
      </Show>

      <div className={styles.footer}>
        <Button
          title={t('procedureBox.actions.ipm.forwardIPM.modal.nextStage.text')}
          buttonType='primary'
          disabled={disabled}
          size='flat'
          round
          onClick={handleCreateDescription}
        />
      </div>
    </ThemeProvider>
  );
}
