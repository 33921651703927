import { useContext, useState } from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { FaCheckSquare, FaTelegramPlane } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { RoundButton } from '../../RoundButton';
import styles from './styles.module.scss';
import { QueryContext } from '../../../context/queryContext';
import { LastTramit } from '../../../@types/model';
import { ButtonMenu } from '../ButtonMenu';

type TramitBoxProps = {
  procedureId: number;
  instruction: LastTramit;
};

export function TramitInfos(props: TramitBoxProps) {
  const { markInstructionAsFinished } = useContext(QueryContext);
  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLastTramit = () => {
    const date = moment(props.instruction?.created_at).format('DD/MM');
    return (
      <Trans
        i18nKey='procedureBox.observations.createdBy'
        tOptions={{ interpolation: { escapeValue: false } }}
        values={{ individual_name: props.instruction?.tramit_by, tramit_date: date }}
      />
    );
  };

  const handleMarkAsFinished = (e: any) => {
    if (props.instruction?.id) {
      markInstructionAsFinished(props.procedureId, props.instruction?.id, !props.instruction?.finished);
      setAnchorEl(null);
      e.stopPropagation();
    }
  };

  const handleOpened = (e: any) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClosed = (e: any) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleBadge = (tramit: LastTramit) => {
    if (props.instruction?.was_attach_document) {
      return undefined;
    }
    return Boolean(tramit?.observation) && !tramit?.finished ? 1 : undefined;
  };

  return (
    <div className={styles.container}>
      <RoundButton
        icon={ <FaTelegramPlane/> }
        className={styles.button}
        tooltip={t('procedureBox.observations.tramitObservations')}
        size={isMobile ? 'intermediate' : 'medium'}
        onClick={(e) => { handleOpened(e); }}
        badge={handleBadge(props.instruction)}
      />
      <ButtonMenu
        anchorEl={anchorEl}
        handleClosed={handleClosed}
        id='tramit-content'
      >
        <div className={styles.tramitContent}>
          {
            props.instruction?.individual_name ? (
              <div className={styles.tramitContent}>
                <div className={styles.header}>
                  <span>{handleLastTramit()}</span>
                </div>
                <span className={styles.observation}>{props.instruction?.observation}</span>
                <div className={`${styles.action} ${props.instruction.finished ? styles.done : ''}`}>
                  <FaCheckSquare onClick={(e) => handleMarkAsFinished(e)} />
                  <span>{t(`procedureBox.observations.${props.instruction.finished ? 'done' : 'markAsFinished'}`)}</span>
                </div>
              </div>
            ) : (
              <span className={ styles.emptyText }>{t('procedureBox.observations.empty')}</span>
            )
          }
        </div>
      </ButtonMenu>
    </div>
  );
}
