import { Grid } from '@mui/material';
import { t } from 'i18next';
import { FaPlus } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Marker } from '../../../@types/model';
import { MarkerOption } from '../../ProcedureList/Markers/Menu/MarkerItem/MarkerOption';
import { Button } from '../../Button';
import Show from '../../Show';

type MarkersListProps = {
  markers: Marker[];
  selectedMarkers: Marker[];
  procedureId: number;
  openMarkerModal: (type: 'edit' | 'create') => void;
  addSelectedMarker: (value: Marker) => void;
  removeSelectedMarker: (value: Marker) => void;
};

export function MarkersList(props: MarkersListProps) {
  return (
    <Grid
      container
      columns={{ xs: 1, sm: 9, md: 9 }}
      className={styles.container}
    >
      <Show if={props.selectedMarkers.length > 0}>
        {props.selectedMarkers.map((marker: Marker) => (
          <Grid
            item
            xs={1}
            sm={3}
            md={3}
            onClick={() => props.removeSelectedMarker(marker)}
          >
            <div className={`${styles.marker} ${styles.selectedMarker}`}>
              <MarkerOption
                onChange={() => {}}
                marker={marker}
                expanded={true}
                procedureId={props.procedureId}
                showTotalProcedures={true}
                truncate={28}
              />
            </div>
          </Grid>
        ))}
      </Show>
      <Show if={props.markers.length > 0}>
        {props.markers.map((marker: Marker) => (
          <Grid
            item
            xs={1}
            sm={3}
            md={3}
            onClick={() => props.addSelectedMarker(marker)}
          >
            <div className={styles.marker}>
              <MarkerOption
                onChange={() => {}}
                marker={marker}
                expanded={true}
                procedureId={props.procedureId}
                showTotalProcedures={true}
                truncate={28}
              />
            </div>
          </Grid>
        ))}
      </Show>
      <Show if={props.markers.length === 0 && props.selectedMarkers.length === 0}>
        <Grid item xs={1} sm={9} md={9}>
          <div className={styles.notFound}>
            <span>{t('procedureBox.markersSelect.notFound')}</span>
          </div>
        </Grid>
      </Show>
      <Grid item xs={1} sm={9} md={9}>
        <Button
          onClick={() => props.openMarkerModal('create')}
          title={t('procedureBox.markers.newMarker')}
          icon={<FaPlus />}
        />
      </Grid>
    </Grid>
  );
}
