import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { SelectedProcedures } from '../SelectedProcedures';
import { TextArea } from '../TextArea';
import styles from './styles.module.scss';

type UnarchiveProps = {
  multipleActions?: MultipleActionStep;
};

export function Unarchive(props: UnarchiveProps) {
  const [observation, setObservation] = useState('');
  const { proceduresSeleted, setAlert, setModal } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.unarchive.modal.alertModal.multiple.title'
          components={{ br: <br /> }}
          values={{ number: proceduresSeleted.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.unarchive.modal.alertModal.single.title'
        components={{ br: <br /> }}
        values={{ number: proceduresSeleted[0].process_number }}
      />
    );
  };

  const renderText = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
        i18nKey='procedureBox.actions.unarchive.modal.alertModal.multiple.text'
          components={{ bold: <strong /> }}
          values={{ number: proceduresSeleted.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.unarchive.modal.alertModal.single.text'
        components={{ span: <span style={{ textDecoration: 'underline' }} /> }}
        values={{ number: proceduresSeleted[0].process_number }}
      />
    );
  };

  const handleUnarchive = () => {
    const body = {
      observation,
    };

    const procedures = proceduresSeleted;
    const type = 'unarchive';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };

    addBatch(batch);
    setAlert(undefined);
    setModal(undefined);
  };

  const handleConfirm = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleUnarchive(),
      title: renderTitle(),
      text: renderText(),
      type: 'warning',
      confirmText: t('procedureBox.actions.forceTramit.modal.modal_alert.buttonConfirm'),
      cancelText: t('procedureBox.actions.forceTramit.modal.modal_alert.buttonCancel'),
    });
  };

  return <div className={ styles.container }>
    <SelectedProcedures />

    <div className={styles.header}>
      <span className={styles.title}>{ t('procedureBox.actions.unarchive.title')}</span>
    </div>

    <div className={styles.form}>
      <div className={styles.inputTextArea}>
        <TextArea
          onChange={(event) => { setObservation(event.target.value); }}
          label={t('procedureBox.actions.unarchive.label')}
          placeholder={t('procedureBox.actions.unarchive.placeholder')} />
      </div>
      <div className={styles.button}>
        <Button
          title={t('procedureBox.actions.unarchive.button')}
          disabled={observation === ''}
          onClick={() => {
            handleConfirm();
          } }
          buttonType='primary'
          round/>
      </div>
    </div>
  </div>;
}
