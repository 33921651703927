import { t } from 'i18next';
import { useContext } from 'react';
import { FaPlus } from 'react-icons/fa';
import { DropdownButton } from '../../../components/DropdownButton';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';

export function NewProcedureButton() {
  const { openNewProcedure, openNewCitationIntimation } = useContext(ProcedureActionsContext);

  return (
    <DropdownButton
      title={ t('procedureBox.newProcedure.button') }
      icon={ <FaPlus /> }
      items={
        [
          { label: t('procedureBox.newProcedure.newProcedure'), onClick: () => { openNewProcedure(); }, dataCy: 'cypress-new-procedure' },
          { label: t('procedureBox.newProcedure.citationIntimation.title'), onClick: () => { openNewCitationIntimation(); } },
        ]}
      dataCy={'cypress-new-procedure-button'}
    />
  );
}
