import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { TramitRequest } from '../../@types/config';
import { Individual, Procedure } from '../../@types/model';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { SelectedProcedures } from '../SelectedProcedures';
import { Form } from './Form';
import styles from './styles.module.scss';
import { Suggestions } from './Suggestions';
import GeneralService from '../../services/generalService';
import Show from '../Show';
import ProcedureBoxService from '../../services/procedureBoxService';
import { QueryContext } from '../../context/queryContext';

type TramitProcedureProps = {
  closeModal: () => void;
  tramitForm?: TramitRequest | undefined;
  multipleActions?: MultipleActionStep;
  isCitationIntimation?: boolean;
};

export function TramitProcedure(props: TramitProcedureProps) {
  const [suggestion, setSuggestion] = useState<Individual | undefined>();
  const [form, setForm] = useState<TramitRequest | undefined>();
  const [formContent, setFormContent] = useState<TramitRequest | undefined>();
  const {
    proceduresSeleted,
    setAlert,
    removeProcedures,
    citationIntimationSelected,
    addToasts,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const { loadData: loadDataQuery } = useContext(QueryContext);

  const getIndividual = () => {
    if (suggestion) {
      return { individual: suggestion.individual_name, jobTitle: suggestion.function_name };
    }
    return { individual: form?.individual.individual_name, jobTitle: form?.individual.function_name };
  };

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.tramit.modal.alertConfirmation.titleManyProcedures'
          components={{ bold: <strong /> }}
          values={{ number: proceduresSeleted.length }}
        />
      );
    }
    if (props.isCitationIntimation) {
      return (
        <Trans
        i18nKey='procedureBox.actions.citation_intimation.tramit.alert.title'
        components={{ bold: <strong /> }}
        values={{ citationIntimationSubject: citationIntimationSelected?.process_number }}
      />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.tramit.modal.alertConfirmation.title'
        components={{ bold: <strong /> }}
        values={{ number: proceduresSeleted[0].process_number }}
      />
    );
  };

  const renderText = (tramitForm?: TramitRequest | undefined) => {
    const { individual, jobTitle } = getIndividual();
    const individualName = tramitForm?.individual.individual_name || individual;
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.tramit.modal.alertConfirmation.textManyProcedures'
          components={{ bold: <strong /> }}
          values={{
            number: proceduresSeleted.length,
            individualName,
            jobTitle,
          }}
        />
      );
    }
    if (props.isCitationIntimation) {
      return (
        <Trans
          i18nKey='procedureBox.actions.citation_intimation.tramit.alert.subTitle'
          components={{ bold: <strong /> }}
          values={{ fieldName: tramitForm?.individual.field_name }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.tramit.modal.alertConfirmation.text'
        components={{ bold: <strong /> }}
        values={{ number: proceduresSeleted[0].process_number, individualName, jobTitle }}
      />
    );
  };

  const sendNotification = (procedures: Procedure[], individual: Individual) => {
    GeneralService.createTramitNotifications(procedures.map((p) => { return p.id; }), individual.individual_id);
  };

  const handleConfirm = (tramitForm?: TramitRequest | undefined) => {
    if (props.isCitationIntimation && tramitForm && citationIntimationSelected) {
      ProcedureBoxService.tramitCitationIntimation(citationIntimationSelected?.id, tramitForm.individual.field_id)
        .then((response) => {
          if (response.status === 200) {
            addToasts({
              type: 'success',
              text: t('procedureBox.actions.citation_intimation.tramit.toast.success'),
            });
          } else {
            addToasts({
              type: 'error',
              text: t('procedureBox.actions.citation_intimation.tramit.toast.error'),
            });
          }
        });
      setAlert(undefined);
      props.closeModal();
      setTimeout(() => {
        loadDataQuery();
      }, 1500);
    }
    if (!props.isCitationIntimation && tramitForm) {
      const procedures = proceduresSeleted;
      const type = 'tramit';
      const id = (Math.random() + 1).toString(36).substring(1);
      const { individual, observation } = tramitForm;
      const batch: Batch = {
        id,
        procedures,
        type,
        successList: [],
        failedList: [],
        count: procedures.length,
        formContent,
        body: {
          institution_id: individual.institution_id,
          field_id: individual.field_id,
          individual_id: individual.individual_id,
          individual_name: individual.individual_name,
          observation,
        },
        multipleActions: props.multipleActions,
      };
      addBatch(batch);
      if (!props.multipleActions) {
        removeProcedures(procedures);
      }
      sendNotification(procedures, individual);
      setAlert(undefined);
      props.closeModal();
    }
  };

  const showAlert = (tramitForm?: TramitRequest | undefined) => {
    setAlert({
      visible: true,
      handleConfirm: () => handleConfirm(tramitForm),
      title: renderTitle(),
      text: renderText(tramitForm),
    });
  };

  useEffect(() => {
    if (form) {
      showAlert();
    }
  }, [form]);

  const handleForm = (request?: TramitRequest | undefined) => {
    if (props.isCitationIntimation) {
      showAlert(request);
    } else if (request) {
      setForm(request);
      setFormContent(request);
      showAlert(request);
    }
  };

  return (
    <div className={ styles.container }>
      <SelectedProcedures />
      <Show if={!props.isCitationIntimation}>
        <Suggestions
          onChange={(ind) => setSuggestion(ind)}
          selected={props.tramitForm?.individual || suggestion}
        />
      </Show>
      <Form
        onChange={() => setSuggestion(undefined)}
        suggestion={props.tramitForm?.individual || suggestion}
        handleConfirm={(request) => handleForm(request)}
        tramitForm={props.tramitForm}
        isCitationIntimationForm={props.isCitationIntimation}
      />
    </div>
  );
}
