import { t } from 'i18next';
import { useContext } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Alert } from '../Alert';

export function CreateDocumentWaiting() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div>
      { batches?.filter((el) => el.status !== 'closed' && el.body.waiting && el.type === 'prepareDocument').map((batch) => (
        <Alert
          icon={<FaPaperPlane />}
          title={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.alertWaiting.title')}
          alertText={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.alertWaiting.alertText.prepare')}
          waiting={true}
          text={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.alertWaiting.text')}
          batch={batch}
        />
      ))}
    </div>
  );
}
