import styles from './styles.module.scss';

type SwitchButtonProps = {
  leftText: string;
  rightText: string;
  selected: number;
  leftOnClick: () => void;
  rightOnClick: () => void;
};

export function SwitchButton(props: SwitchButtonProps) {
  return (
    <div className={styles.container}>
      <div className={`${styles.button} ${props.selected === 0 ? styles.selected : ''}`} onClick={props.leftOnClick}>
        <p className={styles.text}>{props.leftText}</p>
      </div>
      <div className={`${styles.button} ${props.selected === 1 ? styles.selected : ''}` } onClick={props.rightOnClick}>
        <p className={styles.text}>{props.rightText}</p>
      </div>
    </div>
  );
}
