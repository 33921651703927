import { useEffect } from 'react';
import { EPGM_URL } from '../../../config/constants';
import { IFrame } from '../../../components/IFrame';
import { loadTitle } from '../../../helpers/changeTitle';

type DashboardProps = {
  title?: string;
};

export function Dashboard(props: DashboardProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/processos_digitais/dashboard`}/>
  );
}
