import { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaTag } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { MarkersFixedValues } from './MarkersFixedValues';
import styles from './styles.module.scss';

export function MarkersSelect() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const renderContent = () => {
    if (isOpen) {
      return (
        <div>
          <MarkersFixedValues
            type='priority'
          />
          <MarkersFixedValues
            type='pendency'
          />
          <MarkersFixedValues
            type='custom'
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={ styles.container }>
      <div className={ styles.title } onClick={ () => setIsOpen(!isOpen) }>
        { isOpen ? <FaChevronUp className={ styles.chevron }/> : <FaChevronDown className={ styles.chevron }/> }
        <FaTag />
        <span>{ t('procedureBox.markersSelect.title') }</span>
      </div>

      { renderContent() }
    </div>
  );
}
