import { FormControlLabel, Grid, Radio } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { InputText } from '../../../../../components/InputText';
import { RadioGroup } from '../../../../../components/RadioGroup';
import { RoundButton } from '../../../../../components/RoundButton';
import { Gender, PersonTypes } from '../../../../../helpers/enums';
import styles from './styles.module.scss';
import { isCnpj, isCpf } from '../../../../../helpers/validator';
import FormHelper from '../../../../../helpers/form';
import AddressService from '../../../../../services/addressService';
import { Errors, OptionType } from '../../../../../@types/config';
import Select from '../../../../../components/Select';
import { Label } from '../../../../../components/Label';
import Show from '../../../../../components/Show';

type FormProps = {
  passive?: boolean;
  id: number;
  options: OptionType[];
  control: any;
  setValue: (name: string, value: string) => void;
  setErrors: (errors: Errors) => void;
  errors: any;
  unregister: (name: string) => void;
  delete: () => void;
  prefix?: string;
  interestedParts?: any;
  partType?: string;
  flux?: number;
  isUniq?: boolean;
  isProurma?: boolean;
  partId?: number;
  dataCy?: string;
};

export function Form(props: FormProps) {
  const prefix = props.prefix ? props.prefix : 'interested_parts_attributes';
  const [complement, setComplement] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [addressNumberDisabled, setAddressNumberDisabled] = useState<boolean>(false);
  const [documentDisabled, setDocumentDisabled] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState();

  const definyType = () => {
    let defineType = PersonTypes()[0].value;
    if (props.interestedParts) {
      if (props.interestedParts.length !== 0) {
        props.interestedParts.map((interestedPart: any) => {
          if ((props.interestedParts[0]?.cpf?.length === 0 && props.interestedParts[0]?.rg !== t('procedureBox.newProcedure.interestedParts.form.noDocument.label')) || null) {
            defineType = PersonTypes()[1].value;
          } else {
            defineType = PersonTypes()[0].value;
          }
        });
      } else if ((props.interestedParts[0]?.cpf?.length === 0 && props.interestedParts[0]?.rg !== t('procedureBox.newProcedure.interestedParts.form.noDocument.label')) || null) {
        return PersonTypes()[1].value;
      } else {
        return PersonTypes()[0].value;
      }
    } else {
      return PersonTypes()[0].value;
    }
    return defineType;
  };

  const defyneGender = () => {
    const partData = props.interestedParts[0];
    if (partData) {
      return partData.gender;
    }
    return 'female';
  };

  const [type, setType] = useState(definyType());

  useEffect(() => {
    props.setValue(`${prefix}[${props.id}].participation_id`, selectedOption || props.options?.[0]?.value);
  }, []);

  useEffect(() => {
    props.setValue(`${prefix}[${props.id}].cpf`, documentDisabled && type === 'cpf' ? t('procedureBox.newProcedure.interestedParts.form.noDocument.label') : '');
    props.setValue(`${prefix}[${props.id}].rg`, documentDisabled && type === 'cpf' ? t('procedureBox.newProcedure.interestedParts.form.noDocument.label') : '');
    props.setValue(`${prefix}[${props.id}].issuing_authority`, documentDisabled && type === 'cpf' ? t('procedureBox.newProcedure.interestedParts.form.noDocument.label') : '');
    props.setValue(`${prefix}[${props.id}].cnpj`, documentDisabled && type === 'cnpj' ? t('procedureBox.newProcedure.interestedParts.form.noDocument.label') : '');
  }, [type, documentDisabled]);

  const handleDelete = () => {
    props.unregister(`${prefix}[${props.id}]`);
    props.delete();
  };

  const returnCep = async (cep: string) => {
    if (cep) {
      const {
        logradouro, complemento, bairro, localidade,
      } = await AddressService.getAddressFromCep(cep);
      setComplement(complemento);
      setAddress(logradouro);
      setNeighborhood(bairro);
      setCity(localidade);

      props.setValue(`${prefix}[${props.id}].complement`, complemento);
      props.setValue(`${prefix}[${props.id}].address`, logradouro);
      props.setValue(`${prefix}[${props.id}].neighborhood`, bairro);
      props.setValue(`${prefix}[${props.id}].city`, localidade);
    }
  };

  const verifyDocument = (documentType: string, document: string) => {
    const attributeName = documentType === 'cpf' ? `${prefix}[${props.id}].cpf` : `${prefix}[${props.id}].cnpj`;
    const isDocumentValid = document === '' ? true : documentType === 'cpf' ? isCpf(document) : isCnpj(document);
    const errorMessage = documentType === 'cpf' ? t('procedureBox.actions.spu.validators.cpf') : t('procedureBox.actions.spu.validators.cnpj');

    if (isDocumentValid) {
      const errorsCopy = { ...props.errors };
      delete errorsCopy[attributeName];
      props.setErrors(errorsCopy);
    } else {
      const newError = { [attributeName]: [errorMessage] };
      props.setErrors({ ...props.errors, ...newError });
    }
  };

  const setPartsValuesCpf = (interestedParts: any) => {
    if (!props.isProurma) {
      props.setValue(`${prefix}[${props.id}].participation_id`, interestedParts.participation?.value);
    }
    props.setValue(`${prefix}[${props.id}].cpf`, interestedParts.cpf);
    props.setValue(`${prefix}[${props.id}].rg`, interestedParts.rg);
    props.setValue(`${prefix}[${props.id}].issuing_authority`, interestedParts.issuing_authority);
    props.setValue(`${prefix}[${props.id}].name`, interestedParts.name);
    props.setValue(`${prefix}[${props.id}].cep`, interestedParts.cep);
    props.setValue(`${prefix}[${props.id}].address_number`, interestedParts.address_number);
    props.setValue(`${prefix}[${props.id}].gender`, interestedParts.gender);
    if (interestedParts.cep && !interestedParts.address) {
      returnCep(interestedParts.cep);
    } else {
      setComplement(interestedParts.complement);
      setAddress(interestedParts.address);
      setNeighborhood(interestedParts.neighborhood);
      setCity(interestedParts.city);

      props.setValue(`${prefix}[${props.id}].complement`, interestedParts.complement);
      props.setValue(`${prefix}[${props.id}].address`, interestedParts.address);
      props.setValue(`${prefix}[${props.id}].neighborhood`, interestedParts.neighborhood);
      props.setValue(`${prefix}[${props.id}].city`, interestedParts.city);
    }
  };

  const setPartsValuesCnpj = (interestedParts: any) => {
    if (interestedParts.cnpj !== t('procedureBox.newProcedure.interestedParts.form.noDocument.label')) {
      props.setValue(`${prefix}[${props.id}].cnpj`, interestedParts.cnpj);
    }
    props.setValue(`${prefix}[${props.id}].participation_id`, interestedParts?.participation?.value);
    props.setValue(`${prefix}[${props.id}].name`, interestedParts.name);
    props.setValue(`${prefix}[${props.id}].cep`, interestedParts.cep);
    props.setValue(`${prefix}[${props.id}].address_number`, interestedParts.address_number);
    returnCep(interestedParts.cep);
  };

  const setInteressedPartsData = () => {
    props.interestedParts.map((interestedParts: any) => {
      if (interestedParts.id === props.partId) {
        if (interestedParts.cpf || interestedParts.rg === t('procedureBox.newProcedure.interestedParts.form.noDocument.label')) {
          setPartsValuesCpf(interestedParts);
        } else {
          setPartsValuesCnpj(interestedParts);
        }

        if (interestedParts.rg || interestedParts.cnpj === t('procedureBox.newProcedure.interestedParts.form.noDocument.label')) {
          setDocumentDisabled(true);
        }

        if (interestedParts.address_number === 'Sem Número') {
          setAddressNumberDisabled(true);
        }
      }
    });
  };

  const notRequidedFluxes = () => {
    if (documentDisabled) {
      return true;
    }
    return [28, 13, 38].includes(props.flux ? props.flux : 0);
  };

  useEffect(() => {
    if (props.interestedParts) {
      setInteressedPartsData();
    }
  }, []);

  const getValue = (value: string | number) => {
    return value;
  };

  const removeValue = (removeType: string, disabled?: boolean) => {
    if (removeType === 'address') {
      props.setValue(`${prefix}[${props.id}].address_number`, disabled ? t('procedureBox.newProcedure.interestedParts.form.noAddressNumber.label') : '');
    } else if (type === 'cpf') {
      props.setValue(`${prefix}[${props.id}].cpf`, disabled ? t('procedureBox.newProcedure.interestedParts.form.noDocument.label') : '');
      props.setValue(`${prefix}[${props.id}].rg`, disabled ? t('procedureBox.newProcedure.interestedParts.form.noDocument.label') : '');
      props.setValue(`${prefix}[${props.id}].issuing_authority`, disabled ? t('procedureBox.newProcedure.interestedParts.form.noDocument.label') : '');
    } else {
      props.setValue(`${prefix}[${props.id}].cnpj`, t('procedureBox.newProcedure.interestedParts.form.noDocument.label'));
    }
  };

  return (
    <div className={ styles.container }>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Show if={!props.isProurma}>
          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={props.control}
              name={`${prefix}[${props.id}].participation_id`}
              render={({ field }) => (
                <Select
                  {...field}
                  dataCy={`${props.dataCy}_interested_part_participation_id`}
                  options={props.options}
                  label={t(`procedureBox.newProcedure.interestedParts.form.${props.isUniq ? 'uniq' : (props.passive ? 'passive' : 'active')}.label`)}
                  placeholder={t('procedureBox.newProcedure.interestedParts.form.passive.placeholder')}
                  defaultValue={props.interestedParts ? props.interestedParts[0]?.participation : props.options?.[0]}
                  onChange={(e) => {
                    setSelectedOption(e?.value);
                    field.onChange(e);
                  }}
                  returnValue
                  required={!props.isProurma}
                />
              )}
            />
          </Grid>
        </Show>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ props.isProurma ? 8 : 4 }
          className={styles.input}
        >
          <RadioGroup
            dataCy={`interested_part_type[${props.id}]`}
            value={definyType()}
            label={t('procedureBox.newProcedure.interestedParts.form.type')}
            onChange={(e) => setType(e.target.value) }
            options={PersonTypes()}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.deleteButton}
        >
          <RoundButton
            icon={<FaTrashAlt />}
            size='small'
            onClick={handleDelete}
          />
        </Grid>
      </Grid>

      <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <FormControlLabel
            value="start"
            className={styles.radio}
            label={
              <Label
                text={t('procedureBox.newProcedure.interestedParts.form.noDocument.label')}
                className={styles.radio}
              />
            }
            control={
              <Radio
                data-cy={`interested_part_not_document[${props.id}]`}
                checked={documentDisabled}
                onClick={() => {
                  setDocumentDisabled(!documentDisabled);
                  removeValue('document', !documentDisabled);
                }}
              />
            }
          />
        </Grid>

      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        {type === 'cpf' ? (
          <>
            <Controller
              control={props.control}
              name={`${prefix}[${props.id}].cnpj`}
              render={({ field }) => (
                <input type="hidden" {...field} value=""/>
              )}
            />
            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
              className={styles.input}
            >
              <Controller
                control={props.control}
                name={`${prefix}[${props.id}].cpf`}
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={`interested_part_cpf[${props.id}]`}
                    label={t('procedureBox.newProcedure.interestedParts.form.cpf.label')}
                    placeholder={t('procedureBox.newProcedure.interestedParts.form.cpf.placeholder')}
                    value={getValue(field.value)}
                    onBlur={(e) => verifyDocument('cpf', e.target.value)}
                    onChange={(e) => field.onChange(e)}
                    mask={documentDisabled ? '' : '999.999.999-99'}
                    required={!documentDisabled}
                  />
                )}
              />
              {FormHelper.renderErrorField(`${prefix}[${props.id}].cpf`, props.errors)}
            </Grid>
            <Grid
              item
              xs={1}
              sm={5}
              md={4}
              className={styles.input}
            >
              <Controller
                control={props.control}
                name={`${prefix}[${props.id}].rg`}
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={`interested_part_rg[${props.id}]`}
                    label={t('procedureBox.newProcedure.interestedParts.form.rg.label')}
                    placeholder={t('procedureBox.newProcedure.interestedParts.form.rg.placeholder')}
                    value={getValue(field.value)}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={5}
              md={4}
              className={styles.input}
            >
              <Controller
                control={props.control}
                name={`${prefix}[${props.id}].issuing_authority`}
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={`interested_part_issuing_authority[${props.id}]`}
                    label={t('procedureBox.newProcedure.interestedParts.form.issuing_authority.label')}
                    placeholder={t('procedureBox.newProcedure.interestedParts.form.issuing_authority.placeholder')}
                    value={getValue(field.value)}
                    onChange={(e) => field.onChange(e)}
                    required={!notRequidedFluxes()}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
              className={styles.input}
            >
              <Controller
                control={props.control}
                name={`${prefix}[${props.id}].name`}
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={`interested_part_name[${props.id}]`}
                    label={t('procedureBox.newProcedure.interestedParts.form.name.label')}
                    placeholder={t('procedureBox.newProcedure.interestedParts.form.name.placeholder')}
                    required
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
              className={styles.input}
            >
              <Controller
                control={props.control}
                name={`${prefix}[${props.id}].gender`}
                defaultValue='female'
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    dataCy={`interested_part_gender[${props.id}]`}
                    label={t('procedureBox.newProcedure.interestedParts.form.gender.label')}
                    options={Gender()}
                    value={defyneGender()}
                    notDefaultOptions={props.isProurma}
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <>
            <Controller
              control={props.control}
              name={`${prefix}[${props.id}].cpf`}
              render={({ field }) => (
                <input type="hidden" {...field} value=""/>
              )}
            />
            <Controller
              control={props.control}
              name={`${prefix}[${props.id}].rg`}
              render={({ field }) => (
                <input type="hidden" {...field} value=""/>
              )}
            />
            <Controller
              control={props.control}
              name={`${prefix}[${props.id}].issuing_authority`}
              render={({ field }) => (
                <input type="hidden" {...field} value=""/>
              )}
            />
            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
              className={styles.input}
            >
              <Controller
                control={props.control}
                name={`${prefix}[${props.id}].cnpj`}
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={`interested_part_cnpj[${props.id}]`}
                    label={t('procedureBox.newProcedure.interestedParts.form.cnpj.label')}
                    placeholder={t('procedureBox.newProcedure.interestedParts.form.cnpj.placeholder')}
                    value={getValue(field.value)}
                    mask={documentDisabled ? '' : '99.999.999/9999-99'}
                    onBlur={(e) => verifyDocument('cnpj', e.target.value)}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    required={!documentDisabled}
                  />
                )}
              />
              {FormHelper.renderErrorField(`${prefix}[${props.id}].cnpj`, props.errors)}
            </Grid>

            <Grid
              item
              xs={ 1 }
              sm={ 5 }
              md={ 4 }
              className={styles.input}
            >
              <Controller
                control={props.control}
                name={`${prefix}[${props.id}].name`}
                render={({ field }) => (
                  <InputText
                    {...field}
                    dataCy={`interested_part_name[${props.id}]`}
                    label={t('procedureBox.newProcedure.interestedParts.form.nameCnpj.label')}
                    placeholder={t('procedureBox.newProcedure.interestedParts.form.nameCnpj.placeholder')}
                    required
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`${prefix}[${props.id}].cep`}
            render={({ field }) => (
              <InputText
                {...field}
                dataCy={`interested_part_cep[${props.id}]`}
                label={t('procedureBox.newProcedure.interestedParts.form.cep.label')}
                placeholder={t('procedureBox.newProcedure.interestedParts.form.cep.placeholder')}
                onBlur={(e) => returnCep(e.target.value)}
                required={!props.isUniq}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`${prefix}[${props.id}].address`}
            render={({ field }) => (
              <InputText
                {...field}
                dataCy={`interested_part_address[${props.id}]`}
                label={t('procedureBox.newProcedure.interestedParts.form.address.label')}
                placeholder={t('procedureBox.newProcedure.interestedParts.form.address.placeholder')}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  field.onChange(e);
                }}
                required={!props.isProurma}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 1 }
          md={ 1 }
          className={styles.input}
        >
          <FormControlLabel
            value="start"
            className={styles.radio}
            label={
              <Label
                text={t('procedureBox.newProcedure.interestedParts.form.noAddressNumber.label')}
                className={styles.radio}
              />
            }
            control={
              <Radio
                data-cy={`interested_part_not_address_number[${props.id}]`}
                checked={addressNumberDisabled}
                onClick={() => {
                  setAddressNumberDisabled(!addressNumberDisabled);
                  removeValue('address', !addressNumberDisabled);
                }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 4 }
          md={ 3 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`${prefix}[${props.id}].address_number`}
            render={({ field }) => (
              <InputText
                {...field}
                dataCy={`interested_part_address_number[${props.id}]`}
                label={t('procedureBox.newProcedure.interestedParts.form.address_number.label')}
                placeholder={t('procedureBox.newProcedure.interestedParts.form.address_number.placeholder')}
                onChange={(e) => field.onChange(e)}
                disabled={addressNumberDisabled}
                required={props.isProurma ? !props.isProurma : !addressNumberDisabled}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`${prefix}[${props.id}].complement`}
            render={({ field }) => (
              <InputText
                {...field}
                dataCy={`interested_part_complement[${props.id}]`}
                label={t('procedureBox.newProcedure.interestedParts.form.complement.label')}
                placeholder={t('procedureBox.newProcedure.interestedParts.form.complement.placeholder')}
                value={complement}
                onChange={(e) => {
                  field.onChange(e);
                  setComplement(e.target.value);
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`${prefix}[${props.id}].neighborhood`}
            render={({ field }) => (
              <InputText
                {...field}
                dataCy={`interested_part_neighborhood[${props.id}]`}
                label={t('procedureBox.newProcedure.interestedParts.form.neighborhood.label')}
                placeholder={t('procedureBox.newProcedure.interestedParts.form.neighborhood.placeholder')}
                value={neighborhood}
                onChange={(e) => {
                  setNeighborhood(e.target.value);
                  field.onChange(e);
                }}
                required={!props.isProurma}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`${prefix}[${props.id}].city`}
            render={({ field }) => (
              <InputText
                {...field}
                dataCy={`interested_part_city[${props.id}]`}
                label={t('procedureBox.newProcedure.interestedParts.form.city.label')}
                placeholder={t('procedureBox.newProcedure.interestedParts.form.city.placeholder')}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  field.onChange(e);
                }}
                required={!props.isProurma}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
