import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type CollectionChargesProps = {
  title?: string;
};

export function CollectionCharges(props: CollectionChargesProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/relatorios/honorarios`}/>
  );
}
