import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type EvolutionDebtStockProps = {
  title?: string;
};

export function EvolutionDebtStock(props: EvolutionDebtStockProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/dashboards/evolucao_do_estoque_da_divida`}/>
  );
}
