/* eslint-disable import/no-default-export */
import ptBrTranslations from './pt-br';
import faqTranslations from './faq';
import debitBoxTranslations from './debtBox.pt-br';
import reportsTranslations from './reports.pt-br';

export default {
  'pt-BR': {
    translations: {
      ...faqTranslations.translations,
      ...ptBrTranslations.translations,
      ...debitBoxTranslations.translations,
      ...reportsTranslations.translations,
    },
  },
};
