import { useContext, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { t } from 'i18next';
import { Marker } from '../../../@types/model';
import { QueryContext } from '../../../context/queryContext';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { ProcedureMarkersContext } from '../../../context/procedureMarkersContext';
import { Button } from '../../Button';
import { Option } from '../Option';
import { CustomOption } from './CustomOptions';
import styles from './styles.module.scss';
import { GenericMarker } from '../../../@types/markers';
import { isMarker } from '../../../helpers/markers';

type MarkersFixedValuesProps = {
  type: 'priority' | 'pendency' | 'custom';
  open?: boolean;
};

export function MarkersFixedValues(props: MarkersFixedValuesProps) {
  const {
    filterMarkers, filterGenericMarkers, addMarker, removeMarker,
  } = useContext(QueryContext);
  const { removeAllProcedures, openMarkerModal } = useContext(ProcedureActionsContext);
  const { markers } = useContext(ProcedureMarkersContext);
  const [isOpen, setIsOpen] = useState(props.open);
  let options: GenericMarker[] | Marker[];
  const title = t(`procedureBox.markers.${props.type}`);

  switch (props.type) {
    case 'priority':
      options = markers.priority_markers;
      break;
    case 'pendency':
      options = markers.pending_markers;
      break;
    case 'custom':
      options = [...markers.private_markers, ...markers.public_markers];
      break;
    default:
      break;
  }

  const mountQuery = (marker: any) => {
    if (isMarker(marker)) {
      if (filterMarkers.includes(marker)) {
        removeMarker(marker);
      } else {
        addMarker(marker);
      }
    } else if (filterGenericMarkers.includes(marker)) {
      removeMarker(marker);
    } else {
      addMarker(marker);
    }
    removeAllProcedures();
    return false;
  };

  const verifyChecked = (marker: Marker | GenericMarker) => {
    if (isMarker(marker)) {
      return filterMarkers.includes(marker);
    }
    return filterGenericMarkers.includes(marker);
  };

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const renderOptions = () => {
    if (props.type === 'custom') {
      return (
        <div className={ styles.customMarkers }>
          <Button onClick={() => openMarkerModal('create')} title={t('procedureBox.markers.newMarker')} icon={ <FaPlus /> }/>
          <CustomOption type='public' open={props.open} />
          <CustomOption type='private' open={props.open} />
        </div>
      );
    }
    return options.map((option) => {
      return (
        <Option
          onChange={(marker) => mountQuery(marker)}
          marker={ option }
          value={ verifyChecked(option) }
        />
      );
    });
  };

  return (
    <div className={ styles.container }>
      <div className={ styles.title } onClick={ (e) => handleOnClick(e) }>
        { isOpen ? <FaChevronUp/> : <FaChevronDown/> }
        <span>{ title }</span>
      </div>

      { isOpen ? renderOptions() : null }
    </div>
  );
}
