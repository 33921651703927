import { useContext, useEffect, useState } from 'react';
import { ResponseFilter } from '../../../../@types/digitalProcessesDashboard';
import { QueryContext } from '../../../../context/queryContext';
import { OptionType } from '../../../SelectFilter';
import { Remote } from './Remote';

type AutocompleteRemoteProps = {
  id: string;
  title: string,
  placeholder: string;
  tooltip?: string;
  setData?: (data: OptionType) => void;
  getUrl?: (input: string) => Promise<any>;
  onChange?: (value?: OptionType) => void;
  onChangeInput?: (value?: string) => void;
  labelBold?: boolean;
};

export function AutoCompleteRemote(props: AutocompleteRemoteProps) {
  const [getValue, setGetValue] = useState<OptionType>();
  const [getValues, setGetValues] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState('');
  const { updateAdvancedFilter, hasAnyFilter } = useContext(QueryContext);

  const loadGetValues = async () => {
    setGetValues([]);
  };

  const handleChangeGetValues = async () => {
    setGetValue(undefined);
    if (props.onChangeInput) {
      props.onChangeInput(inputValue);
    }
    if (inputValue.length > 2 && props.getUrl) {
      const response: ResponseFilter[] = await props.getUrl(inputValue);
      setGetValues(response.map((p: ResponseFilter) => {
        return {
          label: p.name || p.num || p.label,
          value: p.id || p.procedure_id || p.value,
        };
      }));
    }
  };

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value);
    updateAdvancedFilter(props.id, e.target.value);
  };

  const handleGetValue = (value: OptionType) => {
    if (value.label.length > 0) {
      if (props.setData) {
        props.setData(value);
      }
      setGetValue(value);
      if (props.onChange) {
        props.onChange(value);
      }
      if (props.onChangeInput) {
        props.onChangeInput(value.label);
      }
      setInputValue(value.label);
      updateAdvancedFilter(props.id, value.label);
    }
  };

  useEffect(() => {
    handleChangeGetValues();
  }, [inputValue]);

  useEffect(() => {
    loadGetValues();
  }, []);

  useEffect(() => {
    if (hasAnyFilter === false) {
      setInputValue('');
      setGetValues([]);
    }
  }, [hasAnyFilter]);

  return (
    <Remote
      title={props.title}
      placeholder={props.placeholder}
      tooltip={props.tooltip}
      options={getValues}
      value={getValue}
      inputValue={inputValue}
      onChange={handleGetValue}
      onChangeInput={(e: any) => handleChangeInput(e)}
      labelBold={props.labelBold}
    />
  );
}
