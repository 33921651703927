import { t } from 'i18next';
import { Grid, Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Controller, useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import { Table } from '../../Table';
import { CollaboratorData, IndividualData } from '../../../@types/config';
import { ErrorPropsType, QuantityPerFlux } from '../../../@types/digitalProcesses/fluxes';
import { Procedure } from '../../../@types/model';
import Show from '../../Show';
import { PaginationMenu } from '../../PaginationMenu';
import { InputText } from '../../InputText';

type AutomaticProps = {
  showForm: boolean,
  collaboratorData?: CollaboratorData,
  processFlux: number;
  procedures: Procedure[];
  distributedQuantity: (distributeData?: QuantityPerFlux[]) => void;
  valuePerIndividual: QuantityPerFlux[];
  errors: (error: ErrorPropsType[]) => void
  singleDistribute?: boolean;
};

export function Automatic(props: AutomaticProps) {
  const [collaboratorData, setCollaboratorData] = useState<CollaboratorData>();
  const [distributeQuantityFlux, setDistributeQuantityFlux] = useState<QuantityPerFlux[]>([]);
  const [errors, setErrors] = useState<ErrorPropsType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [showAlertError, setShowAlertError] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Number>();
  const { control } = useForm();
  const maxProcessQuantity = props.procedures.filter((e) => e.flux.id === props.processFlux).length;
  const i18nKey = 'procedureBox.actions.distributeProcesses.modal.distributePerCollaborator';
  const columns = props.singleDistribute
    ? [t(`${i18nKey}.collaboratorsTable.selection`),
      t(`${i18nKey}.collaboratorsTable.collaborator`),
      t(`${i18nKey}.collaboratorsTable.currentCollection`),
      t(`${i18nKey}.collaboratorsTable.receivedOnTheDay`),
      t(`${i18nKey}.collaboratorsTable.dayOuts`)]
    : [t(`${i18nKey}.collaboratorsTable.collaborator`),
      t(`${i18nKey}.collaboratorsTable.currentCollection`),
      t(`${i18nKey}.collaboratorsTable.receivedOnTheDay`),
      t(`${i18nKey}.collaboratorsTable.dayOuts`),
      t(`${i18nKey}.collaboratorsTable.distributeQuantity`)];

  const colaboratorDataPresent = !!props.collaboratorData;

  useEffect(() => {
    if (props.collaboratorData) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = Math.min(startIndex + itemsPerPage, props.collaboratorData.individuals.length);
      const totalItens = { individuals: props.collaboratorData.individuals.slice(startIndex, endIndex) };
      setCollaboratorData(totalItens as unknown as CollaboratorData);
      setTotalItems(props.collaboratorData?.individuals.length);
    }
  }, [props.collaboratorData, currentPage, itemsPerPage]);

  const changePage = (action: string, value: number) => {
    if (currentPage * itemsPerPage < totalItems && action === 'nextPage') {
      setCurrentPage(value);
    } else if (value * itemsPerPage >= 1 && action === 'prevPage') {
      setCurrentPage(value);
    }
  };

  const mountObjectToSend = (totalAmount: number, userId: number) => {
    if (props.singleDistribute) {
      const newObject = {
        fluxId: props.procedures[0].flux.id,
        quantity: totalAmount,
        individualId: userId,
      };
      setDistributeQuantityFlux([...distributeQuantityFlux, newObject]);
      props.distributedQuantity([{ fluxId: props.procedures[0].flux.id, quantity: totalAmount, individualId: userId }]);
    } else {
      const newObject = {
        fluxId: props.processFlux,
        quantity: totalAmount,
        individualId: userId,
      };
      setDistributeQuantityFlux([...distributeQuantityFlux, newObject]);
      props.distributedQuantity([{ fluxId: props.processFlux, quantity: totalAmount, individualId: userId }]);
    }
  };

  const setAndUpdateAmount = (amount: number, userId: number) => {
    const totalAmount = amount;
    const updatedDistributeQuantity = distributeQuantityFlux.map((distributionData) => {
      if (distributionData?.individualId === userId && distributionData.fluxId === props.processFlux) {
        return {
          ...distributionData,
          quantity: totalAmount,
        };
      }
      return distributionData;
    });

    if (!updatedDistributeQuantity.some((distributionData) => distributionData.individualId === userId && distributionData.fluxId === props.processFlux)) {
      if (props.singleDistribute) {
        updatedDistributeQuantity.push({ fluxId: props.procedures[0].flux.id, quantity: totalAmount, individualId: userId });
      } else {
        updatedDistributeQuantity.push({ fluxId: props.processFlux, quantity: totalAmount, individualId: userId });
      }
    }

    const totalQuantity = updatedDistributeQuantity
      .filter((e) => e.fluxId === props.processFlux)
      .reduce((counter, currentValue) => { return counter + currentValue.quantity; }, 0);
    const procedureLength = props.procedures.filter((e) => e.flux.id === props.processFlux).length;

    if (totalQuantity > procedureLength) {
      setErrors([...errors, { individualId: userId, fluxId: props.processFlux }]);
      props.errors([{ individualId: userId, fluxId: props.processFlux }]);
    } else {
      const newErrors = errors.filter((error: ErrorPropsType) => error.fluxId !== props.processFlux && error.individualId !== userId);
      setErrors(newErrors);
      props.errors(newErrors);
    }

    if (updatedDistributeQuantity.some((distributionData) => distributionData.individualId === userId && distributionData.fluxId === props.processFlux)) {
      props.distributedQuantity(updatedDistributeQuantity);
      setDistributeQuantityFlux(updatedDistributeQuantity);
    } else {
      mountObjectToSend(totalAmount, userId);
    }
  };

  const verifyQuantities = (value: string, id: number) => {
    if (parseInt(value, 10) >= 0) {
      setAndUpdateAmount(parseInt(value, 10), id);
    }
  };

  const setAlertError = (value: IndividualData | undefined) => {
    if (value) {
      const sameFluxError = errors.filter((error: ErrorPropsType) => error.individualId === value.id && error.fluxId === props.processFlux).length > 0;
      if (errors.length !== 0 && sameFluxError) {
        setShowAlertError(true);
      } else {
        setShowAlertError(false);
      }
    } else if (errors.filter((error: ErrorPropsType) => error.fluxId === props.processFlux).length === 0) {
      setShowAlertError(false);
    } else {
      setShowAlertError(true);
    }
  };

  useEffect(() => {
    setAlertError(undefined);
  }, [props.processFlux]);

  const setClassName = (value: IndividualData) => {
    return (
      errors.filter((error: ErrorPropsType) => error.individualId === value.id && error.fluxId === props.processFlux).length > 0
        ? styles.processQuantityInvalid : styles.processQuantity
    );
  };

  const verifyAndTestValue = (value: IndividualData) => {
    const valuesByFlux = props.valuePerIndividual.filter((e) => e.individualId === value.id
    && props.processFlux === e.fluxId);
    let result = '0';
    if (valuesByFlux.length > 0) {
      const { quantity } = valuesByFlux[0];
      result = quantity.toString().padStart(2, '0');
    }
    return (
      result
    );
  };

  const verifyAndDisableFields = (field: { value: number }) => {
    return (
      (props.valuePerIndividual.filter((ed) => props.processFlux === ed.fluxId && ed.quantity > 0)
        .reduce((interator, current) => { return interator + current.quantity; }, 0) >= maxProcessQuantity)
          && (field.value === undefined || field.value === 0)
    );
  };

  const selectCollaborator = () => {
    if (collaboratorData) {
      let smallestValue = collaboratorData?.individuals[0]?.total_inbox;
      for (let i = 1; i < collaboratorData?.individuals.length; i += 1) {
        if (collaboratorData.individuals[i].total_inbox < smallestValue) {
          smallestValue = collaboratorData.individuals[i].total_inbox;
        }
      }
      const collaboratorWithSmallestValue = collaboratorData?.individuals.find((item) => item.total_inbox === smallestValue);
      if (collaboratorWithSmallestValue) {
        setSelectedValue(collaboratorWithSmallestValue.id);
        verifyQuantities('1', collaboratorWithSmallestValue.id);
      }
    }
  };

  useEffect(() => {
    if (props.singleDistribute) {
      selectCollaborator();
    }
  }, [props.collaboratorData, collaboratorData]);

  const radioSelect = (e: any) => {
    const valueId = parseInt(e.target.value, 10);
    setSelectedValue(valueId);
    verifyQuantities('1', valueId);
  };

  const collaboratorsTable = () => {
    return (
      <div className={styles.table}>
        <div className={styles.pagination}>
          <PaginationMenu
            pageNumber={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            handlePagination={changePage}
            onPerPageChange={(e) => setItemsPerPage(Number(e))}
            isDistributeProcess={true}
          />
        </div>
        <Table>
          <thead>
            <tr>
              {columns.map((col: string) => <th>{col}</th>)}
            </tr>
          </thead>
          <Show if={colaboratorDataPresent}>
            {collaboratorData && collaboratorData.individuals.map((value: IndividualData) => (
              <tbody key={`${value.id}${props.processFlux}`}>
                <Show if={props.singleDistribute as boolean}>
                  <td>
                    <Radio
                      name={value.name}
                      checked={selectedValue === value.id}
                      value={value.id}
                      onChange={(e) => radioSelect(e)}
                    />
                  </td>
                </Show>
                <td>{value.name}</td>
                <td>{value.total_inbox}</td>
                <td>{value.count_process_distribution_per_day}</td>
                <td>{value.count_all_process_distribution}</td>
                <Show if={!props.singleDistribute as boolean}>
                  <td>
                    <Controller
                      control={control}
                      name={`${value.id}${props.processFlux}`}
                      render={({ field }) => (
                        <InputText
                          {...field}
                          label={''}
                          id={`${value.id}${props.processFlux}`}
                          key={`${value.id}${props.processFlux}`}
                          type='number'
                          placeholder={'00'}
                          onChange={(e) => {
                            field.onChange(e);
                            verifyQuantities(e.target.value, value.id);
                          }}
                          onKeyUp={() => setAlertError(value)}
                          className={setClassName(value)}
                          value={verifyAndTestValue(value)}
                          disabled={verifyAndDisableFields(field)}
                        />
                      )}
                    />
                  </td>
                </Show>
              </tbody>
            ))}
          </Show>
        </Table>
        <Show if={ showAlertError }>
          <div className={styles.alertIconContainer}>
            <FaExclamationTriangle className={styles.checkIcon}/>
            <div>
              {t(`${i18nKey}.distributeQuantityError.outQuantity`)}
            </div>
          </div>
        </Show>
      </div>
    );
  };

  return (
    <form className={ styles.container }>
      <Grid
        container
        columns={{ xs: 1, sm: 3, md: 3 }}
        className={styles.content}
      >
        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.select}
        >
          {collaboratorsTable()}
        </Grid>
      </Grid>
    </form>
  );
}
