import styles from './styles.module.scss';

type LabelProps = {
  text: string;
  required?: boolean;
  className?: string;
  onClick?: () => void;
  bold?: boolean;
};

export function Label(props: LabelProps) {
  return (
    <label
      className={`${styles.label} ${props.className} ${props.bold ? styles.bold : ''}`}
      onClick={props.onClick}
    >
      {props.text} {props.required ? '*' : ''}
    </label>
  );
}
