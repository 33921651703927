import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { Result } from '../Result';
import { Button } from '../../Button';
import { Loading } from '../Loading';

export function VerifySignatures() {
  const {
    proceduresSeleted,
    removeProcedures,
    verifySignatures,
    setVerifySignatures,
    setIpmStateSelected,
    setArchives,
  } = useContext(ProcedureActionsContext);
  const [disabledNextStage, setDisabledNextStage] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [totalSigned, setTotalSigned] = useState(0);
  const [totalUnsigned, setTotalUnsigned] = useState(0);
  const [valueProgress, setValueProgress] = useState(0);
  const [withoutDocuments, setWithoutDocuments] = useState<number[]>([]);
  const getPercentageSuccess = () => (valueProgress / proceduresSeleted.length) * 100;

  const checkDocuments = async () => {
    const documents: any[] = [];
    const idsWithoutDocuments: number[] = [];
    let count = 0;
    let countSigned = 0;
    let countUnsigned = 0;

    proceduresSeleted.forEach(async (procedure) => {
      await ProcedureBoxService.checkDocuments(procedure.id, true).then((res) => {
        count += 1;
        setValueProgress(count);

        if (res.archives.length > 0) {
          countSigned += 1;
          setTotalSigned(countSigned);

          res.archives.map((archive) => {
            const obj = { ...archive, procedure_id: procedure.id };
            documents.push(obj);
          });
        } else {
          countUnsigned += 1;
          setTotalUnsigned(countUnsigned);
          idsWithoutDocuments.push(procedure.id);
        }
      });
    });

    setWithoutDocuments(idsWithoutDocuments);
    setArchives(documents);
  };

  useEffect(() => {
    setLoadingProgress(true);
    setDisabledNextStage(true);
    checkDocuments();
  }, []);

  useEffect(() => {
    if (proceduresSeleted.length === valueProgress) {
      setLoadingProgress(false);
    }
  }, [valueProgress]);

  useEffect(() => {
    if (totalSigned === 0) {
      setDisabledNextStage(true);
    } else {
      setDisabledNextStage(false);
    }

    if (totalSigned === proceduresSeleted.length) {
      setVerifySignatures(true);
    }
  }, [totalSigned]);

  useEffect(() => {
    if (totalUnsigned > 0) {
      setVerifySignatures(false);
    }
  }, [totalUnsigned]);

  const handleNextStage = () => {
    const procedures: any[] = [];

    withoutDocuments.map((id) => {
      const item = proceduresSeleted.find((p) => p.id === id);
      procedures.push(item);
    });

    if (procedures.length > 0) {
      removeProcedures(procedures);
    }

    setIpmStateSelected('description');
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <span>{t('procedureBox.actions.ipm.forwardIPM.modal.verifySignatures.info')}</span>
      </div>
      { loadingProgress
        ? <Loading porcentage={getPercentageSuccess()}
            length={proceduresSeleted.length} value={valueProgress} type={'verifySignature'} />
        : <Result
            status={verifySignatures}
            totalWithoutDocument={totalUnsigned}
            proceduresLength={proceduresSeleted.length}
            disabled={disabledNextStage}
            withDocument={totalSigned}
            type={'verifySignature'} /> }

      <div className={styles.footer}>
        <Button
          title={t('procedureBox.actions.ipm.forwardIPM.modal.nextStage.text')}
          buttonType='primary'
          size='flat'
          disabled={disabledNextStage}
          round
          onClick={handleNextStage}
        />
      </div>
    </div>
  );
}
