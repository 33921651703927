import { useContext } from 'react';
import { Grid } from '@mui/material';
import { saveAs } from 'file-saver';
import { t } from 'i18next';
import {
  FaDownload,
  FaEye,
} from 'react-icons/fa';
import styles from './styles.module.scss';
import {
  locality,
  secret,
} from './constants';
import { LabelGroup } from '../LabelGroup';
import { ConsultProcedure } from '../../../../@types/pje';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';

type BasicDataProps = {
  data?: ConsultProcedure;
  procedureId: number;
};

export function BasicData(props: BasicDataProps) {
  const keyI18n = 'procedureBox.processExtract.pjeExtract.basicData';
  const { openNotices, openMovements } = useContext(ProcedureActionsContext);

  const renderValue = (value: any, list: any) => {
    const text = list.find((item: any) => item.code === Number(value));

    if (text) {
      return `${text.code} - ${text.description}`;
    }

    return value;
  };

  const downloadDocuments = () => {
    if (props.data?.documents) {
      const ids = props.data?.documents.map((d) => d.code);
      ProcedureBoxService.downloadDocumentsPje(props.procedureId, ids).then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        saveAs(blob, `processo_${props.procedureId}`);
      });
    }
  };

  const openModalMovements = () => {
    if (props.data && props.data.movements_count > 0) {
      openMovements(props.data.movements);
    }
  };

  const openModalNotices = () => {
    if (props.data && props.data?.notices_count > 0) {
      openNotices(props.data.pending_notices);
    }
  };

  return (
    <div className={ styles.container }>
      <h3>{t(`${keyI18n}.title`)}</h3>
      <Grid container className={styles.content}>
        <Grid item xs={2}>
          <LabelGroup
            label={'subject'}
            value={props.data?.subject}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'situation'}
            value={props.data?.situation}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'classProcedural'}
            value={props.data?.class_procedural}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'competence'}
            value={props.data?.competence}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'judgingOrgan'}
            value={props.data?.judging_organ}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'actingMagistrate'}
            value={props.data?.acting_magistrate}/>
        </Grid>
      </Grid>

      <Grid container className={styles.content}>
        <Grid item xs={2}>
          <LabelGroup
            label={'localityCode'}
            value={renderValue(props.data?.locality_code, locality)}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'judgeDate'}
            value={props.data?.judge_date}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'levelSecrecy'}
            value={renderValue(props.data?.level_secrecy, secret)}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'mpintervention'}
            value={t(`${keyI18n}.${props.data?.mp_intervention ? 'withIntervention' : 'withoutIntervention'}`)}/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'attachments'}
            icon={<FaDownload className={styles.icon} />}
            onClick={ downloadDocuments }
            value={ t(`${keyI18n}.attachmentsCount`, { count: props.data?.attachments_count }) }/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'lastMove'}
            value={props.data?.last_mov}/>
        </Grid>
      </Grid>

      <Grid container className={styles.content}>
        <Grid item xs={2}>
          <LabelGroup
            label={'warningHistory'}
            icon={<FaEye className={styles.icon} />}
            onClick={openModalNotices}
            value={ t(`${keyI18n}.noticesCount`, { count: props.data?.notices_count }) }/>
        </Grid>

        <Grid item xs={2}>
          <LabelGroup
            label={'transactionHistory'}
            icon={<FaEye className={styles.icon} />}
            onClick={openModalMovements}
            value={ t(`${keyI18n}.movementsCount`, { count: props.data?.movements_count }) }/>
        </Grid>
      </Grid>
    </div>
  );
}
