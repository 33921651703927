import {
  FormControlLabel, Modal as ModalMui, Radio, RadioGroup,
} from '@mui/material';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { FaTimes } from 'react-icons/fa';
import saveAs from 'file-saver';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { Button } from '../../../components/Button';
import ProcedureBoxService from '../../../services/procedureBoxService';

const WARNING_ANIMATION = require('../../../assets/lotties/warning.json');

type PropsAlert = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fileUrls?: string;
};

export function AlertDigitalFolder(props: PropsAlert) {
  const { pathname } = useLocation();
  const i18nKey = 'procedureBox.actions.digitalFolder';
  const [type, setType] = useState('application/zip');

  const donwloadFiles = () => {
    if (props.fileUrls) {
      ProcedureBoxService.downloadDocuments(props.fileUrls, type.split('/')[1]).then((response) => {
        const blob = new Blob([response.data], { type });
        saveAs(blob, `processo_${Number(pathname.split('/')[2])}`);
      });
    }
    props.setOpen(false);
  };

  if (props.open) {
    return (
      <ModalMui
        open={props.open}
        onClose={() => props.setOpen(false)}
        disableAutoFocus={true}
        onBackdropClick={() => props.setOpen(false)}
        >
        <div className={styles.box}>
          <div className={styles.body}>
            <a className={styles.closeButton} onClick={() => props.setOpen(false)}>
              <FaTimes />
            </a>
            <div className={styles.icon}>
              <Player
                autoplay
                keepLastFrame={true}
                src={WARNING_ANIMATION}
                speed={2}
                className={styles.lottie}
              >
                <Controls visible={false} />
              </Player>
            </div>
            <span className={styles.title}>{ t(`${i18nKey}.modal.title`) }</span>
            <span className={styles.text}>{ t(`${i18nKey}.modal.subtitle`) }</span>
            <RadioGroup
              aria-labelledby='radioGroup'
              name='controlledRadioGroup'
              onChange={(e) => {}}
              className={styles.radioGroup}
            >
              <FormControlLabel
                value={true}
                onChange={() => setType('application/pdf')}
                checked={type === 'application/pdf'}
                control={<Radio className={styles.radio} />}
                label={t(`${i18nKey}.modal.unique`).toString()}
              />

              <FormControlLabel
                value={false}
                onChange={() => setType('application/zip')}
                checked={type === 'application/zip'}
                control={<Radio className={styles.radio} />}
                label={t(`${i18nKey}.modal.multiple`).toString()}
              />
            </RadioGroup>
            <div className={styles.actions}>
              <Button
                buttonType={'default'}
                round
                size='flat'
                title={t('general.cancel')}
                onClick={() => props.setOpen(false)}
              />
              <Button
                buttonType={'primary'}
                round
                size='flat'
                title={t('general.confirm')}
                onClick={donwloadFiles}
              />
            </div>
          </div>
        </div>
      </ModalMui>
    );
  }
  return <></>;
}
