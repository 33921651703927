import { useEffect } from 'react';
import { EPGM_URL } from '../../../config/constants';
import { IFrame } from '../../../components/IFrame';
import { loadTitle } from '../../../helpers/changeTitle';

type ProcedureCabinetProps = {
  title?: string;
};

export function ProceduresCabinet(props: ProcedureCabinetProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/processos_digitais/relatorios/processos_gabinete`}/>
  );
}
