export const verifyUrlProcedureDetails = () => {
  const currentUrl = window.location.href;
  const desiredSegment = '/procedure_box/show_procedure';
  if (currentUrl.includes(desiredSegment)) {
    return currentUrl.split('/')[5];
  }
  return false;
};

export function redirectIfProcedureDetails(): void {
  if (verifyUrlProcedureDetails()) {
    window.location.href = '/procedure_box';
  }
}
