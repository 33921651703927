import { useState } from 'react';
import { t } from 'i18next';
import { FaFolder, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Show from '../../../components/Show';
import styles from './styles.module.scss';

type HeaderProps = {
  procedure?: any;
  citationIntimation?: any;
};

export function Header(props: HeaderProps) {
  const i18nKey = 'procedureBox.actions.digitalFolder';
  const [show, setShow] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.info} onClick={() => setShow(!show)}>
        <span>
          <FaFolder />
          { props.procedure ? props.procedure?.process_number : props.citationIntimation?.subject }
        </span>
        { show ? <FaChevronUp /> : <FaChevronDown /> }
      </div>
      <Show if={show}>
        <div className={styles.content}>
          <div className={styles.title}>
            {t(`${i18nKey}.header.flux`)}
          </div>
          <div className={styles.value}>
            { props.procedure ? props.procedure?.flux?.name : props.citationIntimation?.subject }
          </div>
          <div className={styles.title}>
            {t(`${i18nKey}.header.individual`)}
          </div>
          <div className={styles.value}>
            { props.procedure ? props.procedure?.individual_name : props.citationIntimation?.individual?.name }
          </div>
        </div>
      </Show>
    </div>
  );
}
