import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import {
  FaSearch,
} from 'react-icons/fa';
import styles from './styles.module.scss';
import { OptionType } from '../../@types/config';
import Screen from '../../helpers/screen';

type SearchDashboardProps = {
  options: OptionType[];
  placeholder?: string;
  value?: OptionType;
  inputValue?: string;
  onChange?: (value: OptionType) => void;
  onChangeInput?: (value: any) => void;
  onClick?: () => void;
};

export function SearchDashboard(props: SearchDashboardProps) {
  const {
    inputValue,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    popupOpen,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    options: props.options,
    value: props.value,
    getOptionLabel: (option) => option.label,
    clearOnBlur: true,
    clearOnEscape: true,
    onChange: (el, val) => {
      if (props.onChange && val) {
        props.onChange(val);
      }
    },
  });
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [hasError, setHasError] = useState(false);
  const isMobile = Screen.isMobile();

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const hasOpen = () => {
    return isMobile || open || hover || inputValue.length > 0 || popupOpen;
  };

  const isNotEmpty = () => {
    return groupedOptions.length > 0;
  };

  const verifyField = () => {
    if (groupedOptions.length > 0) {
      setHasError(true);
    }
  };

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  useEffect(() => {
    verifyField();
  }, [props.inputValue]);

  return (
    <div className={styles.container} onMouseLeave={handleMouseLeave}>
      <div className={styles.inputWrapper} {...getRootProps()}>
        <div
          className={`${styles.inputGroup} ${!isNotEmpty() && popupOpen && hasError ? styles.error : ' '}`}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          ref={setAnchorEl}>
          <input
            {...getInputProps()}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleClick();
              }
            }}
            placeholder={props.placeholder}
            onChange={props.onChangeInput}
            value={props.inputValue}
            className={`${styles.input} ${hasOpen() ? styles.open : ''}`}
          />
          <FaSearch
            onClick={() => handleClick()}
            className={`${styles.icon} ${hasOpen() ? styles.open : ''}`}
            onMouseEnter={handleMouseEnter}
          />
        </div>
      </div>
      { isNotEmpty() ? (
        <ul className={styles.listbox} {...getListboxProps()} style={{ width: anchorEl?.offsetWidth }}>
          {(groupedOptions as typeof props.options).map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option.label}</li>
          ))}
        </ul>
      ) : popupOpen && hasError
        ? <span className={styles.isEmpty}>
            {t('general.noOptions')}
          </span>
        : null
      }
    </div>
  );
}
