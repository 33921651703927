import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Button } from '../../Button';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from '../styles.module.scss';
import { Loading } from '../Loading';
import { Result } from '../Result';

export function Verify() {
  const {
    proceduresSeleted,
    verify,
    setVerify,
    setIpmStateSelected,
  } = useContext(ProcedureActionsContext);
  const [disabledNextStage, setDisabledNextStage] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [totalWithoutDocument, setTotalWithoutDocument] = useState(0);
  const [withDocument, setWithDocument] = useState(0);
  const [valueProgress, setValueProgress] = useState(0);
  const getPercentageSuccess = () => (valueProgress / proceduresSeleted.length) * 100;

  const checkDocuments = async () => {
    const procedureIds = proceduresSeleted.map(({ id }) => id);
    let count = 0;
    let totalWithout = 0;
    let totalWithDocument = 0;
    procedureIds.forEach(async (id) => {
      await ProcedureBoxService.checkDocuments(id, false).then((res) => {
        count += 1;
        setValueProgress(count);
        if (res.archives.length > 0) {
          totalWithDocument += 1;
          setWithDocument(totalWithDocument);
        } else {
          totalWithout += 1;
          setTotalWithoutDocument(totalWithout);
        }
      });
    });
  };

  useEffect(() => {
    setLoadingProgress(true);
    setDisabledNextStage(true);
    checkDocuments();
  }, []);

  useEffect(() => {
    if (proceduresSeleted.length === valueProgress) {
      setLoadingProgress(false);
    }
  }, [valueProgress]);

  useEffect(() => {
    if (withDocument === 0) {
      setDisabledNextStage(true);
    } else {
      setDisabledNextStage(false);
    }

    if (withDocument === proceduresSeleted.length) {
      setVerify(true);
    }
  }, [withDocument]);

  useEffect(() => {
    if (totalWithoutDocument > 0) {
      setVerify(false);
    }
  }, [totalWithoutDocument]);

  return (
    <div>
      <div className={styles.verify}>
        <div className={styles.text}>
          <span>{t('procedureBox.actions.ipm.forwardIPM.modal.verify.info')}</span>
          <div>
            <span>{t('procedureBox.actions.ipm.forwardIPM.modal.verify.processAdministrative')}</span>
            <span>{t('procedureBox.actions.ipm.forwardIPM.modal.verify.retirement')}</span>
          </div>
        </div>
      </div>
      { loadingProgress
        ? <Loading porcentage={getPercentageSuccess()}
           length={proceduresSeleted.length} value={valueProgress} type={'verifyAttach'} />
        : <Result
            status={verify}
            totalWithoutDocument={totalWithoutDocument}
            proceduresLength={proceduresSeleted.length}
            disabled={disabledNextStage}
            withDocument={withDocument}
            type={'verifyAttach'} /> }
      <div className={styles.footer}>
        <Button
          title={t('procedureBox.actions.ipm.forwardIPM.modal.nextStage.text')}
          buttonType='primary'
          size='flat'
          round
          onClick={() => setIpmStateSelected('verifySignatures')}
        />
      </div>
    </div>
  );
}
