import { useContext } from 'react';
import { t } from 'i18next';
import IconButton from '@mui/material/IconButton';
import { FiMoreVertical } from 'react-icons/fi';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { ProcedureMarkersContext } from '../../../../context/procedureMarkersContext';
import { Marker } from '../../../../@types/model';
import { GenericMarker } from '../../../../@types/markers';
import { isMarker } from '../../../../helpers/markers';

type CustomMenuProps = {
  open?: boolean;
  className?: string;
  marker: Marker | GenericMarker;
};

export function CustomMenu(props: CustomMenuProps) {
  const isEnable = Number(props.marker.total) === 0;
  const options = [
    t('general.edit'),
    t('general.destroy'),
  ];
  const { openMarkerModal } = useContext(ProcedureActionsContext);
  const { setMarkerId, deleteMarker } = useContext(ProcedureMarkersContext);

  const handleGetMarkerIdAndExecute = (index: number) => {
    if (index === 0) {
      openMarkerModal('edit');
      if (isMarker(props.marker)) {
        setMarkerId(props.marker.id);
      }
    } else if (index === 1) {
      if (isMarker(props.marker)) {
        deleteMarker(props.marker);
      }
    }
  };

  return (
    <div className={ `${styles.container} ${props.className}` }>
      <div className={`${styles.dropdown} ${!isEnable ? styles.disabled : ''}`}>
        <IconButton
          className={styles.button}
        >
          <FiMoreVertical className={ styles.icon }/>
        </IconButton>
        <div className={styles.content}>
          {isEnable ? options.map((option :string, index) => (
            <div
              key={index}
              className={styles.item}
              onClick={() => handleGetMarkerIdAndExecute(index)}
            >
              <p>
                {option}
              </p>
            </div>
          )) : <label>{t('procedureBox.markers.alert')}</label>}
        </div>
      </div>
    </div>
  );
}
