import { FaTrashAlt } from 'react-icons/fa';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { CollaboratorData } from '../../../@types/config';
import { Table } from '../../Table';
import { Procedure } from '../../../@types/model';
import Select from '../../Select';
import { PaginationMenu } from '../../PaginationMenu';
import { ManualDistributionData } from '../../../@types/distribution';

type ManualProps = {
  colaboratorData: CollaboratorData | undefined;
  proceduresSelected: Procedure[];
  currentFlux: number;
  distributionData: ManualDistributionData[];
  setDistributionData: (distributionData: ManualDistributionData[]) => void;
  handleRemoveProcedure: (procedure: Procedure) => void;
};

export function Manual(props: ManualProps) {
  const [procedures, setProcedures] = useState<Procedure[]>(
    props.proceduresSelected,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const i18nKey = 'procedureBox.actions.distributeProcesses.modal.distributePerCollaborator';
  const columns = [
    t(`${i18nKey}.collaboratorsTable.process`),
    t(`${i18nKey}.collaboratorsTable.collaborator`),
    t(`${i18nKey}.collaboratorsTable.currentCollection`),
    t(`${i18nKey}.collaboratorsTable.receivedOnTheDay`),
    t(`${i18nKey}.collaboratorsTable.dayOuts`),
  ];

  useEffect(() => {
    setCurrentPage(1);
    const filteredProcedures = props.proceduresSelected.filter((procedure) => procedure.flux.id === props.currentFlux);
    const newProcedures = filteredProcedures.slice(currentPage - 1, itemsPerPage);
    setTotalItems(filteredProcedures.length);
    setProcedures(newProcedures);
  }, [props.currentFlux]);

  useEffect(() => {
    const calc = (currentPage - 1) * itemsPerPage;
    const newProcedures = props.proceduresSelected
      .filter((procedure) => procedure.flux.id === props.currentFlux)
      .slice(calc, (currentPage * itemsPerPage));
    setProcedures(newProcedures);
  }, [itemsPerPage, currentPage]);

  const removeProcedure = (objectProcedure: Procedure) => {
    const newProcedures = procedures.filter(
      (procedure) => procedure.id !== objectProcedure.id,
    );
    const newObject = props.distributionData.filter(
      (item: ManualDistributionData) => item.procedureId !== objectProcedure.id,
    );

    setProcedures(newProcedures);

    props.setDistributionData(newObject);
    props.handleRemoveProcedure(objectProcedure);
  };

  const info = (procedureId: number, field: string) => {
    const individualObject = props.distributionData.filter(
      (obj: ManualDistributionData) => obj?.fluxId === props.currentFlux && obj?.procedureId === procedureId,
    );
    if (props.colaboratorData && individualObject.length > 0) {
      const individual = props.colaboratorData.individuals.filter(
        (ind) => ind.id === individualObject[0].individualId,
      )[0];

      switch (field) {
        case 'count_all_process_distribution':
          return individual?.count_all_process_distribution;
        case 'count_process_distribution_per_month':
          return individual?.count_process_distribution_per_month;
        case 'count_process_distribution_per_day':
          return individual?.count_process_distribution_per_day;
        default:
          break;
      }
    }
    return '-';
  };

  const addObject = (
    procedure: Procedure,
    individualId: number,
    individualName: string | undefined,
  ) => {
    if (individualId === undefined && individualName === undefined) {
      const newObject = props.distributionData.filter(
        (item: ManualDistributionData) => item.procedureId !== procedure.id,
      );
      props.setDistributionData(newObject);
    } else if (
      !props.distributionData.some(
        (item: ManualDistributionData) => item.fluxId === props.currentFlux && item.procedureId === procedure.id,
      ) && individualName !== undefined
    ) {
      const newObject = [
        ...props.distributionData,
        {
          fluxId: props.currentFlux,
          procedureId: procedure.id,
          individualId,
          individualName,
        },
      ];
      props.setDistributionData(newObject);
    } else {
      const newObject = props.distributionData.map((item: any) => {
        if (
          item.fluxId === props.currentFlux
            && item.procedureId === procedure.id
        ) {
          return {
            ...item,
            individualId,
            individualName,
          };
        }

        return item;
      });
      props.setDistributionData(newObject);
    }
  };

  const getValue = (procedureId: number) => {
    const value = props.distributionData.filter(
      (item: ManualDistributionData) => item.fluxId === props.currentFlux && item.procedureId === procedureId,
    );

    if (value.length > 0) {
      return {
        label: value[0].individualName,
        value: value[0].individualId,
      };
    }
    return { label: '', value: 0 };
  };

  const changePage = (action: string, value: number) => {
    if (currentPage * itemsPerPage < totalItems && action === 'nextPage') {
      setCurrentPage(value);
    } else if (value * itemsPerPage >= 1 && action === 'prevPage') {
      setCurrentPage(value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.pagination}>
        <PaginationMenu
          pageNumber={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          handlePagination={changePage}
          onPerPageChange={(e) => setItemsPerPage(Number(e))}
          isDistributeProcess
        />
      </div>
      <Table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th>{column}</th>
            ))}
          </tr>
        </thead>
        {procedures.map((procedure) => (
          <tbody>
            <tr>
              <td>{procedure.process_number}</td>
              <td>
                <Select
                  label={''}
                  options={
                    props.colaboratorData?.individuals.map((individual) => ({
                      label: individual.name,
                      value: individual.id,
                    })) || []
                  }
                  value={getValue(procedure.id)}
                  onChange={(e) => addObject(procedure, e?.value, e?.label)}
                  noOptionsMessage={t('procedureBox.actions.distributeProcesses.modal.distributePerCollaborator.collaboratorsTable.noCollaboratorOption')}
                />
              </td>
              <td>{info(procedure.id, 'count_all_process_distribution')}</td>
              <td>
                {info(procedure.id, 'count_process_distribution_per_month')}
              </td>
              <td>
                {info(procedure.id, 'count_process_distribution_per_day')}
              </td>
              <td>
                <FaTrashAlt onClick={() => removeProcedure(procedure)} />
              </td>
            </tr>
          </tbody>
        ))}
      </Table>
    </div>
  );
}
