import { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { QueryContext } from '../../../context/queryContext';
import styles from './styles.module.scss';
import Format from '../../../helpers/format';

type FilterAdvancedProps = {
  isOpen: boolean;
  removeFilter: () => void;
};

export function QuickSearch(props: FilterAdvancedProps) {
  const { t } = useTranslation();
  const { totalProcedureValues, totalProcedures, loading } = useContext(QueryContext);

  return (
    <div className={`${styles.container} ${!props.isOpen ? styles.openQuickSearch : ''}`}>
      <div className={styles.titleBlock}>
        <h3 className={styles.title}>
          <Trans
            i18nKey='procedureBox.quickSearch.title'
            components={{ bold: <strong /> }}
          />
        </h3>
        <a onClick={ props.removeFilter } className={ styles.removeFilter }>{ t('procedureBox.quickSearch.clean') }</a>
      </div>
      {
        totalProcedures > 0 && (
          <div className={`${styles.info}`}>
            <div className={`${styles.total}`}>
              <p className={styles.label}>{t('procedureBox.quickSearch.label')}</p>
              <p className={styles.value}>{totalProcedures}</p>
            </div>
            <div className={`${styles.total}`}>
              <p className={styles.label}>{t('procedureBox.quickSearch.total')}</p>
              <p className={styles.value}>{Format.currency(totalProcedureValues)}</p>
            </div>
          </div>
        )
      }
      {
        loading && (
          <>
            <p className={styles.loading}>
              {t('procedureBox.quickSearch.loading')}
            </p>
            <Skeleton variant={'rectangular'} className={ styles.skeleton }/>
          </>
        )
      }

      {
        Boolean(totalProcedures === 0 && !loading) && (
          <p className={styles.empty} data-cy={'box-message-query-empty'}>
            {t('procedureBox.quickSearch.empty')}
          </p>
        )
      }
     </div>
  );
}
