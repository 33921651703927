import { OptionType } from '../@types/config';

export function truncate(text: string, max: number): string {
  if (text?.length > max) {
    return `${text.slice(0, max)}...`;
  }
  return text;
}

export function getPaginationTitle(currentPage: number, currentPerPage: number, totalProcedures: number): string {
  const page = `${currentPage === 1 ? currentPage : currentPerPage * currentPage - currentPerPage + 1}`;
  const totalPerPage = `${currentPerPage * currentPage < totalProcedures ? currentPerPage * currentPage : totalProcedures}`;
  return `${page} - ${totalPerPage} de ${totalProcedures}`;
}

export function orderOptionType(optionType: OptionType[]) {
  const filteredData = Object.values(
    optionType.reduce((acc, enumItem) => {
      const { label } = enumItem;
      const value = parseInt(enumItem.value, 10);

      if (!acc[label] || value > parseInt(acc[label].value, 10)) {
        acc[label] = { label, value: value.toString() };
      }

      return acc;
    }, {} as { [key: string]: { label: string, value: string } }),
  );

  return filteredData;
}
