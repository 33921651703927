export const locality = [
  { code: 1, description: 'Município de Fortaleza' },
];

export const secret = [
  { code: 0, description: 'Sem sigílo' },
];

export const typeCommunication = [
  { code: 'INT', description: 'Intimação da Sentença' },
  { code: 'CIT', description: 'Citação' },
];
