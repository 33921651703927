import { useContext, useEffect, useState } from 'react';
import { FaRedo } from 'react-icons/fa';
import { t } from 'i18next';
import styles from './styles.module.scss';
import SelectionBoxMenu from './SelectionBoxMenu';
import { QueryContext } from '../../../context/queryContext';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Menu } from './Menu';
import { ResponsiveMenu } from './ResponsiveMenu';
import Show from '../../Show';
import { RoundButton } from '../../RoundButton';
import { MenuCitationIntimation } from './MenuCitationIntimation';

export function Actions() {
  const { isEmpty, citationIntimationSelected } = useContext(ProcedureActionsContext);
  const { loadData, box } = useContext(QueryContext);
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1520px)').matches,
  );

  const verifyCitationIntimation = () => {
    return citationIntimationSelected ? <MenuCitationIntimation /> : null;
  };

  useEffect(() => {
    window
      .matchMedia('(max-width: 1520px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);
  return (
    <div className={ styles.container }>
      <div className={ styles.content }>
        <div className={ styles.rightBorder }>
          <SelectionBoxMenu />
          <Show if={isEmpty && box !== 'fieldbox'}>
            <RoundButton
              className={ styles.btnReload }
              icon={<FaRedo/>}
              tooltip={t('general.refresh')}
              size='small'
              onClick={() => loadData()}
            />
          </Show>
        </div>
        { !isEmpty && !matches ? <Menu /> : verifyCitationIntimation() }
        { !isEmpty && matches ? <ResponsiveMenu /> : null }
      </div>
    </div>
  );
}
