import { t } from 'i18next';
import styles from './styles.module.scss';

type TabProps = {
  title: string;
  onClick: () => void;
  markerTabSelected: boolean
};

export function Tab(props: TabProps) {
  return (
    <div className={styles.container}>
      <a
        onClick={props.onClick}
        className={
          `
            ${styles.tab} 
            ${props.markerTabSelected ? styles.selected : ''}
          `
        }>
        {t(`procedureBox.markers.${props.title}`)}
      </a>
    </div>
  );
}
