import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { ThemeProvider } from '@emotion/react';
import { Menu as MuiMenu, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { menuTheme } from '../../../../styles/customThemes/menuTheme';
import styles from './styles.module.scss';
import { MarkerItem } from './MarkerItem';
import { GenericMarker } from '../../../../@types/markers';

type SelectorProps = {
  pendencyMarkers: GenericMarker[];
  priorityMarkers: GenericMarker[];
  procedureId: number;
};

export function Menu(props: SelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visible, setVisible] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseTooltip = () => {
    setVisible(false);
  };

  const handleOpenTooltip = () => {
    setVisible(true);
  };

  return (
    <div
      className={`${styles.container}`}
    >
      <ThemeProvider theme={menuTheme}>
        <Tooltip
          open={visible}
          placement="right-start"
          title={`${t('procedureBox.markers.addButton')}`}
        >
          <div
            className={`${styles.title}`}
            onClick={open ? handleClose : handleClick}
            onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}
          >
            <FaPlus />
          </div>
        </Tooltip>
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div>
          <MarkerItem
            onChange={handleClose}
            type='priority'
            options={props.priorityMarkers}
            procedureId={props.procedureId}
            open
          />
          <MarkerItem
            onChange={handleClose}
            type='pendency'
            options={props.pendencyMarkers}
            procedureId={props.procedureId}
            open
          />
          <MarkerItem
            onChange={handleClose}
            type='custom'
            procedureId={props.procedureId}
            open
          />
        </div>
      </MuiMenu>
      </ThemeProvider>
    </div>
  );
}
