import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { DigitalFolder } from '../pages/DigitalFolder';

const DigitalFolderRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/digital_folder/:id/:type/" element={<DigitalFolder />} />
    </Routes>
  );
};

export default DigitalFolderRoutes;
