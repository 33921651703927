import { t } from 'i18next';
import { useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import { GenericMarker } from '../../../../@types/markers';
import { Marker } from '../../../../@types/model';
import { QueryContext } from '../../../../context/queryContext';
import { getMarkerLabel, isMarker } from '../../../../helpers/markers';
import styles from './styles.module.scss';

export function Item(props: Marker | GenericMarker) {
  const { removeMarker } = useContext(QueryContext);

  const renderContent = () => {
    if (isMarker(props)) {
      return (
        <span>{ props.private_marker ? `${t(`procedureBox.markersSelect.types.${props.private_marker}`)}:` : '' } { props.label?.toLocaleLowerCase() }</span>
      );
    }
    return (
      <span>{ getMarkerLabel(props).toLocaleLowerCase() }</span>
    );
  };

  return (
    <div className={ styles.container }>
      <FaTimes onClick={ () => removeMarker(props) }/>
      {renderContent()}
    </div>
  );
}
