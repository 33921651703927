import { t } from 'i18next';
import styles from './styles.module.scss';

interface TableProps {
  partsInterested: [{
    polo: string | null;
    name: string | null;
    cpf: string | null;
    cnpj: string | null;
  }] | undefined;
}

export function Table(props: TableProps) {
  return (
    <table className={styles.table}>
      <thead>
        <th>{t('procedureBox.actions.initialPetition.modal.procedureParts.pole')}</th>
        <th>{t('procedureBox.actions.initialPetition.modal.procedureParts.personType')}</th>
        <th>{t('procedureBox.actions.initialPetition.modal.procedureParts.name')}</th>
        <th>{t('procedureBox.actions.initialPetition.modal.procedureParts.documentType')}</th>
        <th>{t('procedureBox.actions.initialPetition.modal.procedureParts.documentCode')}</th>
        <th>{t('procedureBox.actions.initialPetition.modal.procedureParts.documentIssuer')}</th>
      </thead>

      <tbody>
      {
          props.partsInterested?.map((parts: any) => {
            return (
              <tr>
                <td>
                  <span>{
                    parts.polo === 'active'
                      ? t('procedureBox.actions.initialPetition.modal.procedureParts.content.active')
                      : t('procedureBox.actions.initialPetition.modal.procedureParts.content.passive')
                    }
                  </span>
                </td>
                <td>
                  <span>{
                    parts.cpf === null
                      ? t('procedureBox.actions.initialPetition.modal.procedureParts.content.legal')
                      : t('procedureBox.actions.initialPetition.modal.procedureParts.content.physical')
                    }
                  </span>
                </td>
                <td><span>{ parts.name }</span></td>
                <td>
                  <span>{
                    parts.cpf === null
                      ? t('procedureBox.actions.initialPetition.modal.procedureParts.content.cnpj')
                      : t('procedureBox.actions.initialPetition.modal.procedureParts.content.cpf')
                  }</span>
                </td>
                <td><span>{ parts.cpf === null ? parts.cnpj : parts.cpf }</span></td>
                <td>
                  <span>{
                    parts.cpf === null
                      ? t('procedureBox.actions.initialPetition.modal.procedureParts.content.irs')
                      : t('procedureBox.actions.initialPetition.modal.procedureParts.content.ssp')
                  }</span>
               </td>
             </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}
