import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type ExtinguishSuspendCreditProps = {
  title?: string;
};

export function ExtinguishSuspendCredit(props: ExtinguishSuspendCreditProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/inscricoes/cancelar_suspender`}/>
  );
}
