import { t } from 'i18next';
import styles from './styles.module.scss';

type IconProps = {
  text?: string;
};

export function Icon(props: IconProps) {
  return (
    <div
      className={`${styles.container}`}
    >
        <span className={styles.button}>
          {t(`procedureBox.actions.${props.text}.title`)}
        </span>
    </div>
  );
}
