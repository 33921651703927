import { t } from 'i18next';
import { Markup } from 'interweave';
import styles from './styles.module.scss';

type PreviewProps = {
  content: string;
  description: string;
  template: string;
  templateName: string;
};
export function Preview(props: PreviewProps) {
  const renderModelName = () => {
    if (props.template === 'empty') {
      return t('procedureBox.actions.prepareDocument.modal.form.selectGroup.empty');
    }
    if (props.template === 'newTemplate') {
      return props.description || t('procedureBox.actions.prepareDocument.modal.form.selectGroup.new');
    }
    return props.templateName;
  };

  const renderDocumentName = () => {
    if (props.template === 'newTemplate') {
      return '-';
    }
    return props.description;
  };

  return (
    <div className={ styles.container }>
      <p className={ styles.title }>{t('procedureBox.actions.prepareDocument.modal.form.name')}</p>
      <p className={ styles.text }>{renderModelName()}</p>
      <p className={ styles.title }>{t('procedureBox.actions.prepareDocument.modal.form.docName')}</p>
      <p className={ styles.text }>{renderDocumentName()}</p>
      <p className={ styles.title }>{t('procedureBox.actions.prepareDocument.modal.form.label')}</p>
      <Markup content={props.content} />
    </div>
  );
}
