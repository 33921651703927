import { CKEditor } from 'ckeditor4-react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { t } from 'i18next';
import { DocumentVariables } from '../../../@types/createDocument';

import styles from './styles.module.scss';
import { Label } from '../../Label';
import { Tooltip } from '../../Tooltip';

type CkeditorProps = {
  variables: DocumentVariables[];
  document?: string;
  displayAlert?: (display: boolean, type: 'info' | 'success' | 'error') => void;
  onChangeText: (text: string) => void;
};

export function CkEditor(props: CkeditorProps) {
  const ckeditorConfig = {
    extraPlugins: 'mentions',
    mentions: [
      {
        feed: props.variables.map((item) => {
          return item.name;
        }),
        minChars: 0,
        outputTemplate: (mention: DocumentVariables) => {
          const content = props.variables.find((i) => i.name === mention.name.replace('@', ''));
          if (content) {
            return content.fullName.replace('@', '');
          }
          return '';
        },
      },
    ],
  };

  const handleEditorChange = (e: any) => {
    props.onChangeText(e.editor.getData());
  };

  return (
    <div className={styles.container}>
      <div className={ `${styles.header}` }>
        <Label text={t('procedureBox.actions.prepareDocument.modal.form.label')} required />
        <Tooltip title={t('procedureBox.actions.prepareDocument.modal.form.tooltip.variables')}>
          <FaRegQuestionCircle />
        </Tooltip>
      </div>
      <div className={styles.content}>
        <CKEditor
          config={ckeditorConfig}
          editorUrl="public/ckeditor/ckeditor.js"
          initData={props.document || ''}
          name="myeditor"
          readOnly={false}
          type="classic"
          onChange={handleEditorChange}
        />
      </div>
    </div>
  );
}
