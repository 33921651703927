import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { SelectedProcedures } from '../SelectedProcedures';
import { OrderData } from './OrderData';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../services/procedureBoxService';
import {
  PaymentOrderData, PaymentOrderDocument, paymentOrderInitialState,
} from '../../@types/paymentOrder';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { Batch } from '../../@types/backgroundActions';
import { Header } from '../Header';

type PaymentOrderProps = {
  paymentOrderObject?: PaymentOrderDocument;
};

export function PaymentOrder(props: PaymentOrderProps) {
  const { proceduresSeleted, setAlert, setModal } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [paymentOrderObject, setPaymentOrderObject] = useState<PaymentOrderDocument>(props.paymentOrderObject || paymentOrderInitialState);
  const [beneficiariesData, setBeneficiariesData] = useState();
  const [dependencies, setDependencies] = useState({});
  const [parentProcedure, setParentProcedure] = useState(0);

  const getDependencies = async (id: number) => {
    ProcedureBoxService.getDependenciesPaymentOrders(id).then((dep) => {
      setDependencies(dep);
    });
  };

  const createSubProcess = async () => {
    const subprocedure = {
      parent_procedure: proceduresSeleted[0].id,
      flux_id: 61,
      number_process: proceduresSeleted[0].process_number,
    };
    ProcedureBoxService.startSubProcedure(subprocedure).then((response) => {
      setParentProcedure(response.id);
      getDependencies(response.id);
    });
  };

  const parseOrderType = (orderType: string | undefined) => {
    if (orderType) {
      return Number(orderType);
    }
    return 0;
  };

  const sendPaymentRequest = (paymentOrderItem: PaymentOrderDocument, nextStep: string) => {
    const procedures = proceduresSeleted;
    const id = (Math.random() + 1).toString(36).substring(1);

    const paymentOrder = {
      procedureId: paymentOrderItem.paymentOrderData?.parentProcedureId,
      deadline: paymentOrderItem.paymentOrderData?.deadline,
      paymentOrderData: {
        order_type: parseOrderType(paymentOrderItem.paymentOrderData?.orderType),
        aware_date: paymentOrderItem.paymentOrderData?.acknowledgmentDate,
        court_id: paymentOrderItem.paymentOrderData?.court.value,
        payment_order_interested_parts_attributes: paymentOrderItem.beneficiariesData?.applicantsToSend,
      },
    };

    const body = {
      paymentOrderObject: paymentOrderItem,
      paymentOrder,
      nextStep,
    };

    const batch: Batch = {
      body,
      id,
      procedures,
      type: 'paymentOrder',
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
    };
    addBatch(batch);
    setAlert(undefined);
    setModal(undefined);
  };

  const handleAlert = (paymentOrderItem: PaymentOrderDocument, nextStep: string) => {
    setAlert({
      visible: true,
      handleConfirm: () => sendPaymentRequest(paymentOrderItem, nextStep),
      title: t('procedureBox.actions.paymentOrder.modal.alertConfirmation.title', { processNumber: proceduresSeleted[0].process_number }),
      text: t('procedureBox.actions.paymentOrder.modal.alertConfirmation.text'),
      confirmText: t('general.yes'),
      cancelText: t('general.no'),
    });
  };

  useEffect(() => {
    if (!props.paymentOrderObject) {
      createSubProcess();
    } else {
      const id = props.paymentOrderObject.paymentOrderData?.parentProcedureId;
      if (id !== undefined) {
        setParentProcedure(id);
        getDependencies(id);
      }
    }
  }, []);

  const handleOrderData = (values: PaymentOrderData, nextStep: string) => {
    const paymentOrderItem = { ...paymentOrderObject, paymentOrderData: values, beneficiariesData };
    setPaymentOrderObject(paymentOrderItem);
    handleAlert(paymentOrderItem, nextStep);
  };

  return (
    <div className={styles.container}>
      <SelectedProcedures />
      <div className={styles.content}>
        <div className={styles.header}>
          <Header text={t('procedureBox.actions.paymentOrder.modal.tabs.paymentOrder.title')}/>
        </div>
        <OrderData
          content={paymentOrderObject.paymentOrderData}
          dependencies={dependencies}
          parentProcedureId={parentProcedure}
          paymentOrderObject={paymentOrderObject}
          nextStep={(values, nextStep) => handleOrderData(values, nextStep)}
          setBeneficiariesData={(val) => setBeneficiariesData(val)}
        />
      </div>
    </div>
  );
}
