import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Flux, Procedure as ProcedureType } from '../../@types/model';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Procedure } from './Procedure';
import styles from './styles.module.scss';

type SelectedProceduresProps = {
  procedures?: ProcedureType[];
};

export function SelectedProcedures({ procedures }: SelectedProceduresProps) {
  const { proceduresSeleted, removeProcedures } = useContext(ProcedureActionsContext);
  const [fluxesOpen, setFluxesOpen] = useState<number[]>([]);
  const proceduresList = procedures || proceduresSeleted;

  const handleCollapseFlux = (id: number) => {
    if (fluxesOpen.includes(id)) {
      setFluxesOpen(fluxesOpen.filter((e) => e !== id));
    } else {
      setFluxesOpen((old) => [...old, id]);
    }
  };

  const removeAllFlux = (id: number) => {
    const proceduresFlux = proceduresList.filter((e) => e.flux.id === id);
    removeProcedures(proceduresFlux);
  };

  const renderContent = () => {
    if (proceduresList.length === 1) {
      return (
        <div className={styles.header}>
          <span className={styles.title}>
            <Trans
              i18nKey='procedureBox.actions.tramit.modal.selectedProcedure'
              components={{ bold: <strong /> }}
              values={{ number: proceduresList[0].process_number, flux: proceduresList[0].flux.name }}
            />
          </span>
        </div>
      );
    }
    if (proceduresList.length > 1) {
      const fluxes: Flux[] = [];
      proceduresList.map((procedure) => {
        if (fluxes.filter((e) => e.id === procedure.flux.id).length === 0) {
          fluxes.push(procedure.flux);
        }
      });
      return (
        fluxes.map((flux) => (
          <div className={styles.content}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12 }}
              className={styles.header}
            >
              <Grid
                item
                xs={12} sm={10} md={10}
              >
                <span className={styles.title}>
                  <Trans
                    i18nKey='procedureBox.actions.tramit.modal.selectedManyProcedures'
                    components={{ bold: <strong /> }}
                    values={{ number: proceduresList.filter((e) => e.flux.id === flux.id).length, flux: flux.name }}
                  />
                </span>
              </Grid>
              <Grid
                item
                xs={12} sm={2} md={2}
                className={styles.actions}
              >
                <div>
                  { (fluxes.length > 1 && !procedures) ? <a className={styles.removeAll} onClick={() => removeAllFlux(flux.id)}>{t('procedureBox.actions.tramit.modal.unselect')}</a> : null}
                </div>
                <a onClick={() => handleCollapseFlux(flux.id)}>
                  {fluxesOpen.includes(flux.id) ? <FaChevronUp className={styles.arrow}/> : <FaChevronDown className={styles.arrow}/>}
                </a>
              </Grid>

            </Grid>
              {fluxesOpen.includes(flux.id) ? (
                <div className={styles.proceduresPills}>
                  {proceduresList.filter((e) => e.flux.id === flux.id).map((procedure) => (
                    <Procedure procedure={procedure} link={!!procedures} />
                  ))}
                </div>) : null}
          </div>
        ))
      );
    }
    return (
      <div></div>
    );
  };

  return (
    <div className={ styles.container }>
      { renderContent() }
    </div>
  );
}
