import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { Header } from '../../Header';
import Select from '../../Select';
import { TextArea } from '../../TextArea';
import { Button } from '../../Button';
import { OptionType } from '../../../@types/config';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';

type FormProps = {
  submit: (flux: OptionType, observation: string) => void,
  flux?: OptionType,
  observation?: string;
};

export function Form(props: FormProps) {
  const { proceduresSeleted, setAlert } = useContext(ProcedureActionsContext);
  const [observation, setObservation] = useState('');
  const [fluxes, setFluxes] = useState([]);
  const [flux, setFlux] = useState<any>();

  const handleFlux = (selectedFlux: OptionType | null) => {
    if (selectedFlux) {
      setFlux(selectedFlux);
    }
  };

  const getAlertTitle = () => {
    if (proceduresSeleted.length > 1) {
      return <Trans
        i18nKey='procedureBox.actions.changeFlux.modal.alert.pluralTitle'
        values={{ procedureLength: proceduresSeleted.length }}
      />;
    }
    return t('procedureBox.actions.changeFlux.modal.alert.singleTitle');
  };

  const getAlertText = () => {
    return <Trans
      i18nKey='procedureBox.actions.changeFlux.modal.alert.text'
      components={{ b: <b />, br: <br/> }}
      values={{ fluxName: flux?.label }}
    />;
  };

  useEffect(() => {
    ProcedureBoxService.getFluxes().then((e: any) => {
      setFluxes(e.map((f: any) => { return { label: f.name, value: f.id }; }));
    });
    if (props.flux !== undefined) {
      setFlux(props.flux);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header
          text={t('procedureBox.actions.changeFlux.modal.form.title')}
        />
      </div>
      <Grid
        container
        columns={{ xs: 1, sm: 2, md: 2 }}
        className={styles.content}
        spacing={2}
      >
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Select
            options={fluxes}
            label={t('procedureBox.actions.changeFlux.modal.form.newFlux')}
            placeholder={t('procedureBox.actions.changeFlux.modal.form.newFluxPlaceholder')}
            value={flux}
            onChange={handleFlux}
            required
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={2}
          md={2}
          className={styles.observation}
        >
          <TextArea
            label={t('procedureBox.actions.changeFlux.modal.form.observation')}
            placeholder={t('procedureBox.actions.changeFlux.modal.form.observationPlaceholder')}
            value={props.observation}
            onChange={ (event) => setObservation(event.target.value) }
            required
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={2}
          md={2}
          className={styles.submit}
        >
          <Button
            title={t('procedureBox.actions.changeFlux.modal.form.submit')}
            textCenter
            round
            disabled={flux === undefined || observation === ''}
            buttonType='primary'
            size='flat'
            onClick={() => setAlert({
              handleConfirm: () => props.submit && props.submit(flux, observation),
              visible: true,
              title: getAlertTitle(),
              text: getAlertText(),
              type: 'warning',
            })}
          />
        </Grid>
      </Grid>
    </div>
  );
}
