import { useEffect } from 'react';
import { EPGM_URL } from '../../../../config/constants';
import { IFrame } from '../../../../components/IFrame';
import { loadTitle } from '../../../../helpers/changeTitle';

type NewStatementResponsibilityProps = {
  title?: string;
};

export function NewStatementResponsibility(props: NewStatementResponsibilityProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/divida_ativa/declaracao_de_responsabilidade_divida/criar`}/>
  );
}
