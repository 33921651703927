import { useEffect } from 'react';
import { IFrame } from '../../../components/IFrame';
import { EPGM_URL } from '../../../config/constants';
import { loadTitle } from '../../../helpers/changeTitle';

type FluxesManagementProps = {
  title?: string;
};

export function FluxesManagement(props: FluxesManagementProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/fluxo_tarefas`}/>
  );
}
