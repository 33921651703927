import { Skeleton } from '@mui/material';

type Variant = 'text' | 'rectangular' | 'circular';

type LoadingProps = {
  variant: Variant;
  styles: string;
};

export function Loading(props: LoadingProps) {
  return (
    <Skeleton variant={props.variant} className={ props.styles } />
  );
}
